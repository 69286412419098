import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {
    
    messageText: any;
    @Input() set textMessage(value: string) {
        this.messageText = value;
    }

    constructor() { }

    ngOnInit() {
    }

}
