import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatChipInputEvent, MatChipList } from '@angular/material';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { ValidateHelper } from 'src/app-core/helper/vailidate-helper';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-email-chip-list',
    templateUrl: './email-chip-list.component.html',
    styleUrls: ['./email-chip-list.component.scss']
})

export class EmailChipListComponent implements OnInit {

    @ViewChild('emailChipList', { static: true }) emailChipList: MatChipList;
    @Output() emailListEvent = new EventEmitter<any>();
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    emailList: string[] = [];
    showEmailErrorMessage = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(emailTagList: any) {
        this.emailList = emailTagList;
    }

    @Input() set showErrorMessage(data: boolean) {
        this.showEmailErrorMessage = data;
    }

    constructor(
        private customToastrService: CustomToastrService) {
    }

    ngOnInit() {
    }

    onAddEmail(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if (value) {
            if (ValidateHelper.validateEmail(value.trim())) {
                if (this.emailList) {
                    if ((value || '').trim()) {
                        const index = this.emailList.indexOf(value.trim());
                        if (index === -1) {
                            this.emailList.push(value.trim());
                            this.emailListEvent.emit(this.emailList);
                        }
                    }
                } else {
                    this.customToastrService.error(CommonConstants.appConstant.emailCountErrorMessage + this.emailList.length);
                }
            } else {
                this.customToastrService.error(CommonConstants.appConstant.invalidEmailErrorMessage);
                input.value = '';
            }
        }
        if (input) {
            input.value = '';
        }
    }

    onRemoveEmail(email: string) {
        const index = this.emailList.indexOf(email);
        if (index >= 0) {
            this.emailList.splice(index, 1);
            this.emailListEvent.emit(this.emailList);
        }
    }

}
