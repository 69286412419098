import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-invoice-comments',
    templateUrl: './invoice-comments.component.html',
    styleUrls: ['./invoice-comments.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceCommentsComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    invoiceCommentsFormModel = this.commonDataModel.invoiceCommentsFormModel;
    isLoading = false;
    invoiceId: string;
    showCommentsForm = true;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set invoiceData(data: any) {
        if (data) {
            this.invoiceId = data;
            this.loadItems();
        }
    }

    constructor(
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
        this.invoiceCommentsFormModel.ownerId = this.commonService.getOwnerId();
    }

    ngOnInit() {
    }

    loadItems(): void {
        this.isLoading = true;
        this.commonService.getComments(this.invoiceId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                if (response.results) {
                    this.commonService.objectMapping(this.invoiceCommentsFormModel, response.results);
                    if (response.results.comments) {
                        this.showCommentsForm = false;
                    } else {
                        this.showCommentsForm = true;
                    }
                } else {
                    this.showCommentsForm = true;
                    this.invoiceCommentsFormModel.invoiceId = this.invoiceId;
                }
            });
    }

    onSaveComments(): void {
        this.isLoading = true;
        this.commonService.postComments(this.invoiceCommentsFormModel)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                const message = this.invoiceCommentsFormModel.id ?
                    CommonConstants.invoiceForm.commentsFormUpdateMessage : CommonConstants.invoiceForm.commentsFormAddMessage;
                this.customToastrService.success(message);
            });
    }

    onEditForm(): void {
        this.showCommentsForm = true;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
