export class MaintenanceConstants {

    static readonly superAdmin = 'super admin';

    static readonly locationTypes = {
        customer: 'Customer',
        vendor: 'Vendor',
        facility: 'Facility',
        billTo: 'Bill To'
    };

    static readonly ownerSettingName = {
        setEmailAddress: 'SetEmailAddress'
    };

    static readonly eproForm = {
        addFormTitle: 'Add ePro Settings',
        editFormTitle: 'Edit ePro Settings',
        addFormSuccessMessage: 'Successfully added ePro Settings.',
        updateFormSuccessMessage: 'Successfully updated ePro Settings.',
        primarySequenceErrorMessage: 'Primary sequence `To` should be greater than Primary sequence `From`',
        secondarySequenceToErrorMessage: 'Secondary sequence `To` should be greater than secondary sequence `From`',
        secondarySequenceOverlapMessage: 'Secondary Sequence should not overlap with Primary sequence.'
    };

    static readonly itemForm = {
        updateFormSuccessMessage: 'Item updated successfully.',
    };

    static readonly locationForm = {
        removedContactMessage: 'Contact has been removed.',
    };

    static readonly enterpriseForm = {
        APIKeySuccessMessage: 'API Key is generated. Please click on Submit to save your changes.',
        APIKeyErrorMessage: 'API Key already generated.',
        APIKeyDeletedMessage: 'API Key is deleted. Please click on Submit to save your changes.',
        APIKeyNotSavedMessage: 'API Key is not Saved. Please click on Submit to save the changes.',
        permissionSuccessMessage: 'Permission is selected. Please click on Generate API Key.',
        permissionErrorMessage: 'Permission is not selected. Please select the permission.',
        updateFormSuccessMessage: 'Enterprise details updated successfully.',
    };

    static readonly referenceSettingsForm = {
        addFormSuccessMessage: 'Reference settings added successfully.',
        updateFormSuccessMessage: 'Reference settings updated successfully.'
    };

    static readonly referenceTypeForm = {
        addFormSuccessMessage: 'Reference Type added successfully.',
        updateFormSuccessMessage: 'Reference Type updated successfully.'
    };

    static readonly roleForm = {
        addRoleFormSuccessMessage: 'Role added successfully.',
        updateRoleFormSuccessMessage: 'Role updated successfully.',
        addPermissionFormSuccessMessage: 'Permission added successfully.',
        updatePermissionFormSuccessMessage: 'Permission updated successfully.',
        permissionFormErrorMessage: 'Please select any permission.'
    };

    static readonly settingsForm = {
        XML: 'xml',
        Int: 'int',
        color: 'color',
        boolean: 'bool',
        string: 'string',
        timeSpan: 'timespan',
        textArea: 'textarea',
        addFormSuccessMessage: 'Settings added successfully.',
        updateFormSuccessMessage: 'Settings updated successfully.'
    };

    static readonly ssoBIForm = {
        updateFormSuccessMessage: 'BI Settings saved successfully.',
        deleteFormSuccessMessage: 'Settings deleted successfully.',
        deleteSingleFormSuccessMessage: 'Setting deleted successfully.',
        deletePopupTitle: 'Do you want to proceed with the deletion of selected Power BI Setting(s)?'
    };

    static readonly blockedIPs = {
        updateFormSuccessMessage: 'Records updated successfully.',
        deletePopupTitle: 'Do you want to proceed with the unblocking of selected IP(s)?'
    };

    static readonly userForm = {
        phone: 'phone',
        active: 'active',
        updateFormSuccessMessage: 'User updated successfully.',
        updateFormErrorMessage: 'Super Admin role cannot be combined with any other role.',
        userStatusWarningMessage: 'Logged in user cannot be inactivated.',
        updateNotifyWarningMessage: 'records failed please try again.',
        updateNotifySuccessMessage: ' records updated successfully.'
    };

    static readonly whiteLabelForm = {
        uploadFileError: 'Please choose a file.',
        updateFormSuccessMessage: 'White Labeling settings saved successfully.',
        deletePopupTitle: 'Are you sure you want to proceed with the delete of item entry?',
        deleteErrorMessage: 'Default root owner settings cannot be deleted.',
        deleteUpdateErrorMessage: 'Default root owner settings will not be deleted.',
        deleteFormSuccessMessage: 'White Labeling setting deleted successfully.'
    };

    static readonly commonForm = {
        invalidFileFormat: 'Invalid file format.',
        fileSizeMessage: 'File size is more than 26 MB.'
    };

    static readonly carrierForm = {
        addFormSuccessMessage: 'Carrier added successfully.',
        updateFormSuccessMessage: 'Carrier updated successfully.'
    };

}
