import { Component, Input, OnInit } from '@angular/core';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../../common-data-model';
import * as moment from 'moment';

@Component({
    selector: 'app-items-info',
    templateUrl: './items-info.component.html'
})

export class ItemsInfoComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    commonConstants: typeof CommonConstants = CommonConstants;
    truckloadModule = this.commonConstants.moduleName.scheduleTruckload;
    trackingItemCalculationModel = Object.assign({}, this.commonDataModel.trackingItemCalculationModel);
    moduleName: any;
    itemsList: Array<object> = [];
    trackingDetails = false;
    shippingModeName: any;
    shipmentDetails: any;
    totalValue: any = 0;

    @Input() set childData(items: any) {
        this.itemsList = items;
    }

    @Input() set shipmentData(items: any) {
        this.shipmentDetails = items;
        this.totalValue = 0;
        this.shipmentDetails.commodities.map((data: any) => {
            if (this.shipmentDetails.carrierInfo && this.shipmentDetails.carrierInfo.insuranceCost && data.value) {
                this.totalValue = this.totalValue + parseInt(data.value);
            }

            const pickUpStopId = this.shipmentDetails.shipmentStops.findIndex((item: any) => item.shipmentStopId == data.pickupStopId);
            if (pickUpStopId != -1) {
                data.pickUpCity = this.shipmentDetails.shipmentStops[pickUpStopId].location.city;
                data.pickUpStateName = this.shipmentDetails.shipmentStops[pickUpStopId].location.stateName;
            } else {
                data.pickUpCity = null;
                data.pickUpStateName = null;
            }

            const dropStopId = this.shipmentDetails.shipmentStops.findIndex((item: any) => item.shipmentStopId == data.dropStopId);
            if (dropStopId != -1) {
                data.dropCity = this.shipmentDetails.shipmentStops[dropStopId].location.city;
                data.dropStateName = this.shipmentDetails.shipmentStops[dropStopId].location.stateName;
            } else {
                data.dropCity = null;
                data.dropStateName = null;
            }
        })
    }


    @Input() set calculationData(data: any) {
        this.trackingItemCalculationModel = data;
    }

    @Input() set moduleNameValue(data: any) {
        this.moduleName = data;
    }

    @Input() set checkPageName(data: any) {
        this.trackingDetails = data;
    }

    @Input() set shippingMode(name: any) {
        if (name) {
            this.shippingModeName = name;
        }
    }

    constructor(
        private commonService: CommonService) {
    }

    ngOnInit() {
    }

    checkModuleName() {
        return this.commonService.checkModuleNameValue(this.moduleName);
    }

    checkShippingMode() {
        return this.shippingModeName != this.commonConstants.moduleName.parcel ? true : false;
    }

    getBgColor() {
        return '#c8efd0';
    }

    getDates(dateValue: any) {
        if (dateValue) {
            const date = new Date(dateValue);
            const shipmentDate = moment(date).format("MM/DD/YY hh:mm");
            return shipmentDate;
        } else {
            return '';
        }
    }

    getMoreInformation(data: any, event: any): string {
        if (event == 'DROP') {
            if (data.dropActualDate) {
                return `Delivered: ${this.getDates(data.dropActualDate)} \n ${data.dropCity ?data.dropCity : ''}  ${data.dropStateName ? ', ' + data.dropStateName : ''}`;
            } else { 
                return `Delivered: Awaiting Delivery \n ${data.dropCity ?data.dropCity : ''}  ${data.dropStateName ? ', ' + data.dropStateName : ''}`;
            }
        } else {
            if (data.pickupActualDate) {
                return `Picked up: ${this.getDates(data.pickupActualDate)} \n ${data.pickUpCity ?data.pickUpCity : ''}  ${data.pickUpStateName ? ', ' + data.pickUpStateName : ''}`;
            } else {
                return `Picked up: Awaiting Pickup \n ${data.pickUpCity ? data.pickUpCity : ''} ${data.pickUpStateName ? ', ' + data.pickUpStateName : ''}`;
            }
        }
    }

}
