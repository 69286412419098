import { CommonConstants } from 'src/app-core/constants/common-constants';

export class MaintenanceDataModel {

    public gridEvents = {
        skipCount: null,
        results: null,
        orderBy: null,
        sortOrder: null,
        filterFields: null
    };

    public gridSettingsModel = {
        definition: {
            id: null,
            definitionId: null,
            userId: null,
            definitionName: null,
            definitionShortName: null,
            gridName: null,
            sortByColumn: null,
            sortByAscending: true,
            allowToDelete: false,
            allowToEdit: false,
            groups: [],
            columns: [],
            filters: [],
            isDefault: false,
            isPublic: false,
            isActive: true,
            tenantId: null
        },
        ownerId: null,
        associatedOwnerId: null,
        isActive: true,
        userId: null,
        skipCount: null,
        results: null,
        IsChildHierarchy: true
    };

    public locationFormModel = {
        id: null,
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        locationTypeId: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: CommonConstants.defaultCountryCode,
        ownerId: null,
        ownerName: null,
        latitude: 0,
        longitude: 0,
        contacts: [],
        pickupNotes: null,
        deliveryNotes: null,
        shippingStartTime: null,
        shippingEndTime: null,
        receivingStartTime: null,
        receivingEndTime: null,
        loggedInOwnerId: null,
        isResidential: null,
        shipInstructions: null,
        receiveInstructions: null,
        senderServices: [],
        receiveServices: [],
        defaultSenderServices: [],
        defaultReceiveServices: [],
        searchDefaultShipperService: null,
        searchDefaultConsigneeService: null,
        searchShipperService: null,
        searchConsigneeService: null,
    };

    public contactListModel = {
        contactName: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        isDefault: false
    };

    public itemFormModel = {
        tmsItemId: null,
        id: null,
        itemId: null,
        description: null,
        uN_NA: null,
        freightClass: null,
        isDensityBased: false,
        minPCF: null,
        maxPCF: null,
        isLengthBased: false,
        minLength: null,
        maxLength: null,
        packageType: null,
        isHazmat: false,
        hazMatClass: null,
        hazMatGroup: null,
        hazMatShipping: null,
        hazMatContactName: null,
        hazMatContactPhone: null,
        nmfC_Code: null,
        width: null,
        height: null,
        weight: null,
        length: null,
        value: null,
        pieces: null,
        quantity: null,
        ownerId: null,
        ownerName: null,
        commodityId: null,
        commodityCategory: null,
    };

    public settingsFormModel = {
        id: null,
        fieldLength: null,
        fieldId: null,
        fieldType: null,
        fieldName: null,
        fieldDescription: null,
        required: true,
        value: null,
        order: 0,
        isVisible: true,
        isHighlighted: true,
        ownerId: null,
        ownerName: null,
        settingId: null,
        userId: null
    };

    public referenceTypeFormModel = {
        code: null,
        ediCode: null,
        description: null
    };

    public referenceSettingsFormModel = {
        userId: null,
        ownerId: null,
        ownerName: null,
        referenceTypeId: null,
        definitionXml: null,
        editable: false
    };

    public userFormModel = {
        ownerId: null,
        ownerName: null,
        userId: null,
        userName: null,
        firstName: null,
        lastName: null,
        phone: null,
        displayName: null,
        email: null,
        cellPhone: null,
        cellPhoneProvider: null,
        lastLogin: null,
        isActive: false,
        notifyUser: false,
        passwordExpires: null,
        roleIds: null,
        roleList: [],
        vrShipLocationCode: null
    };

    public roleFormModel = {
        roleId: null,
        roleName: null,
        description: null,
        isDefault: null,
        userId: null
    };

    public permissionFormModel = {
        roleId: null,
        permissionIds: null
    };

    public enterpriseFormModel = {
        id: null,
        ownerId: null,
        parentId: null,
        parentOwnerId: null,
        enterpriseName: null,
        customerAccountNumber: null,
        parentEnterpriseName: null,
        ownerType: null,
        salesRep: null,
        apiKey: null,
        newAPIKey: null,
        apiKeyActive: null,
        deletedApiKey: null,
        services: [],
        restrictScheduling: false
    };

    public eproSettingsFormModel = {
        id: null,
        eproSettingsId: null,
        ownerId: null,
        ownerName: null,
        enterpriseName: null,
        carrierId: null,
        ownerLocationId: null,
        prefix: null,
        checkDigitLogic: null,
        primarySequenceFrom: null,
        primarySequenceTo: null,
        primarySequenceFromJson: null,
        primarySequenceToJson: null,
        secondarySequenceFrom: null,
        secondarySequenceTo: null,
        secondarySequenceFromJson: null,
        secondarySequenceToJson: null,
        usedValue: null,
        isExhausted: false,
        emails: [],
        isActive: null
    };

    public ssoBIFormModel = {
        id: null,
        ownerName: null,
        ownerId: null,
        ssoId: null,
        defaultDashboardId: null
    };

    public BISettingFormModel = {
        id: null,
        ownerName: null,
        ownerId: null,
        userEmail: null,
        notes: null
    };

    public whiteLabelingFormModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        contactInfo: null,
        address1: null,
        address2: null,
        zipCode: null,
        country: null,
        stateName: null,
        city: null,
        phone: null,
        extension: null,
        email: null,
        fax: null,
        filePath: null,
        fileType: null,
        fileName: null,
        isNewFile: false
    };

    public locationCodeModel = {
        ownerId: null,
        LocationCode: null
    };

    public carrierFormModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        carrierId: null,
        carrierName: null,
        scac: null,
        mcNumber: null,
        trackingLink: null,
        logoFile: null,
        carrierLogoLink: null,
        fileName: null
    };

    public userItemMappingModel = {
        userId: null,
        itemIdList: []
    };

}

export class Locations {
    locationId: number;
    locationCode: string;
}
