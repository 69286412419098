import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { TrackingDetailsTabEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { LoaderService } from 'src/app-core/services/loader.service';
import { CommonDataModel } from '../common-data-model';
import * as moment from 'moment';

@Component({
    selector: 'app-tracking-map-view-popup',
    templateUrl: './tracking-map-view-popup.component.html',
    styleUrls: ['./tracking-map-view-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrackingMapViewPopupComponent implements OnChanges, OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    trackingDetailsModel = this.commonDataModel.trackingDetailsModel;
    trackingItemCalculationModel = Object.assign({}, this.commonDataModel.trackingItemCalculationModel);
    invoicePermissionModel = this.commonDataModel.invoicePermissionModel;
    selectedIndex = 0;
    showCost = false;
    moduleName: string;
    shipmentTypeValue: string;
    publicTrackingUrl: string;
    trackingDetailsPage = false;
    showAlertService = true;
    showAlertServiceForm = true;
    pageName = CommonConstants.pageName.trackingMapView;
    truckloadModule = CommonConstants.moduleName.scheduleTruckload;
    shippingMode: any;
    trackingDetailsTabEnum: typeof TrackingDetailsTabEnum = TrackingDetailsTabEnum;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(detailsListModel: any) {
        this.trackingDetailsModel = detailsListModel;
        if (this.trackingDetailsModel.trackingNumberLink && this.trackingDetailsModel.trackingNumberLink.trackingUrl) {
            this.trackingDetailsModel.carrierTrackingLink = this.trackingDetailsModel.trackingNumberLink.trackingUrl
        }
        this.shipmentTypeValue = detailsListModel.moduleName;
        this.shippingMode = detailsListModel.shipmentMode;
        this.commonService.objectMapping(this.trackingItemCalculationModel, detailsListModel);
        if (this.trackingDetailsModel && this.trackingDetailsModel.carrierInfo) {
            this.showCost = this.trackingDetailsModel.carrierInfo.vrShowCost;
        }

        this.trackingDetailsModel.commodities.map((data: any) => {
            const pickUpStopId = this.trackingDetailsModel.shipmentStops.findIndex((item: any) => item.shipmentStopId == data.pickupStopId);
            if (pickUpStopId != -1) {
                data.pickUpCity = this.trackingDetailsModel.shipmentStops[pickUpStopId].location.city;
                data.pickUpStateName = this.trackingDetailsModel.shipmentStops[pickUpStopId].location.stateName;
            } else {
                data.pickUpCity = null;
                data.pickUpStateName = null;
            }

            const dropStopId = this.trackingDetailsModel.shipmentStops.findIndex((item: any) => item.shipmentStopId == data.dropStopId);
            if (dropStopId != -1) {
                data.dropCity = this.trackingDetailsModel.shipmentStops[dropStopId].location.city;
                data.dropStateName = this.trackingDetailsModel.shipmentStops[dropStopId].location.stateName;
            } else {
                data.dropCity = null;
                data.dropStateName = null;
            }
        })
    }

    @Input() set moduleNameValue(data: string) {
        this.moduleName = data;
    }

    constructor(
        private router: Router,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private loaderService: LoaderService) {
        this.invoicePermissionModel = this.commonService.permissionChecker(this.invoicePermissionModel);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            if (changes.childData && this.trackingDetailsModel && this.trackingDetailsModel.shipmentId) {
                this.publicTrackingUrl = this.commonService.getPublicTrackingUrl(this.trackingDetailsModel.shipmentId);
            }
        }
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.getTabIndexValue.subscribe(data => {
            this.selectedIndex = this.selectedIndex - 1;
        }));
    }

    receiveDocResponse($event: any) {
        this.loaderService.hide();
    }

    onTabChanged({ index, tab }) {
        if (tab.textLabel === 'Invoice') {
            switch (this.moduleName) {
                case CommonConstants.moduleName.shipmentTracking:
                    this.router.navigateByUrl('app/tracking/tracking-details/' +
                        this.trackingDetailsModel.shipmentId + '?moduleName=' + CommonConstants.moduleName.shipmentTracking);
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.router.navigateByUrl('app/ltl-vendor/tracking-details/' +
                        this.trackingDetailsModel.shipmentId + '?moduleName=' + CommonConstants.moduleName.LTLVendorBooking);
                    break;
                case CommonConstants.moduleName.TLVendorBooking:
                    this.router.navigateByUrl('app/tl-vendor/tracking-details/' +
                        this.trackingDetailsModel.shipmentId + '?moduleName=' + CommonConstants.moduleName.TLVendorBooking);
                    break;
            }
            this.commonService.sendTabIndexValue(TrackingDetailsTabEnum.invoiceTab);
        }
    }

    onShowCarrierTrackingLink(dataItem: any) {
        dataItem['mode'] = dataItem.shipmentMode;
        return this.commonMethod.showCarrierTrackingLink(dataItem);
    }

    onTrackNavigation(dataItem: any) {
        if (dataItem.carrierInfo) {
            const proValue = dataItem.carrierInfo.pro ? dataItem.carrierInfo.pro : '0';
            this.commonMethod.copyClipboard(proValue);
        }
    }

    onSetTrackingLink(dataItem: any) {
        return this.commonMethod.mapCarrierTrackingLink(dataItem);
    }

    checkParcelShipment() {
        return this.shippingMode === this.commonConstants.moduleName.parcel ? false : true;
    }

    getEventType(stopObj: any) {
        let url = '';
        switch (stopObj.eventType) {
            case 2:
                url = stopObj.actual ? 'assets/images/dropEvent.svg' : 'assets/images/drop.svg';
                break;
            default:
                url = stopObj.actual ? 'assets/images/pickupEvent.svg' : 'assets/images/pickup.svg';
                break;
        }

        return url;
    }

    getDates(dateValue: any) {
        if (dateValue) {
            const date = new Date(dateValue);
            const shipmentDate = moment(date).format("MM/DD/YY hh:mm");
            return shipmentDate;
        } else {
            return '';
        }
    }

    addMargin(length: any) {
        if (length > 2) {
            return true;
        } else {
            return false;
        }
    }

    getAddress(stop: any) {
        return `${stop.address1 ? stop.address1 : ''}${stop.city ? ',' : ''} ${stop.city ? stop.city : ''}${stop.stateName ? ',' : ''} ${stop.stateName ? stop.stateName : ''}${stop.zipCode ? ',' : ''} ${stop.zipCode ? stop.zipCode : ''}`;
    }

    getReferences(stop: any, last: boolean) {
        if (stop.value) {
            return `${stop.type}:${stop.value}${!last ? ',' : ''}`;
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
