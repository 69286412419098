import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { EnterpriseSearchLengthEnum } from 'src/app-core/enums/common-enum';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-tree-dropdown',
    templateUrl: './tree-dropdown.component.html',
    styleUrls: ['./tree-dropdown.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    encapsulation: ViewEncapsulation.None
})

export class TreeDropdownComponent implements OnInit {

    @ViewChild('searchBox', { static: true }) private searchBox: ElementRef;
    commonDataModel = new CommonDataModel();
    selectedEnterprisePopupModel = this.commonDataModel.selectedEnterprisePopupModel;
    treeData: any[];
    selectedKeys: any[] = [];
    data: any[];
    expandedKeys: any[] = [];
    expandedList: any;
    ownerId: string;
    showTree = false;
    @Input() set childData(ownerId: string) {
        if (ownerId) {
            this.ownerId = ownerId;
            this.loadItems();
        }
    }
    @Input() set ownerName(ownerName: string) {
        if (ownerName) {
            this.selectedEnterprisePopupModel.ownerName = ownerName;
        }
    }
    @Input() isRoot = false;
    @Input() labelValue: string;
    @Output() ownerEvent = new EventEmitter<any>();

    constructor(
        private cdRef: ChangeDetectorRef,
        private commonService: CommonService,
        private router: Router) { }

    ngOnInit() {
    }

    loadItems(): void {
        this.selectedKeys = [];
        this.selectedKeys.push(this.ownerId);
        if (this.router.url.includes('enterprise-configuration')) {
            this.isRoot = true;
        }
        this.commonService.getOwnerHierarchy(this.isRoot).subscribe(response => {
            this.data = new Array(response);
            this.treeData = this.data;
            this.expandedList = this.commonService.expandSelected(this.treeData, this.ownerId);
            this.expandedKeys = this.expandedList.expandedKeysList;
            this.selectedEnterprisePopupModel.accountNumber = this.expandedList.accountNumber;
            this.selectedEnterprisePopupModel.ownerName = this.expandedList.selectedOwnerName;
            this.selectedEnterprisePopupModel.ownerId = this.ownerId;
            this.ownerEvent.emit(this.selectedEnterprisePopupModel);
        });
    }

    onSearch(value: string): void {
        if (value && value.length >= EnterpriseSearchLengthEnum.value) {
            this.treeData = this.commonService.ownerSearch(this.data, value.trim());
            this.ownerEvent.emit('');
            if (this.treeData.length) {
                this.treeData.filter(list => {
                    if (list.ownerName.toUpperCase() === value.toUpperCase()) {
                        this.ownerEvent.emit(list);
                    }
                });
            }
        } else {
            this.ownerEvent.emit('');
            this.treeData = this.data;
        }
        this.cdRef.detectChanges();
        this.searchBox.nativeElement.focus();
    }

    // A function that determines whether a given node has children
    hasChildren = (item: any) => item.children && item.children.length > 0;

    // A function that returns an observable instance which contains the child nodes for a given parent node
    fetchChildren = (item: any) => of(item.children);

    onToggle(): void {
        this.showTree = !this.showTree;
    }

    onHandleSelection({ dataItem }: any): void {
        this.showTree = false;
        if (dataItem) {
            this.commonService.objectMapping(this.selectedEnterprisePopupModel, dataItem);
            this.ownerEvent.emit(this.selectedEnterprisePopupModel);
            this.cdRef.detectChanges();
        }
    }

    onClose() {
        this.showTree = false;
    }

}
