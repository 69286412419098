import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-retry-form',
    templateUrl: './shipment-retry-form.component.html',
    styleUrls: ['./shipment-retry-form.component.scss']
})

export class ShipmentRetryFormComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    shipmentRetryModel = this.commonDataModel.shipmentRetryModel;
    isLoading = true;

    constructor(
        private dialogRef: MatDialogRef<ShipmentRetryFormComponent>) {
    }

    ngOnInit() {
        this.isLoading = false;
    }

    onSubmit(): void {
        this.dialogRef.close({ responseData: this.shipmentRetryModel });
    }

    onClose(): void {
        this.dialogRef.close({ responseData: {} });
    }

}
