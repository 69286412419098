import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-custom-kendo-filter',
    templateUrl: './custom-kendo-filter.component.html'
})

export class CustomKendoFilterComponent implements OnInit {

    column: any;
    filter: any;
    filterService: any;

    @Input() set columnData(data: any) {
        this.column = data;
    }

    @Input() set filterData(data: any) {
        this.filter = data;
    }

    @Input() set filterServiceData(data: any) {
        this.filterService = data;
    }

    constructor() { }

    ngOnInit() {
    }

}
