import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { rangeValidator } from '../helper/vailidate-helper';

@Directive({
    selector: '[rangeValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RangeValidatorDirective, multi: true }]
})

export class RangeValidatorDirective implements Validator {

    @Input() fromField: string;
    @Input() toField: string;
    validate(control: AbstractControl): ValidationErrors {
        return rangeValidator(control as FormGroup, this.fromField, this.toField);
    }
}
