import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from 'src/app/shared/common-data-model';

@Component({
    selector: 'app-details-tab',
    templateUrl: './details-tab.component.html',
    styleUrls: ['./details-tab.component.scss']
})

export class DetailsTabComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    isLoading = false;
    shipmentId: string;
    publicTrackingUrl: string;
    commonDataModel = new CommonDataModel();
    moduleName: any;
    headingDetailsList = this.commonDataModel.trackingHeaderModel;
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    trackingDetailsModel = Object.assign({}, this.commonDataModel.trackingDetailsModel);
    trackingItemCalculationModel = Object.assign({}, this.commonDataModel.trackingItemCalculationModel);
    selectionList = this.commonDataModel.copySettingsModel;
    trackingDetailsPage = false;
    isEditTLVendorItems = false;
    shippingModeName: any;
    trackingNumber: any;
    mode: any;
    commonConstants: typeof CommonConstants = CommonConstants;
    @Output() getOSDStatus: EventEmitter<any> = new EventEmitter<boolean>();
    @Input() set childData(headingDetails: any) {
        this.headingDetailsList = headingDetails;
    }

    @Input() set shippingMode(name: any) {
        if (name) {
            this.shippingModeName = name;
        }
    }

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
        this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
        this.isEditTLVendorItems = this.commonService.permissionCheckByName(CommonConstants.permissionName.VR_ItemEdit);
    }

    ngOnInit() {
        this.isLoading = true;
        this.mode = this.activeRoute.snapshot.queryParamMap.get('mode');
        this.getOSDStatus.emit({ osdStatus: null, isDataLoaded: false })
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            this.loadItems();
        }));
        this.checkTrackingDetailsPage(this.activeRoute.snapshot.queryParamMap.get('moduleName'));
    }

    checkTrackingDetailsPage(pageName: string) {
        switch (pageName) {
            case this.commonConstants.moduleName.shipmentTracking:
                this.trackingDetailsPage = true;
                break;
            case this.commonConstants.moduleName.LTLVendorBooking:
                this.trackingDetailsPage = true;
                break;
            default:
                this.trackingDetailsPage = false;
                break;
        }
    }

    loadItems(): void {
        this.trackingDetailsGetModel.shipmentId = this.shipmentId;
        if (this.mode === this.commonConstants.moduleName.parcel) {
            this.commonService.getParcelTrackingDetails(this.trackingDetailsGetModel).subscribe(response => {
                this.trackingDetailsModel = Object.assign({}, this.commonDataModel.trackingDetailsModel);
                this.commonService.objectMappingNullCheck(this.trackingDetailsModel, response);
                this.commonService.objectMapping(this.headingDetailsList, response);
                this.commonService.objectMapping(this.trackingItemCalculationModel, response);
                this.moduleName = response.moduleName;
                this.trackingNumber = response.trackingNumber;
                const data = {
                    osdStatus: response.osdStatus,
                    isDataLoaded: true
                }
                this.getOSDStatus.emit(data);
                this.isLoading = false;
            });
        } else {
            this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe(response => {
                this.trackingDetailsModel = Object.assign({}, this.commonDataModel.trackingDetailsModel);
                this.commonService.objectMappingNullCheck(this.trackingDetailsModel, response);
                this.commonService.objectMapping(this.headingDetailsList, response);
                this.commonService.objectMapping(this.trackingItemCalculationModel, response);
                this.moduleName = response.moduleName;
                this.trackingNumber = response.trackingNumber;
                const data = {
                    osdStatus: response.osdStatus,
                    isDataLoaded: true
                }
                this.getOSDStatus.emit(data);
                this.isLoading = false;
            });
        }
        this.publicTrackingUrl = this.commonService.getPublicTrackingUrl(this.shipmentId);
    }

    onShowEditItem() {
        if (this.isEditTLVendorItems &&
            this.trackingDetailsModel.status &&
            this.trackingDetailsModel.commodities.length &&
            this.moduleName === CommonConstants.moduleName.TLVendorBooking &&
            this.commonService.getOwnerId() === this.trackingDetailsModel.ownerId) {
            if (this.trackingDetailsModel.status === CommonConstants.trackingStatus.Delivered ||
                this.trackingDetailsModel.status === CommonConstants.trackingStatus.Invoiced) {
                return false;
            }
            return true;
        }
        return false;
    }

    onEditItem(): void {
        this.selectionList.SN_CopyEquipmentByDefault = true;
        this.selectionList.SN_CopyItemsByDefault = true;
        this.selectionList.SN_CopyReferencesByDefault = true;
        this.selectionList.SN_CopyServicesByDefault = true;
        this.selectionList.SN_CopyEquipmentByDefault = false;
        this.commonService.setCopyShipmentNavigation(true);
        this.commonService.sendCopyShipment(this.selectionList);
        this.router.navigate(['app/tl-vendor/booking/' + this.shipmentId], {
            queryParams: {
                editItem: true
            }
        });
    }

    checkModuleName() {
        return this.commonService.checkModuleNameValue(this.moduleName);
    }

    checkParcelShipment() {
        return this.shippingModeName === this.commonConstants.moduleName.parcel ? false : true;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
