import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app-core/services/common.service';
import { AppComponentBase } from '../app-component-base';

@Component({
    selector: 'app-import-item-list',
    templateUrl: './import-item-list.component.html',
    styleUrls: ['./import-item-list.component.scss']
})
export class ImportItemListComponent extends AppComponentBase implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    pageSize = 20;
    skip = 0;
    dataList: any;
    items: Array<any> = [];
    gridData: GridDataResult;

    constructor(
        injector: Injector,
        private commonService: CommonService,
        private dialogRef: MatDialogRef<ImportItemListComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        super(injector);
        this.dataList = data;
        this.items = this.dataList.errorRecords || [];
        this.onLoadItems();
    }

    ngOnInit() {
    }

    onLoadItems(): void {
        this.gridData = {
            data: this.items.slice(this.skip, this.skip + this.pageSize),
            total: this.items.length
        };
    }

    pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.onLoadItems();
    }

    onClose(): void {
        this.commonService.sendImportResponseList(this.dataList);
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
