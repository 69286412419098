import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-documents',
    templateUrl: './shipment-documents.component.html',
    styleUrls: ['./shipment-documents.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentDocumentsComponent implements OnInit {
    commonDataModel = new CommonDataModel();
    componentSubscriptions: Subscription = new Subscription();
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    documentList: Array<any> = [];
    pageName = CommonConstants.pageName.shipmentDocument;
    truckloadModule = CommonConstants.moduleName.scheduleTruckload;
    moduleName: string;
    isTruckload = false;
    purchaseLabels: any;
    showAlertServiceForm = false;
    mode: any;
    hideFetchDocButton = true;
    tlSummary = true;
    showAlertServicesInLtl = false;
    insuredAmount: any = 0;
    constructor(
        private router: Router,
        private commonService: CommonService,
        private activeRoute: ActivatedRoute) { }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.purchaseLabels.subscribe((purchaseLabels: any) => {
            this.purchaseLabels = purchaseLabels;
        }))

        this.moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
        this.mode = this.activeRoute.snapshot.queryParamMap.get('mode');
        if (this.moduleName === this.truckloadModule) {
            this.showAlertServiceForm = true;
            this.isTruckload = true;
        } else if ((this.moduleName === CommonConstants.moduleName.scheduleShipment || this.moduleName === CommonConstants.moduleName.LTLVendorBooking) && !this.mode) {
            this.isTruckload = false;
            this.showAlertServiceForm = true;
        } else {
            this.isTruckload = false;
        }

        this.activeRoute.paramMap.subscribe((params: any) => {
            this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
            this.trackingDetailsGetModel.shipmentId = params.get('id');
            this.getShipmentDetails();
        })
    }

    receiveDocResponse(receiveDocumentList: any) {
        this.documentList = receiveDocumentList;
        if (this.mode === CommonConstants.parcelRateDropDownName.parcel) {
            this.getUrls();
        }
    }

    getUrls() {
        this.documentList.map((data, index) => {
            const documentUrl = { labelUrl: data.labelUrl, packageName: data.packageName };
            this.commonService.downloadPurchaseLabel(documentUrl)
                .subscribe(response => {
                    const blob = new Blob([response.body as any], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(blob);
                    this.documentList[index]['link'] = fileURL;
                });
        })
    }

    downloadPurchaseLabel(document: any) {
        if (document.lableUrl) {
            const documentData = { packageName: document.packageName, labelUrl: document.labelUrl };
            this.commonService.downloadAllPurchaseLabels(documentData);
        }
    }

    onloadingDocument($event: any) {
        // this.loaderService.show();
    }

    onLoadComplete($event: any) {
        // this.loaderService.hide();
    }

    onLoadError(error: any) {
        // this.customToastrService.error('Document downloaded failed!');
    }

    onNavigate(): void {
        switch (this.moduleName) {
            case CommonConstants.moduleName.scheduleShipment:
            case CommonConstants.moduleName.scheduleLTL:
                this.router.navigateByUrl('app/shipment/shipment-booking/schedule-shipment/new');
                break;
            case CommonConstants.moduleName.scheduleTruckload:
                this.router.navigateByUrl('app/shipment/truckload-booking/schedule-truckload/new');
                break;
            case CommonConstants.moduleName.LTLVendorBooking:
                this.router.navigateByUrl('app/ltl-vendor/booking/new');
                break;
            default:
                break;
        }
    }

    showBookButtonLabel() {
        if (this.moduleName === CommonConstants.moduleName.scheduleShipment) {
            return true;
        } else {
            return false;
        }
    }

    getShipmentDetails() {
        if (this.mode != CommonConstants.parcelRateDropDownName.parcel && (this.moduleName == CommonConstants.moduleName.scheduleShipment || this.moduleName == CommonConstants.moduleName.LTLVendorBooking)) {
            this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe((response: any) => {
                response.commodities.map((data: any) => {
                    if (response.carrierInfo && response.carrierInfo.insuranceCost && data.value) {
                        this.insuredAmount = this.insuredAmount + parseInt(data.value);
                    }
                })
            })
        }
    }

}
