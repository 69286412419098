import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-warning-dialog',
    templateUrl: './warning-dialog.component.html'
})

export class WarningDialogComponent {

    inputData: any;
    confirmButton = false;
    isCheckboxSelected = false;

    constructor(
        private dialogRef: MatDialogRef<WarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        this.inputData = data;
    }

    onConfirm() {
        this.confirmButton = true;
        this.dialogRef.close({ confirmResult: this.confirmButton, isChecked: this.isCheckboxSelected });
    }

    onClose() {
        this.confirmButton = false;
        this.dialogRef.close({ confirmResult: this.confirmButton, isChecked: this.isCheckboxSelected });
    }

}
