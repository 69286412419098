import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-address-view',
    templateUrl: './shipment-address-view.component.html',
    styleUrls: ['./shipment-address-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentAddressViewComponent implements OnInit {

    @Output() isExpandClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    commonDataModel = new CommonDataModel();
    addressListModel = this.commonDataModel.shipmentBookingListModel.originAddress;
    infoWindowOpened = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set addressListData(data: any) {
        this.addressListModel = data;
    }

    @Input() moduleName?: string;

    @Input() addressType?: string;

    constructor() { }

    ngOnInit() {
    }

    onCollapseClick(): void {
        this.isExpandClicked.emit(true);
    }

}
