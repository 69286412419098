import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-shipment-time-out-dialog',
    templateUrl: './shipment-time-out-dialog.component.html',
    styleUrls: ['./shipment-time-out-dialog.component.scss']
})
export class ShipmentTimeOutDialogComponent implements OnInit {

    inputData: any;
    cancelButon: any;

    constructor(
        private dialogRef: MatDialogRef<ShipmentTimeOutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: string) {
        this.inputData = data;
    }

    ngOnInit() {
    }

    onClose(selection: any) {
        this.dialogRef.close({ confirmResult: selection });
    }

}
