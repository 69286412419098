import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-references-info',
    templateUrl: './references-info.component.html'
})

export class ReferencesInfoComponent implements OnInit {

    referencesList: Array<object> = [];

    @Input() set childData(references: any) {
        this.referencesList = references;
    }

    constructor() {
    }

    ngOnInit() {
    }

}
