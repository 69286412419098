export enum PermissionModuleEnum {
    noStartup = 0,
    trackingModule = 4,
    shipmentBookingModule = 5,
    quickRateModule = 6,
    lessThanTruckLoadAccess = 7,
    truckLoadAccess = 8,
    notificationPage = 16,
    reportModule = 201,
    bolModule = 210,
    dashboardModule = 217,
    ltlVendorModule = 301,
    TLVendorModule = 304,
}

export enum EmailCountLimitEnum {
    value = 20
}

export enum EnterpriseSearchLengthEnum {
    value = 3
}

export enum ExportFileTypeEnum {
    xml = 1,
    xls = 2,
    xlsx = 3,
    csv = 4
}

export enum GlobalSearchEnum {
    from = 0,
    pageSize = 20
}

export enum LocationContactCount {
    maxContactsCount = 10
}

export enum ShipmentTrackingChuckSize {
    minValue = 20,
    maxValue = 100
}

export enum GridPagination {
    skipCount = 0,
    results = 20
}

export enum BookingTypeEnum {
    saveQuote = 1,
    bookingShipment = 2
}

export enum LatLongEnum {
    latitude = 37.090240,
    longitude = -95.712891
}

export enum MapLatLongEnum {
    latitude = 40.713956,
    longitude = -89.085388
}

export enum MapZoomEnum {
    value = 4
}

export enum APITimeoutEnum {
    defaultTime = 60000, // 1 Min
    defaultMGCallTime = 190000, // 3 Min 10 Sec
    defaultDownloadFileTime = 300000, // 5 Min
    defaultClaimRequestTime = 120000  // 2 Min
}

export enum GridDefinitionEnum {
    clearDefinition = 1,
    clearFilter = 2,
    removeOnlyFilter = 3
}

export enum TrackingDetailsTabEnum {
    detailsTab = 0,
    actionsTab = 1,
    notesTab = 2,
    historyTab = 3,
    terminalInfoTab = 4,
    invoiceTab = 5
}

export enum ShipmentAddressTypeEnum {
    ShipperInfo = 1,
    ConsigneeInfo = 2,
    BillToInfo = 3
}

export enum TenderOffTime {
    tenderOffTimeValue = '00:00'
}
