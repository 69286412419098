import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { updatedDiff } from 'deep-object-diff';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LTLVendorService } from 'src/app/ltl-vendor/ltl-vendor.service';
import { CommonDataModel } from 'src/app/shared/common-data-model';
import { ShipmentItemSearchComponent } from 'src/app/shared/shipment-item-search/shipment-item-search.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ImportFormDialogComponent } from '../import-form-dialog/import-form-dialog.component';
import { SignalrService } from 'src/app-core/services/signalr.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shipment-multi-class-items',
    templateUrl: './shipment-multi-class-items.component.html',
    styleUrls: ['./shipment-multi-class-items.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentMultiClassItemsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('shipmentItemForm', { static: true }) shipmentItemForm: any;
    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    customPatterns = new CustomPatterns();
    itemTotalList = this.commonDataModel.itemTotalModel;
    settingItemModel = this.commonDataModel.settingModel;
    searchItemModel = this.commonDataModel.searchItemsModel;
    settingsGetModel = this.commonDataModel.settingsGetModel;
    modifyFieldsModel = this.commonDataModel.modifyFieldsModel;
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    itemsListModel = Object.assign({}, this.commonDataModel.itemsListModel);
    shipmentRatePermission = this.commonDataModel.shipmentRatePermissionModel;
    isLoading = false;
    itemForm: any;
    paramId: string;
    itemFormBtn = false;
    duplicateItems: Array<any> = [];
    shipmentItems: Array<any> = [];
    handlingUnitList: Array<any> = [];
    handlingUnitPackageList: Array<any> = [];
    itemHandlingUnitList: Array<any> = [];
    hazmatPackageGroupList: Array<any> = [];
    itemClassList: Array<any> = [];
    moduleName: string;
    numberToDisplay: any;
    regexIntPattern = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    donotWarnOnCommodityOverride = true;
    timeOutArray = [];
    classDensityBreaksList = [];
    classDensityBreakSetting: any;
    disableClassComboBox = false;
    enableClassDensityBreak = false;
    itemSequence = [1];
    LTLshipmentModeList = this.commonDataModel.parcelShipmentDropdownModel
    selectedLTLModeValue = 1;
    recipientList = this.commonDataModel.recipientTypeList;
    shippoConnectionId: any;
    shippoRedirectUrl: any;
    windows = [];
    savedShippoToken = false;
    shippoClientId: string;
    allItemHasInsurance = false;
    insuranceCommodities: Array<any> = [];
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
        this.insuranceCommodities = this.shipmentSettingsModel.InsuranceCommodities;
        this.enableClassDropDownSelection();
        if (this.shipmentSettingsModel.ClassBreaks) {
            this.classDensityBreaksList = this.commonMethod.prepareClassBreakDensityList(this.shipmentSettingsModel.ClassBreaks);
        }

        if (this.shipmentSettingsModel.ShippoBearerToken) {
            if (this.shipmentSettingsModel.ShippoBearerToken != 'Authorize') {
                this.selectedLTLModeValue = 0;
                this.onChangeMode(this.selectedLTLModeValue);
            } else {
                this.selectedLTLModeValue = 1;
                this.onChangeMode(this.selectedLTLModeValue);
            }
        }
    }

    @Input() set totalCalculation(totalList: any) {
        this.itemTotalList = totalList;
    }
    @Input() set formStatus(formList: object) {
        this.itemForm = formList;
    }
    @Input() set modifyFields(modifyFieldsList: any) {
        this.modifyFieldsModel = modifyFieldsList;
    }
    @Input() set childData(shipmentItemList: any) {
        this.shipmentItems = shipmentItemList;
        if (Array.isArray(this.shipmentItems) && this.shipmentItems.length) {
            this.shipmentItems.map((data, parentIndex) => {
                data.items.map((item, index) => {
                    item.tempFreightClass = item.freightClass;
                    if (this.selectedLTLModeValue != 2) {
                        item.perQuantityValue = item.value && item.quantity ? item.value / item.quantity : item.perQuantityValue;

                        if (parseFloat(item.perQuantityValue)) {
                            item.perQuantityValue = Math.ceil(item.perQuantityValue);
                        }

                        if (this.insuranceCommodities && this.insuranceCommodities.length) {
                            const position = this.insuranceCommodities.findIndex((x: any) => x.id === item.commodityId);
                            if (position == -1) {
                                this.shipmentItems[parentIndex].items[index].commodityId = null;
                            }
                        }
                    }
                })
            })
            this.calculateTotals();
            this.timeOutArray.push(setTimeout(() => {
                this.checkParcelItemsValidation();
            }, 2000));
            this.shipmentItems.map((item) => {
                this.commonMethod.mapValueAndFreightClassField(item, true);
                this.onPrepareItemTotal(item);
            });
            this.addSearchBoxProperties();
        }
    }
    @Input() set module(data: any) {
        this.moduleName = data;
        this.enableClassDropDownSelection();
    }
    @Input() set componentNo(data: number) {
        this.numberToDisplay = data;
    }

    constructor(
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private signalrService: SignalrService,
        private customToastrService: CustomToastrService,
        private LTLVendorBookingService: LTLVendorService,) {
        this.searchItemModel.skipCount = 0;
        this.searchItemModel.results = 20;
        this.searchItemModel.ownerId = this.commonService.getOwnerId();
        this.settingItemModel.ownerId = this.commonService.getOwnerId();
        this.settingsGetModel.userId = this.commonService.getUserId();
        this.settingsGetModel.ownerId = this.commonService.getOwnerId();
        this.settingsGetModel.accountSettingType = CommonConstants.accountSettingTypeConstants.scheduleShipment;
        this.shipmentRatePermission = this.commonService.permissionChecker(this.shipmentRatePermission);
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activatedRoute.paramMap.subscribe(params => {
            this.paramId = params.get('id');
            if (!this.paramId) {
                this.componentSubscriptions.add(this.activatedRoute.firstChild.paramMap.subscribe(childParams => {
                    this.paramId = childParams.get('id');
                }));
            }
        }));
        this.componentSubscriptions.add(this.shipmentItemForm.statusChanges.subscribe(result => {
            this.itemForm.itemsForm = this.shipmentItemForm.valid;
        }));
        this.commonService.getAccountSettings(this.settingsGetModel).subscribe(response => {
            this.donotWarnOnCommodityOverride = response.DonotWarnOnCommodityOverride;
        });
        if (this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
            this.commonService.getVendorItemClassList().subscribe(response => {
                this.itemClassList = response;
            });
        } else {
            this.commonService.getItemClassList().subscribe(response => {
                this.itemClassList = response;
            });
        }
        this.componentSubscriptions.add(this.shipmentItemForm.valueChanges.subscribe(result => {
            this.commonService.shipmentItemSubject.next(this.shipmentItems);
        }));
        this.componentSubscriptions.add(this.commonService.getImportResponseList.subscribe((data: any) => {
            if (data && !data.itemsModule) {
                this.mappingImportItems(data);
            }
        }));

        this.componentSubscriptions.add(
            this.signalrService.getShippoAuthConnectionId().subscribe((connectionId) => {
                if (connectionId) {
                    this.shippoConnectionId = connectionId;
                }
            })
        );

        this.componentSubscriptions.add(this.signalrService.hubShippoAuthConnection.on('shippoAccessTokenStatus', (data) => {
            const shippoTokenDetails = JSON.parse(data);
            const tokenData = shippoTokenDetails ? shippoTokenDetails : null
            let tokenState;
            this.commonService.setShippoAccessTokenData(shippoTokenDetails);
            if (shippoTokenDetails['access_token']) {
                tokenState = shippoTokenDetails['State'].split("signalR").at(0);
            }

            if (tokenData && shippoTokenDetails['access_token'] && tokenState === this.commonService.getUserId()) {

                this.shipmentSettingsModel.ShippoBearerToken = shippoTokenDetails['access_token']
                this.selectedLTLModeValue = 0;
                this.customToastrService.success(CommonConstants.appConstant.parcelShippoTokenSuccess);

            } else {
                this.customToastrService.error(shippoTokenDetails['error'] + ' ' + CommonConstants.appConstant.parcelShippoTokenError);
                this.shipmentSettingsModel.ShippoBearerToken = 'Authorize';
                this.savedShippoToken = false;
            }

            setTimeout(() => {
                this.windows.forEach(element => {
                    element.close();
                });
            }, 600)
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            this.loadItems();
        }
    }

    loadItems(): void {
        this.isLoading = true;
        if (this.shipmentSettingsModel.HandlingUnitTypeComboValues) {
            this.handlingUnitList = this.commonService.sortingByField(
                this.shipmentSettingsModel.HandlingUnitTypeComboValues, 'display');
        }
        if (this.shipmentSettingsModel.HandlingUnitItemPackageTypesComboValues) {
            this.handlingUnitPackageList = this.shipmentSettingsModel.HandlingUnitItemPackageTypesComboValues;
        }
        if (this.shipmentSettingsModel.PackageTypeComboValues) {
            this.itemHandlingUnitList = this.commonService.sortingByField(
                this.shipmentSettingsModel.PackageTypeComboValues, 'display');
        }
        if (this.shipmentSettingsModel.HazMatPackageGroupComboValues) {
            this.hazmatPackageGroupList = this.shipmentSettingsModel.HazMatPackageGroupComboValues;
        }
        if (this.paramId === CommonConstants.paramName.new) {
            this.loadDefaultItems(this.shipmentSettingsModel);
            this.isLoading = false;
            this.setDefaultDropDownValues(0, 0);
        } else {
            this.isLoading = false;
        }
        if (this.shipmentSettingsModel.ShippoBearerToken == 'Authorize' && this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            this.getClientId();
        }
    }

    loadDefaultItems(defaultShipmentSettings: any) {
        if (defaultShipmentSettings.DefaultItemCode) {
            const searchItem = Object.assign({}, this.searchItemModel);
            searchItem.itemId = defaultShipmentSettings.DefaultItemCode;
            switch (this.moduleName) {
                case this.commonConstants.moduleName.LTLVendorBooking:
                    this.LTLVendorBookingService.getSearchLTLVendorItems(searchItem).subscribe((response) => {
                        if (response.items.length) {
                            this.searchDefaultItem(defaultShipmentSettings, searchItem);
                        }
                    })
                    break;
                default:
                    this.searchDefaultItem(defaultShipmentSettings, searchItem);
                    break;
            }
        }
    }

    onAddNewItem($event: Event, index: number, listName: string): void {
        $event.stopPropagation();
        let itemCount = 0;
        if (this.selectedLTLModeValue != 1) {
            const itemSequence = _.cloneDeep(this.itemSequence);
            itemSequence.map((seq: any, index: any) => {
                this.itemSequence[index] = index + 1;
            })
        }
        this.shipmentItems.filter(list => { itemCount += list.items.length; });
        if (itemCount < Number(this.shipmentSettingsModel.MaxItemRows)) {
            if (listName === CommonConstants.itemForm.parentItem || listName === CommonConstants.itemForm.handlingUnit) {
                const itemsList = Object.assign({}, this.commonDataModel.itemsMultiClassListModel);
                itemsList.items = [];
                itemsList.isNestedItems = (listName === CommonConstants.itemForm.parentItem ? false : true);
                itemsList.items.push(Object.assign({}, this.itemsListModel));
                itemsList.handlingUnitType = this.shipmentSettingsModel.DefaultHandlingUnitType;
                itemsList.quantity = 1;
                itemsList.items[0].handlingType = itemsList.isNestedItems ? this.shipmentSettingsModel.DefaultPackagingType : this.shipmentSettingsModel.DefaultHandlingUnitType;
                this.shipmentItems.push(itemsList);
            } else {
                const itemList = Object.assign({}, this.itemsListModel);
                itemList.handlingType = this.shipmentItems[index].isNestedItems ? this.shipmentSettingsModel.DefaultPackagingType : this.shipmentSettingsModel.DefaultHandlingUnitType;
                this.shipmentItems[index].items.push(itemList);
            }

            if (this.selectedLTLModeValue != 1) {
                let count = 1;
                this.shipmentItems.map((itemsObject: any) => {
                    itemsObject.items.map((data: any) => {
                        data.itemCount = count++;
                    })
                })
            }
            this.calculateTotals();
            this.setShipmentItemsToDuplicateItems();
        } else {
            this.customToastrService.error(
                CommonConstants.itemForm.maxCountMessage + this.shipmentSettingsModel.MaxItemRows + CommonConstants.itemForm.items
            );
        }
    }

    onSearchItem($event: Event, index: number, list: any, childIndex: number): void {
        $event.stopPropagation();
        this.commonService.objectMapping(this.searchItemModel, list);
        this.commonService.checkPreserveSearchData(list, this.searchItemModel);
        const userEnteredData = this.searchItemModel.preserveCheckBox;
        this.searchItemModel.moduleName = this.moduleName
        const dialogRef = this.matDialog.open(ShipmentItemSearchComponent, {
            panelClass: 'center-dialog-wrapper',
            hasBackdrop: false,
            data: this.searchItemModel || null
        });
        dialogRef.afterClosed().subscribe((result: { itemResult: any }) => {
            if (!this.commonService.isEmptyObject(result.itemResult)) {
                if (result.itemResult.preserveCheckBox) {
                    this.commonService.shipmentItemMapping(this.shipmentItems[index].items[childIndex], result.itemResult);
                } else {
                    this.commonMethod.shipmentItemValueMapping(this.shipmentItems[index].items[childIndex], result.itemResult);
                    if (!this.donotWarnOnCommodityOverride && userEnteredData) {
                        this.customToastrService.warning(CommonConstants.shipmentItemConstants.overrideWarning);
                    }
                    if (this.shipmentItems[index].items[childIndex].isHandlingUnitSelected) {
                        const position = this.handlingUnitPackageList.findIndex((x: any) => x.value === result.itemResult.packageType);
                        if (position != -1) {
                            this.shipmentItems[index].items[childIndex].handlingType = result.itemResult.packageType;
                        } else {
                            this.shipmentItems[index].items[childIndex].handlingType = null;
                        }
                    }
                }

                const position = this.insuranceCommodities.findIndex((x: any) => x.id === result.itemResult.commodityId);
                if (position == -1) {
                    this.shipmentItems[index].items[childIndex].commodityId = null;
                }

                this.shipmentItems[index].items[childIndex].freightClass = this.commonMethod.mapFreightClassValue(this.itemClassList, result.itemResult.freightClass);
                this.shipmentItems[index].items[childIndex].tempFreightClass = this.commonMethod.mapFreightClassValue(this.itemClassList, result.itemResult.freightClass);
                if (this.shipmentItems[index].items[childIndex].freightClass) {
                    this.shipmentItems[index].items[childIndex].isFreightClassSelected = true;
                } else {
                    this.shipmentItems[index].items[childIndex].isFreightClassSelected = false;
                }

                this.shipmentItems[index].items[childIndex].perQuantityValue = this.shipmentItems[index].items[childIndex].value && this.shipmentItems[index].items[childIndex].quantity ? this.shipmentItems[index].items[childIndex].value / this.shipmentItems[index].items[childIndex].quantity : null;
                if (this.shipmentItems[index].items[childIndex].perQuantityValue) {
                    this.shipmentItems[index].items[childIndex].perQuantityValue = Math.ceil(this.shipmentItems[index].items[childIndex].perQuantityValue);
                }

                this.shipmentItems[index].valueAdded = this.shipmentItems[index].items.every((x) => x.value);
                this.onPerQuantityValueChange(index, childIndex);
                this.checkPackageDropDownValue(result.itemResult.packageType, index, childIndex, !result.itemResult.preserveCheckBox);
                this.onPrepareItemTotal(this.shipmentItems[index]);
                this.onTriggerItemCodeReferenceSettings();
                this.setShipmentItemsToDuplicateItems();
                this.checkParcelItemsValidation();
            }
        });
    }

    onPrepareItemTotal(list: any): void {
        this.commonMethod.calculateNestedItemVolumeAndDensity(list);
        this.calculateTotals();
    }

    onPrepareItemTotalWithInsurance(list: any, parentIndex: any, index: any, insurance: boolean, value: any, quantity): void {
        this.onPrepareItemTotal(list);
        this.onChangeWeight(parentIndex, index, this.shipmentItems[parentIndex].items[index].weight, this.shipmentItems[parentIndex], this.shipmentItems[parentIndex].items[index].quantity);
        this.onInsuranceCheckboxChange(parentIndex, index, insurance, value, quantity);
    }

    calculateTotals(disableClassDensityBreak?: boolean): void {
        this.itemFormBtn = true;
        this.commonService.objectMapping(this.itemTotalList,
            this.commonService.calculateMultiClassItemTotals(
                this.shipmentItems, this.modifyFieldsModel.linearFeetFieldModify, this.modifyFieldsModel.totalPalletsFieldModify));
        this.itemTotalList.linearFeet = this.modifyFieldsModel.linearFeetFieldModify ?
            this.modifyFieldsModel.linearFeetValue : this.itemTotalList.linearFeet;
        this.itemTotalList.totalPallets = this.modifyFieldsModel.totalPalletsFieldModify ?
            this.modifyFieldsModel.totalPalletsValue : this.itemTotalList.totalPallets;
        if (!disableClassDensityBreak && this.shipmentSettingsModel.ProcessClassBreaks) {
            this.commonMethod.setSuggestedClass(this.shipmentItems, this.classDensityBreaksList);
        }
    }

    validateLengthRange(parentIndex: any, childIndex: any): boolean {
        return this.commonService.checkLengthRange(this.shipmentItems[parentIndex].items[childIndex]);
    }

    validateDensityRange(parentIndex: any, childIndex: any): boolean {
        return this.commonService.checkDensityRange(this.shipmentItems[parentIndex].items[childIndex]);
    }

    checkPackageDropDownValue(packageTypeValue: string, index: number, childIndex: number, setValue: boolean): void {
        if (packageTypeValue) {
            const filterData = this.handlingUnitPackageList.find(list => list.value === packageTypeValue);
            if (!filterData) {
                this.handlingUnitPackageList.push({
                    display: packageTypeValue,
                    value: packageTypeValue
                });
            }
        }
        this.handlingUnitPackageList = this.commonService.sortingByField(this.handlingUnitPackageList, 'display');
    }

    onBlurItemCode(list: any, index: number, childIndex: number): void {
        if (list.itemId && list.id) {
            this.searchItemModel.density = null;
            this.searchItemModel.description = null;
            this.searchItemModel.length = null;
            this.searchItemModel.nmfC_Code = null;
            this.searchItemModel.itemId = list.itemId;
            this.commonService.getSearchItem(this.searchItemModel).subscribe(response => {
                if (Array.isArray(response.items) && response.items.length) {
                    const data = response.items.find(item => item.itemId === list.itemId);
                    if (data) {
                        const currentValue = Object.assign({}, list);
                        this.commonService.shipmentItemMapping(this.shipmentItems[index].items[childIndex], data);
                        if (currentValue.nmfC_Code && currentValue.nmfC_Code !== data.nmfC_Code) {
                            this.shipmentItems[index].items[childIndex].nmfC_Code = currentValue.nmfC_Code;
                        }
                        if (currentValue.description && currentValue.description !== data.description) {
                            this.shipmentItems[index].items[childIndex].description = currentValue.description;
                        }
                        if (!this.disableClassComboBox && currentValue.freightClass && currentValue.freightClass !== data.freightClass) {
                            this.shipmentItems[index].items[childIndex].freightClass = currentValue.freightClass;
                            this.shipmentItems[index].items[childIndex].tempFreightClass = currentValue.freightClass;
                        }
                        this.checkPackageDropDownValue(data.packageType, index, childIndex, true);
                    } else {
                        this.checkItemsModified(index, childIndex);
                        this.clearHazmatFields(index, childIndex);
                    }
                } else {
                    this.checkItemsModified(index, childIndex);
                    this.clearHazmatFields(index, childIndex);
                }
                this.calculateTotals();
            });
        }
    }

    checkItemsModified(index: number, childIndex: number): void {
        const diff: any = updatedDiff(this.shipmentItems[index].items[childIndex], this.duplicateItems[index].items[childIndex]);
        const userModifiedObjectModel = Object.assign({}, this.commonDataModel.userModifiedListModel);
        if (Object.keys(diff).length !== 0) {
            Object.keys(userModifiedObjectModel).map((key: any) => {
                Object.keys(diff).map((childKey: any) => {
                    if (key === childKey) {
                        userModifiedObjectModel[key] = true;
                    }
                });
            });
            if (!userModifiedObjectModel.nmfC_Code) {
                this.shipmentItems[index].items[childIndex].nmfC_Code = null;
            }
            if (!userModifiedObjectModel.description) {
                this.shipmentItems[index].items[childIndex].description = null;
            }
            if (!userModifiedObjectModel.freightClass && !this.disableClassComboBox) {
                this.shipmentItems[index].items[childIndex].freightClass = null;
                this.shipmentItems[index].items[childIndex].tempFreightClass = null;
            }
        }
    }

    clearHazmatFields(index: number, childIndex: number): void {
        this.commonService.clearHazMatFields(this.shipmentItems[index].items[childIndex]);
    }

    setShipmentItemsToDuplicateItems(): void {
        this.duplicateItems = [];
        this.duplicateItems = JSON.parse(JSON.stringify(this.shipmentItems));
    }

    onClearItem($event: Event, index: number, childIndex: number): void {
        $event.stopPropagation();
        this.shipmentItems[index].itemId = null;
        this.shipmentItems[index].items[childIndex] = Object.assign({}, this.itemsListModel);
        this.customToastrService.success(CommonConstants.shipmentItemConstants.itemCleared);
        this.calculateTotals(true);
        this.onTriggerItemCodeReferenceSettings();
        this.setShipmentItemsToDuplicateItems();
    }

    onRemoveItem($event: Event, index: number, listName: string, childIndex?: number): void {
        $event.stopPropagation();
        if (listName === CommonConstants.itemForm.parentItem || listName === CommonConstants.itemForm.handlingUnit) {
            this.shipmentItems.splice(index, 1);
        } else {
            this.shipmentItems[index].items.splice(childIndex, 1);
        }

        let count = 1;
        this.shipmentItems.map((itemsObject: any) => {
            itemsObject.items.map((data: any) => {
                data.itemCount = count++;
            })
        })
        this.customToastrService.success(CommonConstants.shipmentItemConstants.itemRemoved);
        this.calculateTotals();
        this.setShipmentItemsToDuplicateItems();
    }

    onChangeFields(value: any, fieldName: string): void {
        switch (fieldName) {
            case CommonConstants.itemForm.linearFeet:
                this.modifyFieldsModel.linearFeetValue = value;
                this.modifyFieldsModel.linearFeetFieldModify = (value === '0' || value === '' || value === null ? false : true);
                break;
            case CommonConstants.itemForm.totalPallets:
                this.modifyFieldsModel.totalPalletsValue = value;
                this.modifyFieldsModel.totalPalletsFieldModify = (value === '0' || value === '' || value === null ? false : true);
                break;
        }
        this.calculateTotals();
    }

    onChangeItemCode(event: any): void {
        if (!event) {
            this.onTriggerItemCodeReferenceSettings();
        }
    }

    onTriggerItemCodeReferenceSettings(): void {
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.itemCode);
    }

    onChangeMixedClass(): void {
        if (!this.shipmentItems[0].isNestedItems) {
            this.commonMethod.switchToSingleClass(this.shipmentItems);
            this.shipmentItems.map((handlingUnit, index) => {
                const unitValue = this.itemHandlingUnitList.some((x) => x.value === handlingUnit.items[0].handlingType);
                if (!unitValue) {
                    this.shipmentItems[index].items[0].handlingType = null;
                }
            });
        } else {
            this.commonMethod.switchToMulticlass(this.shipmentItems);
            this.shipmentItems.map((handlingUnit, index) => {
                handlingUnit.items.map((item, position) => {
                    const unitValue = this.handlingUnitPackageList.some((x) => x.value === item.handlingType);
                    if (!unitValue) {
                        this.shipmentItems[index].items[position].handlingType = null;
                    }
                });
            });
        }
        this.calculateTotals();
    }

    onShowConformationPopup(index: number): void {
        const confirmText = CommonConstants.shipmentItemConstants.conformationPopup;
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: confirmText || undefined,
        });
        dialogRef
            .afterClosed()
            .subscribe((result: { confirmResult: boolean }) => {
                if (result.confirmResult) {
                    const searchItem = Object.assign({}, this.searchItemModel);
                    searchItem.itemId = this.shipmentItems[index].isNestedItems ?
                        this.shipmentSettingsModel.DefaultHandlingUnitItemCode : this.shipmentSettingsModel.DefaultItemCode;
                    if (searchItem.itemId) {
                        this.commonService.getSearchItem(searchItem).subscribe(responseItem => {
                            if (Array.isArray(responseItem.items) && responseItem.items.length) {
                                this.commonService.shipmentItemMapping(this.shipmentItems[index].items[index], responseItem.items[index]);
                                this.checkPackageDropDownValue(responseItem.items[0].packageType, index, 0, true);
                                if (this.shipmentItems[index].items[index].itemId) {
                                    this.onTriggerItemCodeReferenceSettings();
                                }
                            } else {
                                this.shipmentItems[index].itemId = null;
                                this.shipmentItems[index].items[index] = Object.assign({}, this.itemsListModel);
                                this.calculateTotals();
                                this.onTriggerItemCodeReferenceSettings();
                                this.setDefaultDropDownValues(index, index);
                            }
                            this.setShipmentItemsToDuplicateItems();
                        });
                    }
                } else {
                    this.setDefaultDropDownValues(index, index);
                }
                this.onPrepareItemTotal(this.shipmentItems[index]);
            });
    }

    setDefaultDropDownValues(index: number, childIndex: number): void {
        this.shipmentItems[index].handlingUnitType = this.shipmentSettingsModel.DefaultHandlingUnitType;
        this.shipmentItems[index].items[childIndex].handlingType = this.shipmentSettingsModel.UseNestedItemByDefault ?
            this.shipmentSettingsModel.DefaultPackagingType : this.shipmentSettingsModel.DefaultHandlingUnitType;
        this.shipmentItemForm.form.controls.handlingType_0_0.setValue(this.shipmentItems[index].items[childIndex].handlingType);
    }

    checkIfDimensionsRequired(item: any) {
        const quantity = parseInt(item.quantity, 0);
        if (quantity === 0 && this.selectedLTLModeValue == 1) {
            return false;
        }
        return true;
    }

    addSearchBoxProperties(): void {
        this.shipmentItems.filter(parent => {
            parent.searchBoxHandlingUnitType = null;
            parent.items.filter(child => {
                child.searchBoxClass = null;
                child.searchBoxHandlingType = null;
                child.searchBoxPackageGroup = null;
            });
        });
    }

    checkHandlingUnit() {
        if (this.shipmentSettingsModel.AllowNestedClass) {
            if (this.shipmentItems.length > 1 && this.shipmentItems[0].isNestedItems) {
                return true;
            }
            return false;
        }
        return true;
    }

    onHandlingUnitChange(parentIndex: any, childIndex: any) {
        this.shipmentItems[parentIndex].items[childIndex].isHandlingUnitSelected = false;
    }

    onImportItem(): void {
        this.matDialog.open(ImportFormDialogComponent, {
            panelClass: 'upload-dialog-wrapper',
            hasBackdrop: false,
            data: {
                fileName: 'ShipmentItems',
                moduleName: this.moduleName
            }
        });
    }

    mappingImportItems(data: any) {
        if (data.dataList && data.dataList.length) {
            const results = _.cloneDeep(data.dataList);
            let showItemError = false;
            let itemCount = 0;
            this.shipmentItems.filter(x => { itemCount += x.items.length; });
            _.filter(results, (list: any) => {
                if (itemCount < Number(this.shipmentSettingsModel.MaxItemRows)) {
                    const pieces = Number(list.pieces);
                    const piecesValueType = Number.isInteger(pieces);
                    list.pieces = piecesValueType ? list.pieces : null;
                    const hazmatFlag = list.isHazmat.toLowerCase();
                    list.isHazmat = hazmatFlag === 'true' ? true : false;
                    if (this.shipmentItems[0].isNestedItems) {
                        const itemDataModel = _.cloneDeep(this.commonDataModel.shipmentMultiClassItems[0].items[0]);
                        this.commonMethod.mapValueAndFreightClassField(list, false);
                        this.commonService.objectMapping(itemDataModel, list);
                        this.commonMethod.shipmentItemValueMapping(itemDataModel, list);
                        this.shipmentItems[0].items.push(itemDataModel);
                        itemCount++;
                    } else {
                        const itemDataModel = _.cloneDeep(this.commonDataModel.shipmentMultiClassItems);
                        this.commonMethod.mapValueAndFreightClassField(list, false);
                        this.commonService.objectMapping(itemDataModel[0].items[0], list);
                        itemDataModel[0].isNestedItems = this.shipmentItems[0].isNestedItems;
                        this.commonMethod.shipmentItemValueMapping(itemDataModel[0].items[0], list);
                        this.shipmentItems.push(itemDataModel[0]);
                        itemCount++;
                    }
                    showItemError = false;
                } else {
                    showItemError = true;
                }
            });
            if (showItemError) {
                this.customToastrService.error(
                    CommonConstants.itemForm.maxCountMessage + this.shipmentSettingsModel.MaxItemRows + CommonConstants.itemForm.items
                );
            }
            const shipmentItems = _.cloneDeep(this.shipmentItems);
            this.shipmentItems = [];
            this.shipmentItems = shipmentItems;
            this.shipmentItems.map((list, index) => {
                this.onPrepareItemTotal(this.shipmentItems[index]);
            });
            this.calculateTotals();
            this.onTriggerItemCodeReferenceSettings();
            this.setShipmentItemsToDuplicateItems();
            this.commonService.sendImportResponseList([]);
            this.timeOutArray.push(setTimeout(() => {
                const formControlKeysArray = Object.keys(this.shipmentItemForm.form.value);
                formControlKeysArray.map((key: any) => {
                    if (this.shipmentItemForm.form.controls[`${key}`].value) {
                        this.shipmentItemForm.form.controls[`${key}`].markAsTouched();
                    }
                });
            }, 100));
        }
    }

    resetItems(): void {
        this.shipmentItemForm.resetForm();
    }

    trackbyFn(index: any) {
        return index;
    }

    searchDefaultItem(defaultShipmentSettings: any, searchItemPayload: any) {
        this.commonService.getSearchItem(searchItemPayload).subscribe(responseItem => {
            if (Array.isArray(responseItem.items) && responseItem.items.length) {
                responseItem.items[0].isHandlingUnitSelected = true;
                this.commonService.shipmentItemMapping(this.shipmentItems[0].items[0], responseItem.items[0]);
                this.checkPackageDropDownValue(responseItem.items[0].packageType, 0, 0, true);
                if (this.shipmentItems[0].items[0].itemId) {
                    this.onTriggerItemCodeReferenceSettings();
                }
                this.shipmentItems[0].items[0].freightClass = this.commonMethod.mapFreightClassValue(this.itemClassList, responseItem.items[0].freightClass);
                this.shipmentItems[0].items[0].tempFreightClass = this.commonMethod.mapFreightClassValue(this.itemClassList, responseItem.items[0].freightClass);
                if (this.selectedLTLModeValue != 2) {
                    this.shipmentItems[0].items[0].totalAmount = this.shipmentItems[0].items[0].value;
                }

                this.shipmentItems[0].valueAdded = this.shipmentItems[0].items.every((x) => x.value && x.value > 0);
                this.shipmentItems[0].items[0].perQuantityValue = this.shipmentItems[0].items[0].quantity && this.shipmentItems[0].items[0].value ? this.shipmentItems[0].items[0].value / this.shipmentItems[0].items[0].quantity : null;
                if (this.shipmentItems[0].items[0].perQuantityValue) {
                    this.shipmentItems[0].items[0].perQuantityValue = Math.ceil(this.shipmentItems[0].items[0].perQuantityValue);
                }

                const position = this.insuranceCommodities.findIndex((x: any) => x.id === responseItem.items[0].commodityId);
                if (position == -1) {
                    this.shipmentItems[0].items[0].commodityId = null;
                }

                this.onPerQuantityValueChange(0, 0);
                this.setDefaultDropDownValues(0, 0);
                this.duplicateItems = JSON.parse(JSON.stringify(this.shipmentItems));
                this.calculateTotals();
                this.onPrepareItemTotal(this.shipmentItems[0]);

                if (this.shipmentSettingsModel.ShippoBearerToken && this.shipmentSettingsModel.ShippoBearerToken != 'Authorize') {
                    this.shipmentItems.map((data) => {
                        data.quantity = 1;
                    })
                }

                this.checkParcelItemsValidation();
            } else {
                this.checkPackageDropDownValue(defaultShipmentSettings.DefaultPackagingType, 0, 0, true);
            }
        });
    }

    onChangeFreightClass(parentIndex: any, index: any) {
        this.shipmentItems[parentIndex].items[index].isFreightClassSelected = true;
    }

    enableClassDropDownSelection() {
        switch (this.moduleName) {
            case this.commonConstants.moduleName.scheduleShipment:
                if (this.commonService.permissionCheckByName(CommonConstants.AllowClassModification.ss_classmodification)) {
                    this.enableClassDensityBreak = true;
                }
                break;
            case this.commonConstants.moduleName.LTLVendorBooking:
                if (this.commonService.permissionCheckByName(CommonConstants.AllowClassModification.vr_classmodification)) {
                    this.enableClassDensityBreak = true;
                }
                break;
            default:
                this.enableClassDensityBreak = false;
                break;
        }
        setTimeout(() => {
            if (this.shipmentSettingsModel.ProcessClassBreaks && !this.enableClassDensityBreak) {
                this.disableClassComboBox = true;
            } else {
                this.disableClassComboBox = false;
            }
        }, 1000);
    }

    onValueChange(list: any, parentIndex: any, index: any, value: any, insurance: boolean, quantity: any) {
        if (this.selectedLTLModeValue != 2) {
            this.shipmentItems[parentIndex].items[index].value = this.shipmentItems[parentIndex].items[index].value ? parseFloat(this.shipmentItems[parentIndex].items[index].value) : this.shipmentItems[parentIndex].items[index].value;
        }

        if (insurance) {
            if (!this.shipmentItems[parentIndex].items[index].amountUpdated) {
                if (this.selectedLTLModeValue != 2) {
                    this.shipmentItems[parentIndex].items[index].totalAmount = this.shipmentItems[parentIndex].items[index].value;
                }

                if (this.shipmentItems[parentIndex].items[index].quantity && value) {
                    const amount = Number(value) / Number(this.shipmentItems[parentIndex].items[index].quantity);
                    this.shipmentItems[parentIndex].items[index].totalAmountPerPackage = amount.toFixed(2)
                } else {
                    this.shipmentItems[parentIndex].items[index].totalAmountPerPackage = 0;
                }
            }
        }

        if (this.selectedLTLModeValue != 2) {
            this.shipmentItems[parentIndex].items[index].perQuantityValue = this.shipmentItems[parentIndex].items[index].value && this.shipmentItems[parentIndex].items[index].quantity ? this.shipmentItems[parentIndex].items[index].value / this.shipmentItems[parentIndex].items[index].quantity : null;

            if (this.shipmentItems[parentIndex].items[index].perQuantityValue) {
                this.shipmentItems[parentIndex].items[index].perQuantityValue = Math.ceil(this.shipmentItems[parentIndex].items[index].perQuantityValue);
            } else {
                this.shipmentItems[parentIndex].items[index].perQuantityValue = null;
            }

            this.shipmentItems.map((data: any, parentIndex) => {
                data.valueAdded = data.items.every((x) => x.value);
            })
        }

        this.onPrepareItemTotal(list);
    }

    onPerQuantityValueChange(parentIndex: any, index: any) {
        const value = this.shipmentItems[parentIndex].items[index].perQuantityValue && this.shipmentItems[parentIndex].items[index].quantity ? this.shipmentItems[parentIndex].items[index].quantity * this.shipmentItems[parentIndex].items[index].perQuantityValue : this.shipmentItems[parentIndex].items[index].value;
        this.shipmentItems[parentIndex].items[index].value = Math.ceil(value);
        this.shipmentItems[parentIndex].valueAdded = this.shipmentItems[parentIndex].items.every((x) => x.value);
        this.onPrepareItemTotal(this.shipmentItems[parentIndex]);
    }

    onInsuranceCheckboxChange(parentIndex: any, index: any, insurance: boolean, value: any, quantity) {
        if (insurance) {
            this.shipmentItems[parentIndex].items[index].amountUpdated = false;
            if (this.selectedLTLModeValue != 2) {
                this.shipmentItems[parentIndex].items[index].totalAmount = this.shipmentItems[parentIndex].items[index].value;
                this.onChangeTotalAmount(parentIndex, index, value, quantity);
            }
        }
    }

    onChangeMode(selectedLTLModeValue: any) {
        this.commonService.sendShipmentModeSelection(selectedLTLModeValue);
        this.checkParcelItemsValidation();
        this.shipmentItems.map((data: any, parentIndex: any) => {
            data.items.map((item: any, index: any) => {
                this.shipmentItems[parentIndex].items[index].isRequiredInsurance = false;
                this.shipmentItems[parentIndex].items[index].totalAmount = null;
            })
        })
    }

    isClassAndNfmcRequired(selectedMode: any) {
        if (selectedMode == 2) {
            return true;
        } else {
            return false;
        }
    }

    onChangeLength(index: any, i: any, value: any, list: any, hazmat: any) {
        if (value && Number(value) > Number(this.shipmentSettingsModel.ParcelMaxLength)) {
            list.items[i].lengthValid = false;
            if (hazmat && this.selectedLTLModeValue === 2) {
                this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors({ incorrect: true });
            }
        } else {
            if (value) {
                list.items[i].lengthValid = true;
                this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors(null);
            } else {
                list.items[i].lengthValid = false;
                if (this.selectedLTLModeValue === 2) {
                    this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors({ incorrect: true });
                }
            }
        }
        this.onPrepareItemTotal(list);
    }

    onChangeWidth(index: any, i: any, value: any, list: any, hazmat: any) {
        if (value && Number(value) > Number(this.shipmentSettingsModel.ParcelMaxLength)) {
            list.items[i].widthValid = false;
            if (hazmat && this.selectedLTLModeValue === 2) {
                this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors({ incorrect: true });
            }
        } else {
            if (value) {
                list.items[i].widthValid = true;
                this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors(null);
            } else {
                list.items[i].widthValid = false;
                if (this.selectedLTLModeValue === 2) {
                    this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors({ incorrect: true });
                }
            }
        }
        this.onPrepareItemTotal(list);
    }

    onChangeHeight(index: any, i: any, value: any, list: any, hazmat: any) {
        if (value && Number(value) > Number(this.shipmentSettingsModel.ParcelMaxLength)) {
            list.items[i].heightValid = false;
            if (hazmat && this.selectedLTLModeValue === 2) {
                this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors({ incorrect: true });
            }
        } else {
            if (value) {
                list.items[i].heightValid = true;
                this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors(null);
            } else {
                list.items[i].heightValid = false;
                if (this.selectedLTLModeValue === 2) {
                    this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors({ incorrect: true });
                } else {
                    this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors(null);
                }
            }
        }
        this.onPrepareItemTotal(list);
    }

    onChangeWeight(index: any, i: any, value: any, list: any, quantity: any) {
        if (this.shipmentItems && this.shipmentItems.length) {
            const itemsList = [];
            this.shipmentItems.map((data: any) => {
                data.items.map((item) => {
                    itemsList.push(item);
                })
            })
            const hazmat = itemsList.every((x) => x.isHazmat === false)
            const quantiyValue = quantity ? Number(quantity) : 1;
            const totalWeight = Number(value) / quantiyValue;
            if (value && this.shipmentSettingsModel.ShippoBearerToken && this.shipmentSettingsModel.ShippoBearerToken != 'Authorize' && totalWeight > Number(this.shipmentSettingsModel.ParcelMaxWeight) && this.selectedLTLModeValue !== 1) {
                list.items[i].totalWeightValid = false;
                if (hazmat && this.selectedLTLModeValue === 2) {
                    this.shipmentItemForm.form.controls[`weight_${index}_${i}`].setErrors({ incorrect: true });
                    this.shipmentItemForm.form.controls[`weight_${index}_${i}`].markAsTouched();
                } else {
                    this.shipmentItemForm.form.controls[`weight_${index}_${i}`].setErrors(null);
                }
            } else {
                if (!value) {
                    list.items[i].totalWeightValid = false;
                    if (hazmat && this.selectedLTLModeValue === 2) {
                        this.shipmentItemForm.form.controls[`weight_${index}_${i}`].setErrors({ incorrect: true });
                        this.shipmentItemForm.form.controls[`weight_${index}_${i}`].markAsTouched();
                    }
                } else {
                    list.items[i].totalWeightValid = true;
                    this.shipmentItemForm.form.controls[`weight_${index}_${i}`].setErrors(null);
                }

            }
            this.onPrepareItemTotal(list);
        }
    }

    onChangeTotalAmount(parentIndex: any, index: any, value: any, quantity: any) {
        if (this.shipmentItems[parentIndex].items[index].quantity && value) {
            const amount = Number(value) / Number(this.shipmentItems[parentIndex].items[index].quantity);
            this.shipmentItems[parentIndex].items[index].totalAmountPerPackage = amount.toFixed(2)
        } else {
            this.shipmentItems[parentIndex].items[index].totalAmountPerPackage = 0;
        }
    }

    onFocusOutAmount(parentIndex: any, index: any) {
        this.shipmentItems[parentIndex].items[index].amountUpdated = true;
    }

    onGetDimension(index: any, i: any, value: any, list: any, key: any) {
        this.onPrepareItemTotal(list);
        if (this.shipmentItems && this.shipmentItems.length && this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            const itemsList = [];
            this.shipmentItems.map((data: any) => {
                data.items.map((item) => {
                    itemsList.push(item);
                })
            })
            const hazmat = itemsList.every((x) => x.isHazmat === false)
            if (this.shipmentSettingsModel.ShippoBearerToken && this.shipmentSettingsModel.ShippoBearerToken != 'Authorize' && this.selectedLTLModeValue !== 1) {
                let largest = 0;
                let dimension = 0;
                if (Number(list.items[i].length) > Number(list.items[i].width) && Number(list.items[i].length) > Number(list.items[i].height)) {
                    largest = Number(list.items[i].length);
                    dimension = largest + 2 * (Number(list.items[i].width) + Number(list.items[i].height));
                } else if (Number(list.items[i].width) > Number(list.items[i].length) && Number(list.items[i].width) > Number(list.items[i].height)) {
                    largest = Number(list.items[i].width);
                    dimension = largest + 2 * (Number(list.items[i].length) + Number(list.items[i].height));
                } else if (Number(list.items[i].height) > Number(list.items[i].length) && Number(list.items[i].height) > Number(list.items[i].width)) {
                    largest = Number(list.items[i].height);
                    dimension = largest + 2 * (Number(list.items[i].width) + Number(list.items[i].length));
                } else if ((Number(list.items[i].height) === Number(list.items[i].length) && Number(list.items[i].height) === Number(list.items[i].width)) || Number(list.items[i].height) === Number(list.items[i].width) && Number(list.items[i].height) === Number(list.items[i].length)) {
                    largest = Number(list.items[i].height);
                    dimension = largest + 2 * (Number(list.items[i].width) + Number(list.items[i].length));
                } else if (Number(list.items[i].height) === Number(list.items[i].length) && Number(list.items[i].height) > Number(list.items[i].width)) {
                    largest = Number(list.items[i].height);
                    dimension = largest + 2 * (Number(list.items[i].width) + Number(list.items[i].length));
                } else if ((Number(list.items[i].length) === Number(list.items[i].height) && Number(list.items[i].length) > Number(list.items[i].width)) || Number(list.items[i].length) === Number(list.items[i].width) && Number(list.items[i].length) > Number(list.items[i].height)) {
                    largest = Number(list.items[i].length);
                    dimension = largest + 2 * (Number(list.items[i].width) + Number(list.items[i].height));
                } else if ((Number(list.items[i].width) === Number(list.items[i].height) && Number(list.items[i].width) > Number(list.items[i].length)) || (Number(list.items[i].width) === Number(list.items[i].length) && Number(list.items[i].width) > Number(list.items[i].height))) {
                    largest = Number(list.items[i].width);
                    dimension = largest + 2 * (Number(list.items[i].length) + Number(list.items[i].height));
                }

                if (dimension > Number(this.shipmentSettingsModel.ParcelMaxAllDimensions)) {
                    list.items[i].lengthValid = false;
                    list.items[i].widthValid = false;
                    list.items[i].heightValid = false;
                    if (hazmat && this.selectedLTLModeValue === 2) {
                        this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors({ incorrect: true });
                        this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors({ incorrect: true });
                        this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors({ incorrect: true });
                        this.shipmentItemForm.form.controls[`height_${index}_${i}`].markAsTouched();
                        this.shipmentItemForm.form.controls[`width_${index}_${i}`].markAsTouched();
                        this.shipmentItemForm.form.controls[`length_${index}_${i}`].markAsTouched();
                    } else {
                        this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors(null);
                        this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors(null);
                        this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors(null);
                    }
                } else {
                    if (list.items[i].length && list.items[i].width && list.items[i].height) {
                        list.items[i].lengthValid = true;
                        list.items[i].widthValid = true;
                        list.items[i].heightValid = true;
                        this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors(null);
                        this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors(null);
                        this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors(null);
                    }

                    switch (key) {
                        case 'length':
                            this.onChangeLength(index, i, value, list, hazmat);
                            break;
                        case 'width':
                            this.onChangeWidth(index, i, value, list, hazmat);
                            break;
                        case 'height':
                            this.onChangeHeight(index, i, value, list, hazmat);
                            break;
                        default:
                            break;
                    }
                }
            } else {
                list.items[i].lengthValid = true;
                list.items[i].widthValid = true;
                list.items[i].heightValid = true;
                if (list.items[i].length && list.items[i].height) {
                    this.shipmentItemForm.form.controls[`height_${index}_${i}`].setErrors(null);
                }

                if (list.items[i].length && list.items[i].width) {
                    this.shipmentItemForm.form.controls[`width_${index}_${i}`].setErrors(null);
                }

                if (list.items[i].length && list.items[i].length) {
                    this.shipmentItemForm.form.controls[`length_${index}_${i}`].setErrors(null);
                }
            }
        }
    }

    checkIfQuantityRequired() {
        if (this.selectedLTLModeValue == 2) {
            return false;
        }
        return true;
    }

    showAdditionalParcelInfo(items: any) {
        if (!this.shipmentSettingsModel.ShippoBearerToken || this.shipmentSettingsModel.ShippoBearerToken == 'Authorize' || this.selectedLTLModeValue === 1 || !items.lengthValid || !items.widthValid || !items.heightValid || !items.totalWeightValid) {
            return false;
        } else {
            true;
        }
    }

    checkParcelItemsValidation() {
        if (this.shipmentItems && this.shipmentItems.length) {
            const itemsList = [];
            this.shipmentItems.map((data: any) => {
                data.items.map((item) => {
                    itemsList.push(item);
                })
            })
            const hazmat = itemsList.every((x) => x.isHazmat === false)
            this.shipmentItems.map((data: any, parentIndex: any) => {
                data.items.map((item: any, index: any) => {
                    if (this.selectedLTLModeValue === 2) {
                        this.shipmentItems[parentIndex].items[index].isHazmat = false;
                    }

                    this.onChangeWeight(parentIndex, index, item.weight, data, item.quantity);
                    this.onGetDimension(parentIndex, index, item.length, data, 'length');
                    this.onGetDimension(parentIndex, index, item.height, data, 'height');
                    this.onGetDimension(parentIndex, index, item.width, data, 'width');
                    if (hazmat) {
                        if (this.selectedLTLModeValue === 2) {
                            setTimeout(() => {
                                if (!item.totalWeightValid) {
                                    this.shipmentItemForm.form.controls[`weight_${parentIndex}_${index}`].setErrors({ incorrect: true });
                                    this.shipmentItemForm.form.controls[`weight_${parentIndex}_${index}`].markAsTouched();
                                }

                                if (!item.lengthValid) {
                                    this.shipmentItemForm.form.controls[`length_${parentIndex}_${index}`].setErrors({ incorrect: true });
                                    this.shipmentItemForm.form.controls[`length_${parentIndex}_${index}`].markAsTouched();
                                }

                                if (!item.widthValid) {
                                    this.shipmentItemForm.form.controls[`width_${parentIndex}_${index}`].setErrors({ incorrect: true });
                                    this.shipmentItemForm.form.controls[`width_${parentIndex}_${index}`].markAsTouched();
                                }

                                if (!item.heightValid) {
                                    this.shipmentItemForm.form.controls[`height_${parentIndex}_${index}`].setErrors({ incorrect: true });
                                    this.shipmentItemForm.form.controls[`height_${parentIndex}_${index}`].markAsTouched();
                                }
                            }, 1000);
                        }
                    }
                })
            })
        }
    }

    onChangeDryIceWeight(parentIndex: any, index: any, value: any) {
        if (value < 0) {
            this.shipmentItemForm.form.controls[`dryIceWeight_${parentIndex}_${index}`].setErrors({ incorrect: true });
            this.shipmentItemForm.form.controls[`dryIceWeight_${parentIndex}_${index}`].markAsTouched();
        } else {
            this.shipmentItemForm.form.controls[`dryIceWeight_${parentIndex}_${index}`].setErrors(null);
        }
    }

    onChangeHazmat(value: boolean, index: any, i: any) {
        if (this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            this.checkParcelItemsValidation();
        }
    }

    onUpdateContainseAlcohol(value: any) {
        this.commonService.sendContainsAlcoholValue(value);
    }

    checkShippoAuthStatus() {
        return this.shipmentSettingsModel.ShippoBearerToken == 'Authorize' && this.moduleName === this.commonConstants.moduleName.scheduleShipment;
    }

    onRedirectToShippoLogIn() {

        if (this.shippoClientId) {

            this.shippoRedirectUrl = `https://goshippo.com/oauth/authorize?response_type=code&client_id=${this.shippoClientId}&scope=*&state=${this.commonService.getUserId()}signalR${this.shippoConnectionId}ownerId_${this.commonService.getOwnerId()}&redirect_uri=`

            this.iniatialiseShippoConnection();
            const redirectUrl = encodeURIComponent(`${environment.baseUrl}ShippoOAuth/oauthRedirect/`);
            const shippoUrl = this.shippoRedirectUrl + redirectUrl;
            this.windows.push(
                window.open(shippoUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
            );
        }
    }

    iniatialiseShippoConnection() {
        this.commonService.initialiseShippoGetAuthToken(this.shippoConnectionId).subscribe(() => { })
    }

    getClientId() {
        this.commonService.getClientId().subscribe((response: any) => {

            this.shippoClientId = response.clientId;
        })
    }

    showParcelComboBox() {
        return this.shipmentSettingsModel.ShippoBearerToken && this.moduleName === this.commonConstants.moduleName.scheduleShipment;
    }

    onShowInsuranceValidationText() {
        if (this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            return this.shipmentSettingsModel.ShippoBearerToken && this.shipmentItems.every((x) => x.valueAdded == true);
        } else {
            return this.shipmentItems.every((x) => x.valueAdded == true);
        }
    }

    onChangeItemCategory(parentIndex: any, index: any) {
        const selectedItem: any = this.shipmentItems[parentIndex].items[index];
        const position = this.shipmentSettingsModel.InsuranceCommodities.findIndex((x) => x.id == selectedItem.commodityId)
        if (position != -1) {
            this.shipmentItems[parentIndex].items[index].commodityCategory = this.shipmentSettingsModel.InsuranceCommodities[position].description;
        } else {
            this.shipmentItems[parentIndex].items[index].commodityCategory = null;
        }
    }

    onQuantityValueChange(list: any, parentIndex: any, index: any, isRequiredInsurance: any, value: any, quantity: any) {
        if (this.selectedLTLModeValue != 2) {
            this.shipmentItems[parentIndex].items[index].perQuantityValue = this.shipmentItems[parentIndex].items[index].value && this.shipmentItems[parentIndex].items[index].quantity ? this.shipmentItems[parentIndex].items[index].value / this.shipmentItems[parentIndex].items[index].quantity : this.shipmentItems[parentIndex].items[index].perQuantityValue;
            if (this.shipmentItems[parentIndex].items[index].perQuantityValue) {
                this.shipmentItems[parentIndex].items[index].perQuantityValue = Math.ceil(this.shipmentItems[parentIndex].items[index].perQuantityValue);
            } else {
                this.shipmentItems[parentIndex].items[index].perQuantityValue = null;
            }

            this.shipmentItems[parentIndex].items[index].value = this.shipmentItems[parentIndex].items[index].perQuantityValue && this.shipmentItems[parentIndex].items[index].quantity ? this.shipmentItems[parentIndex].items[index].quantity * this.shipmentItems[parentIndex].items[index].perQuantityValue : this.shipmentItems[parentIndex].items[index].value;
        }

        this.onPrepareItemTotalWithInsurance(list, parentIndex, index, isRequiredInsurance, value, quantity)
    }


    isCommodityRequired(parentIndex: any, index: any) {
        return (this.shipmentItems[parentIndex].items[index].value && this.shipmentItems[parentIndex].items[index].value != 0 || this.shipmentSettingsModel.RequireInsurance) && !_.isEmpty(this.shipmentSettingsModel.InsuranceConfig) ? true : false;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.timeOutArray.map((timeOutId) => {
            clearTimeout(timeOutId);
        });
    }

}
