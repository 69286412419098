import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { AppComponentBase } from '../app-component-base';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactListComponent extends AppComponentBase implements OnInit {

    @ViewChild('childGrid', { static: true }) public grid: GridComponent;
    @Output() addressSelectionEvent = new EventEmitter<object>();
    @Input() set contactData(contactData: any) {
        this.parentData = contactData;
        if (contactData.contacts.length) {
            this.gridDataModel.data = contactData.contacts;
            this.gridDataModel.total = contactData.contacts.length;
        }
    }
    commonDataModel = new CommonDataModel();
    gridDataModel = this.commonDataModel.gridDataModel;
    parentData: any;

    constructor(
        injector: Injector) {
        super(injector);
    }

    ngOnInit() {
    }

    onCellClickHandler(list: any): void {
        list.dataItem.isDefault = true;
        const contactData = Object.assign({}, this.parentData);
        contactData.contacts = [];
        contactData.contacts.push(list.dataItem);
        this.addressSelectionEvent.emit(contactData);
    }

}
