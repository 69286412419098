import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-copy-input',
    templateUrl: './copy-input.component.html',
    styleUrls: ['./copy-input.component.scss']
})

export class CopyInputComponent {

    copyItem: string;
    title: string;
    isTruckloadBooking = false;
    @Input() set titleOfPage(data: string) {
        this.title = data;
    }

    @Input() set copyData(data: string) {
        this.copyItem = data;
    }

    @Input() set truckloadSummaryInput(data: boolean) {
        this.isTruckloadBooking = data;
    }

    onCopyItem(inputElement: any) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

}
