import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MaintenanceConstants } from 'src/app-core/constants/maintenance-constants';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { SignalrService } from 'src/app-core/services/signalr.service';
import { ImportItemListComponent } from 'src/app/shared/import-item-list/import-item-list.component';

@Component({
    selector: 'app-import-form-dialog',
    templateUrl: './import-form-dialog.component.html'
})

export class ImportFormDialogComponent implements OnInit {

    file: any;
    isLoading = false;
    uploadUrl: string;
    downloadFileName: string;
    displayFileSize: string;
    importStatusMessage: string;
    moduleName: any;
    maintenanceConstants: typeof MaintenanceConstants = MaintenanceConstants;

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ImportFormDialogComponent>,
        private signalrService: SignalrService,
        @Inject(MAT_DIALOG_DATA) downloadFileName?: any) {
        if (downloadFileName.hasOwnProperty('fileName')) {
            this.downloadFileName = downloadFileName.fileName;
            this.moduleName = downloadFileName.moduleName;
        } else {
            this.downloadFileName = downloadFileName;
        }
    }

    ngOnInit() {
        this.signalrService.hubImportConnection.on('importProgressStatus', (message) => {
            if (message) {
                this.importStatusMessage = message;
            }
        });
    }

    onChangeFile(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].size <= 26000000) {
                const file = event.target.files[0];
                const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                const allowedExtensions = ['xls'];
                if (allowedExtensions.indexOf(extension) === -1) {
                    this.customToastrService.error(MaintenanceConstants.commonForm.invalidFileFormat);
                    this.file = false;
                    return false;
                } else {
                    this.file = event.target.files[0];
                    this.displayFileSize = this.commonMethod.formatFileSize(this.file.size, 1);
                }
            } else {
                this.file = false;
                this.customToastrService.error(MaintenanceConstants.commonForm.fileSizeMessage);
            }
        }
    }

    onValidateImport() {
        this.signalrService.onImportConnect();
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('ownerId', this.commonService.getOwnerId());

        this.signalrService.onImportGetConnectionId().subscribe((connectionId) => {
            if (connectionId) {
                formData.append('signalRConnectionId', connectionId);
            }
        });

        setTimeout(() => {
            switch (this.downloadFileName) {
                case 'User':
                    this.commonService.importUserFile(formData)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.navigateImportExcel(response);
                        });
                    break;
                case 'TLItems':
                case 'TLVendorItems':
                case 'ShipmentItems':
                    formData.append('moduleName', this.moduleName);
                    this.commonService.importShipmentItem(formData)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.navigateImportExcel(response);
                        });
                    break;
                default:
                    this.commonService.importFile(formData, this.downloadFileName)
                        .pipe(finalize(() => {
                            this.isLoading = false;
                        }))
                        .subscribe(response => {
                            this.navigateImportExcel(response);
                        });
                    break;
            }
        }, 2000);
    }

    navigateImportExcel(response: any) {
        switch (this.downloadFileName) {
            case 'TLItems':
            case 'ShipmentItems':
            case 'TLVendorItems':
                this.dialogRef.close();
                this.matDialog.open(ImportItemListComponent, {
                    panelClass: 'center-dialog-wrapper',
                    hasBackdrop: false,
                    data: response.results
                });
                break;
            default:
                this.commonService.sendImportResponseList(response.results, true);
                this.router.navigateByUrl('app/maintenance/import-excel');
                break;
        }
        this.onClose();
    }

    onClose() {
        this.dialogRef.close();
    }

}
