export class ShipmentConstants {

    static readonly LTLVendorSuccessMessage = {
        emailSuccessMessage: 'Email sent successfully.'
    }

    static readonly LTLVendorMoveType = {
        inbound: 'Inbound',
        drop: 'Drop'
    };

    static readonly LTLVendorLCPSettings = {
        allowLCPSelectionOnly: 'AllowLCPSelectionOnly',
        showLCPOnly: 'ShowLCPOnly',
        showCost: 'ShowCost'
    };

    static readonly ScheduleShipmentRateColumnNames = {
        carrier: 'Carrier',
        service: 'Service',
        transitTime: 'Transit Time'
    };

    static readonly VendorBookingRateColumnNames = {
        carrier: 'Carrier Name',
        originService: 'Origin Service',
        destinationService: 'Destination Service',
        serviceDays: 'Service Days'
    };

    static readonly BOLWarningMessage = {
        addressSection: 'For Section 1: Address information appears to be missing.',
        itemSection: 'For Section 2: Item information appears to be missing.',
        carrierSection: 'For Section 5: Carrier information appears to be missing.',
        billToInfoSection: 'For Section 7: Bill To information appears to be missing.'
    };

}
