import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueCheck'
})

export class ValueCheckPipe implements PipeTransform {

    transform(value: any): any {
        if (value === null || value === '' || value === undefined) {
            return 'N/A';
        } else {
            return value;
        }
    }

}
