import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-services',
    templateUrl: './shipment-services.component.html',
    styleUrls: ['./shipment-services.component.scss']
})

export class ShipmentServicesComponent implements OnChanges, OnInit {

    @ViewChild('shipmentServicesForm', { static: true }) shipmentServicesForm: NgForm;
    isLoading = false;
    servicesForm: any;
    serviceList: Array<any> = [];
    LAServiceListDropDown: Array<any> = [];
    LAServiceSelectedList: Array<any> = [];
    commonDataModel = new CommonDataModel();
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    savedDataList = Object.assign({}, this.commonDataModel.savedDataModel);
    shipmentBookingList = Object.assign({}, this.commonDataModel.shipmentBookingListModel);
    searchLimitedAccess: string;
    numberToDisplay: any;
    isDisableServiceSection = false;
    editRequiredServicePermission = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set formStatus(formList: object) {
        this.servicesForm = formList;
    }
    @Input() set savedDataStatus(savedlist: any) {
        this.savedDataList = savedlist;
    }
    @Input() set childData(shipmentServicesList: any) {
        this.shipmentBookingList.shipmentServices = shipmentServicesList;
    }
    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
    }
    @Input() set componentNo(data: number) {
        this.numberToDisplay = data;
    }
    @Input() set isDisabledSection(data: boolean) {
        this.isDisableServiceSection = data;
    }

    constructor(
        private commonService: CommonService) {
    }

    ngOnInit() {
        if (this.commonService.permissionCheckByName(CommonConstants.editRequiredServicePermission.editRequiredService)) {
            this.editRequiredServicePermission = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            this.loadItems();
        }
    }

    loadItems(): void {
        this.isLoading = true;
        if (this.shipmentSettingsModel.Services) {
            this.serviceList = JSON.parse(JSON.stringify(this.shipmentSettingsModel.Services));
            if (Array.isArray(this.serviceList) && this.serviceList.length) {
                const LAServiceListData = this.serviceList.filter(list => {
                    list.isLAService = false;
                    return list.display.toUpperCase().includes('LA:');
                });

                if (LAServiceListData.length) {
                    this.LAServiceSelectedList = LAServiceListData.map(list => {
                        list.isLAService = true;
                        list.displayText = list.display.replace('LA:', '');
                        if (list.isSelected) {
                            return list.value;
                        }
                    }).filter(x => x !== undefined);
                    this.LAServiceListDropDown = this.commonService.sortingByField(
                        JSON.parse(JSON.stringify(LAServiceListData)), 'displayText');
                }
                this.serviceList = this.commonService.sortingByField(this.serviceList, 'display');
                this.isLoading = false;
                this.preSelectedList();
            }
        }
    }

    preSelectedList(): void {
        if (Array.isArray(this.shipmentBookingList.shipmentServices.services) &&
            this.shipmentBookingList.shipmentServices.services.length) {

            this.serviceList.map((list) => {
                list.isSelected = false;
                this.shipmentBookingList.shipmentServices.services.find(item => {
                    if (list.value === item.value) {
                        list.isSelected = item.isSelected;
                    }
                });
            });

            if (this.LAServiceListDropDown.length) {
                this.LAServiceSelectedList = [];
                this.LAServiceListDropDown.map(list => {
                    this.shipmentBookingList.shipmentServices.services.find(item => {
                        if (list.value === item.value) {
                            this.LAServiceSelectedList.push(list.value);
                        }
                    });
                });
            }
            this.servicesForm.servicesForm = true;
            this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.serviceCode);
        } else {
            if (!this.savedDataList.services) {
                this.onChangeService();
            } else {
                this.serviceList.filter(item => { item.isSelected = false; });
                this.LAServiceSelectedList = [];
            }
        }
    }

    onChangeService(): void {
        const itemList = this.serviceList.filter((list) => {
            return list.isSelected;
        });
        this.shipmentBookingList.shipmentServices.services = itemList;
        this.savedDataList.services = true;
        this.servicesForm.servicesForm = itemList.length > 0 ? true : false;
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.serviceCode);
    }

    onLASelectionChange(): void {
        this.serviceList.filter(list => { if (list.isLAService) { list.isSelected = false; } });
        if (this.LAServiceSelectedList.length) {
            this.LAServiceSelectedList.filter(list => {
                this.serviceList.filter(child => {
                    if (child.value === list) {
                        child.isSelected = true;
                    }
                });
            });
        }
        this.onChangeService();
    }

    resetServices(): void {
        this.shipmentServicesForm.reset();
        this.serviceList = [];
        this.LAServiceSelectedList = [];
        this.savedDataList.services = false;
        this.shipmentBookingList.shipmentServices.services = [];
        this.loadItems();
    }

    editRequiredPermission(required: boolean) {
        if (required) {
            if (this.editRequiredServicePermission) {
                return false;
            }
            return true;
        }
        return false;
    }

    checkSelectedService(item: any) {
        if (item && item.isRequired) {
            return true;
        }
        return false;
    }

}
