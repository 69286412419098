import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { LatLongEnum, TrackingDetailsTabEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { LoaderService } from 'src/app-core/services/loader.service';
import { CommonDataModel } from 'src/app/shared/common-data-model';
import { ConfirmClaimsDialogComponent } from '../confirm-claims-dialog/confirm-claims-dialog.component';
import { ShipmentSelectionDialogComponent } from '../shipment-selection-dialog/shipment-selection-dialog.component';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
@Component({
    selector: 'app-tracking-details',
    templateUrl: './tracking-details.component.html',
    styleUrls: ['./tracking-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrackingDetailsComponent implements OnInit, OnDestroy {
    adjustFeedBack = true;
    componentSubscriptions: Subscription = new Subscription();
    isLoading = true;
    showStopDetails = false;
    shipmentId: string;
    selectedIndex = 0;
    pageName = 'tracking';
    infoLoading = false;
    commonDataModel = new CommonDataModel();
    infoWindowResponseModel = this.commonDataModel.infoWindowResponseModel;
    trackingInfoWindowModel = this.commonDataModel.trackingInfoWindowModel;
    headingDetailsList = this.commonDataModel.trackingHeaderModel;
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    trackingDetailsModel = Object.assign({}, this.commonDataModel.trackingDetailsModel);
    trackingAdditionalModel = Object.assign({}, this.commonDataModel.trackingAdditionalModel);
    invoicePermissionModel = this.commonDataModel.invoicePermissionModel;
    moduleAccessModel = this.commonDataModel.moduleAccessModel;
    latitude = LatLongEnum.latitude;
    longitude = LatLongEnum.longitude;
    gridNavigationUrl: string;
    invoiceId: string;
    showCost = null;
    moduleName = null;
    moduleTitle: string;
    activeTrackingModuleName = null;
    disableTabs = false;
    activeInvoiceTab = false;
    bookingMode: any;
    shippingMode: any;
    feedback = false;
    claimsStatus: any;
    showClaims = true;
    showAlertService = true;
    showAlertServiceForm = true;
    vendorBookingStartUpPage: string;
    purchaseLabels: any;
    insuranceTestMode = false;
    claimsSelfService = false
    insurancePurchaseId: any;
    redkikUrl: any;
    LatLongEnum: typeof LatLongEnum = LatLongEnum;
    originInfoWindowOpened = false;
    trackingMapFilterModel = this.commonDataModel.trackingMapFilterModel;
    commonConstants: typeof CommonConstants = CommonConstants;
    trackingDetailsTabEnum: typeof TrackingDetailsTabEnum = TrackingDetailsTabEnum;
    activeIndex = 0;
    constructor(
        private router: Router,
        private dialog: MatDialog,
        private commonMethod: CommonMethod,
        private activeRoute: ActivatedRoute,
        private loaderService: LoaderService,
        private commonService: CommonService) {
        this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
        this.invoicePermissionModel = this.commonService.permissionChecker(this.invoicePermissionModel);
        this.moduleAccessModel = this.commonService.permissionChecker(this.moduleAccessModel);
        this.vendorBookingStartUpPage = this.commonService.getVendorBookingStartUpPage();
        this.trackingMapFilterModel.filter.status = CommonConstants.trackingMapForm.active;
        this.trackingMapFilterModel.ownerId = this.commonService.getOwnerId();
    }

    ngOnInit() {
        this.moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
        this.invoiceId = this.activeRoute.snapshot.queryParamMap.get('invoiceId');
        this.shippingMode = this.activeRoute.snapshot.queryParamMap.get('mode');
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            if (this.shipmentId === '0') {
                this.shipmentId = null;
                this.disableTabs = true;
                this.activeInvoiceTab = true;
            }
            this.loaderShipmentHeader();
            if (this.moduleName !== CommonConstants.moduleName.invoice) {
                this.loadItems();
            }
            if (this.selectedIndex === TrackingDetailsTabEnum.actionsTab) {
                this.showClaims = false;
                this.loaderShipmentHeader();
            }
        }));
        this.componentSubscriptions.add(this.commonService.getTabIndexValue.subscribe(data => {
            this.selectedIndex = data;
            if (!this.invoiceId && this.selectedIndex === TrackingDetailsTabEnum.invoiceTab) {
                this.activeInvoiceTab = true;
                this.loaderShipmentHeader();
            }
            if (this.selectedIndex === TrackingDetailsTabEnum.actionsTab) {
                this.showClaims = false;
                this.loaderShipmentHeader();
            }
        }));

        this.componentSubscriptions.add(this.commonService.purchaseLabels.subscribe((purchaseLabels: any) => {
            this.purchaseLabels = purchaseLabels;
        }))

        this.componentSubscriptions.add(this.activeRoute.queryParams.subscribe(params => {
            switch (params.moduleName) {
                case CommonConstants.moduleName.shipmentTracking:
                    this.gridNavigationUrl = 'app/tracking/tracking-list';
                    this.moduleTitle = CommonConstants.moduleTitle.tracking;
                    this.loadItems();
                    this.activeTrackingModuleName = CommonConstants.moduleName.shipmentTracking
                    break;
                case CommonConstants.moduleName.invoice:
                    this.loaderShipmentHeader();
                    this.gridNavigationUrl = 'app/invoice/invoice-list';
                    this.moduleTitle = CommonConstants.moduleTitle.invoice;
                    this.selectedIndex = 6;
                    this.activeInvoiceTab = true;
                    this.activeTrackingModuleName = CommonConstants.moduleName.invoice
                    break;
                case CommonConstants.moduleName.scheduleTruckload:
                    this.gridNavigationUrl = 'app/tracking/tracking-list';
                    this.moduleTitle = CommonConstants.moduleTitle.tracking;
                    this.loadItems();
                    this.activeTrackingModuleName = CommonConstants.moduleName.scheduleTruckload
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.activeTrackingModuleName = CommonConstants.moduleName.LTLVendorBooking
                    this.gridNavigationUrl = 'app/ltl-vendor/tracking/tracking-list';
                    this.moduleTitle = CommonConstants.moduleTitle.vendorTracking;
                    this.getVRShowCost();
                    this.loadItems();
                    break;
                case CommonConstants.moduleName.TLVendorBooking:
                    this.gridNavigationUrl = 'app/tl-vendor/tracking/tracking-list';
                    this.moduleTitle = CommonConstants.moduleTitle.vendorTracking;
                    this.loadItems();
                    break;
            }
        }));

        this.componentSubscriptions.add(this.commonService.getShipmentStatusValue.subscribe((data: boolean) => {
            if (data) {
                this.loaderShipmentHeader();
            }
        }))

    }

    loadItems(): void {
        this.getInsuranceIsTestSetting();
        this.getClaimsSelfServiceSetting();
        this.commonService.getTrackingAdditionalInfo(this.shipmentId).subscribe(response => {
            this.trackingAdditionalModel = Object.assign({}, this.commonDataModel.trackingAdditionalModel);
            this.commonService.objectMappingNullCheck(this.trackingAdditionalModel, response);
            this.trackingAdditionalModel.mapView.latLongInfoList.map((data: any, index: any) => {
                const pos = this.trackingAdditionalModel.shipmentStops.findIndex((x: any) => x.shipmentStopId == data.shipmentStopId);
                if (pos != -1) {
                    data.stopReference = this.trackingAdditionalModel.shipmentStops[pos].references;
                    data.stopAddress = this.trackingAdditionalModel.shipmentStops[pos].location;
                }
            })

            this.trackingAdditionalModel.multiStopList = _.cloneDeep(this.trackingAdditionalModel.mapView.latLongInfoList);
        });
    }

    getInsuranceIsTestSetting() {
        this.commonService.getSettingByName('SS_InsuranceTestMode').subscribe((response: any) => {
            this.insuranceTestMode = response.value ? true : false;
            this.getRedkikUrl(this.insuranceTestMode);
        })
    }

    getClaimsSelfServiceSetting() {
        this.commonService.getSettingByName('SN_ClaimsSelfService').subscribe((response: any) => {
            this.claimsSelfService = response.value;
        })
    }

    getRedkikUrl(setting: any) {
        if (this.insurancePurchaseId) {
            this.commonService.getRedkikRedirectUrl(setting).subscribe((data: any) => {
                this.redkikUrl = data.url;
            })
        }
    }

    loaderShipmentHeader(): void {
        if (this.shipmentId) {
            this.trackingDetailsGetModel.shipmentId = this.shipmentId;
            if (this.shippingMode === this.commonConstants.moduleName.parcel) {
                this.commonService.getParcelTrackingDetails(this.trackingDetailsGetModel).subscribe((response: any) => {
                    if (response.shipmentMode === this.commonConstants.moduleName.parcel) {
                        this.trackingDetailsModel['mode'] = response.shipmentMode;
                        this.trackingDetailsModel.carrierTrackingLink = response.trackingNumberLink.trackingUrl
                    }
                    this.commonService.objectMapping(this.headingDetailsList, response);
                    this.commonService.objectMapping(this.trackingDetailsModel, response);
                    this.bookingMode = response.moduleName;
                    this.shippingMode = response.shipmentMode;
                });
            } else {
                this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe((response: any) => {
                    this.commonService.objectMapping(this.headingDetailsList, response);
                    this.commonService.objectMapping(this.trackingDetailsModel, response);
                    this.bookingMode = response.moduleName;
                    this.shippingMode = response.shipmentMode;
                    this.insurancePurchaseId = response.insurancePurchaseID;
                });
            }
        }
    }

    getVRShowCost() {
        this.trackingDetailsGetModel.shipmentId = this.shipmentId;
        this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe(response => {
            this.commonService.objectMappingNullCheck(this.trackingDetailsModel, response);
            this.showCost = this.trackingDetailsModel.carrierInfo ? this.trackingDetailsModel.carrierInfo.vrShowCost : '';
        });
    }

    onTabChanged({ index, tab }) {
        if (index === TrackingDetailsTabEnum.actionsTab && this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
            this.getVRShowCost();
        }
        if (index === TrackingDetailsTabEnum.detailsTab) {
            this.showClaims = true;
        } else {
            this.showClaims = false;
        }
        if (tab.textLabel === 'Invoice') {
            this.activeInvoiceTab = true;
        } else {
            this.activeInvoiceTab = false;
        }

        if (index != TrackingDetailsTabEnum.historyTab) {
            this.commonService.getShipmentStatus.next(false);
        }
    }

    receiveDocResponse($event: any) {
        this.loaderService.hide();
    }

    onDialogOpen() {
        this.dialog.open(ShipmentSelectionDialogComponent,
            {
                width: '25%',
                height: '600',
                data: {
                    id: this.shipmentId,
                    module: this.bookingMode,
                    page: this.moduleTitle
                }
            }
        );
    }

    onShowCarrierTrackingLink(dataItem: any) {
        return this.commonMethod.showCarrierTrackingLink(dataItem);
    }

    onTrackNavigation(dataItem: any) {
        if (dataItem.carrierInfo) {
            const proValue = dataItem.carrierInfo.pro ? dataItem.carrierInfo.pro : '0';
            this.commonMethod.copyClipboard(proValue);
        }
    }

    onSetTrackingLink(dataItem: any) {
        return this.commonMethod.mapCarrierTrackingLink(dataItem);
    }

    onBack() {
        this.router.navigateByUrl(this.gridNavigationUrl);
    }

    onClaims() {
        const url = `${this.redkikUrl}claim/${this.insurancePurchaseId}`;
        if (this.redkikUrl && this.claimsSelfService && this.insurancePurchaseId && !this.claimsStatus) {
            this.onSubmitInsuranceClaim();
            window.open(url, '_blank');
        } else {
            const selctedData = {
                claimsStatus: this.claimsStatus,
                ownerId: this.commonService.getOwnerId(),
                shipmentId: this.shipmentId,
                hasBackdrop: false,
                redKikUrl: this.redkikUrl && this.insurancePurchaseId && this.claimsSelfService ? url : null
            }
            const dialogSetting = {
                width: this.claimsStatus ? '25%' : '80%',
                height: '90%',
                data: selctedData,
                hasBackdrop: false
            }
            if (this.claimsStatus) {
                delete dialogSetting.height
            }
            this.dialog.open(ConfirmClaimsDialogComponent, dialogSetting);
        }
    }

    getClaimsStatus(event: any) {
        this.claimsStatus = event.osdStatus;
        setTimeout(() => {
            this.isLoading = !event.isDataLoaded;
        }, 1000);
    }

    checkModuleName() {
        if ((this.activeTrackingModuleName === this.commonConstants.moduleName.shipmentTracking || this.activeTrackingModuleName === this.commonConstants.moduleName.scheduleTruckload) && this.showClaims) {
            return true;
        } else {
            return false;
        }
    }

    checkParcelShipment() {
        return this.shippingMode === this.commonConstants.moduleName.parcel ? false : true;
    }

    showTimeZone(value: any) {
        if (value == 'UTC') {
            return value;
        } else {
            return '';
        }
    }

    onSubmitInsuranceClaim() {
        const payload = {
            userQuery: null,
            shipmentId: this.shipmentId,
            ownerId: this.trackingDetailsModel.ownerId
        }

        this.commonService.submitInsuranceClaimsFormRequest(payload).subscribe((response: any) => { })
    }

    showMultistops(index: any, data: any) {
        if (data && data.length) {
            const length = data.length - 1;
            if (index < length && index > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    onSelectMarker(): void {
        const listArr = [];
        this.commonService.objectMapping(this.trackingInfoWindowModel, this.trackingMapFilterModel);
        this.commonService.getInfoWindowRecords(this.trackingInfoWindowModel, this.moduleName)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                // response['eventType'] = points.eventType;
                this.infoWindowResponseModel = response;
                this.infoLoading = false;
            });
    }

    onGetStopDetails(event: Event, selectdStop: any, index: any) {
        event.stopPropagation();
        this.showStopDetails = false;
        this.infoLoading = true;
        this.infoWindowResponseModel = Object.assign({}, this.commonDataModel.infoWindowResponseModel);
        this.trackingInfoWindowModel.latitude = selectdStop.latitude;
        this.trackingInfoWindowModel.longitude = selectdStop.longitude;
        this.trackingInfoWindowModel.shipmentIds = [this.shipmentId];
        this.commonService.objectMapping(this.trackingInfoWindowModel, this.trackingMapFilterModel);
        this.commonService.getInfoWindowRecords(this.trackingInfoWindowModel, this.moduleName)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.showStopDetails = true;
                response['eventType'] = selectdStop.eventType;
                this.infoWindowResponseModel = response;
                this.infoLoading = false;
            });
    }

    onClose() {
        this.showStopDetails = false;
    }

    onClickOutside() {
        this.showStopDetails = false;
    }

    getDates(dateValue: any) {
        if (dateValue) {
            const date = new Date(dateValue);
            const shipmentDate = moment(date).format("MM/DD/YY hh:mm");
            return shipmentDate;
        } else {
            return '';
        }
    }

    checkActualDate(value: any) {
        return value ? '#E1113D' : '#4580BA';
    }

    getEventType(stopObj: any) {
        let url = '';
        switch (stopObj.eventType) {
            case 'DROP':
                url = stopObj.actualDate ? 'assets/images/dropEvent.svg' : 'assets/images/drop.svg';
                break;
            default:
                url = stopObj.actualDate ? 'assets/images/pickupEvent.svg' : 'assets/images/pickup.svg';
                break;
        }

        return url;
    }

    setActiveIndex(points: any) {
        const getIndex = this.trackingAdditionalModel.mapView.latLongInfoList.findIndex((x:any)=> x.shipmentStopId == points.shipmentStopId)
        if(getIndex != -1){
            this.activeIndex = getIndex;
        } else {
            this.activeIndex = 0;
        }
    }

    getAddress(stop:any){
        return `${stop.address1?stop.address1:''}${stop.city?',':''} ${stop.city?stop.city:''}${stop.stateName?',':''} ${stop.stateName?stop.stateName:''}${stop.zipCode?',':''} ${stop.zipCode?stop.zipCode:''}`;
    }

    getReferences(stop:any,last:boolean){
        if(stop.value){
            return `${stop.type}:${stop.value} ${!last?',':''}`;
        }
    } 

    onClickPrev(length:any) {
        this.activeIndex = this.activeIndex? this.activeIndex - 1: length - 1;
    }

    onClickNext(length:any) {
        const pos = length - 1;
        this.activeIndex = pos > this.activeIndex? this.activeIndex + 1: 0;
    }

    ngOnDestroy(): void {
        this.commonService.getShipmentStatus.next(false);
        this.componentSubscriptions.unsubscribe();
    }

}
