import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
    selector: 'app-import-grid-list',
    templateUrl: './import-grid-list.component.html'
})
export class ImportGridListComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    pageSize = 20;
    skip = 0;
    dataList: any;
    items: Array<any> = [];
    gridData: GridDataResult;

    constructor(
        private location: Location,
        private commonService: CommonService) {
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.getImportResponseList.subscribe(data => {
            this.dataList = data;
            this.items = this.dataList.errorRecords || [];
            this.loadItems();
        }));
    }

    pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.loadItems();
    }

    loadItems(): void {
        this.gridData = {
            data: this.items.slice(this.skip, this.skip + this.pageSize),
            total: this.items.length
        };
    }

    onBack() {
        this.location.back();
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
