import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { LoaderService } from '../services/loader.service';
import 'rxjs/add/observable/fromPromise';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    userManager = new UserManager(getClientSettings());
    user: User | null;

    private authSource = new BehaviorSubject<boolean>(true);
    authStatus = this.authSource.asObservable();

    constructor(
        private loaderService: LoaderService) {
        this.userManager.events.addUserLoaded(_ => {
            this.userManager.getUser()
                .then(user => {
                    this.user = user;
                    if (this.user) {
                        this.authSource.next(true);
                    } else {
                        this.authSource.next(this.isAuthenticated());
                    }
                });
        });
        this.userManager.events.addSilentRenewError(() => {
            console.log('Silent Renew Error', new Date());
        });
        this.userManager.events.addAccessTokenExpired(() => {
            this.logout();
        });
    }

    login() {
        return this.userManager.signinRedirect();
    }

    async completeAuthentication() {
        this.user = await this.userManager.signinRedirectCallback();
        this.authSource.next(this.isAuthenticated());
    }

    getUser(): Observable<User> {
        return Observable.fromPromise(this.userManager.getUser());
    }

    isAuthenticated(): boolean {
        return this.user != null && !this.user.expired;
    }

    get loggedUser(): any {
        return this.user != null ? this.user.profile : '';
    }

    async logout() {
        this.loaderService.show();
        await this.userManager.signoutRedirect();
        this.authSource.next(false);
        this.user = null;
        sessionStorage.removeItem('oidcTranslogistics');
        sessionStorage.removeItem('oidcBreadcrumbs');
        sessionStorage.removeItem('visitedTab');
        sessionStorage.removeItem('oidcBIVisitedDashboards');
        localStorage.removeItem('shippoAuthDetails');
        this.loaderService.hide();
    }

}

export function getClientSettings(): UserManagerSettings {
    return {
        loadUserInfo: true,
        response_type: 'code',
        response_mode: 'query',
        client_id: 'CustomerPortal',
        authority: environment.authority,
        scope: 'openid profile TLI.Service.ApiGateway IdentityServerApi offline_access',
        redirect_uri: environment.appUrl + 'account/auth-callback',
        automaticSilentRenew: true,
        silent_redirect_uri: environment.appUrl + 'assets/silent-refresh.html',
        post_logout_redirect_uri: environment.appUrl + 'account/login',
        userStore: new WebStorageStateStore({ store: window.sessionStorage })
    };
}
