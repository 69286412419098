import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from 'src/app-core/services/loader.service';
import { AuthService } from './app-core/auth/auth.service';
import { CommonMethod } from './app-core/method/common-method';
import { ApiCommonService } from './app-core/services/api-common.service';
import { CommonService } from './app-core/services/common.service';
import { AppCommonModule } from './app/shared/app-common.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';

@NgModule({
    declarations: [
        RootComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RootRoutingModule,
        AppCommonModule.forRoot(),
        HttpClientModule,
    ],
    providers: [
        ApiCommonService,
        AuthService,
        CommonMethod,
        CommonService,
        LoaderService
    ],
    bootstrap: [RootComponent]
})

export class RootModule { }
