import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[NumericNumber]'
})

export class NumericNumberDirective {

    private regex: RegExp = new RegExp(/^\d+$/);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];

    @Input() minNumber?: number;
    @Input() maxNumber?: number;
    @Input() isNegativeNumberAllowed?: boolean;
    @Input() removeConcat?: boolean;

    @Output() ngModelChange = new EventEmitter();


    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);

        if (this.isNegativeNumberAllowed) {
            this.regex = new RegExp(/^[-+]?\d*$/);
        }

        if (!this.removeConcat) {
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
                return;
            }

            const currentValue = parseInt(next, null);
            if ((this.minNumber && currentValue < this.minNumber) || (this.maxNumber && currentValue > this.maxNumber)) {
                event.preventDefault();
            }
        } else {
            this.run(this.el.nativeElement.value);
        }
    }

    private check(value: string) {
        return String(value).match(new RegExp(this.regex));
    }


    private run(oldValue) {
        setTimeout(() => {
            let currentValue = this.el.nativeElement.value;
            if (currentValue !== '' && !this.check(currentValue)) {
                this.el.nativeElement.value = oldValue;
                this.ngModelChange.emit(this.el.nativeElement.value);
            }
            currentValue = parseInt(currentValue, null);
            if ((this.minNumber && currentValue < this.minNumber) || (this.maxNumber && currentValue > this.maxNumber)) {
                this.el.nativeElement.value = oldValue;
                this.ngModelChange.emit(this.el.nativeElement.value);
            }
        });
    }

}
