import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from 'src/app-core/services/common.service';
import { SSODashboardComponent } from 'src/app/sso-dashboard/sso-dashboard.component';

@Component({
    selector: 'app-bi-alert-dialog',
    templateUrl: './bi-alert-dialog.component.html',
    styleUrls: ['./bi-alert-dialog.component.scss']
})
export class BiAlertDialogComponent implements OnInit {

    constructor(
        public router: Router,
        public dialogRef: MatDialogRef<SSODashboardComponent>,
        private commonService: CommonService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onGoToNewTab() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/app/steps-to-enable-cookies`])
        );
        window.open(url, '_blank');
    }

    onCloseDialog() {
        this.dialogRef.close();
    }

    onCloseDialogPermanently() {
        this.commonService.setCookieConfirmationDialog(true);
        this.dialogRef.close();
    }

}
