export enum ReportActionEnum {
    edit = 1,
    delete = 2,
    runAndDownload = 3,
    viewLastReport = 4,
    resendReport = 5,
    generateAndSendReports = 6,
    enable_disable = 7
}

export enum ReportType {
    tracking = 0
}

export enum MoveType {
    inbound = 0,
    outbound = 1,
    drop = 2,
    shuttle = 3
}

export enum ScheduleFrequency {
    once = 0,
    daily = 1,
    weekly = 2,
    monthly = 3,
}

export enum ScheduleType {
    downloadNow = 1,
    schedule = 2
}

export enum ReportDaysCalculation {
    value = 15
}

export enum FormatType {
    pdf = 'pdf'
}

export enum ReportFormat {
    pdf = 0,
    excel_xls = 1,
    xlsx = 2
}

export enum ReportDownloadTypeSetting {
    type_Pdf = 0,
    type_Excel = 1,
    type_Xls_Xlsx = 2
}

export enum ShipmentDateType {
    booked = 1,
    schedulePickup = 2,
    pickup = 3
}
