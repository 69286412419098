import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-invoice-tab',
    templateUrl: './invoice-tab.component.html',
    styleUrls: ['./invoice-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceTabComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    invoiceSummaryModel = Object.assign({}, this.commonDataModel.invoiceSummaryModel);
    invoiceDownloadModel = Object.assign({}, this.commonDataModel.invoiceDownloadModel);
    invoiceDetailsModel = Object.assign({}, this.commonDataModel.invoiceDetailsModel);
    shipmentId: string;
    isLoading = false;
    invoiceId: string;
    moduleName: string;
    selectedTab: string;
    showNoRecord = false;
    selectedIndexValue = 0;
    selectedInvoiceNumber: number;
    selectedInvoiceData: any;
    invoiceList: Array<any> = [];
    accessMasterInvoice = false;
    showMasterInvoiceView = false;
    downloadInvoiceSummary = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
        this.invoiceDownloadModel.ownerId = this.commonService.getOwnerId();
        this.invoiceDetailsModel.ownerId = this.commonService.getOwnerId();
        this.invoiceDetailsModel.accountNumber = this.commonService.getAccountNumber();
    }

    ngOnInit() {
        this.isLoading = true;
        this.moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
        this.invoiceId = this.activeRoute.snapshot.queryParamMap.get('invoiceId');
        this.selectedTab = this.activeRoute.snapshot.queryParamMap.get('tabValue');
        this.accessMasterInvoice = JSON.parse(this.activeRoute.snapshot.queryParamMap.get('masterInvoice'));
        if (!this.accessMasterInvoice) {
            this.accessMasterInvoice = false;
        }
        if (!this.invoiceId) {
            this.invoiceId = null;
        }
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            if (this.shipmentId === '0') {
                this.shipmentId = null;
            }
            this.invoiceDownloadModel.shipmentId = this.shipmentId;
            this.loadItems();
        }));
    }

    loadItems(): void {
        this.commonService.getInvoices(this.shipmentId, this.invoiceId)
            .subscribe(response => {
                if (response && Array.isArray(response) && response.length) {
                    this.invoiceList = response;
                    this.showNoRecord = false;
                    if (this.invoiceId) {
                        this.invoiceList.find(list => {
                            if (list.invoiceId === this.invoiceId) {
                                this.selectedInvoiceNumber = list.invoiceNumber;
                                this.onShowInvoiceSummary(list);
                            }
                        });
                    } else {
                        this.selectedInvoiceNumber = this.invoiceList[0].invoiceNumber;
                        this.onShowInvoiceSummary(this.invoiceList[0]);
                    }
                } else {
                    this.isLoading = false;
                    this.showNoRecord = true;
                }
            });
    }

    onInvoiceSelect(data: any, type: string): void {
        if (type === CommonConstants.invoiceForm.masterInvoice) {
            this.accessMasterInvoice = true;
        } else {
            this.accessMasterInvoice = false;
        }
        this.selectedIndexValue = 0;
        this.selectedInvoiceNumber = data.invoiceNumber;
        this.onShowInvoiceSummary(data);
    }

    onShowInvoiceSummary(data: any): void {
        this.isLoading = true;
        this.selectedInvoiceData = data;
        if (this.accessMasterInvoice) {
            this.commonService.getMasterInvoiceSummary(data.masterInvoiceId)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    this.showMasterInvoiceView = true;
                    this.invoiceSummaryModel = Object.assign({}, this.commonDataModel.invoiceSummaryModel);
                    this.commonService.objectMapping(this.invoiceSummaryModel, response);
                });
        } else {
            this.invoiceDetailsModel.shipmentId = this.shipmentId;
            this.invoiceDetailsModel.invoiceId = data.invoiceId;
            this.commonService.getInvoiceSummary(this.invoiceDetailsModel)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    this.showMasterInvoiceView = false;
                    this.invoiceSummaryModel = Object.assign({}, this.commonDataModel.invoiceSummaryModel);
                    this.commonService.objectMapping(this.invoiceSummaryModel, response);
                });
        }
    }

    onDownloadInvoice(): void {
        this.downloadInvoiceSummary = true;
        if (this.showMasterInvoiceView) {
            this.invoiceDownloadModel.id = this.selectedInvoiceData.masterInvoiceId;
            this.commonService.postMasterInvoiceSummary(this.invoiceDownloadModel)
                .pipe(finalize(() => this.downloadInvoiceSummary = false))
                .subscribe(response => {
                    if (response.body) {
                        this.commonService.downloadBolbFile(response.body as any, { type: response.body.type }, 'Consolidated-Invoice.pdf');
                    }
                });
        } else {
            this.invoiceDownloadModel.id = this.selectedInvoiceData.invoiceId;
            this.commonService.postInvoiceSummary(this.invoiceDownloadModel)
                .pipe(finalize(() => this.downloadInvoiceSummary = false))
                .subscribe(response => {
                    if (response.body) {
                        this.commonService.downloadBolbFile(response.body as any, { type: response.body.type }, 'Invoice-Summary.pdf');
                    }
                });
        }
    }

    onCheckInvoiceDueDate(data: any) {
        let result = false;
        if (this.selectedTab !== CommonConstants.invoiceForm.complete &&
            data.status !== CommonConstants.invoiceForm.paid) {
            if (this.commonService.convertDate(data.invoiceDueDate) < this.commonService.convertDate(new Date())) {
                result = true;
            }
        }
        return result;
    }

    onTabChanged({ index }) {
        this.selectedIndexValue = index;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
