import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { MaintenanceConstants } from 'src/app-core/constants/maintenance-constants';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-upload-document-file',
    templateUrl: './upload-document-file.component.html'
})
export class UploadDocumentFileComponent implements OnInit {

    file: any;
    isLoading = false;
    uploadUrl: string;
    downloadFileName: string;
    displayFileSize: string;
    importStatusMessage: string;
    moduleName: any;
    uploadFileModel: any;
    resultData: any;
    commonDataModel = new CommonDataModel();
    allowedExtensions = ['png', 'jpeg', 'pdf', 'jpg', 'tif', 'jfif', 'pjpeg', 'pjp', 'gif', 'avif'];
    allowedExtensionsString = `.${this.allowedExtensions.join(',.')}`;
    maintenanceConstants: typeof MaintenanceConstants = MaintenanceConstants;

    constructor(
        private commonMethod: CommonMethod,
        private dialogRef: MatDialogRef<UploadDocumentFileComponent>) {
    }

    ngOnInit() { }

    onClose() {
        this.dialogRef.close(null);
    }

    onFileUpload(event: any) {
        if (event.target.files.length) {
            this.commonMethod.imageFileUploadValidation(event, true).then((response) => {
                this.resultData = response
                this.resultData.file = event.target.files[0];
                if (this.resultData.isFileValid) {
                    this.uploadFileModel = this.resultData;
                } else {
                    this.uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                }
            });
        }
    }

    onUploadFileData() {
        if (this.resultData.isFileValid) {
            this.dialogRef.close(this.resultData);
        } else {
            this.dialogRef.close(null);
        }
    }

}
