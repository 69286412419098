import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { ShipmentConstants } from 'src/app-core/constants/shipment-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { SignalrService } from 'src/app-core/services/signalr.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { CommonDataModel } from '../common-data-model';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';

export interface selectedParcelRate {
    parcelItem: any,
    parcelRate: any
}
@Component({
    selector: 'app-shipment-parcel-rates',
    templateUrl: './shipment-parcel-rates.component.html',
    styleUrls: ['./shipment-parcel-rates.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShipmentParcelRatesComponent extends AppComponentBase implements OnInit, OnChanges, OnDestroy {

    @ViewChild('kg', { static: true }) private grid: GridComponent;
    @Output() isRequestQuote: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() isPrintDocument: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    parcelRateListModel = Object.assign({}, this.commonDataModel.parcelRateListModel);
    shipmentRateListModel = Object.assign({}, this.commonDataModel.shipmentRateListModel);
    shipmentLCPDetailsModel = Object.assign({}, this.commonDataModel.shipmentLCPDetailsModel);
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    informationDataModel = this.commonDataModel.informationDataModel;
    isLoading = false;
    ratesForm: any;
    reRateBtn = false;
    displayLoadingText: string;
    rowSelectionList: number[] = [];
    gridData = { items: [], totalResults: 0 };
    moduleName: string;
    lcpCost: number;
    showCost = false;
    showLCPOnly = false;
    allowLCPSelectionOnly = false;
    isRequestQuoteBtn = true;
    isRequestBtnDisabled = false;
    isRedLineWarning = false;
    isRequestQuoteEnabled = false;
    fetchRateBtn = false;
    isPrintDocumentBtn = true;
    isRequestQuoteLoading = false;
    bookingMode = true;
    rateResponse: any;
    selectedParentRate = [];
    selectedChildRate = [];
    selectedParcelRates = [];
    suggectedRates = [];
    expandParentRow = [];
    expandChildRows = [];
    disableParcelRates = false;
    commonConstants: typeof CommonConstants = CommonConstants;
    shipmentConstants: typeof ShipmentConstants = ShipmentConstants;

    @Input() set fetchRateResponse(fetchRateResponse: any) {
        this.rateResponse = [];
        this.gridData.items = fetchRateResponse;
        this.rateResponse = fetchRateResponse;
        if (this.rateResponse && this.rateResponse.length) {
            this.rateResponse.sort((a, b) => a.totalCost - b.totalCost);
        }

        if (this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
            this.applySettings();
        }
    }

    @Input() set childData(data: any) {
        this.shipmentRateListModel = data;
    }
    @Input() set formStatus(formList: object) {
        this.ratesForm = formList;
    }
    @Input() set module(value: any) {
        this.moduleName = value;
    }
    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
    }
    @Input() set shipmentLCPData(data: any) {
        this.shipmentLCPDetailsModel = data;
    }
    @Input() set fetchRateBtnStatus(data: boolean) {
        this.fetchRateBtn = data;
    }
    @Input() set bookingModeValue(data: boolean) {
        this.bookingMode = data;
    }
    @Input() set requestQuoteBtnStatus(data: boolean) {
        this.isRequestBtnDisabled = data;
    }

    expandedRows: Set<any> = new Set();
    selectedShipmentMode = 0;
    selectedRateType: any;
    isColapseRow = [];
    expandedColapseRows = new Map<any, Set<any>>();

    constructor(
        injector: Injector,
        private matDialog: MatDialog,
        private commonService: CommonService,
        private signalrService: SignalrService) {
        super(injector);
    }

    isRowExpanded(dataItem: any): boolean {
        return this.expandedRows.has(dataItem);
    }

    toggleRow(dataItem: any, parent: any, rowType: any, type: any): void {
        if (dataItem.mode == this.commonConstants.parcelRateDropDownName.parcel) {
            if (this.expandedRows.has(dataItem)) {
                this.expandedRows.delete(dataItem);
            } else {
                this.expandedRows.add(dataItem);
            }
        }

        if (rowType == 'parent' && type != 'Parcel Rate') {
            dataItem.parentSelected = !dataItem.parentSelected;
            this.onChangeRateSelection(dataItem.parentSelected, parent, '', '', dataItem, rowType, type);
        }

    }



    toggleColapseRow(dataItem: any, colapsedItem: any, index: any): void {
        if (dataItem.type == this.commonConstants.rateTypes.parcelRate) {
            if (!this.expandedColapseRows.has(dataItem)) {
                this.expandedColapseRows.set(dataItem, new Set<any>());
            }
            const colapsedSet = this.expandedColapseRows.get(dataItem);

            if (colapsedSet.has(colapsedItem)) {
                this.isColapseRow[index] = false;
                colapsedSet.delete(colapsedItem);
            } else {
                this.isColapseRow[index] = true;
                colapsedSet.add(colapsedItem);
            }
        }
    }
    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.shipmentModeSelection.subscribe((value) => {
            this.selectedShipmentMode = value;
        }))
        this.componentSubscriptions.add(this.commonService.selectedRateValue.subscribe((value) => {
            this.selectedRateType = value;
        }))
        this.componentSubscriptions.add(this.commonService.getRefreshFetchRate.subscribe(data => {
            if (data) {
                this.showGridLoader();
            } else {
                this.hideGridLoader();
            }
        }));
        this.componentSubscriptions.add(this.commonService.getRefreshFetchRate.subscribe(data => {
            if (data) {
                this.showGridLoader();
            } else {
                this.hideGridLoader();
            }
        }));
        this.componentSubscriptions.add(this.signalrService.hubConnection.on('ratingProgressStatus', (message) => {
            if (message) {
                this.displayLoadingText = message;
            }
        }));

        if (this.commonService.permissionCheckByName(CommonConstants.permissionName.checkRequestQuoteAccess)) {
            this.isRequestQuoteEnabled = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            switch (this.moduleName) {
                case CommonConstants.moduleName.quickRate:
                    this.isRequestQuoteBtn = false;
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.isPrintDocumentBtn = false;
                    this.applySettings();
                    break;
            }
        }
    }

    onSelectedKeysChange(selectedRate: any): void {
        const selectedRowId = selectedRate.id;
        const mgRateList = [];
        this.rateResponse.map((data) => {
            if (data.mode == 'LTL') {
                mgRateList.push(data);
            }
        })

        if (selectedRate.parentSelected == true) {
            this.clearChildSelection();
        }

        if (selectedRowId && selectedRate['mode'] != 'Parcel') {
            const selecteMgRate = mgRateList.find(list => list.id === selectedRowId);
            if (selecteMgRate) {
                this.commonService.objectMapping(this.shipmentRateListModel, selecteMgRate);
                this.clearChildSelection();
                this.suggectedRates.splice(0, this.suggectedRates.length);
            }
            else {
                const parcelRateSelected: selectedParcelRate = {
                    parcelItem: selectedRate.parcelItem,
                    parcelRate: selectedRate
                };

                if (selectedRate.selected) {
                    this.selectedParcelRates.push(parcelRateSelected);
                    this.clearSelection();
                } else {
                    this.selectedParcelRates.splice(this.selectedParcelRates.indexOf(selectedRate.id), 1);
                }
            }

            this.ratesForm.ratesForm = true;
            const lowestRateData = _.minBy(mgRateList, 'totalCost');
            const minFilterData = mgRateList.filter(list => list.totalCost <= lowestRateData.totalCost);

            if (minFilterData.length) {

                const filterData = minFilterData.find(list => list.id === selectedRowId);

                if (filterData) {
                    this.commonService.objectMapping(this.shipmentLCPDetailsModel, filterData);
                    this.shipmentRateListModel.isLowestRate = true;
                    this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.lowRate);
                } else {
                    this.commonService.objectMapping(this.shipmentLCPDetailsModel, lowestRateData);
                    this.shipmentRateListModel.isLowestRate = false;
                    this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.highRate);
                }

                this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.carrierId);
            }
        } else {
            this.clearMGRateSelection();
            if (selectedRate.parentSelected == true) {
                this.selectedParcelRates.splice(0, this.selectedParcelRates.length);
                const ratesToSelect = selectedRate.rateCharges;
                ratesToSelect.map((rate: any) => {
                    const parcelRateSelected: selectedParcelRate = {
                        parcelItem: rate.parcelItem,
                        parcelRate: rate
                    };

                    this.suggectedRates.push(parcelRateSelected);
                });

            }
            else {
                this.clearSelection();
            }
        }
    }

    showGridLoader(): void {
        this.isLoading = true;
        this.displayLoadingText = CommonConstants.loaderDisplayText;
    }

    hideGridLoader(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    }

    applySettings() {
        if (this.shipmentSettingsModel && Array.isArray(this.gridData.items) && this.gridData.items.length) {

            let minFilterData = _.minBy(this.gridData.items, 'totalCost');

            if (minFilterData) {
                minFilterData = new Array(minFilterData);
                this.lcpCost = minFilterData[0].totalCost;
            }

            this.allowLCPSelectionOnly = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.allowLCPSelectionOnly];
            this.showCost = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.showCost];
            this.showLCPOnly = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.showLCPOnly];
        }
    }

    onRequestQuote(): void {
        this.isRequestQuoteLoading = true;
        this.isRequestQuote.emit(true);
    }

    checkRequestQuoteEnabled(): boolean {
        if ((this.isRequestQuoteEnabled || this.isRedLineWarning) && this.isRequestQuoteBtn && this.fetchRateBtn) {
            return true;
        }
        return false;
    }

    onPrintDocument(): void {
        this.isPrintDocument.emit(true);
    }

    onShowRedLinePopup(): void {
        this.informationDataModel = this.commonService.setInformationDialogStatus(
            CommonConstants.shipmentRateForm.redLineWarningError, 'warning_amber', '#ff4747', '45px', 'Ok', false);
        this.matDialog.open(InformationDialogComponent, {
            panelClass: 'upload-dialog-wrapper',
            hasBackdrop: false,
            data: this.informationDataModel
        });
    }

    onChangeRateSelection(e, parentIndex: any, childIndex: any, index: any, item: any, type: any, recordType: any) {
        this.selectedParentRate = [];
        this.commonService.selectedRateMethod(item.mode);
        this.rateResponse.map((data: any, position: any) => {
            if (parentIndex != position) {
                this.rateResponse[position].parentSelected = false;
            }
        })

        if (type == 'child') {
            if (e) {
                this.rateResponse[parentIndex].rateCharges[childIndex].selectedRate = item;
            } else {
                this.rateResponse[parentIndex].rateCharges[childIndex].selectedRate = null;
            }

            if (this.selectedParcelRates.length) {
                this.selectedParcelRates.map((index) => {
                    this.selectedParcelRates.splice(index, 1);
                })
            }

            if (this.selectedChildRate.length && e) {
                this.rateResponse[parentIndex].rateCharges[childIndex].rateCharges.map((data, pos) => {
                    const itemPosition = this.selectedChildRate.findIndex((x) => _.isEqual(x, data));
                    if (itemPosition != -1) {
                        this.selectedChildRate.splice(itemPosition, 1);
                    }
                })
            }


            this.rateResponse[parentIndex].rateCharges[childIndex].rateCharges.map((rate: any, pos: any) => {
                if (index != pos) {
                    this.rateResponse[parentIndex].rateCharges[childIndex].rateCharges[pos].selected = null;
                    this.onSelectedKeysChange(rate);
                } else {
                    if (e) {
                        this.selectedChildRate.push(rate);
                    } else {
                        const deleteIndex = this.selectedChildRate.findIndex((x) => x.id == item.id);
                        if (deleteIndex != -1) {
                            this.selectedChildRate.splice(deleteIndex, 1);
                        }
                    }
                    this.clearSelection();
                }
            })

            if (this.selectedChildRate.length === this.rateResponse[parentIndex].rateCharges.length) {
                this.rateResponse[parentIndex].totalCost = 0;
                this.selectedChildRate.map((data) => {
                    this.rateResponse[parentIndex].totalCost = Number(this.rateResponse[parentIndex].totalCost) + Number(data.amount);
                })

                let maxTransitTime = '';
                let minTransitTime = '';
                let days = '';

                const getMaxDays = this.selectedChildRate.sort((a, b) => parseInt(b.transitTime) - parseInt(a.transitTime));
                maxTransitTime = getMaxDays[0].transitTime;

                const getMinDays = this.selectedChildRate.sort((a, b) => parseInt(a.transitTime) - parseInt(b.transitTime));
                minTransitTime = getMinDays[0].transitTime;
                days = maxTransitTime == minTransitTime ? `${maxTransitTime}` : `${minTransitTime} - ${maxTransitTime}`;
                this.rateResponse[parentIndex].transitTime = days;

                this.rateResponse[parentIndex].parentSelected = true;
                const obj = {
                    rateType: recordType,
                    selectedRates: this.selectedChildRate
                }

                this.ratesForm.ratesForm = true;
                this.commonService.sendSelectedRate(obj);
            } else {
                this.rateResponse[parentIndex].transitTime = '';
                this.rateResponse[parentIndex].totalCost = 0;
            }

            if (!this.selectedParentRate.length && this.selectedChildRate.length != this.rateResponse[parentIndex].rateCharges.length) {
                this.ratesForm.ratesForm = false;
            }
            this.clearMGRateSelection();
        }

        if (type == 'parent') {
            if (this.selectedChildRate.length) {
                this.selectedChildRate.map((d, index) => {
                    this.selectedChildRate.splice(index, 1);
                })

                this.rateResponse.map((data, index) => {
                    if (data.type == this.commonConstants.rateTypes.parcelRate) {
                        this.rateResponse[index].totalCost = 0;
                        data.rateCharges.map((rate) => {
                            rate.rateCharges.map((rateObject) => {
                                rateObject.selected = false;
                                rateObject.selectedRate = null;
                            })
                        })
                    }
                })
            }
            if (item.parentSelected == true) {
                this.rateResponse.map((rate: any) => {
                    rate.rateCharges.map((data: any) => {
                        data.selected = null;
                    })
                });
            }

            if (item.id) {
                this.rateResponse.map((data: any, i: any) => {
                    if (parentIndex != i) {
                        data.parentSelected = null;
                    }
                    else {
                        if (e) {
                            this.selectedParentRate.push(data);
                        } else {
                            const deleteIndex = this.selectedParentRate.findIndex((x) => x.id == item.id);
                            if (deleteIndex != -1) {
                                this.selectedParentRate.splice(deleteIndex, 1);
                            }
                        }

                        if (item.mode == 'LTL')
                            this.onSelectedKeysChange(data);
                    }
                });
            } else {
                if (e) {
                    this.selectedParentRate.push(item);
                    this.ratesForm.ratesForm = true;
                }
                this.onSelectedKeysChange(item);
            }
            const obj = {
                rateType: recordType,
                selectedRates: this.selectedParentRate
            }
            this.commonService.sendSelectedRate(obj);
        } else {
            this.clearMGRateSelection();
        }

        if (!e) {
            this.clearMGRateSelection();
        }

        if (!this.selectedParentRate.length && !this.selectedChildRate.length) {
            this.ratesForm.ratesForm = false;
        }
    }

    showBookingCount() {
        if (this.selectedShipmentMode == this.commonConstants.parcelRateDropDownValue.parcel || this.selectedRateType == this.commonConstants.parcelRateDropDownName.parcel) {
            return 4;
        } else {
            return 5;
        }
    }

    clearSelection() {
        this.rateResponse.map((data: any) => {
            data.parentSelected = null;
            this.suggectedRates.splice(0, this.suggectedRates.length);
        })
    }

    clearMGRateSelection() {
        const rateObject = Object.assign({}, this.commonDataModel.shipmentRateListModel);
        const lCPDetailsModel = Object.assign({}, this.commonDataModel.shipmentLCPDetailsModel);
        this.commonService.objectMapping(this.shipmentRateListModel, rateObject);
        this.commonService.objectMapping(this.shipmentLCPDetailsModel, lCPDetailsModel);
        this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.hidden);
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.carrierId);
    }

    clearChildSelection() {
        this.rateResponse.map((data: any) => {
            data.selected = null;
            this.selectedParcelRates.splice(0, this.selectedParcelRates.length);
        })
    }

    resetRates(): void {
        this.clearSelection();
        this.clearChildSelection();
        this.gridData = { items: [], totalResults: 0 };
    }

    isVendorModule() {
        return this.moduleName === CommonConstants.moduleName.LTLVendorBooking;
    }

    enableReRate(): void {
        this.reRateBtn = true;
        this.rateResponse = [];
        this.suggectedRates = [];
        this.selectedParcelRates = [];
        this.clearSelection();
        this.clearChildSelection();
    }

    isRowEnable(dataItem: any) {
        if (dataItem.mode == this.commonConstants.parcelRateDropDownName.parcel) {
            return false;
        } else {
            return (this.allowLCPSelectionOnly && dataItem.totalCost !== this.lcpCost ||
                !dataItem.isSelectable)
        }
    }

    getValue(data: any, type: any, key: any) {
        if (type === 'Parcel Rate') {
            return '';
        } else {
            if (key === 'servicelevel') {
                return data.parcelRate.servicelevel.name;
            } else {
                return data.parcelRate[key];
            }
        }
    }

    getItemName(data: any, type: any) {
        if (type == 'Parcel Rate') {
            return data.itemName;
        } else {
            return data.parcelItem.itemName;
        }
    }

    getColorCode(type?: string, index?: any, packageInfo?: string, isSelected?: boolean) {
        if (!isSelected) {
            if (index) {
                return '#CCE9FC';
            } else {
                if (type == 'Parcel Rate' || packageInfo == 'show-package') {
                    return '#CCE9FC';
                } else {
                    return '#fff';
                }
            }
        } else {
            return '#4580ba';
        }
    }

    highlightParentRow(data) {
        if (!data.parentSelected && data.mode == 'LTL' && data.isRedLineWarning) {
            return '#ff8080';
        } else if (data.parentSelected) {
            return '#4580ba'
        } else {
            return '';
        }
    }

    getTextColor(isSelected) {
        if (!isSelected) {
            return '#757575'
        } else {
            return '#fff';
        }
    }

    getRedLineWarningColor(isSelected) {
        if (!isSelected) {
            return '#757575'
        } else {
            return '#fff';
        }
    }

    getTotalCost(value) {
        if (value) {
            return 'Selected';
        } else {
            return 'Not Selected';
        }
    }

    getRecordType(value: string) {
        if (value == this.commonConstants.rateTypes.parcelRate) {
            return 'Ala Carte';
        } else {
            return value;
        }
    }

    getLTLMode(value: string) {
        if (value == this.commonConstants.parcelRateDropDownName.parcel) {
            return true;
        } else {
            return false;
        }
    }

    getRateType(value: string) {
        if (value == this.commonConstants.rateTypes.parcelRate) {
            return true;
        } else {
            return false;
        }
    }

    getAttributes(value: any) {
        if (value && value.length) {
            value.map((data, index) => {
                if (data === 'BESTVALUE') {
                    value.splice(index, 1);
                } else {
                    return value;
                }
            })
            return value;
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.signalrService.onDisconnect();
    }
}
