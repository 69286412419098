import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { CommonDataModel } from '../common-data-model';
import { ContactListComponent } from '../contact-list/contact-list.component';

@Component({
    selector: 'app-shipment-address-book',
    templateUrl: './shipment-address-book.component.html',
    styleUrls: ['./shipment-address-book.component.scss'],
})

export class ShipmentAddressBookComponent extends AppComponentBase implements OnInit {

    @ViewChild('contactGrid', { static: true }) public contactGrid: ContactListComponent;
    @ViewChild('childGrid', { static: true }) public childGrid: GridComponent;
    commonDataModel = new CommonDataModel();
    selectedLocation = this.commonDataModel.addressBookModel;
    searchLocationModel = this.commonDataModel.searchLocationModel;
    rowSelectionList: number[] = [];
    shipmentAddressData = [];
    showRecord = false;
    isLoading = false;
    totalResults = 0;
    constructor(
        injector: Injector,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private dialogRef: MatDialogRef<ShipmentAddressBookComponent>,
        @Inject(MAT_DIALOG_DATA) searchQuery?: any) {
        super(injector);
        this.commonService.objectMapping(this.searchLocationModel, searchQuery);
    }

    ngOnInit() {
        this.searchLocationModel.results = this.kendogridHelper.defaultRecordsCountPerPage;
        this.searchLocationModel.skipCount = this.kendogridHelper.skipCount;
        this.searchLocationModel.sortByAscending = false;
        this.loadItems();
    }

    loadItems(): void {
        this.isLoading = true;
        this.commonService.getSearchAddress(this.searchLocationModel).subscribe(response => {
            this.shipmentAddressData = response.items;
            this.totalResults = response.totalResults;
            this.isLoading = false;
            this.showRecord = true;
        });
    }

    onPageChange(event: any): void {
        this.isLoading = true;
        this.kendogridHelper.skipCount = event.skip;
        this.searchLocationModel.results = event.take;
        this.searchLocationModel.skipCount = event.skip;
        this.loadItems();
    }

    onSearchFilter(searchInput: string): void {
        if (searchInput && searchInput.length >= 3) {
            this.loadItems();
        } else {
            if (!searchInput) {
                this.loadItems();
            }
        }
    }

    onClearSearch(): void {
        this.searchLocationModel.searchFor = null;
        this.loadItems();
    }

    onCellClickHandler(list: any): void {
        this.onSelectLocation(list.dataItem);
    }

    receiveSelectedAddress($event: any) {
        this.onSelectLocation($event);
    }

    onSelectLocation(list: any): void {
        const responseData = Object.assign({}, list);
        this.commonService.objectMapping(this.selectedLocation, responseData);
        delete this.selectedLocation.contacts;
        if (Array.isArray(responseData.contacts) && responseData.contacts.length) {
            const contacts = responseData.contacts.find(obj => obj.isDefault);
            if (contacts) {
                const locationId = this.selectedLocation.id;
                this.commonService.objectMapping(this.selectedLocation, contacts);
                this.selectedLocation.id = locationId;
                this.selectedLocation.contactId = contacts.id;
            }
        }
        this.dialogRef.close({ addressResult: this.selectedLocation });
    }

    onClose(): void {
        this.dialogRef.close({ addressResult: {} });
    }

    showShipperServices(item: any) {
        return this.commonMethod.showRequiredServices(this.searchLocationModel.moduleName, this.searchLocationModel.showShipperServices, item);
    }

    showConsigneeServices(item: any) {
        return this.commonMethod.showRequiredServices(this.searchLocationModel.moduleName, this.searchLocationModel.showConsigneeServices, item);
    }

}
