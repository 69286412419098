import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export class ValidateHelper {
    static validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}

export function rangeValidator(control: FormGroup, fromField, toField): ValidationErrors | null {
    const fromRange = control.value[fromField];
    const toRange = control.value[toField];
    return fromRange && toRange && Number(fromRange) > Number(toRange)
        ? { fromGreaterThanTo: true }
        : null;
}
