export class CommonConstants {

    static readonly clientVersion = '1.0.0';

    static readonly defaultCountryCode = 'US';

    static readonly moveType = 'moveType';

    static readonly successMessageSent = 'Message sent successfully.';

    static readonly SSODashboardErrorMessage = 'No configuration set for Dashboard.';

    static readonly passwordFormSuccessMessage = 'Password updated successfully.';

    static readonly profileFormSuccessMessage = 'Profile updated successfully.';

    static readonly applicationFormSuccessMessage = 'Application settings updated successfully.';

    static readonly APITimeoutErrorMessage = 'Oops! This is taking longer than usual. ' +
        'Please refresh the page or contact your administrator.';

    static readonly ClaimsRequestTimeOutError = 'Oops! This is taking longer than usual. ' +
        'Your claim form has been submitted.  It may take up to 24 hours before your claim status appears here in the ViewPoint portal.';

    static readonly loaderDisplayText = 'Securing Connection';

    static readonly insuranceText = 'Purchasing Insurance'

    static readonly generatingReportsText = 'Generating Reports';

    static readonly loginSuccessMessage = 'Login successfull';

    static readonly errorBookingMessage = 'something went wrong';

    static readonly errorFromMG = 'Error occurred while sending shipment to MG.';

    static readonly rootEnterpriseId = {
        rootId: '7A5BCF6A-B1EE-4E98-BBFD-A461D4A97161'
    }

    static readonly locationTypes = {
        shipperLocation: 'shipperlocation',
        consigneelocation: 'consigneelocation'
    };

    static readonly trackingStatus = {
        Booked: 'Booked',
        InTransit: 'In Transit',
        ManualQuote: 'Manual Quote',
        Delivered: 'Delivered',
        OnHold: 'On Hold',
        Pending: 'Pending',
        Rated: 'Rated',
        Incomplete: 'Incomplete',
        Invoiced: 'Invoiced'
    };

    static readonly parcelRateDropDownName = {
        parcel: 'Parcel',
        ltl: 'LTL'
    };

    static readonly rateTypes = {
        cheapest: 'Cheapest',
        fastest: 'Fastest',
        parcelRate: 'Parcel Rate',
        carrierSpecific: 'Carrier Specific',
        usps: 'USPS',
        ups: 'UPS'
    };

    static readonly parcelRateDropDownValue = {
        ltlAndParcel: 0,
        ltl: 1,
        parcel: 2
    };

    static readonly moduleName = {
        shipmentTracking: 'Shipment Tracking',
        scheduleTruckload: 'TruckLoad',
        scheduleShipment: 'Schedule Shipment',
        scheduleLTL: 'LTL',
        bolOnly: 'BOL Only',
        quickRate: 'Quick Rate',
        invoice: 'Invoice',
        trackingGridView: 'Tracking Grid View',
        LTLVendorBooking: 'LTL Vendor Booking',
        TLVendorBooking: 'TL Vendor Booking',
        publicShipmentTracking: 'Public Shipment Tracking',
        parcel: 'Parcel'
    };

    static readonly referenceSettingsModuleName = {
        scheduleShipment: 'SS',
        vendorBooking: 'VB'
    };

    static readonly routeName = {
        ltlRoute: 'scheduleShipment',
        tlRoute: 'scheduleTruckload',
        bolRoute: 'scheduleBOL'
    };

    static readonly residentialLocationAccess = {
        residentialAddressAccess: 'IsResidentialCheckboxAccess'
    };

    static readonly updatePasswordPermission = {
        updatePasswordAccess: 'UpdatePassword'
    };

    static readonly editRequiredServicePermission = {
        editRequiredService: 'EditServices'
    };

    static readonly AllowClassModification = {
        ss_classmodification: 'SS_AllowClassModification',
        vr_classmodification: 'VR_AllowClassModification'
    };

    static readonly residentialCheckboxStatus = {
        residential: 'Yes',
        nonResidential: 'No',
        unknown: 'Unknown'
    };

    static readonly temperatureUnits = {
        fahrenheite: 'F',
        celcius: 'C',
    };

    static readonly temperatureTypes = {
        maximumTemperature: 'maximumTemperature',
        minimumTemperature: 'minimumTemperature'
    };

    static readonly documentSetting = {
        truckLoadSetting: 'TL_Documents',
        scheduleShipmentSetting: 'SS_Documents',
        ltlVendorSetting: 'VR_Documents',
        tlVendorSetting: 'VR_TLDocuments'
    };

    static readonly moduleTitle = {
        invoice: 'Invoice',
        tracking: 'Tracking',
        vendorTracking: 'Vendor Tracking'
    };

    static readonly pageName = {
        shipmentDocument: 'shipmentDocument',
        trackingDetails: 'trackingDetails',
        trackingMapView: 'trackingMapView'
    };

    static readonly referenceSettingCode = {
        allCode: 'all',
        lcpeCode: '!lcp',
        carrierId: '!cid',
        itemCode: '!item',
        serviceCode: '!svc',
        locationCode: '!loc',
        shipperLocationCode: '!sloc',
        consigneeLocationCode: '!cloc'
    };

    static readonly fileFormatConstants = {
        xml: 'xml',
        xls: 'xls',
        xlsx: 'xlsx',
        csv: 'csv'
    };

    static readonly shipmentDocumentType = {
        bol: 'BOL',
        label: 'Label',
        quote: 'Quote Form',
        summary: 'Summary'
    };

    static readonly shipmentRate = {
        hidden: 'hidden',
        lowRate: 'lowRate',
        highRate: 'highRate'
    };

    static readonly paramName = {
        new: 'new',
        scheduleShipment: 'schedule-shipment',
        scheduleTruckLoad: 'schedule-truckload'
    };

    static readonly gridName = {
        eproGrid: 'epro',
        userGrid: 'Users',
        rolesGrid: 'Roles',
        ownerGrid: 'owners',
        tracking: 'tracking',
        reportGrid: 'report',
        itemGrid: 'ownerItems',
        loginLogsGrid: 'loginLogs',
        settingsGrid: 'ownerSetting',
        carrierConfigGrid: 'Carrier Config',
        locationGrid: 'ownerLocations',
        contractGrid: 'Contract Config',
        referenceTypeGrid: 'referenceType',
        enterpriseGrid: 'Enterprise Config',
        referenceSettingsGrid: 'referenceSettings',
        ssoBISettingsGrid: 'ssoBISettings',
        whiteLabelingGrid: 'whiteLabeling',
        vendorTracking: 'vendorTracking',
        invoiceGrid: 'invoice',
        carrierGrid: 'carrier',
        TLVendorTracking: 'TLVendorTracking',
        ipGrid:'PRO search ip details'
    };

    static readonly locationSettingsName = {
        defaultShipLocation: 'DefaultShipLocation',
        defaultConsLocation: 'DefaultConsLocation',
        defaultBillToLocation: 'DefaultBillToLocation'
    };

    static readonly modulePrefix = {
        scheduleShipment: 'SS_',
        bolOnly: 'BO_',
        ltlVendor: 'VR_',
        tlVendor: 'VR_TL'
    };

    static readonly addressType = {
        originAddressList: 'originAddressList',
        destinationAddressList: 'destinationAddressList'
    };

    static readonly shipmentItemConstants = {
        itemCleared: 'Item has been cleared.',
        itemRemoved: 'Item has been removed.',
        nestedItemToolTip: 'Select this when you have a pallet or other container that contains multiple items of different classes.',
        conformationPopup: 'The default item Id will now be replaced by the currently added entries. ' +
            'Please confirm to proceed.',
        overrideWarning: 'Selecting this item has overridden one or more of the values you had previously entered. ' +
            'Please check your values to be certain they are correct.',
        shippoParcelToolTip: 'Please click here for parcel authorization.'
    };

    static readonly appConstant = {
        reorder: 'reorder',
        addForm: 'addForm',
        editForm: 'editForm',
        recipient: 'Recipient',
        recipients: 'Recipients',
        emailRecipients: 'Email Recipients',
        itemRemovedMessage: 'Item has been removed.',
        deletePopupTitle: 'Do you want to delete these items?',
        activatePopupTitle: 'Do you want to reactivate these items?',
        defaultSettingConfirmationMessage: 'Are you sure you want to update the setting ?',
        deleteRecordsSuccessMessage: ' records deleted successfully.',
        activateRecordsSuccessMessage: ' records activated successfully.',
        enterpriseErrorMessage: 'Please enter a valid enterprise name.',
        invalidXMLFormate: 'Invalid setting value. The string to be encoded contains characters outside of the Latin1 range.',
        invalidEmailErrorMessage: 'Invalid email. Please enter a valid email.',
        emailCountErrorMessage: 'The maximum no. of emails that can be added are ',
        savedDefinition: 'Saved Definition updated successfully.',
        defaultDefinition: 'Default Definition updated successfully.',
        zipCodeErrorMessage: 'Please enter a valid zip code.',
        formMandatoryErrorMessage: 'Please fill all the mandatory fields.',
        deleteErrorMessage: 'One or more of the selection cannot be deleted as you do not have the required permission. Please recheck the selection to proceed.',
        invalidFileFormat: 'File type not supported',
        imageFileSizeMessage: 'File size is more than',
        carrierTrackLinkTooltip: `Check tracking on the carrier's website. The Pro will be copied to the clipboard.`,
        noRecordsAvailable: 'No records available.',
        confirmLogOutMessage: 'Do you want to logout all the selected users?',
        logOutSuccessMessage: ' logged out successfully.',
        invalidZipCodeErrorMessage: 'Zipcode is missing the required city or state name. Please re-enter zipcode and try fetching the rates again.',
        parcelLableErrorMessage: 'Error while creating the purchase labels.',
        parcelShippoTokenError: 'Parcel Authorization Failed.',
        parcelShippoTokenSuccess: 'Succesfully Authorized Parcel.'
    };

    static readonly buttonName = {
        fetchRate: 'Fetch Rate',
        reRate: 'Re Rate'
    };

    static readonly bookingType = {
        saveQuote: 'SaveQuote',
        bookingShipment: 'BookingShipment'
    };

    static readonly shipmentBooking = {
        forFutureReference: ',<br/>for future reference.',
        shipmentSuccessMessage: 'Shipment has been booked successfully.',
        saveQuoteSuccessMessage: 'Save Quote Successful. <br/>Here is the Shipment ID: ',
        requestQuoteSuccessMessage: 'Request Quote Successful. <br/>Here is the Shipment ID: ',
        resetPopupTitle: 'All the entered information will be cleared, are you sure you want to proceed?',
        shipmentBookingModeSuccess: 'The booking request has been submitted.',
        bookAndWaitTooltip: 'The shipment will be booked and you will wait while the process is completed.',
        bookAndNotifyTooltip: 'This option will send the booking request to the server and allow you to continue working. When the booking process is complete, you will receive a notification which will allow you to fetch the documents.',
        notifyOnlyLCPTooltip: 'The shipment will be booked (using the least cost provider) and you will receive a notification when the booking is complete.',
        notifyOnlyNotLCPTooltip: 'The shipment will be booked with the selected rate and you will receive a notification when the booking is complete.',
        notifyAndEmailLCPTooltip: 'The shipment will be booked (using the least cost provider) and you will receive a notification and email when the booking is complete.',
        notifyAndEmailNotLCPTooltip: 'The shipment will be booked with the selected rate and you will receive a notification and email when the booking is complete.',
        saveQuoteTooltip: 'You must select a Rate to enable this function.',
        TLrequestQuoteSuccessMessage: 'A TL quote was requested successfully. <br/>A member of the operation team will shortly reach out to you.',
        TLSaveBookingConfirmationMessage: 'By clicking ‘Yes’ below, you are requesting that Translogistics obtain the best rate and book this shipment with the information provided here.  Do you wish to continue?',
        residentialMisMatchMessage: 'You have a residential address without a matching residential service selected.  Do you wish to continue?',
        residentialSuccessMessage: 'One of your addresses has been determined to be residential.  Please be sure to select the appropriate Residential service.',
        quoteMandatoryMessage: 'please ensure to fill addresses and items.',
        residentailErrorMessage: 'It appears that you have an incorrect value for the Residential status of this location.  Would you like to save with a corrected value? Selecting “No” will save with the value you specified.',
        locationInstructionsMessage: 'Would you like to save the recent changes in instructions to this location?',
        resetServiceMessage: 'Automatic services were de-selected.  Please review your services to make sure they are correct.',
        generateReportWarningMessage: 'Please do not close the browser as it would take a bit longer than usual to generate the reports. You can continue using the application and once the reports are generated, it will be downloaded automatically.',
        passwordUpdationMessage: 'Your password has been changed and you will be logged out of all sessions.',
        disabledBookingPageMessage: 'This feature is unavailable at this time.<br/>Please contact your TLI Account or Sales Representative for further assistance.',
        MGtimeOutMessage: 'The booking process is taking longer than expected. Please wait a few minutes and check the Tracking page for your shipment. You can access your shipping documents from there. Alternately, you can contact your Customer Service representative at TLI for assistance. To see the current status of all TLI services, click',
        parcelInformationToolTip: "Check for both LTL and Parcel rates. Click for more information."
    };

    static readonly printDocumentForm = {
        fileDownloadErrorMessage: '.pdf document not downloaded.',
        errorLoadDocumentMessage: 'Error load document.',
        emailSuccessMessage: 'Email updated successfully.',
        browserPopupErrorMessage: 'Please check your browser settings to allow pop-up.'
    };

    static readonly confirmationDialogBody = {
        requestQuote: 'requestQuote',
        exportConfirmation: 'exportConfirmation',
        default: 'default'
    };

    static readonly definitionForm = {
        addFormSuccessMessage: 'Definition added successfully.',
        updateFormSuccessMessage: 'Definition updated successfully.',
        defaultDefinitionSuccessMessage: 'Default definition has been changed successfully.',
        deletePopupTitle: 'Do you want to delete this item?',
        deleteSuccessMessage: 'Definition deleted successfully.',
    };

    static readonly browserName = {
        chrome: 'Chrome',
        firefox: 'Firefox',
        edge: 'Edge'
    };

    static readonly trackingMapForm = {
        active: 'Active',
        gridView: 'gridView',
        mapView: 'mapView',
        modes: 'Modes',
        directions: 'Directions'
    };

    static readonly itemForm = {
        contacts: ' contacts.',
        items: ' items.',
        linearFeet: 'linearFeet',
        totalPallets: 'totalPallets',
        parentItem: 'parentItem',
        handlingUnit: 'handlingUnit',
        maxCountMessage: 'We can add maximum ',
        addFormSuccessMessage: 'Item added successfully.',
        updateFormSuccessMessage: 'Item updated successfully.'
    };

    static readonly addressForm = {
        level: ' level?',
        phone: 'Phone',
        email: 'Email',
        shipperPhone: 'shipperPhone',
        consigneePhone: 'consigneePhone',
        addFormSuccessMessage: 'Location added successfully.',
        updateFormSuccessMessage: 'Location updated successfully.',
        addFormErrorMessage: 'Location not added successfully.',
        updateFormErrorMessage: 'Location not updated successfully.',
        popupUpdateFormTitle: 'Do you wish to update the location record at the ',
        popupAddFormTitle: 'Do you wish to save a new location at the ',
    };

    static readonly treePopupForm = {
        updateFormErrorMessage: 'Enterprise is not updated.',
        updateFormSuccessMessage: 'Enterprise updated successfully.'
    };

    static readonly APICallCommon = {
        errorMessageTitle: 'Alert',
        errorMessagePopupTitle: 'Alert (Click to expand)',
        serverErrorMessage: 'Internal server error.',
        sessionErrorMessage: 'Your Session is Timeout, Please re-login.',
        permissionErrorMessage: 'You do not have permission to access this module.',
        settingPermissionErrorMessage: 'You do not have permission to access this setting.'
    };

    static readonly shipmentTrackingForm = {
        origin: 'origin',
        destination: 'destination',
        invalidURL: 'Please enter a valid url',
        formSuccessMessage: 'Shipment tags updated succesfully',
        formErrorMessage: 'Recaptcha failed. Please try again'
    };

    static readonly invoiceForm = {
        current: 'Current',
        complete: 'Complete',
        pastDue: 'PastDue',
        paid: 'Paid',
        invoice: 'invoice',
        masterInvoice: 'masterInvoice',
        masterInvoiceNumber: 'masterInvoiceNumber',
        commentsFormAddMessage: 'Comments added succesfully',
        commentsFormUpdateMessage: 'Comments updated succesfully',
        paymentPendingMessage: 'Indicates that a payment has been made but has not yet posted to your account. ' +
            'This could take up to 48 hours.'
    };

    static readonly shipmentRateForm = {
        LCPSelectionText: 'If alternate carrier selection is required, please contact TLI 610-280-3210, CSTeam@tli.email',
        redLineWarning: 'The rates highlighted in red below may not be valid based on the shipment’s current configuration. ' +
            'Please contact TLI Client Services for an accurate rate quote.',
        requestQuoteToolTip: 'TLI Client Services will receive an email with your shipment information ' +
            'and you will be contacted with a valid rate quote.',
        printPageRedLineWarning: 'The above rate may not be valid due to the shipment configuration.',
        requestQuoteSuccess: 'An email to TLI Client Services has been sent. You should be contacted shortly during normal business hours.',
        redLineWarningError: 'LTL carriers often have difficulty providing an accurate automatic rate quote once a shipment exceeds certain parameters, such as length, (ex. 96"+) cubic volume, (ex. 750 cubic ft+) and/or density (ex. <6 lbs. per cubic foot). Since the LTL carriers all have varying rules, please contact Translogistics Client Services Department at the number in the lower left corner of your page to ensure you have an accurate rate quote for this shipment.',
        parcelWarningError: 'This shipment may qualify for Parcel rates. Parcel rates, however, can only be shown in the Schedule Shipment module.',
    };

    static readonly settingsName = {
        SS_TLMaxItemRows: 'SS_TLMaxItemRows',
        SS_MaxItemRows: 'SS_MaxItemRows',
        SN_MaxShipmentforStatusUpdate: 'SN_MaxShipmentforStatusUpdate',
        SS_MaximumItemIdLen: 'SS_MaximumItemIdLen',
        SS_TLMaximumItemIdLen: 'SS_TLMaximumItemIdLen',
        BO_MaxItemRows: 'BO_MaxItemRows',
        VR_MaxItemRows: 'VR_MaxItemRows',
        VR_TLMaxItemRows: 'VR_TLMaxItemRows',
        SN_ETAThreshold: 'SN_ETAThreshold',
        SN_DateRangeRelativeStart: 'SN_DateRangeRelativeStart',
        SN_DateRangeRelativeEnd: 'SN_DateRangeRelativeEnd'
    };

    static readonly trackingGrid = {
        maxSelectionErrorMessage: 'Please select less than ',
        maxSelectionErrorMessageContinues: ' shipments to update the status all at once.',
        statusSuccessMessage: ' shipment records updated',
        selectionWarningMessage: ' selected shipments do not have the required information to have their status set. If you proceed, the others will be processed.',
        rowSelectionWarningMessage: ' selected shipments do not have the required information to have their status set. Contact Customer Service for more information.'
    };

    static readonly printDocuments = {
        noDocumentMessage: 'No document information is available. Click Fetch Documents to refresh.',
        documentDownloadTooltip: 'This document is still being generated.  Wait a few moments and fetch documents again.',
        noDocumentForPublicTrackingPage: 'No documents available.'
    };

    static readonly bookingModeConstants = {
        bookAndWait: '0',
        notifyOnly: '1',
        notifyAndEmail: '2'
    };

    static readonly headerBarConstants = {
        deleteConfirmationText: 'All the notifications will be deleted, are you sure you want to proceed ?',
        deleteSuccessMessage: 'Notification deleted succesfully.',
        deleteAllNotificationSuccessMessage: 'All notifications deleted successfully.'
    };

    static readonly userFeedback = {
        goodFeedback: 'good',
        badFeedback: 'bad',
        addFormSuccessMessage: 'Thank you for your feedback'
    };

    static readonly permissionName = {
        VR_ItemEdit: 'VR_ItemEdit',
        vendorBooking: 'VendorBooking',
        vendorBooking_TL: 'VendorBooking_TL',
        vendorBooking_LTL: 'VendorBooking_LTL',
        truckloadModuleAccess: 'TruckloadModuleAccess',
        shipmentTrackingModuleAccess: 'ShipmentTrackingModuleAccess',
        isResidentialCheckboxAccess: 'IsResidentialCheckboxAccess',
        checkRequestQuoteAccess: 'RequestQuote'
    };

    static readonly TLVendorModuleConstants = {
        errorItemUpdate: 'No change has been made on items section.',
        exitConformation: 'Unsaved changes will be lost. Are you sure you want to leave this page?',
        successShipmentBooking: ' is successfully saved. All the shipment information has been sent to Client Service Team. They will get in touch with you.',
        errorShipmentBooking: 'Saving Shipment Failed. All the shipment information has been sent to Client Service Team. They will get in touch with you.',
    };

    static readonly vendorBookingModuleConstants = {
        LTL: 'LTL',
        TL: 'TL',
    };

    static readonly itemActionConstants = {
        add: 'add',
        update: 'update',
        delete: 'delete',
    };

    static readonly accountSettingTypeConstants = {
        map: 'Map',
        owner: 'Owner',
        printer: 'Printer',
        shipmentTracking: 'ShipmentTracking',
        scheduleShipment: 'ScheduleShipment',
        LTLVendorRouting: 'LTLVendorRouting',
    };

    static readonly displayModeConstants = {
        map: '1',
        grid: '2'
    };

    static readonly trackingDetailConstant = {
        claimsFormSuccessMessage: 'Your claim form has been submitted. It may take up to 24 hours before your claim status appears here in the ViewPoint portal.',
        claimStatusFormMessage: 'Form has been submitted successfully.'
    }

    static readonly supportedDocumentTyes = {
        damagedProductType: 'Pictures of Damaged Packaging and damaged Product',
        invoiceType: 'Copy of Invoice showing the cost of the goods damaged/lost (Which supports the claim/replacement cost of item(s))',
        bolType: 'Bill of Lading (BOL) (with shortage or loss indicated as such)',
        repairedInvoiceType: 'If Freight can be salvaged, include repair invoice, or estimate for repair'
    }

    static readonly itemsNumericField = {
        width: "width",
        height: "height",
        weight: "weight",
        length: "length",
        value: "value",
        pieces: "pieces",
        quantity: "quantity",
    }

}
