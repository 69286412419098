import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        data: { preload: true }
    },
    {
        path: 'app',
        loadChildren: () => import('./app/app.module').then(m => m.AppModule),
        data: { preload: true }
    },
    {
        path: 'tracking',
        loadChildren: () => import('./public-tracking/public-tracking.module').then(m => m.PublicTrackingModule),
        data: { preload: true }
    },
    { path: '', redirectTo: 'account/login', pathMatch: 'full' },
    {
        path: 'n-app',
        loadChildren: () => import('./non-app/non-app.module').then(na => na.NonAppModule),
        data: { preload: true }
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: []
})

export class RootRoutingModule { }
