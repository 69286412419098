'types/googlemaps';
import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { ZipCodeRegex } from 'src/app-core/pattern/zip-code-regex';
import { CommonService } from 'src/app-core/services/common.service';
import { ReportsDataModel } from 'src/app/reports/reports-data-model';
import { CommonDataModel } from 'src/app/shared/common-data-model';
declare var $: any;
declare var google: any;

@Component({
    selector: 'app-shipment-address-form',
    templateUrl: './shipment-address-form.component.html',
    styleUrls: ['./shipment-address-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class ShipmentAddressFormComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: false }) autoComplete: MatAutocompleteTrigger;
    @ViewChild('addressSearch', { static: false }) addressSearchElementRef: ElementRef;
    @ViewChild('addressGroup', { static: false }) addressGroup: NgForm;
    @Output() addressChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    customPatterns = new CustomPatterns();
    commonDataModel = new CommonDataModel();
    componentSubscriptions: Subscription = new Subscription();
    reportsDataModel = new ReportsDataModel();
    scheduleReport = this.reportsDataModel.scheduleReportFormModel;
    addressListModel = this.commonDataModel.addressListModel;
    USTerritoriesListModel = this.commonDataModel.USTerritoriesListModel;
    addressForm: any;
    rangeValidation = false;
    minScheduledDate = new Date();
    isLoading = false;
    allowZipCall = true;
    zipCleared = false;
    countryDropDownList: Array<any> = [];
    zipCodeDropDownList: Array<any> = [];
    moduleName: string;
    searchBoxCountry: string;
    zipCodePattern: any;
    zipCodeRegex: ZipCodeRegex = new ZipCodeRegex();
    allowStateCode = false;
    parentForm: any;
    commonConstant = CommonConstants.moduleName.scheduleTruckload;
    commonConstants: typeof CommonConstants = CommonConstants;
    placesAutoComplete: any;
    specialKey = true;
    enterKey = false;
    addressLine1Value: any;
    clearTimeOutArr = [];
    isDisableAddressSection = false;
    isAddressLookUpEnable = true;
    @Input() set addressData(data: any) {
        this.addressListModel = data;
        this.addressLine1Value = data.address1;
        if (!this.addressListModel.countryId) {
            this.onCountryChange(this.addressListModel.countryCode, true);
        }
        if (this.addressListModel.countryCode) {
            this.zipCodePattern = this.zipCodeRegex[this.addressListModel.countryCode];
        }
    }

    @Input() set countryList(data: any) {
        this.countryDropDownList = data;
        this.onCountryChange(this.addressListModel.countryCode, true);
    }

    @Input() set form(value: any) {
        this.parentForm = value;
    }

    @Input() set moduleNameValue(value: string) {
        this.moduleName = value;
    }

    @Input() isAlignmentBOL: boolean;

    @Input() isShowContact: boolean;

    @Input() formNamePrefix: string;

    @Input() set isDisabledSection(data: boolean) {
        this.isDisableAddressSection = data;
    }

    constructor(
        private ngZone: NgZone,
        private mapApiLoader: MapsAPILoader,
        private commonService: CommonService) {
        this.isAddressLookUpEnable = this.commonService.getIsEnableAddressLookUp();
    }

    ngOnInit() {
        if (this.isAddressLookUpEnable || this.formNamePrefix === 'location') {
            this.mapApiLoader.load().then(() => {
                this.getAutoCompleteResults();
            });
        }
        this.commonService.getValidatePostalCodeLocalities().subscribe(response => {
            this.commonService.validatePostalCodeLocalities = response.value;
        });
    }

    onChangeAddress(countryCode: string, resetResidential?: boolean) {
        if (resetResidential) {
            this.addressChange.emit(true);
        }
        this.addressLine1Value = this.addressListModel.address1;
        $('.pac-container').css('visibility', 'visible');
        if (this.placesAutoComplete) {
            this.placesAutoComplete.setComponentRestrictions({
                country: countryCode
            });
        }
    }

    getAutoCompleteResults() {
        this.placesAutoComplete = new google.maps.places.Autocomplete(this.addressSearchElementRef.nativeElement, {
            componentRestrictions: {
                country: this.addressListModel.countryCode
            },
            types: ['address']
        });
        this.placesAutoComplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place = this.placesAutoComplete.getPlace();
                const response = [place];
                if (!this.enterKey) {
                    setTimeout(() => {
                        if (this.specialKey) {
                            this.setAddress(response);
                        } else {
                            this.addressSearchElementRef.nativeElement.value = this.addressLine1Value;
                        }
                    }, 100);
                } else {
                    if (Object.keys(response[0]).length > 1) {
                        this.setAddress(response);
                    }
                }

            });
        });
    }

    ngAfterViewInit(): void {
        this.commonService.autocomplete.subscribe((response) => {
            if (this.autoComplete.panelOpen) {
                this.autoComplete.closePanel();
            }
        });
    }

    onCountryChange(countryCode: string, isFirstTime = false, resetResidential?: boolean): void {
        if (countryCode && Array.isArray(this.countryDropDownList) && this.countryDropDownList.length) {
            let defaultValueNotFound = false;
            let filterList = this.countryDropDownList.find(list => list.countryCode === countryCode);
            if (!filterList) {
                filterList = this.countryDropDownList[0];
                defaultValueNotFound = true;
            }
            if (filterList) {
                this.addressListModel.countryId = filterList.id;
                this.addressListModel.countryCode = filterList.countryCode;
                this.zipCodePattern = this.zipCodeRegex[this.addressListModel.countryCode];
                if (!isFirstTime || defaultValueNotFound) {
                    this.onClearZipCodes();
                }
                this.onChangeAddress(this.addressListModel.countryCode);
            }
        }
        if (resetResidential) {
            this.addressChange.emit(true);
        }
        this.clearTimeOutArr.push(setTimeout(() => {
            switch (this.formNamePrefix) {
                case 'shipper':
                    if (this.countryDropDownList.length) {
                        const code = this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].value;
                        const position = this.countryDropDownList.findIndex((x) => x.countryCode === code);
                        if (position === -1) {
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setValue(null);
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setErrors({ incorrect: true });
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsTouched();
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsDirty();
                        }
                    }
                    break;
                case 'consignee':
                    if (this.countryDropDownList.length) {
                        const code = this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].value;
                        const position = this.countryDropDownList.findIndex((x) => x.countryCode === code);
                        if (position === -1) {
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setValue(null);
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setErrors({ incorrect: true });
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsTouched();
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsDirty();
                        }
                    }
                    break;
                default:
                    if (this.countryDropDownList.length) {
                        const code = this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].value;
                        const position = this.countryDropDownList.findIndex((x) => x.countryCode === code);
                        if (position === -1) {
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setValue(null);
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].setErrors({ incorrect: true });
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsTouched();
                            this.addressGroup.control.controls[this.formNamePrefix + 'CountryCode'].markAsDirty();
                        }
                    }
                    break;
            }
        }, 2000));
    }

    onChangeZipCode(zipCodeSearch: string, countryCodeSearch: string, territoriesCount = 0, resetResidential?: boolean): void {
        if (zipCodeSearch && (zipCodeSearch.length >= 10 && zipCodeSearch.indexOf('-') === 5) && this.allowZipCall) {
            this.searchZipCode(zipCodeSearch, countryCodeSearch, territoriesCount, resetResidential);
        } else if (zipCodeSearch && (zipCodeSearch.length >= 5 && zipCodeSearch.indexOf('-') !== 5) && this.allowZipCall) {
            this.searchZipCode(zipCodeSearch, countryCodeSearch, territoriesCount, resetResidential);
        } else {
            this.allowZipCall = true;
        }
        if (resetResidential) {
            this.addressChange.emit(true);
        }
    }

    searchZipCode(zipCodeSearch: string, countryCodeSearch: string, territoriesCount = 0, resetResidential?: boolean) {
        this.commonService.getGeoCode(zipCodeSearch, countryCodeSearch).then(response => {
            if (Array.isArray(response) && response.length) {
                this.zipCodeDropDownList = [];
                this.commonService.zipDataResponseChange(response);
                this.zipCodeDropDownList = this.commonService.prepareZipData(response);
                this.zipCodeDropDownList.map((list) => {
                    list.zipCode = zipCodeSearch;
                });
                const address1 = this.addressListModel.address1;
                if (Array.isArray(this.commonService.prepareZipData(response)) &&
                    this.commonService.prepareZipData(response).length) {
                    this.commonService.objectMapping(this.addressListModel, this.zipCodeDropDownList[0]);
                    this.addressListModel.stateName = this.zipCodeDropDownList[0].stateCode;
                    this.zipCodeDropDownList.map(list => delete list.address1);
                    if (!this.addressListModel.address1) {
                        this.addressListModel.address1 = address1;
                    }
                    this.checkStateCodeAndCity();
                }
            } else {
                this.zipCodeDropDownList = [];
                this.addressListModel.city = null;
                this.addressListModel.stateCode = null;
                this.addressListModel.stateName = null;
                this.addressListModel.latitude = 0;
                this.addressListModel.longitude = 0;
                const data = this.USTerritoriesListModel.find(x => x.stateCode.toLowerCase() === countryCodeSearch.toLowerCase());
                if (data) {
                    if ((territoriesCount + 1) < this.USTerritoriesListModel.length) {
                        this.onChangeZipCode(zipCodeSearch,
                            this.USTerritoriesListModel[territoriesCount + 1].stateCode, territoriesCount + 1);
                    }
                }
            }
        });
    }

    checkStateCodeAndCity(): void {
        this.allowStateCode = false;
        if (!this.addressListModel.stateCode) {
            this.allowStateCode = true;
        }
    }

    onBlurState(): void {
        if (this.addressListModel.zipCode && this.addressListModel.latitude && this.addressListModel.longitude && this.allowStateCode) {
            this.addressListModel.stateCode = this.addressListModel.stateName;
        }
    }

    onSetLocation(item: any): void {
        this.commonService.objectMapping(this.addressListModel, item);
        this.addressListModel.stateName = this.addressListModel.stateCode;
        this.allowZipCall = false;
    }

    onEmailValidation(): void {
        if (this.addressListModel.email) {
            this.addressListModel.email =
                this.commonService.multipleEmailValidation(
                    this.addressListModel.email, this.parentForm, this.formNamePrefix + CommonConstants.addressForm.email
                );
        }
    }

    onValidatePhone(event: any): void {
        const status = this.commonService.validatePhoneNumber(event);
        if (!status) {
            this.parentForm.controls[this.formNamePrefix + CommonConstants.addressForm.phone].setErrors({ invaild: true });
        }
    }

    onClearZipCodes(): void {
        this.addressListModel.zipCode = null;
        this.addressListModel.stateName = null;
        this.addressListModel.stateCode = null;
        this.addressListModel.city = null;
        this.addressListModel.latitude = 0;
        this.addressListModel.longitude = 0;
        this.zipCleared = true;
    }

    isRequired(): boolean {
        if (this.moduleName === CommonConstants.moduleName.bolOnly) {
            return false;
        }
        return true;
    }

    onClearAddressLine() {
        this.addressListModel.address1 = null;
        this.addressLine1Value = null;
    }

    setAddress(data: any) {
        if (Array.isArray(data) && data.length) {
            this.addressSearchElementRef.nativeElement.value = '';
            this.addressListModel.address1 = '';
            this.zipCodeDropDownList = [];
            this.commonService.zipDataResponseChange(data);
            this.zipCodeDropDownList = this.commonService.prepareZipData(data);
            if (this.zipCodeDropDownList.length) {
                this.commonService.objectMapping(this.addressListModel, this.zipCodeDropDownList[0]);
                this.addressSearchElementRef.nativeElement.value = this.zipCodeDropDownList[0].address1;
                this.addressLine1Value = this.zipCodeDropDownList[0].address1;
                this.addressListModel.stateName = this.zipCodeDropDownList[0].stateCode;
            }
        }
    }

    onKeyPress(event: any) {
        if (event.key === 'Enter') {
            this.enterKey = true;
        } else {
            this.enterKey = false;
            if (event.altKey || event.key === 'F1' || event.key === 'F3' || event.key === 'F6' || event.key === 'F7' || event.key === 'F10' || event.key === 'F12' || event.key === 'Shift' || event.key === 'PageUp' || event.key === 'PageDown') {
                $('.pac-container').css('visibility', 'hidden');
                this.addressSearchElementRef.nativeElement.value = this.addressLine1Value;
                this.specialKey = false;
            }
        }
    }

    onFocusIn(addressLine1: any) {
        if (!addressLine1) {
            this.addressLine1Value = '';
        } else {
            if (!this.addressLine1Value) {
                this.addressLine1Value = addressLine1;
            }
        }
        this.specialKey = true;
        $('.pac-container').css('visibility', 'visible');
    }

    onFocusOut() {
        $('.pac-container').css('visibility', 'hidden');
    }

    onStateChange(resetResidential: any) {
        if (resetResidential) {
            this.addressChange.emit(true);
        }
    }

    onCityChange(resetResidential: any) {
        if (resetResidential) {
            this.addressChange.emit(true);
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.clearTimeOutArr.map((timeOutId) => {
            clearTimeout(timeOutId);
        });
    }
}
