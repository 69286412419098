import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { CommonDataModel } from 'src/app/shared/common-data-model';
import { CommonConstants } from '../constants/common-constants';
import { CommonService } from '../services/common.service';
import { CustomToastrService } from '../services/custom-toastr-service';

@Injectable({
    providedIn: 'root'
})

export class CommonMethod {

    commonDataModel = new CommonDataModel();
    loggedUserModel = this.commonDataModel.loggedUserModel;

    private bolInstructionSubject = new BehaviorSubject(false);
    bolInstructionValue = this.bolInstructionSubject.asObservable();

    private vendorBookingStartupPage = new BehaviorSubject<string>('');
    getVendorBookingStartupPage = this.vendorBookingStartupPage.asObservable();

    private setLocationRequiredServices = new BehaviorSubject(false);
    getLocationRequiredServices = this.setLocationRequiredServices.asObservable();

    private setVendorTrackingDisplayMode = new BehaviorSubject<string>('');
    getVendorTrackingDisplayMode = this.setVendorTrackingDisplayMode.asObservable();

    private setVendorItemFormDirty = new BehaviorSubject(false);
    getVendorItemFormDirty = this.setVendorItemFormDirty.asObservable();

    private setVendorItemExit = new BehaviorSubject(false);
    getVendorItemExit = this.setVendorItemExit.asObservable();

    constructor(
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
        this.loggedUserModel = this.commonService.getLoggedUserData();
    }

    sendBolInformationData(value: boolean) {
        this.bolInstructionSubject.next(value);
    }

    sendVendorBookingStartupPage(data: any) {
        this.vendorBookingStartupPage.next(data);
    }

    sendLocationRequiredServices(value: boolean) {
        this.setLocationRequiredServices.next(value);
    }

    sendVendorTrackingDisplayMode(data: string) {
        this.setVendorTrackingDisplayMode.next(data);
    }

    sendVendorItemFormDirty(value: boolean) {
        this.setVendorItemFormDirty.next(value);
    }

    sendVendorItemExit(value: boolean) {
        this.setVendorItemExit.next(value);
    }

    // Object Mapping
    objectMapping(mappingObject: object, sourceObject: object) {
        Object.keys(sourceObject).map((key, index) => {
            if (mappingObject.hasOwnProperty(key)) {
                mappingObject[key] = _.cloneDeep(sourceObject[key]);
            }
        });
    }

    async imageFileUploadValidation(event: any, claimsFormDocument?: boolean) {
        let uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
        if (event.target.files && event.target.files.length > 0) {
            const fileSizeLimit = claimsFormDocument ? 5000000 : 2000000
            if (event.target.files[0].size <= fileSizeLimit) {
                let file = event.target.files[0];
                const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                const isExtensionValid = claimsFormDocument ? this.checkDocumentFileExtension(extension) : this.checkImageFileExtension(extension);
                if (isExtensionValid) {
                    uploadFileModel.isFileValid = true;
                    uploadFileModel.fileName = file.name;
                    uploadFileModel.file = file;
                    uploadFileModel.fileSize = this.formatFileSize(file.size, 1);
                    uploadFileModel.base64Url = await this.convertFileToBase64(file);
                } else {
                    file = '';
                    uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                    this.customToastrService.error(CommonConstants.appConstant.invalidFileFormat);
                }
            } else {
                uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                this.customToastrService.error(`${CommonConstants.appConstant.imageFileSizeMessage} ${claimsFormDocument ? ' 5 MB.' : ' 2 MB.'}`);
            }
            return uploadFileModel;
        }
    }

    convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })

    checkImageFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const imageFileExtensionList = ['png', 'jpeg', 'bmp', 'jpg', 'svg'];
        return imageFileExtensionList.includes(inputType);
    }

    checkDocumentFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const imageFileExtensionList = ['pdf', 'tif', 'png', 'jpeg', 'jpg'];
        return imageFileExtensionList.includes(inputType);
    }

    formatFileSize(bytes: number, decimalPoint: number) {
        if (bytes === 0) { return '0 Bytes'; }
        const dm = decimalPoint || 1;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    copyClipboard(value: any): void {
        const textAreaInput = document.createElement('textarea');
        textAreaInput.style.opacity = '0';
        textAreaInput.value = value;
        document.body.appendChild(textAreaInput);
        textAreaInput.focus();
        textAreaInput.select();
        document.execCommand('copy');
        textAreaInput.setSelectionRange(0, 0);
        document.body.removeChild(textAreaInput);
    }

    copyClipboardFromInput(inputElement: any): void {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    mapCarrierTrackingLink(dataItem: any, type = true) {
        if (dataItem.carrierTrackingLink) {
            let proValue = '0';
            if (type && dataItem.carrierInfo) {
                proValue = dataItem.carrierInfo.pro ? dataItem.carrierInfo.pro : '0';
            } else {
                proValue = dataItem.pro ? dataItem.pro : '0';
            }
            return dataItem.carrierTrackingLink.replace('{0}', proValue);
        }
    }

    showCarrierTrackingLink(dataItem: any, type = true) {
        if (dataItem.mode === CommonConstants.moduleName.parcel) {
            if (dataItem.carrierTrackingLink) {
                return true;
            }
            return false;
        }
        else {
            if (type && dataItem.carrierInfo) {
                if (dataItem.carrierTrackingLink && dataItem.carrierInfo.pro && dataItem.mcNumber) {
                    return true;
                }
                return false;
            } else {
                if (dataItem.carrierTrackingLink && dataItem.pro && dataItem.mcNumber) {
                    return true;
                }
                return false;
            }
        }
    }

    switchToSingleClass(sourceObject: any, isCopiedShipment?: boolean) {
        const items = _.cloneDeep(sourceObject[0].items);
        const data = {
            itemId: sourceObject[0].itemId,
            quantity: null,
            handlingUnitType: sourceObject[0].handlingUnitType,
            weight: null,
            length: null,
            width: null,
            height: null,
            totalWeight: sourceObject[0].totalWeight,
            totalVolume: sourceObject[0].totalVolume,
            totalDensity: sourceObject[0].totalDensity,
            totalValue: sourceObject[0].totalValue,
            totalItems: sourceObject[0].totalItems,
            isNestedItems: false,
            searchBoxHandlingUnitType: sourceObject[0].searchBoxHandlingUnitType,
            items: []
        };
        if (isCopiedShipment) {
            const list = [];
            const copiedItems = _.cloneDeep(sourceObject);
            const length = sourceObject.length;
            sourceObject.splice(0, length);
            copiedItems.filter((element: any) => {
                element.items.filter((item: any, index: number) => {
                    if (index !== items.length) {
                        const result = _.cloneDeep(data);
                        result.items.push(item);
                        list.push(result);
                    }
                });
            });
            list.filter((item: any) => {
                sourceObject.push(item);
            });
        } else {
            items.filter((item: any, index: number) => {
                if (index !== items.length) {
                    const result = _.cloneDeep(data);
                    result.items.push(item);
                    sourceObject.push(result);
                }
            });
            sourceObject.splice(0, 1);
        }
    }

    switchToMulticlass(sourceObject: any) {
        const shipmentItems = _.cloneDeep(sourceObject);
        const itemList = [];
        shipmentItems.map((list: any) => {
            list.items.map((item: any, itemIndex: number) => {
                itemList.push(item);
            });
        });
        sourceObject[0].quantity = 1;
        sourceObject[0].items = itemList;
        sourceObject[0].isNestedItems = true;
        sourceObject.splice(1, shipmentItems.length);
    }

    checkResidentialCheckbox(checkBoxName: any, checkBoxValue: any) {
        if (checkBoxName === CommonConstants.residentialCheckboxStatus.residential) {
            return checkBoxValue ? false : true;
        } else if (checkBoxName === CommonConstants.residentialCheckboxStatus.nonResidential) {
            return checkBoxValue === false ? false : true;
        } else {
            return checkBoxValue === null ? false : true;
        }
    }

    showRequiredServices(moduleName: string, serviceName: string, item: any) {
        const serviceList = _.compact(item);
        if (CommonConstants.moduleName.scheduleShipment === moduleName || CommonConstants.moduleName.scheduleTruckload === moduleName) {
            if (serviceName && !_.isEmpty(serviceList)) {
                return true;
            }
            return false;
        }
    }

    calculateNestedItemVolumeAndDensity(list: any) {
        list.items.filter(item => {
            if (item.quantity && item.width && item.height && item.length) {
                item.volume = parseFloat((item.width * item.height * item.length * item.quantity / 1728).toFixed(2));
                if (item.weight && item.volume) {
                    item.density = parseFloat((item.weight / item.volume).toFixed(2));
                } else {
                    item.density = 0;
                }
            } else {
                item.volume = 0;
                item.density = 0;
            }
        });
    }

    shipmentItemValueMapping(sourceData: any, result: any) {
        const data = Object.assign({}, result);
        data.length = (result.length === 0 ? null : result.length);
        data.width = (result.width === 0 ? null : result.width);
        data.height = (result.height === 0 ? null : result.height);
        data.value = (result.value === 0 ? null : result.value);
        data.pieces = (result.pieces === 0 ? null : result.pieces);
        data.handlingType = result.packageType;
        if (data.freightClass) {
            data.freightClass = data.freightClass.toString();
        }
        if (data.minTemperature === 0) {
            data.minTemperature = null;
        }
        if (data.maxTemperature === 0) {
            data.maxTemperature = null;
        }
        this.objectMapping(sourceData, data);
    }

    mapFreightClassValue(itemClassList: any, value: any) {
        const data = _.find(itemClassList, ['itemClassName', String(value)]);
        if (data) {
            return data.itemClassName;
        }
        return null;
    }

    mapValueAndFreightClassField(list: any, nestedItem: boolean) {
        if (nestedItem) {
            list.items.filter((item: any) => {
                if (item.value && item.value.toString().includes('.00')) {
                    const value = item.value.toString();
                    item.value = value.replace('.00', '');
                }
                if (item.freightClass && item.freightClass.toString().includes('.0')) {
                    const freightClass = item.freightClass.toString();
                    item.freightClass = freightClass.replace('.0', '');
                    list.tempFreightClass = list.freightClass;
                }
            });
        } else {
            if (list.value && list.value.toString().includes('.00')) {
                const value = list.value.toString();
                list.value = value.replace('.00', '');
            }
            if (list.freightClass && list.freightClass.toString().includes('.0')) {
                const freightClass = list.freightClass.toString();
                list.freightClass = freightClass.replace('.0', '');
                list.tempFreightClass = list.freightClass;
            }
        }
    }

    prepareClassBreakDensityList(classBreaksList: any) {
        let classValue = [];
        let densityValue = [];
        let classDensityBreakList = [];
        const classBreaksListstr = classBreaksList.split(',');
        classBreaksListstr.map((x) => {
            const substring = x.split(',');
            const substringArray = substring[0];
            if (substring[0].includes('(')) {
                const result = substringArray.replace('(', '');
                classValue.push(result);
            }

            if (substring[0].includes(')')) {
                const result = substringArray.replace(')', '');
                densityValue.push(result);
            }
        })

        for (let i = 0; i < classValue.length; i++) {
            classDensityBreakList[i] = { class: densityValue[i], density: classValue[i] }
        }

        return classDensityBreakList;
    }

    setSuggestedClass(shipmentItems: any, classDensityBreaksList: any) {
        shipmentItems.map((item, parentIndex) => {
            item.items.map((value, index) => {
                if (value.quantity && value.weight && value.length && value.height && value.width) {
                    let classSuggested = false;
                    const density = shipmentItems[parentIndex].items[index].density;
                    if (!shipmentItems[parentIndex].items[index].tempFreightClass && classDensityBreaksList.length && !shipmentItems[parentIndex].items[index].isFreightClassSelected) {
                        const lastIndex = classDensityBreaksList.length - 1;
                        for (let i = 0; i < classDensityBreaksList.length; i++) {
                            if (!classSuggested && lastIndex != i && density >= classDensityBreaksList[i].density && density < classDensityBreaksList[i + 1].density) {
                                classSuggested = true;
                                shipmentItems[parentIndex].items[index].freightClass = classDensityBreaksList[i].class;
                                shipmentItems[parentIndex].items[index].suggestedFreightClass = classDensityBreaksList[i].class;
                            } else if (!classSuggested && lastIndex == i && density >= classDensityBreaksList[i].density) {
                                classSuggested = true;
                                shipmentItems[parentIndex].items[index].freightClass = classDensityBreaksList[i].class;
                                shipmentItems[parentIndex].items[index].suggestedFreightClass = classDensityBreaksList[i].class;
                            }
                        }
                    }
                }
            })
        })
    }

    getSelectedParcelRates(parcelResponse:any, selectedRate:any) {
        const selectedRatesList = [];
        parcelResponse.parcelRates.map((data: any) => {
            data.parcelRate.map((rate) => {
                const pos = selectedRate.selectedRates.findIndex((x) => x.id == rate.id);
                if (pos != -1) {
                    const rateIndex = data.parcelRate.findIndex((x) => x.id == selectedRate.selectedRates[pos].id);
                    if (rateIndex != -1) {
                        const obj = {
                            parcelItem: data.parcelItem,
                            parcelRate: data.parcelRate[rateIndex]
                        }
                        selectedRatesList.push(obj);
                    }
                }
            })
        })

        return selectedRatesList;
    }

}
