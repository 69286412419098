import { CommonConstants } from 'src/app-core/constants/common-constants';
import { LatLongEnum, ShipmentAddressTypeEnum } from 'src/app-core/enums/common-enum';
import { environment } from 'src/environments/environment';

export class CommonDataModel {

    public recipientTypeList = [
        { display: 'Consumer', value: 'Consumer' },
        { display: 'Licensee', value: 'Licensee' }
    ]

    public googleAddressModel = {
        addressTypes: [
            {
                long_name: null,
                short_name: null,
                type: 'locality'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality_level_1'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality_level_2'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality_level_3'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality_level_4'
            },
            {
                long_name: null,
                short_name: null,
                type: 'sublocality_level_5'
            },
            {
                long_name: null,
                short_name: null,
                type: 'colloquial_area'
            },
            {
                long_name: null,
                short_name: null,
                type: 'neighborhood'
            },
            {
                long_name: null,
                short_name: null,
                type: 'premise'
            },
            {
                long_name: null,
                short_name: null,
                type: 'administrative_area_level_3'
            },
            {
                long_name: null,
                short_name: null,
                type: 'administrative_area_level_2'
            },
        ],
        isCityPresent: false
    }

    public copySettingsModel = {
        SN_CopyItemsByDefault: false,
        SN_CopyServicesByDefault: false,
        SN_CopyEquipmentByDefault: false,
        SN_CopyReferencesByDefault: false,
        pickupDate: null
    };

    public loggedUserModel = {
        email: null,
        userId: null,
        ownerId: null,
        ownerName: null,
        displayMode: null,
        displayName: null,
        startUpPage: null,
        accessToken: null,
        parentOwnerId: null,
        userPermission: null,
        defaultOwnerId: null,
        loggedInOwnerId: null,
        accountNumber: null,
        timezoneId: null,
        timezoneDescription: null,
        MGResponseTimeout: null,
        isAddressCollapse: false,
        EnableAddressLookup: true,
        copyShipmentNavigation: false,
        selectedParentOwnerId: null,
        isRequestQuoteSelected: true,
        vrShipLocationCode: null,
        chatId: null,
        secretKey: null,
        vendorBookingStartUpPage: CommonConstants.vendorBookingModuleConstants.LTL
    };

    public originAddress = {
        id: null,
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: CommonConstants.defaultCountryCode,
        contactId: null,
        contactName: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        pickupNotes: null,
        deliveryNotes: null,
        shippingStartTime: null,
        shippingEndTime: null,
        receivingStartTime: null,
        receivingEndTime: null,
        eventStartDateTime: null,
        ownerId: null,
        ownerName: null,
        latitude: 0,
        longitude: 0,
        loggedInOwnerId: null,
        isShipmentLocation: true,
        shipmentAddressType: ShipmentAddressTypeEnum.ShipperInfo,
        billToAccountNumber: null,
        earliestPickupDateTime: null,
        latestPickupDateTime: null,
        isResidential: null,
        shipInstructions: null,
        receiveInstructions: null,
        updatedShipInstruction: null,
        updatedConsigneeInstruction: null,
        defaultSenderServices: [],
        defaultReceiveServices: [],
        senderServices: [],
        receiveServices: [],
        clearedServices: []
    };

    public destinationAddress = {
        id: null,
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: CommonConstants.defaultCountryCode,
        contactId: null,
        contactName: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        pickupNotes: null,
        deliveryNotes: null,
        shippingStartTime: null,
        shippingEndTime: null,
        receivingStartTime: null,
        receivingEndTime: null,
        eventStartDateTime: null,
        ownerId: null,
        ownerName: null,
        latitude: 0,
        longitude: 0,
        loggedInOwnerId: null,
        isShipmentLocation: true,
        shipmentAddressType: ShipmentAddressTypeEnum.ConsigneeInfo,
        moveType: null,
        billToAccountNumber: null,
        earliestDelieveryDateTime: null,
        latestDelieveryDateTime: null,
        isResidential: null,
        shipInstructions: null,
        receiveInstructions: null,
        updatedShipInstruction: null,
        updatedConsigneeInstruction: null,
        defaultSenderServices: [],
        defaultReceiveServices: [],
        senderServices: [],
        receiveServices: [],
        clearedServices: []
    };

    public addressListModel = {
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: CommonConstants.defaultCountryCode,
        contactId: null,
        contactName: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        latitude: 0,
        longitude: 0,
        loggedInOwnerId: null,
    };

    public shipmentItems = [
        {
            id: null,
            weight: null,
            quantity: null,
            handlingType: null,
            pieces: null,
            length: null,
            width: null,
            height: null,
            minPCF: null,
            maxPCF: null,
            freightClass: null,
            nmfC_Code: null,
            itemId: null,
            description: null,
            value: null,
            uN_NA: null,
            isHazmat: false,
            hazMatClass: null,
            hazMatGroup: null,
            hazMatShipping: null,
            hazMatContactName: null,
            hazMatContactPhone: null,
            volume: 0,
            density: 0,
            searchBoxClass: null,
            searchBoxHandlingType: null,
            searchBoxPackageGroup: null,
            isHandlingUnitSelected: true
        }
    ];

    public shipmentMultiClassItems = [
        {
            itemId: null,
            quantity: null,
            handlingUnitType: null,
            weight: null,
            length: null,
            width: null,
            height: null,
            totalWeight: 0,
            totalVolume: 0,
            totalDensity: 0,
            totalValue: 0,
            totalItems: 0,
            isNestedItems: false,
            searchBoxHandlingUnitType: null,
            valueAdded: false,
            items: [
                {
                    id: null,
                    weight: null,
                    quantity: null,
                    handlingType: null,
                    pieces: null,
                    length: null,
                    width: null,
                    height: null,
                    minPCF: null,
                    maxPCF: null,
                    freightClass: null,
                    nmfC_Code: null,
                    itemId: null,
                    description: null,
                    value: null,
                    uN_NA: null,
                    isHazmat: false,
                    hazMatClass: null,
                    hazMatGroup: null,
                    hazMatShipping: null,
                    hazMatContactName: null,
                    hazMatContactPhone: null,
                    volume: 0,
                    density: 0,
                    searchBoxClass: null,
                    searchBoxHandlingType: null,
                    searchBoxPackageGroup: null,
                    minLength: null,
                    maxLength: null,
                    tempFreightClass: null,
                    isHandlingUnitSelected: true,
                    isFreightClassSelected: false,
                    suggestedFreightClass: null,
                    isRequiredInsurance: false,
                    isContainsDryIce: false,
                    isContainsAlcohol: false,
                    totalAmount: null,
                    notes: '',
                    itemCount: 1,
                    totalAmountPerPackage: null,
                    totalWeightValid: false,
                    lengthValid: false,
                    widthValid: false,
                    heightValid: false,
                    alcoholRecipientType: null,
                    dryIceWeight: null,
                    amountUpdated: false,
                    perQuantityValue: null,
                    searchBoxItem: null,
                    commodityId: null,
                    commodityCategory: null
                }
            ]
        }
    ];

    public billToInfoFormModel = {
        id: null,
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: CommonConstants.defaultCountryCode,
        contactName: null,
        contactId: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        latitude: 0,
        longitude: 0,
        ownerId: null,
        ownerName: null,
        isShipmentLocation: true,
        shipmentAddressType: ShipmentAddressTypeEnum.BillToInfo,
        billToAccountNumber: null
    };

    public shipmentRateListModel = {
        RateRequestId: null,
        id: null,
        totalCost: 0,
        carrier: null,
        service: null,
        mode: null,
        transitTime: null,
        distance: null,
        interLine: null,
        scac: null,
        accessorialTotal: null,
        fuel: null,
        accessorials: null,
        contractID: null,
        freight: 0,
        serviceDays: null,
        rateCharges: null,
        quoteNumber: null,
        isLowestRate: false,
        isRedLineWarning: false,
        isSelectable: true
    };

    public parcelRateServiceLevel = {
        name: null,
        token: null,
        terms: null,
        extended_token: null,
        parent_servicelevel: null
    };

    public shipmentLCPDetailsModel = {
        scac: null,
        carrier: null,
        totalCost: 0,
        freight: 0,
        accessorials: 0,
        fuel: 0,
        serviceDays: 0
    };

    public parcelShipmentListModel = {
        id: null,
        ownerId: null,
        enterpriseId: 0,
        ownerName: null,
        labelFileType: null,
        userName: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        aggreedTAndC: true,
        shipmentReferenceSettings: {
            referenceSettings: []
        },
        shipmentServices: {
            services: []
        },
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        linearFeet: 0,
        totalPallets: 0,
        moduleName: null,
        bookingType: 1,
        parcelRates: []
    }

    public insuranceModel = {
        currencyDecimalPlaces: null,
        currencyDivisionModifier: null,
        insuranceQuoteId: null,
        insuranceTotalAmount: null,
        insurerPremium: null,
        technologyFee: null,
        bookingFee: null,
        insurerPremiumTax: null,
        technologyFeeTax: null,
        bookingFeeTax: null,
        emailUser: false,
        emailShipper: false,
        emailConsignee: false,
        emailOthers: false,
        otherEmailIds: [],
        isTest: false
    }

    public shipmentBookingListModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        tenantId: null,
        isToReRate: false,
        linearFeet: 0,
        totalPallets: 0,
        bookingType: CommonConstants.bookingType.saveQuote,
        bolInstructions: null,
        aggreedTAndC: false,
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        shipmentItems: [],
        shipmentHandlingUnits: this.shipmentMultiClassItems,
        shipmentServices: {
            services: []
        },
        shipmentEquipments: {
            equipments: []
        },
        shipmentReferenceSettings: {
            referenceSettings: []
        },
        shipmentRate: this.shipmentRateListModel,
        lCPDetails: this.shipmentLCPDetailsModel,
        signalRConnectionId: null,
        useLCP: true,
        bookingChoice: CommonConstants.bookingModeConstants.bookAndWait,
        trackingBaseUrl: environment.appUrl + 'tracking/shipment-tracking/',
        IsResidentialServicemismatch: false,
        IsRequestQuote: false,
        NotificationSignalRId: null,
        insurance: this.insuranceModel
    };

    public truckloadItemModel = {
        id: null,
        weight: null,
        quantity: null,
        handlingType: null,
        length: null,
        width: null,
        height: null,
        itemId: null,
        description: null,
        value: null,
        uN_NA: null,
        isHazmat: false,
        hazMatClass: null,
        hazMatGroup: null,
        hazMatShipping: null,
        hazMatContactName: null,
        hazMatContactPhone: null,
        searchBoxClass: null,
        searchBoxHandlingType: null,
        searchBoxPackageGroup: null,
        minTemperature: null,
        maxTemperature: null,
        temperatureUOM: 'F',
        isMixedClass: false,
        linearFeet: 0,
        totalPallets: 0,
        isHandlingUnitSelected: true,
        action: '',
        itemOrderId: null,
        isHanlingUnitRequired: false,
        commodityId: null,
        commodityCategory: null
    };

    public TruckLoadBookingListModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        tenantId: null,
        linearFeet: 0,
        totalPallets: 0,
        bookingType: CommonConstants.bookingType.bookingShipment,
        bolInstructions: null,
        aggreedTAndC: false,
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        shipmentItems: [this.truckloadItemModel],
        shipmentHandlingUnits: [],
        shipmentServices: {
            services: []
        },
        shipmentEquipments: {
            equipments: []
        },
        shipmentReferenceSettings: {
            referenceSettings: []
        },
        trackingBaseUrl: environment.appUrl + 'tracking/shipment-tracking/',
        saveAsQuote: false,
        IsResidentialServicemismatch: false
    };

    public bolBookingListModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        tenantId: null,
        linearFeet: 0,
        totalPallets: 0,
        bolInstructions: null,
        aggreedTAndC: false,
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        shipmentItems: this.shipmentItems,
        shipmentServices: {
            services: []
        },
        shipmentReferenceSettings: {
            referenceSettings: []
        },
        carrierDetails: {
            carrier: null,
            scac: null,
            otherCarrier: null,
            freightTerms: null
        },
        billToInformation: this.billToInfoFormModel,
        generateNewDocument: null,
        isOnlyAddressDetailsChanged: null,
        shipmentGuid: null,
        warningSettingChecked: false,
        IsResidentialServicemismatch: false
    };

    public ltlVendorBookingListModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        tenantId: null,
        linearFeet: 0,
        totalPallets: 0,
        bookingType: CommonConstants.bookingType.bookingShipment,
        bolInstructions: null,
        aggreedTAndC: false,
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        shipmentItems: [],
        shipmentHandlingUnits: this.shipmentMultiClassItems,
        shipmentServices: {
            services: []
        },
        shipmentReferenceSettings: {
            referenceSettings: []
        }, shipmentEquipments: {
            equipments: []
        },
        shipmentRate: this.shipmentRateListModel,
        lCPDetails: this.shipmentLCPDetailsModel,
        carrierDetails: null,
        signalRConnectionId: null,
        IsResidentialServicemismatch: false,
        IsRequestQuote: false,
        insurance: this.insuranceModel
    };

    public fetchRateModel = {
        ownerId: null,
        linearFeet: 0,
        totalPallets: 0,
        handlingUnits: [],
        originAddress: {
            city: null,
            stateName: null,
            stateCode: null,
            zipCode: null,
            countryCode: CommonConstants.defaultCountryCode,
            shippingStartTime: null,
            shippingEndTime: null,
            eventStartDateTime: null,
            latitude: LatLongEnum.latitude,
            longitude: LatLongEnum.longitude,
            isResidential: null
        },
        destinationAddress: {
            city: null,
            stateName: null,
            stateCode: null,
            zipCode: null,
            countryCode: CommonConstants.defaultCountryCode,
            receivingStartTime: null,
            receivingEndTime: null,
            eventStartDateTime: null,
            latitude: LatLongEnum.latitude,
            longitude: LatLongEnum.longitude,
            isResidential: null
        },
        constraints: {
            mode: null,
            services: [],
            equipments: []
        },
        signalRConnectionId: null
    };

    public itemsListModel = {
        id: null,
        weight: null,
        quantity: null,
        handlingType: null,
        pieces: null,
        length: null,
        width: null,
        height: null,
        minPCF: null,
        maxPCF: null,
        freightClass: null,
        nmfC_Code: null,
        itemId: null,
        description: null,
        value: null,
        uN_NA: null,
        isHazmat: false,
        hazMatClass: null,
        hazMatGroup: null,
        hazMatShipping: null,
        hazMatContactName: null,
        hazMatContactPhone: null,
        volume: 0,
        density: 0,
        searchBoxClass: null,
        searchBoxHandlingType: null,
        searchBoxPackageGroup: null,
        minLength: null,
        maxLength: null,
        isHandlingUnitSelected: true,
        isFreightClassSelected: false,
        suggestedFreightClass: null,
        lengthValid: false,
        weightValid: false,
        dimensionValid: false,
        insuranceItem: null,
        perQuantityValue: null,
        valueAdded: false,
        commodityCategory: null,
        commodityId: null
    };

    public itemsMultiClassListModel = {
        itemId: null,
        quantity: null,
        handlingUnitType: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        totalWeight: 0,
        totalVolume: 0,
        totalDensity: 0,
        totalValue: 0,
        totalItems: 0,
        isNestedItems: false,
        searchBoxHandlingUnitType: null,
        items: []
    };

    public settingModel = {
        settingName: 'SS_MaxItemRows',
        ownerId: null,
    };

    public gridSettingsModel = {
        definition: {
            id: null,
            definitionId: null,
            userId: null,
            definitionName: null,
            definitionShortName: null,
            gridName: null,
            sortByColumn: null,
            sortByAscending: true,
            allowToDelete: false,
            allowToEdit: false,
            groups: [],
            columns: [],
            filters: [],
            isDefault: false,
            isPublic: false,
            isActive: true,
            tenantId: null
        },
        dateFrom: null,
        dateTo: null,
        ownerId: null,
        associatedOwnerId: null,
        status: null,
        skipCount: null,
        results: null,
        IsChildHierarchy: true
    };

    public zipListModel = {
        zipCode: null,
        stateCode: null,
        stateName: null,
        countryCode: null,
        countryName: null,
        city: null,
        latitude: 0,
        longitude: 0,
        address1: null
    };

    public searchLocationModel = {
        ownerId: null,
        locationCode: null,
        locationName: null,
        address: null,
        stateCode: null,
        countryId: null,
        city: null,
        zipCode: null,
        results: null,
        skipCount: null,
        searchFor: null,
        orderBy: null,
        sortByAscending: false,
        locationType: null,
        showShipperServices: null,
        showConsigneeServices: null,
        moduleName: null
    };

    public searchItemsModel = {
        ownerId: null,
        itemId: null,
        nmfC_Code: null,
        freightClass: null,
        description: null,
        density: null,
        length: null,
        results: null,
        skipCount: null,
        searchFor: null,
        orderBy: null,
        sortByAscending: true,
        preserveCheckBox: false,
        moduleName: null,
        definition: {
            id: null,
            definitionId: null,
            userId: null,
            definitionName: null,
            definitionShortName: null,
            gridName: null,
            sortByColumn: null,
            sortByAscending: true,
            allowToDelete: false,
            allowToEdit: false,
            groups: [],
            columns: [],
            filters: [],
            isDefault: false,
            isPublic: false,
            isActive: true,
            tenantId: null
        }
    };

    public userSearchItemsModel = {
        ownerId: null,
        itemId: null,
        nmfC_Code: null,
        freightClass: null,
        description: null,
        density: null,
        length: null,
        results: null,
        skipCount: null,
        searchFor: null,
        orderBy: null,
        sortByAscending: true,
        preserveCheckBox: false,
        moduleName: null,
        definition: {
            id: null,
            definitionId: null,
            userId: null,
            definitionName: null,
            definitionShortName: null,
            gridName: null,
            sortByColumn: null,
            sortByAscending: true,
            allowToDelete: false,
            allowToEdit: false,
            groups: [],
            columns: [],
            filters: [],
            isDefault: false,
            isPublic: false,
            isActive: true,
            tenantId: null
        }
    };

    public itemTotalModel = {
        linearFeet: 0,
        totalPallets: 0,
        totalDensity: 0,
        totalVolume: 0,
        totalWeight: 0,
        totalValue: 0
    };

    public itemTotalModelTL = {
        totalWeight: 0,
        totalValue: 0,
        linearFeet: 0,
        totalPallets: 0
    };

    public formStatusModel = {
        addressForm: false,
        itemsForm: false,
        servicesForm: false,
        equipmentForm: false,
        ratesForm: false,
        parcelRatesForm: false,
        referenceForm: false,
        carrierDetailsForm: false,
        billToInformationForm: false
    };

    public defaultLocationList = {
        originAddress: {
            shippingStartTime: null,
            shippingEndTime: null
        },
        destinationAddress: {
            receivingStartTime: null,
            receivingEndTime: null,
        }
    };

    public duplicateLocationModel = {
        locationCode: null,
        address1: null,
        city: null,
        stateCode: null,
        zipCode: null,
        countryCode: null,
        ownerId: null,
    };

    public addressBookModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        locationName: null,
        locationCode: null,
        address1: null,
        address2: null,
        city: null,
        stateName: null,
        stateCode: null,
        zipCode: null,
        countryId: null,
        countryCode: null,
        contactId: null,
        contacts: null,
        contactName: null,
        phone: null,
        phoneSecondary: null,
        email: null,
        fax: null,
        latitude: 0,
        longitude: 0,
        shippingStartTime: null,
        shippingEndTime: null,
        receivingStartTime: null,
        receivingEndTime: null,
        selectedContactId: null,
        pickupNotes: null,
        deliveryNotes: null,
        isResidential: null,
        shipInstructions: null,
        receiveInstructions: null,
        senderServices: [],
        receiveServices: [],
        defaultReceiveServices: [],
        defaultSenderServices: []
    };

    public additionalReferenceModel = {
        type: null,
        label: null,
        value: null,
        searchBox: null,
        isAdditional: true
    };

    public alertServiceModel = {
        shipmentId: null,
        comment: null,
        isBookingFailed: false
    };

    public alertServiceModelTL = {
        id: null,
        ownerId: null,
        shipmentId: null,
        comment: null,
        shipperName: null,
        consigneeName: null,
        trackingBaseUrl: environment.appUrl + 'tracking/shipment-tracking/',
        isBookingFailed: true
    };

    public shipmentTagModel = {
        shipmentId: null,
        tags: []
    };

    public settingsGetModel = {
        userId: null,
        ownerId: null,
        accountSettingType: null
    };

    public shipmentTrackingModel = {
        userId: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.shipmentTracking,
        settings: {
            VisiblePeriodRelativeDateStart: null,
            VisiblePeriodRelativeDateEnd: null,
            DisplayMode: null,
            ChunkSize: null,
            DoNotWarnMeOnExcelExport: false,
            ExpandTilesModule: false
        }
    };

    public LTLVendorSettingsModel = {
        userId: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.LTLVendorRouting,
        settings: {
            DefaultShipmentDateFrom: null,
            DefaultShipmentDateTo: null,
            DisplayMode: null
        }
    };

    public documentOwnerSettingsModel = {
        AutoFetchDocuments: false,
        DocumentsToAutoDownload: null
    };

    public fetchDocumentModel = {
        ownerId: null,
        shipmentId: null
    };

    public savedDataModel = {
        services: false,
        equipments: false
    };

    public globalSearchModel = {
        searchText: null,
        ownerId: null,
        size: 0,
        from: 0,
        sortField: null
    };

    public changePasswordModel = {
        currentPassword: null,
        password: null,
        confirmPassword: null
    };

    public loginLogsModel = {
        osName: null,
        userId: null,
        userLocation: null,
        loginDateTime: null,
        loginDelta: 0,
        loginMessage: null,
        ipAddress: null,
        isSuccess: true,
        userName: null,
        ownerName: null,
        emailAddress: null,
        phoneNumber: null,
        clientVersion: null,
        browserName: null,
        browserVersion: null,
        browserPlatform: null,
        instanceName: null,
        applicationVersion: null,
        timeZone: null
    };

    public selectedEnterpriseModel = {
        ownerId: null,
        ownerName: null
    };

    public selectedEnterprisePopupModel = {
        ownerId: null,
        ownerName: null,
        accountNumber: null
    };

    public scheduleShipmentSettingsModel = {
        SS_MaxItemRows: null,
        SS_DefaultItemCode: null,
        SS_ShowLinearFeet: false,
        SS_ShowTotalPallets: false,
        SS_DefaultPackagingType: null,
        SS_BOLSpecialInstructions: null,
        SS_PackageTypeComboValues: null,
        SS_HazMatPackageGroupComboValues: null
    };

    public TLshipmentSettingsModel = {
        MaxItemRows: null,
        DefaultItemCode: null,
        ShowLinearFeet: null,
        AllowMixedClass: false,
        ShowTotalHandlingUnits: false,
        DefaultPackagingType: null,
        BOLSpecialInstructions: null,
        PackageTypeComboValues: null,
        HazMatPackageGroupComboValues: null,
        DimensionsUOMComboValues: null,
        WeightUOMComboValues: null,
        WeightQualifierComboValues: null,
        FreightTermsComboValues: null,
        Services: null,
        ReferenceTypesComboValues: null,
        Equipment: null,
        CarrierNameComboValues: null,
        DefaultBillToLocation: null,
        IsItemPiecesRequired: null,
        DefaultShipStartTime: null,
        DefaultShipEndTime: null,
        DefaultDeliverStartTime: null,
        DefaultDeliverEndTime: null,
        HandlingUnitTypeComboValues: null,
        DefaultHandlingUnitType: null,
        DefaultHandlingUnitItemCode: null,
        HandlingUnitItemPackageTypesComboValues: null,
        DefaultItemId: null,
        DefaultHandlingUnit: null,
        ItemValueRequired: false,
        ItemDimsRequired: false,
        ItemHandlingunitRequired: false,
        OnCreditHold: false,
        RequireInsurance: false,
        InsuranceCommodities: null,
        InsuranceConfig: null,
        InsuranceTestMode: false
    };

    public shipmentSettingsModel = {
        MaxItemRows: null,
        DefaultItemCode: null,
        ShowLinearFeet: null,
        AllowNestedClass: false,
        UseNestedItemByDefault: false,
        ShowTotalHandlingUnits: false,
        DefaultPackagingType: null,
        BOLSpecialInstructions: null,
        PackageTypeComboValues: null,
        HazMatPackageGroupComboValues: null,
        DimensionsUOMComboValues: null,
        WeightUOMComboValues: null,
        WeightQualifierComboValues: null,
        FreightTermsComboValues: null,
        Services: null,
        ReferenceTypesComboValues: null,
        Equipment: null,
        CarrierNameComboValues: null,
        DefaultBillToLocation: null,
        IsItemPiecesRequired: null,
        DefaultShipStartTime: null,
        DefaultShipEndTime: null,
        DefaultDeliverStartTime: null,
        DefaultDeliverEndTime: null,
        HandlingUnitTypeComboValues: null,
        DefaultHandlingUnitType: null,
        DefaultHandlingUnitItemCode: null,
        HandlingUnitItemPackageTypesComboValues: null,
        OnCreditHold: false,
        LatestTenderTime: '14:00',
        TenderMinimumTime: null,
        ClassBreaks: null,
        ProcessClassBreaks: false,
        AllowClassModification: false,
        ParcelMaxLength: null,
        ParcelMaxAllDimensions: null,
        ParcelMaxWeight: null,
        ParcelLabelSize: null,
        ShippoBearerToken: null,
        ShippoTestToken: null,
        RequireInsurance: false,
        InsuranceCommodities: null,
        InsuranceConfig: null,
        InsuranceTestMode: false,
        InsuranceCSEmail: null,
        InsuranceModeMap: null
    };

    public vendorSettingsModel = {
        MaxItemRows: null,
        DefaultItemCode: null,
        ShowLinearFeet: null,
        AllowNestedClass: false,
        ShowTotalHandlingUnits: false,
        DefaultConsLocation: null,
        DefaultPackagingType: null,
        BOLSpecialInstructions: null,
        PackageTypeComboValues: null,
        HazMatPackageGroupComboValues: null,
        DimensionsUOMComboValues: null,
        WeightUOMComboValues: null,
        WeightQualifierComboValues: null,
        FreightTermsComboValues: null,
        Services: null,
        ReferenceTypesComboValues: null,
        Equipment: null,
        CarrierNameComboValues: null,
        DefaultBillToLocation: null,
        IsItemPiecesRequired: null,
        DefaultShipStartTime: null,
        DefaultShipEndTime: null,
        DefaultDeliverStartTime: null,
        DefaultDeliverEndTime: null,
        AllowLCPSelectionOnly: null,
        ShowLCPOnly: null,
        ShowCost: null,
        SchedulingAgreement: null,
        HandlingUnitTypeComboValues: null,
        DefaultHandlingUnitType: null,
        DefaultHandlingUnitItemCode: null,
        HandlingUnitItemPackageTypesComboValues: null,
        DisableDropShipment: false,
        OnCreditHold: false,
        LatestTenderTime: '14:00',
        TenderMinimumTime: null,
        ClassBreaks: null,
        ProcessClassBreaks: false,
        AllowClassModification: false,
        RequireInsurance: false,
        InsuranceCommodities: null,
        InsuranceConfig: null,
        InsuranceTestMode: false,
        InsuranceCSEmail: null,
        InsuranceModeMap: null
    };

    public gridDataModel = {
        data: [],
        total: 0
    };

    public shipmentRetryModel = {
        option: 1,
        comment: null
    };

    // Permissions List Model

    public quickRatePermissionModel = {
        ShowLinearFeetFieldOnQuickRate: false,
        ShowCarrierPriceOnRatesOnQuickRate: false,
        ScheduleShipmentModuleAccess: false
    };

    public shipmentRatePermissionModel = {
        ShowLinearFeetFieldOnShipment: false,
        ShowTotalPalletFieldOnShipment: false,
        ShowCarrierPriceOnRatesOnShipment: false
    };

    public userPermissionModel = {
        MaintenanceUserUpdate: false,
        MaintenanceUserImport: false
    };

    public ownerPermissionModel = {
        MaintenanceOwnerUpdate: false,
        MaintenanceOwnerImport: false,
        MaintenanceOwnerDelete: false
    };

    public settingsPermissionModel = {
        MaintenanceSettingsCreate: false,
        MaintenanceSettingsUpdate: false,
        MaintenanceSettingsDelete: false,
        ModifyDefaultSettingAcces: false
    };

    public locationPermissionModel = {
        MaintenanceLocationCreate: false,
        MaintenanceLocationUpdate: false,
        MaintenanceLocationImport: false,
        MaintenanceLocationDelete: false
    };

    public itemsPermissionModel = {
        MaintenanceItemImport: false,
        MaintenanceItemUpdate: false,
        MaintenanceItemDelete: false,
        MaintenanceItemAdd: false
    };

    public rolesPermissionModel = {
        MaintenanceRolesCreate: false,
        MaintenanceRolesUpdate: false,
        MaintenanceRolesDelete: false
    };

    public referenceTypePermissionModel = {
        MaintenanceReferenceTypeCreate: false,
        MaintenanceReferenceTypeUpdate: false,
        MaintenanceReferenceTypeDelete: false
    };

    public referenceSettingsPermissionModel = {
        MaintenanceReferenceSettingsCreate: false,
        MaintenanceReferenceSettingsUpdate: false,
        MaintenanceReferenceSettingsDelete: false
    };

    public accountSettingsPermissionModel = {
        BOLOnlyModuleAccess: false,
        ScheduleShipmentModuleAccess: false,
        ShipmentTrackingModuleAccess: false,
        VendorBooking: false
    };

    public trackingPermissionModel = {
        ShipmentDelete: false,
        UpdateShipmentStatus: false
    };

    public reportListPermissionModel = {
        ReportsRun: false,
        ReportsSchedule: false
    };

    public alertPermissionModel = {
        MaintenanceAlertConfigurationCreate: false,
        MaintenanceAlertConfigurationUpdate: false,
        MaintenanceAlertConfigurationDelete: false
    };

    public eproPermissionModel = {
        MaintenanceEproSettingsCreate: false,
        MaintenanceEproSettingsUpdate: false,
        MaintenanceEproSettingsDelete: false
    };

    public moduleAccessModel = {
        ShipmentTrackingModuleAccess: false,
        ScheduleShipmentModuleAccess: false,
        BOLOnlyModuleAccess: false,
        TruckloadModuleAccess: false,
        LessThanTruckLoadAccess: false,
        VendorBooking: false,
        VendorBooking_LTL: false,
        VendorBooking_TL: false
    };

    public whiteLabelingPermissionModel = {
        WhiteLabelCreate: false,
        WhiteLabelUpdate: false,
        WhiteLabelDelete: false
    };

    public bISettingsPermissionModel = {
        BISettingsCreate: false,
        BISettingsUpdate: false,
        BISettingsDelete: false
    };

    public applicationSettingsPermissionModel = {
        BOLOnlyModuleAccess: false,
        ScheduleShipmentModuleAccess: false,
        VendorBooking: false
    };

    public invoicePermissionModel = {
        InvoiceModuleAccess: false,
        MasterInvoiceModuleAccess: false
    };

    public carrierPermissionModel = {
        MaintenanceCarrierAdd: false,
        MaintenanceCarrierEdit: false,
        MaintenanceCarrierDelete: false,
        MaintenanceCarrierImport: false
    };

    // End

    public timeZoneModel = [{
        id: null,
        timeZoneId: null,
        location: null,
        description: null,
        relativeToGMT: null
    }];

    public userTimeZone = {
        timeZoneId: null,
        description: null
    };

    public columnSelectionModel = {
        userId: null,
        gridName: null,
        columns: []
    };

    public gridSelectionColumnModel = {
        field: 'id',
        title: 'Selection Id',
        template: null,
        width: 250,
        reorder: 1,
        hidden: true
    };

    public gridCopyColumnModel = {
        field: 'copy',
        title: 'Copy',
        template: null,
        width: 250,
        reorder: 2,
        hidden: true
    };

    public modifyFieldsModel = {
        linearFeetValue: null,
        totalPalletsValue: null,
        linearFeetFieldModify: false,
        totalPalletsFieldModify: false
    };

    public referenceSettingsRulesModel = [
        {
            name: CommonConstants.referenceSettingCode.shipperLocationCode,
            value: null
        },
        {
            name: CommonConstants.referenceSettingCode.consigneeLocationCode,
            value: null
        },
        {
            name: CommonConstants.referenceSettingCode.carrierId,
            value: null
        }
    ];

    public trackingDetailsGetModel = {
        ownerId: null,
        shipmentId: null
    };

    public trackingAdditionalModel = {
        expectedDeliveryDate: null,
        mapView: {
            shipmentId: null,
            origin: {
                latitude: 0,
                longitude: 0
            },
            destination: {
                latitude: 0,
                longitude: 0
            },
            latLongInfoList: [],
            moveType: null,
            isBooked: false
        },
        consigneeInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null
        },
        shipperInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null
        },
        statusHistoryDetails: [],
        shipmentStops:[],
        multiStopList:[]
    };

    public trackingItemCalculationModel = {
        totalItemDensity: 0,
        totalItemVolume: 0,
        totalItemWeight: 0,
        totalItemHandlingUnits: 0
    };

    public trackingHeaderModel = {
        status: null,
        ownerName: null,
        bolNumber: null,
        pickUpDate: null,
        deliveryDate: null,
        scheduledPickupDate: null,
        expectedDeliveryDate: null,
        pickupTimeZone: null,
        deliveryTimeZone: null
    };

    public trackingMapFilterModel = {
        ownerId: null,
        filter: {
            directions: [],
            modes: [],
            dateFrom: null,
            dateTo: null,
            status: null
        }
    };

    public trackingInfoWindowModel = {
        latitude: 0,
        longitude: 0,
        ownerId: null,
        shipmentIds: [],
        filter: {
            directions: [],
            modes: [],
            dateFrom: null,
            dateTo: null,
            status: null
        }
    };

    public infoWindowResponseModel = {
        eventType: null,
        location: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null
        },
        locationShipments: []
    };

    public trackingDetailsModel = {
        shipmentId: null,
        status: null,
        ownerId: null,
        ownerName: null,
        bolNumber: null,
        pickUpDate: null,
        deliveryDate: null,
        bolInstructions: null,
        scheduledPickupDate: null,
        expectedDeliveryDate: null,
        consigneeInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null
        },
        shipperInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null
        },
        carrierInfo: {
            carrier: null,
            scac: null,
            fuel: null,
            bol: null,
            pro: null,
            freight: null,
            totalCost: null,
            carrierPU: null,
            accessorials: null,
            serviceDays: null,
            vrShowCost: false
        },
        commodities: [],
        equipments: [],
        services: [],
        references: [],
        totalItemDensity: 0,
        totalItemVolume: 0,
        totalItemWeight: 0,
        totalItemHandlingUnits: 0,
        carrierTrackingLink: null,
        mcNumber: null,
        items: [],
        trackingNumberLink: null,
        shipmentStops: []
    };

    public addressInfoModel = {
        shipperInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null,
            pickupNotes: null
        },
        consigneeInfo: {
            stopName: null,
            address: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null,
            deliveryNotes: null
        }
    };

    public carrierInfoModel = {
        carrier: null,
        scac: null,
        fuel: null,
        bol: null,
        pro: null,
        freight: null,
        totalCost: null,
        carrierPU: null,
        accessorials: null,
        serviceDays: null,
        vrShowCost: null,
        mode: null,
        insuranceCost: null
    };

    public terminalInfoModel = {
        origin: {
            locationName: null,
            address1: null,
            address2: null,
            city: null,
            stateCode: null,
            zipCode: null,
            countryCode: null,
            phonePrimary: null,
            phoneSecondary: null,
            email: null,
            fax: null
        },
        destination: {
            locationName: null,
            address1: null,
            address2: null,
            city: null,
            stateCode: null,
            zipCode: null,
            countryCode: null,
            phonePrimary: null,
            phoneSecondary: null,
            email: null,
            fax: null
        }
    };

    public trackingDirectionsColorListModel = [
        {
            settingName: 'InboundShipmentColor',
            displayText: 'Inbound',
            value: '#000000'
        },
        {
            settingName: 'OutboundShipmentColor',
            displayText: 'Outbound',
            value: '#F79646'
        },
        {
            settingName: 'DropShipmentColor',
            displayText: 'Drop',
            value: '#943734'
        },
        {
            settingName: 'ShuttleShipmentColor',
            displayText: 'Shuttle',
            value: '#EDA915'
        }
    ];

    public vendorDirectionsColorListModel = [
        {
            settingName: 'InboundShipmentColor',
            displayText: 'Inbound',
            value: '#000000'
        },
        {
            settingName: 'DropShipmentColor',
            displayText: 'Drop',
            value: '#943734'
        }
    ];

    public vendorMapFilterModel = [
        {
            title: CommonConstants.trackingMapForm.modes,
            items: [
                { value: 'LTL', text: 'LTL', isSelected: false },
                { value: 'Truckload', text: 'Truckload', isSelected: false },
                { value: 'Volume', text: 'Volume', isSelected: false }
            ]
        },
        {
            title: CommonConstants.trackingMapForm.directions,
            items: [
                { value: 'Inbound', text: 'Inbound', isSelected: false },
                { value: 'Drop', text: 'Drop', isSelected: false },
            ]
        }
    ];

    public USTerritoriesListModel = [
        {
            stateCode: 'US',
            stateName: 'United States',
        },
        {
            stateCode: 'PR',
            stateName: 'Puerto Rico',
        },
        {
            stateCode: 'VI',
            stateName: 'U.S. Virgin Islands',
        },
        {
            stateCode: 'MP',
            stateName: 'Northern Mariana Islands',
        },
        {
            stateCode: 'AS',
            stateName: 'American Samoa',
        },
        {
            stateCode: 'GU',
            stateName: 'Guam',
        }
    ];

    public userModifiedListModel = {
        freightClass: false,
        nmfC_Code: false,
        description: false
    };

    public breadcrumbsModel = {
        trackingGrid: null,
        ltlVendorGrid: null,
        invoiceGrid: null
    };

    public invoiceCommentsFormModel = {
        id: null,
        invoiceId: null,
        comments: null,
        updatedBy: null,
        updatedDate: null,
        ownerId: null
    };

    public invoiceSummaryModel = {
        invoiceNumber: null,
        invoiceDate: null,
        invoiceTotal: null,
        paymentTerms: null,
        billTo: null,
        remitTo: null,
        invoices: [],
        carrier: null,
        shipDate: null,
        origin: null,
        destination: null,
        bol: null,
        pro: null,
        mode: null,
        notes: null,
        salesRep: null,
        consigneeInfo: null,
        shipperInfo: null,
        balanceDue: null,
        commodityList: [],
        referencesList: [],
        invoiceChargeDetails: []
    };

    public invoiceDownloadModel = {
        fileType: 1,
        id: null,
        shipmentId: null,
        ownerId: null
    };

    public invoiceDetailsModel = {
        shipmentId: null,
        invoiceId: null,
        ownerId: null,
        accountNumber: null
    };

    public requestQuoteListModel = {
        ownerId: null,
        ownerName: null,
        linearFeet: 0,
        totalPallets: 0,
        totalVolume: 0,
        totalDensity: 0,
        totalWeight: 0,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        shipmentHandlingUnits: [],
        shipmentServices: {
            services: []
        },
        shipmentEquipments: {
            equipments: []
        },
        referenceSettings: [],
        isQuoteEmailSelected: false,
        isQuoteRequest: true,
        bolInstructions: null,
        missedRequiredReferences: false
    };

    public informationDataModel = {
        message: null,
        iconName: null,
        iconColor: null,
        iconSize: '24px',
        buttonName: 'Done',
        showTLElements: false
    };

    public shipmentStatusFormModel = {
        ownerId: null,
        ownerName: null,
        status: null,
        statusCode: null,
        shipmentDetails: [],
        statusDateTime: null
    };

    public feedbackformModel = {
        commentedDate: null,
        isThumbsUp: false,
        shipmentId: null,
        ownerId: null,
        comments: null,
        userName: null,
        email: null,
        recaptchaValue: null
    };

    public filterExcludeModel = {
        turnedOff: false,
        exclude: {
            members: []
        }
    };

    public enterpriseSelectionModel = {
        parent: [],
        child: []
    };

    public dasboardFilterModel = {
        parent: [],
        child: [],
        excludeFilter: {
            parent: null,
            child: null
        }
    };

    public uploadFileModel = {
        file: null,
        base64Url: null,
        fileName: null,
        fileSize: null,
        isFileValid: false,
    };

    public claimsFormPayload = {
        shipmentId: null,
        ownerId: null,
        bol: null,
        pieces: null,
        isClaimTypeDamage: false,
        isClaimTypeLoss: false,
        totalWeightOfDamagedOrLostItem: null,
        claimProductName: null,
        totalAmountToFile: null,
        isApprovedTLIClaimFee: false,
        isDamageType_Shortage: false,
        isDamageType_VisibleDamage: false,
        isDamageType_ConcealedDamage: false,
        isDamageType_Loss: false,
        isDamageType_Others: false,
        damageType_OtherDamageType: null,
        damageClaimDetails: null,
        damagedFreightBeRepairedYes: false,
        damagedFreightBeRepairedNo: false,
        quoteOrInvoiceForRepairCosts: null,
        explanationTotalLossIfNotRepairable: null,
        damagedFreightLocation: null,
        isReturnedToShipper: false,
        isDeliveredToCustomer: false,
        claimInformationLink: null,
        supportedDocuments: []
    }

    public shipmentCountsModel = {
        lastMonth_LTL: 0,
        lastMonth_TL: 0,
        lastMonth_Other: 0,
        thisMonth_LTL: 0,
        thisMonth_TL: 0,
        thisMonth_Other: 0,
        thisYear_LTL: 0,
        thisYear_TL: 0,
        thisYear_Other: 0,
        inTransit: 0,
        pickedUpToday: 0,
        expectedPickupToday: 0,
        deliveredToday: 0,
        expectedDeliveryToday: 0
    }

    public parcelShipmentDropdownModel = [
        { display: 'Rate LTL', value: 1 },
        { display: 'Rate Parcel', value: 2 },
        { display: 'Rate LTL and Parcel', value: 0 },
    ]

    public parcelItemSelected =
        {
            id: null,
            weight: null,
            quantity: null,
            handlingType: null,
            pieces: null,
            length: null,
            width: null,
            height: null,
            minPCF: null,
            maxPCF: null,
            freightClass: null,
            nmfC_Code: null,
            itemId: null,
            description: null,
            value: null,
            uN_NA: null,
            isHazmat: false,
            hazMatClass: null,
            hazMatGroup: null,
            hazMatShipping: null,
            hazMatContactName: null,
            hazMatContactPhone: null,
            volume: 0,
            density: 0,
            searchBoxClass: null,
            searchBoxHandlingType: null,
            searchBoxPackageGroup: null,
            minLength: null,
            maxLength: null,
            tempFreightClass: null,
            isHandlingUnitSelected: true,
            isFreightClassSelected: false,
            suggestedFreightClass: null,
            isRequiredInsurance: false,
            isContainsDryIce: false,
            isContainsAlcohol: false,
            totalAmount: null,
            notes: '',
            itemCount: 1,
            totalAmountPerPackage: null
        }

    public parcelRateListModel = {
        id: null,
        shipmentId: null,
        amount: 0,
        currency: null,
        amountLocal: null,
        currencyLocal: null,
        attributes: [],
        provider: null,
        providerImage75: null,
        providerImage200: null,
        arrives_by: null,
        durationTerms: null,
        messages: [],
        carrier_account: null,
        zone: 0,
        test: null,
        servicelevel: this.parcelRateServiceLevel,
        transitTime: 0,
        includedInsurancePrice: 0,
        mode: null,
        isSelectable: true,
        isLowestRate: false,
        parcelItem: this.parcelItemSelected,
        RateRequestId: null,
    };

    public customerDetailsModel = {
        type: null,
        FirstName: null,
        LastName: null,
        email: null,
        additionalEmail: null,
        businessName: null,
        reference: null,
        taxId: null,
        customerAddress: {
            address1: null,
            address2: null,
            street: null,
            city: null,
            state: null,
            postcode: null,
            country: null
        }
    }

    public customerDetails = {
        type: null,
        businessName: null,
        firstName: null,
        lastName: null,
        email: null,
        additionalEmail: [],
        customerAddress: {
            address1: null,
            address2: null,
            city: null,
            stateCode: null,
            stateName: null,
            zipCode: null,
            countryCode: null,
            countryName: null
        }
    }

    public insuranceEmailModel = {
        emailUser: false,
        emailShipper: false,
        emailConsignee: false,
        emailOthers: false,
        otherEmailIds: []
    }

    public insuranceRequest = {
        commodities: [
            {
                insuredValue: 0,
                categoryId: null,
                itemName: null,
                currencyId: null
            }
        ],
        commodityDescription: null,
        origin: this.originAddress,
        destination: this.destinationAddress,
        originType: 'ADDRESS',
        destinationType: "ADDRESS",
        startDate: null,
        endDate: null,
        ownerId: null,
        ownerName: null,
        customerId: null,
        customer: this.customerDetailsModel,
        organizationId: null,
        isTest: true,
        additionalFields: {},
        serviceDays: null
    }

    public selectedParcelRates = [{
        parcelItem: this.parcelItemSelected,
        parcelRate: this.parcelRateListModel
    }]

    public bookingParcelShipmentModel = {
        id: null,
        ownerId: null,
        ownerName: null,
        tenantId: null,
        isToReRate: false,
        linearFeet: 0,
        totalPallets: 0,
        bookingType: CommonConstants.bookingType.bookingShipment,

        aggreedTAndC: false,
        scheduledPickUpDate: null,
        expectedDeliveryDate: null,
        originAddress: this.originAddress,
        destinationAddress: this.destinationAddress,
        parcelRates: this.selectedParcelRates,

        shipmentServices: {
            services: []
        },
        shipmentReferenceSettings: {
            referenceSettings: []
        },
        signalRConnectionId: null,
        bookingChoice: CommonConstants.bookingModeConstants.bookAndWait,
        NotificationSignalRId: null,
        LabelFileType: null,
        test: environment.instanceName == 'Production' ? false : true,
        trackingBaseUrl: environment.appUrl + 'tracking/shipment-tracking/',
    }

    public emailListModel = {
        email: null,
        shipper: null,
        consignee: null
    }

}
