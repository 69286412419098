import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class SignalrService {

    baseUrl = environment.baseUrl.replace('api/', '');
    logInConnectionUrl = environment.authority
    hubConnection: signalR.HubConnection;
    hubNotificationConnection: signalR.HubConnection;
    hubLogInStatusConnection: signalR.HubConnection;
    hubImportConnection: signalR.HubConnection;
    hubShippoAuthConnection: signalR.HubConnection;
    connectionId: string;
    private connectionSubject = new BehaviorSubject(null);
    private importconnectionSubject = new BehaviorSubject(null);
    private logInConnectionSubject = new BehaviorSubject(null);
    private shippoAuthConnectionSubject = new BehaviorSubject(null);

    constructor() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl + 'progressHub')
            .withAutomaticReconnect()
            .build();
        this.hubImportConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl + 'progressHub')
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
            .build();
        this.hubNotificationConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl + 'progressHub')
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
            .build();
        this.hubLogInStatusConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.logInConnectionUrl + '/IdentityNotificationHub')
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
            .build();
        this.hubShippoAuthConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl + 'progressHub')
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
            .build();
        this.hubNotificationConnection.keepAliveIntervalInMilliseconds = 36000000;
        this.hubNotificationConnection.onreconnected((error) => {
            this.connectionSubject.next(this.hubNotificationConnection.connectionId);
        });
        this.hubLogInStatusConnection.keepAliveIntervalInMilliseconds = 36000000;
        this.hubLogInStatusConnection.onreconnected((error) => {
            this.connectionSubject.next(this.hubLogInStatusConnection.connectionId);
        });
        this.hubShippoAuthConnection.keepAliveIntervalInMilliseconds = 36000000;
        this.hubShippoAuthConnection.onreconnected((error) => {
            this.shippoAuthConnectionSubject.next(this.hubShippoAuthConnection.connectionId);
        });
    }

    onConnect() {
        if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubConnection
                .start()
                .then(() => {
                    this.connectionId = this.hubConnection.connectionId;
                })
                .catch(err => console.log(err));
        }
    }

    onImportConnect() {
        if (this.hubImportConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubImportConnection
                .start()
                .then(() => {
                    this.importconnectionSubject.next(this.hubImportConnection.connectionId);
                })
                .catch(err => console.log(err));
        }
    }

    getNotificationConnectionState() {
        return this.hubNotificationConnection ? this.hubNotificationConnection.state.toLowerCase() : 'disconnected';
    }

    onConnectNotification(): void {
        if (this.hubNotificationConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubNotificationConnection
                .start()
                .then(() => {
                    this.connectionSubject.next(this.hubNotificationConnection.connectionId);
                })
                .catch(err => console.log(err));
        }
    }

    onConnectLogIn(): void {
        if (this.hubLogInStatusConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubLogInStatusConnection
                .start()
                .then(() => {
                    this.logInConnectionSubject.next(this.hubLogInStatusConnection.connectionId);
                })
                .catch(err => console.log(err));
        }
    }

    onConnectShippoLogin(): void {
        if (this.hubShippoAuthConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubShippoAuthConnection
                .start()
                .then(() => {
                    this.shippoAuthConnectionSubject.next(this.hubShippoAuthConnection.connectionId);
                })
                .catch(err => console.log(err));
        }
    }

    onGetConnectionId() {
        return this.connectionId;
    }

    onImportGetConnectionId() {
        return this.importconnectionSubject.asObservable();
    }

    getConnectionId() {
        return this.connectionSubject.asObservable();
    }

    getLogInConnectionId() {
        return this.logInConnectionSubject.asObservable();
    }

    getShippoAuthConnectionId() {
        return this.shippoAuthConnectionSubject.asObservable();
    }

    onDisconnect() {
        this.hubConnection.stop();
        this.connectionId = null;
    }

    onImportDisconnect() {
        this.hubImportConnection.stop();
        this.importconnectionSubject.next(null);
    }

    onDisconnectNotification() {
        this.hubNotificationConnection.stop();
        this.connectionSubject.next(null);
    }

    onLogInDisconnect() {
        this.hubLogInStatusConnection.stop();
        this.logInConnectionSubject.next(null);
    }

    onShippoConnectionDisconnect() {
        this.hubShippoAuthConnection.stop();
        this.shippoAuthConnectionSubject.next(null);
    }

}
