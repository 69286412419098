import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-equipments-info',
    templateUrl: './equipments-info.component.html'
})

export class EquipmentsInfoComponent implements OnInit {

    equipmentList: Array<object> = [];

    @Input() set childData(equipments: any) {
        this.equipmentList = equipments;
    }

    constructor() {
    }

    ngOnInit() {
    }

}
