import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { TrackingDataModel } from 'src/app/tracking/tracking-data-model';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-tracking-map-filter',
    templateUrl: './tracking-map-filter.component.html',
    styleUrls: ['./tracking-map-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrackingMapFilterComponent implements OnInit {

    @Output() mapFilteredData: EventEmitter<any> = new EventEmitter<any>();
    commonDataModel = new CommonDataModel();
    trackingDataModel = new TrackingDataModel();
    trackingMapFilterModel = this.commonDataModel.trackingMapFilterModel;
    mapFilterList: any;
    activeFilterBar = false;

    @Input() set childData(data: any) {
        this.mapFilterList = data;
    }

    constructor() { }

    ngOnInit() {
    }

    onShowFilter() {
        this.activeFilterBar = !this.activeFilterBar;
    }

    onChangeFilter(): void {
        this.trackingMapFilterModel.filter.modes = [];
        this.trackingMapFilterModel.filter.directions = [];
        this.mapFilterList.filter(list => {
            if (list.title === CommonConstants.trackingMapForm.modes) {
                list.items.filter(childList => {
                    if (childList.isSelected) {
                        this.trackingMapFilterModel.filter.modes.push(childList.value);
                    }
                });
            }
            if (list.title === CommonConstants.trackingMapForm.directions) {
                list.items.filter(childList => {
                    if (childList.isSelected) {
                        this.trackingMapFilterModel.filter.directions.push(childList.value);
                    }
                });
            }
        });
        this.mapFilteredData.emit(this.trackingMapFilterModel.filter);
    }

}
