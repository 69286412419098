import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCommonService } from 'src/app-core/services/api-common.service';
import { CommonService } from 'src/app-core/services/common.service';

@Injectable({
    providedIn: 'root'
})

export class LTLVendorService {

    MGResponseTimeout: number;

    constructor(
        private apiService: ApiCommonService,
        private commonService: CommonService) {
        this.MGResponseTimeout = this.commonService.getMGResponseTimeout();
    }

    bookShipment(inputData: object): Observable<any> {
        return this.apiService.postRecord('VendorShipments', inputData, this.MGResponseTimeout);
    }

    
     // Save Insurance Quote
     purchaseInsuranceQuote(inputData: object): Observable<any> {
        return this.apiService.postRecord('Insurance/bookings/purchase', inputData, this.MGResponseTimeout);
    }

    getSearchLTLVendorItems(inputData: object): Observable<any> {
        return this.apiService.postRecord('Items/SearchVendorItems', inputData);
    }

}
