import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { MapLatLongEnum, MapZoomEnum, TrackingDetailsTabEnum } from 'src/app-core/enums/common-enum';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';
import * as moment from 'moment';

@Component({
    selector: 'app-tracking-map-view',
    templateUrl: './tracking-map-view.component.html',
    styleUrls: ['./tracking-map-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrackingMapViewComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    trackingDetailsModel = this.commonDataModel.trackingDetailsModel;
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    trackingMapFilterModel = this.commonDataModel.trackingMapFilterModel;
    trackingInfoWindowModel = this.commonDataModel.trackingInfoWindowModel;
    infoWindowResponseModel = this.commonDataModel.infoWindowResponseModel;
    publicTrackingInfoWindowData: any;
    latitude = MapLatLongEnum.latitude;
    longitude = MapLatLongEnum.longitude;
    trackingMapList: any;
    infoLoading = false;
    isLoading = false;
    showPopup = false;
    moduleName: string;
    mapZoom = MapZoomEnum.value;
    paramId: string;
    trackingNumber: any;
    originInfoWindowOpened = false;
    destinationInfoWindowOpened = false;
    MapZoomEnum: typeof MapZoomEnum = MapZoomEnum;
    MapLatLongEnum: typeof MapLatLongEnum = MapLatLongEnum;
    trackingDetailsTabEnum: typeof TrackingDetailsTabEnum = TrackingDetailsTabEnum;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(data: any) {
        this.trackingMapList = data;
    }

    @Input() set infoWindowData(data: any) {
        this.publicTrackingInfoWindowData = data;
    }

    @Input() set moduleNameValue(data: string) {
        this.moduleName = data;
    }

    @Input() set zoomLevel(data: string) {
        if (data) {
            this.mapZoom = Number(data);
        }
    }

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
        this.trackingMapFilterModel.filter.status = CommonConstants.trackingMapForm.active;
        this.trackingMapFilterModel.ownerId = this.commonService.getOwnerId();
        this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
        this.trackingInfoWindowModel.ownerId = this.commonService.getOwnerId();
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramId = params.get('id');
        }));
    }

    onSelectMarker(latitude: number, longitude: number, points: any): void {
        const listArr = [];
        if (Array.isArray(this.trackingMapList) && this.trackingMapList.length) {
            this.infoLoading = true;
            const filterShipmentId = [];
            this.trackingMapList.map((list: any, index: any) => {
                if (list.latLongInfoList.length > 2) {
                    const x = filterShipmentId.findIndex((co: any) => co == list.shipmentId);
                    list.latLongInfoList.map((data: any) => {
                        if (x == -1 && data.latitude == latitude && data.longitude == longitude) {
                            listArr.push(list);
                            filterShipmentId.push(list.shipmentId);
                        }
                    })
                } else {
                    if ((list.latLongInfoList[0].latitude === latitude && list.latLongInfoList[0].longitude === longitude) ||
                        (list.latLongInfoList[list.latLongInfoList.length - 1].latitude === latitude && list.latLongInfoList[list.latLongInfoList.length - 1].longitude === longitude)) {
                        listArr.push(list);
                        filterShipmentId.push(list.shipmentId);
                    }
                }
            }).filter(list => list !== undefined);
            this.infoWindowResponseModel = Object.assign({}, this.commonDataModel.infoWindowResponseModel);
            this.trackingInfoWindowModel.latitude = latitude;
            this.trackingInfoWindowModel.longitude = longitude;

            if (Array.isArray(filterShipmentId) && filterShipmentId.length) {
                this.trackingInfoWindowModel.shipmentIds = filterShipmentId;
            }
            this.commonService.objectMapping(this.trackingInfoWindowModel, this.trackingMapFilterModel);
            this.commonService.getInfoWindowRecords(this.trackingInfoWindowModel, this.moduleName)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    response['eventType'] = points.eventType;
                    this.infoWindowResponseModel = response;
                    this.infoLoading = false;
                });
        }
    }

    onShowTrackingDetails(shipmentId: string, item: any): void {
        this.trackingNumber = item.trackingNumber;
        switch (this.moduleName) {
            case CommonConstants.moduleName.shipmentTracking:
                if (item.trackingNumber) {
                    this.router.navigate(['app/tracking/tracking-map/' + shipmentId],
                        { queryParams: { moduleName: CommonConstants.moduleName.shipmentTracking, mode: 'Parcel' } });
                } else {
                    this.router.navigate(['app/tracking/tracking-map/' + shipmentId],
                        { queryParams: { moduleName: CommonConstants.moduleName.shipmentTracking } });
                }
                break;
            case CommonConstants.moduleName.LTLVendorBooking:
                this.router.navigate(['app/ltl-vendor/tracking/map/' + shipmentId],
                    { queryParams: { moduleName: CommonConstants.moduleName.LTLVendorBooking } });
                break;
            case CommonConstants.moduleName.TLVendorBooking:
                this.router.navigate(['app/tl-vendor/tracking/map/' + shipmentId],
                    { queryParams: { moduleName: CommonConstants.moduleName.TLVendorBooking } });
                break;
            default:
                break;
        }
        this.showPopup = true;
        this.isLoading = true;
        this.commonService.sendTabIndexValue(TrackingDetailsTabEnum.detailsTab);
        this.trackingDetailsGetModel.shipmentId = shipmentId;
        if (item.trackingNumber) {
            this.commonService.getParcelTrackingDetails(this.trackingDetailsGetModel)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    const resp: any = response;
                    resp.shipmentId = shipmentId;
                    this.trackingDetailsModel = resp;
                });
        } else {
            this.commonService.getTrackingDetails(this.trackingDetailsGetModel)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    const resp: any = response;
                    resp.shipmentId = shipmentId;
                    this.trackingDetailsModel = resp;
                });
        }
    }

    getLocationValues(property: any) {
        if (this.infoWindowResponseModel && this.infoWindowResponseModel.location) {
            return this.infoWindowResponseModel.location[property];
        }
        return '';
    }

    onClosePopup() {
        this.showPopup = !this.showPopup;
        setTimeout(() => {
            switch (this.moduleName) {
                case CommonConstants.moduleName.shipmentTracking:
                    this.router.navigateByUrl('app/tracking/tracking-map/0');
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.router.navigateByUrl('app/ltl-vendor/tracking/map/0');
                    break;
                case CommonConstants.moduleName.TLVendorBooking:
                    this.router.navigateByUrl('app/tl-vendor/tracking/map/0');
                    break;
                default:
                    break;
            }
            this.commonService.sendTabIndexValue(TrackingDetailsTabEnum.detailsTab);
        }, 500);
    }

    onTrackingDetailsNav(): void {
        switch (this.moduleName) {
            case CommonConstants.moduleName.shipmentTracking:
                if (this.trackingNumber) {
                    this.router.navigateByUrl('app/tracking/tracking-details/' +
                        this.paramId + '?moduleName=' + CommonConstants.moduleName.shipmentTracking + '&mode=Parcel');
                } else {
                    this.router.navigateByUrl('app/tracking/tracking-details/' +
                        this.paramId + '?moduleName=' + CommonConstants.moduleName.shipmentTracking);
                }
                break;
            case CommonConstants.moduleName.LTLVendorBooking:
                this.router.navigateByUrl('app/ltl-vendor/tracking-details/' +
                    this.paramId + '?moduleName=' + CommonConstants.moduleName.LTLVendorBooking);
                break;
            case CommonConstants.moduleName.TLVendorBooking:
                this.router.navigateByUrl('app/tl-vendor/tracking-details/' +
                    this.paramId + '?moduleName=' + CommonConstants.moduleName.TLVendorBooking);
                break;
            default:
                break;
        }
    }


    getIcons(index: any, items: any) {
        const length = items.length - 1;
        if (index == 0) {
            return 'assets/images/source_marker.png'
        } else if (index == length) {
            return 'assets/images/destination_marker.png'
        } else {
            return index;
        }
    }

    showMultistops(index: any, data: any) {
        if (data && data.length) {
            const length = data.length - 1;
            if (index < length && index > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    getDates(dateValue: any) {
        if (dateValue) {
            const date = new Date(dateValue);
            const shipmentDate = moment(date).format("MM/DD/YY hh:mm");
            return shipmentDate;
        } else {
            return '';
        }
    }

    getEventType(stopObj: any) {
        let url = '';
        switch (stopObj.eventType) {
            case 'DROP':
                url = stopObj.actualDate ? 'assets/images/dropEvent.svg' : 'assets/images/drop.svg';
                break;
            default:
                url = stopObj.actualDate ? 'assets/images/pickupEvent.svg' : 'assets/images/pickup.svg';
                break;
        }

        return url;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
