import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-insurance-rate-dialog',
  templateUrl: './insurance-rate-dialog.component.html',
  styleUrls: ['./insurance-rate-dialog.component.scss']
})
export class InsuranceRateDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<InsuranceRateDialogComponent>) { }

  ngOnInit() {
  }

  onClose(){
    this.dialogRef.close({ confirmResult: true });
  }

}
