import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonDataModel } from '../../common-data-model';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonConstants } from 'src/app-core/constants/common-constants';

@Component({
    selector: 'app-invoice-summary',
    templateUrl: './invoice-summary.component.html',
    styleUrls: ['./invoice-summary.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceSummaryComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    invoiceSummaryModel = this.commonDataModel.invoiceSummaryModel;
    showMasterInvoice = false;
    whiteLabelingSettings:any;
    @Input() set invoiceSummaryData(data: any) {
        this.invoiceSummaryModel = data;
    }

    @Input() set showMasterInvoiceView(data: boolean) {
        this.showMasterInvoice = data;
    }

    constructor(private commonService: CommonService) { }

    ngOnInit() {
        this.getWhiteLablingDetails();
    }

    getWhiteLablingDetails(){
        this.commonService.getRootWhiteLabelingSettings(CommonConstants.rootEnterpriseId.rootId).subscribe((data:any)=>{
            this.whiteLabelingSettings = data;
        })
    }
}
