import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlphaNumericDirective } from './directive/alpha-numeric.directive';
import { BusyIfDirective } from './directive/busy-if.directive';
import { ButtonBusyDirective } from './directive/button-busy.directive';
import { DecimalNumberDirective } from './directive/decimal-number.directive';
import { NumericNumberDirective } from './directive/numeric-number.directive';
import { RangeValidatorDirective } from './directive/range-validator.directive';
import { CurrentTimeZonePipe } from './pipe/current-time-zone.pipe';
import { DecodeBase64Pipe } from './pipe/decode-base64.pipe';
import { EncodeBase64Pipe } from './pipe/encode-base64.pipe';
import { FilterByFieldsPipe } from './pipe/filter-by-fields.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { RemoveUnderscorePipe } from './pipe/remove-underscore.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { ValueCheckPipe } from './pipe/value-check.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BusyIfDirective,
        DecimalNumberDirective,
        NumericNumberDirective,
        AlphaNumericDirective,
        ButtonBusyDirective,
        EncodeBase64Pipe,
        DecodeBase64Pipe,
        FilterPipe,
        ValueCheckPipe,
        SortByPipe,
        SafeUrlPipe,
        FilterByFieldsPipe,
        CurrentTimeZonePipe,
        RemoveUnderscorePipe,
        RangeValidatorDirective
    ],
    exports: [
        CommonModule,
        BusyIfDirective,
        DecimalNumberDirective,
        NumericNumberDirective,
        AlphaNumericDirective,
        ButtonBusyDirective,
        EncodeBase64Pipe,
        DecodeBase64Pipe,
        FilterPipe,
        ValueCheckPipe,
        SortByPipe,
        SafeUrlPipe,
        FilterByFieldsPipe,
        CurrentTimeZonePipe,
        RemoveUnderscorePipe,
        RangeValidatorDirective
    ],
    providers: [],
})

export class UtilsModule { }
