import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-common-dialog',
    templateUrl: './common-dialog.component.html'
})

export class CommonDialogComponent {

    inputData: any;

    constructor(
        private dialogRef: MatDialogRef<CommonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: string) {
        this.inputData = data;
        if (typeof (this.inputData.message) === 'string') {
            this.inputData.message = new Array(this.inputData.message);
        }
    }

    onClose() {
        this.dialogRef.close();
    }

}
