import { Component, Inject } from '@angular/core';
import { MatCheckboxChange, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-report-recipient-list',
    templateUrl: './email-recipient-list.component.html',
    styleUrls: ['./email-recipient-list.component.scss']
})

export class EmailRecipientModalComponent {

    title: string;
    items: any[];
    selectedListOfEmails = [];
    itemId: any;
    showResendDialogContent = false;
    generateAndSendFlag = false;
    isLoading = false;

    constructor(
        public commonService: CommonService,
        private customToastrService: CustomToastrService,
        public dialogRef: MatDialogRef<EmailRecipientModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.title = data.title;
        this.items = data.items;
        this.showResendDialogContent = data.showResendDialog;
        this.itemId = this.showResendDialogContent ? data.id : null;
        if (data.hasOwnProperty) {
            this.generateAndSendFlag = data.resendAndGenerateReport;
        }
    }

    onCheckboxChange(event: MatCheckboxChange) {
        if (event.checked) {
            this.selectedListOfEmails.push(event.source.value);
        } else {
            const index = this.selectedListOfEmails.findIndex((x) => x === event.source.value);
            this.selectedListOfEmails.splice(index, 1);
        }
    }

    onSubmit() {
        this.isLoading = true;
        const isresendReport = {
            id: this.itemId,
            isGenerateAndSend: this.generateAndSendFlag,
            emailIds: this.selectedListOfEmails
        };
        this.commonService.resendReport(isresendReport)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((response) => {
                this.onClose();
                this.customToastrService.success(response.message);
            });
    }

    onClose() {
        this.dialogRef.close();
    }

}
