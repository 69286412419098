import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { ShipmentConstants } from 'src/app-core/constants/shipment-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { SignalrService } from 'src/app-core/services/signalr.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { CommonDataModel } from '../common-data-model';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';

@Component({
    selector: 'app-shipment-rate',
    templateUrl: './shipment-rate.component.html',
    styleUrls: ['./shipment-rate.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentRateComponent extends AppComponentBase implements OnInit, OnChanges, OnDestroy {

    @ViewChild('kg', { static: true }) private grid: GridComponent;
    @Output() isRequestQuote: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() isPrintDocument: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    shipmentRateListModel = Object.assign({}, this.commonDataModel.shipmentRateListModel);
    shipmentLCPDetailsModel = Object.assign({}, this.commonDataModel.shipmentLCPDetailsModel);
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    informationDataModel = this.commonDataModel.informationDataModel;
    isLoading = false;
    ratesForm: any;
    reRateBtn = false;
    displayLoadingText: string;
    rowSelectionList: number[] = [];
    gridData = { items: [], totalResults: 0 };
    moduleName: string;
    lcpCost: number;
    showCost = false;
    showLCPOnly = false;
    allowLCPSelectionOnly = false;
    isRequestQuoteBtn = true;
    isRequestBtnDisabled = false;
    isRedLineWarning = false;
    isRequestQuoteEnabled = false;
    fetchRateBtn = false;
    isPrintDocumentBtn = true;
    isRequestQuoteLoading = false;
    bookingMode = true;
    isParcelRatesShowMessage = false;
    rateResponse: any
    commonConstants: typeof CommonConstants = CommonConstants;
    shipmentConstants: typeof ShipmentConstants = ShipmentConstants;

    @Input() set fetchRateResponse(fetchRateResponse: any) {
        this.rateResponse = fetchRateResponse;
        this.gridData.items = fetchRateResponse;
        if (this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
            this.applySettings();
        }
        this.onRefreshGrid();
    }
    @Input() set childData(data: any) {
        this.shipmentRateListModel = data;
    }
    @Input() set formStatus(formList: object) {
        this.ratesForm = formList;
    }
    @Input() set module(value: any) {
        this.moduleName = value;
    }
    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
    }
    @Input() set shipmentLCPData(data: any) {
        this.shipmentLCPDetailsModel = data;
    }
    @Input() set fetchRateBtnStatus(data: boolean) {
        this.fetchRateBtn = data;
    }
    @Input() set bookingModeValue(data: boolean) {
        this.bookingMode = data;
    }
    @Input() set requestQuoteBtnStatus(data: boolean) {
        this.isRequestBtnDisabled = data;
    }

    constructor(
        injector: Injector,
        private matDialog: MatDialog,
        private commonService: CommonService,
        private signalrService: SignalrService) {
        super(injector);
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.showQRParcelText.subscribe((value) => {
            this.isParcelRatesShowMessage = value;
        }))
        this.componentSubscriptions.add(this.commonService.getRefreshFetchRate.subscribe(data => {
            if (data) {
                this.showGridLoader();
            } else {
                this.hideGridLoader();
            }
        }));
        this.isSelectable = this.isSelectable.bind(this);
        this.componentSubscriptions.add(this.signalrService.hubConnection.on('ratingProgressStatus', (message) => {
            if (message) {
                this.displayLoadingText = message;
            }
        }));

        if (this.commonService.permissionCheckByName(CommonConstants.permissionName.checkRequestQuoteAccess)) {
            this.isRequestQuoteEnabled = true;
        }

        this.componentSubscriptions.add(this.signalrService.hubShippoAuthConnection.on('shippoAccessTokenStatus', (data) => {
            const shippoTokenDetails = JSON.parse(data);
            const tokenData = shippoTokenDetails ? shippoTokenDetails : null
            if (tokenData && shippoTokenDetails['access_token'] && !shippoTokenDetails['error']) {
                this.shipmentSettingsModel.ShippoBearerToken = shippoTokenDetails['access_token']
            } else {
                this.shipmentSettingsModel.ShippoBearerToken = 'Authorize';
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            switch (this.moduleName) {
                case CommonConstants.moduleName.quickRate:
                    this.isRequestQuoteBtn = false;
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.isPrintDocumentBtn = false;
                    this.applySettings();
                    break;
            }
        }
    }

    onRefreshGrid() {
        this.grid.dataStateChange.emit({
            skip: 0,
            take: this.kendogridHelper.defaultRecordsCountPerPage,
            filter: undefined,
            group: undefined,
            sort: this.kendogridHelper.sort
        });
    }

    getGridRecords(event: DataStateChangeEvent) {
        this.showGridLoader();
        if (event) {
            this.kendogridHelper.dataStateChangeEvent(event);
        }
        this.kendogridHelper.setGridData(this.gridData);
        this.isRequestBtnDisabled = false;
        this.isRedLineWarning = this.commonService.checkRedLineWarningFromData(this.gridData.items);
        this.hideGridLoader();
    }

    onSelectedKeysChange(selectedRateId: any): void {
        if (selectedRateId.length) {

            const selectedData = this.gridData.items.find(list => list.id === selectedRateId[0]);

            if (selectedData) {
                this.commonService.objectMapping(this.shipmentRateListModel, selectedData);
            }
            this.ratesForm.ratesForm = true;
            const lowestRateData = _.minBy(this.gridData.items, 'totalCost');
            const minFilterData = this.gridData.items.filter(list => list.totalCost <= lowestRateData.totalCost);

            if (minFilterData.length) {

                const filterData = minFilterData.find(list => list.id === selectedData.id);

                if (filterData) {
                    this.commonService.objectMapping(this.shipmentLCPDetailsModel, filterData);
                    this.shipmentRateListModel.isLowestRate = true;
                    this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.lowRate);
                } else {
                    this.commonService.objectMapping(this.shipmentLCPDetailsModel, lowestRateData);
                    this.shipmentRateListModel.isLowestRate = false;
                    this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.highRate);
                }
                this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.carrierId);
            }
        } else {
            this.clearSelection();
        }
    }

    showGridLoader(): void {
        this.isLoading = true;
        this.displayLoadingText = CommonConstants.loaderDisplayText;
    }

    hideGridLoader(): void {
        this.isLoading = false;
    }

    applySettings() {
        if (this.shipmentSettingsModel && Array.isArray(this.gridData.items) && this.gridData.items.length) {

            let minFilterData = _.minBy(this.gridData.items, 'totalCost');

            if (minFilterData) {
                minFilterData = new Array(minFilterData);
                this.lcpCost = minFilterData[0].totalCost;
            }

            this.allowLCPSelectionOnly = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.allowLCPSelectionOnly];
            this.showCost = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.showCost];
            this.showLCPOnly = this.shipmentSettingsModel[this.shipmentConstants.LTLVendorLCPSettings.showLCPOnly];

            if (this.showLCPOnly && this.isVendorModule()) {
                this.gridData.items = minFilterData;
            }
        }
    }

    canShowCost() {
        return this.isVendorModule() && this.showCost || !this.isVendorModule();
    }

    isSelectable(context: any) {
        return {
            'k-disabled': (this.isVendorModule() && this.allowLCPSelectionOnly && context.dataItem.totalCost !== this.lcpCost ||
                !context.dataItem.isSelectable),
            red_line_Warning: context.dataItem.isRedLineWarning
        };
    }

    isVendorModule() {
        return this.moduleName === CommonConstants.moduleName.LTLVendorBooking;
    }

    showParcelMessage() {
        return (this.isParcelRatesShowMessage && this.moduleName === CommonConstants.moduleName.quickRate) ? true : false;
    }

    getCarrierColumnName() {
        return this.isVendorModule() ? this.shipmentConstants.VendorBookingRateColumnNames.carrier :
            this.shipmentConstants.ScheduleShipmentRateColumnNames.carrier;
    }

    getOriginServiceColumnName() {
        return this.isVendorModule() ? this.shipmentConstants.VendorBookingRateColumnNames.originService :
            this.shipmentConstants.ScheduleShipmentRateColumnNames.service;
    }

    getDestinationServiceColumnName() {
        return this.isVendorModule() ? this.shipmentConstants.VendorBookingRateColumnNames.destinationService :
            this.shipmentConstants.ScheduleShipmentRateColumnNames.service;
    }

    getTimeColumnName() {
        return this.isVendorModule() ? this.shipmentConstants.VendorBookingRateColumnNames.serviceDays :
            this.shipmentConstants.ScheduleShipmentRateColumnNames.transitTime;
    }

    enableReRate(): void {
        this.reRateBtn = true;
        this.clearSelection();
    }

    clearSelection() {
        this.rowSelectionList = [];
        this.ratesForm.ratesForm = false;
        const rateObject = Object.assign({}, this.commonDataModel.shipmentRateListModel);
        const lCPDetailsModel = Object.assign({}, this.commonDataModel.shipmentLCPDetailsModel);
        this.commonService.objectMapping(this.shipmentRateListModel, rateObject);
        this.commonService.objectMapping(this.shipmentLCPDetailsModel, lCPDetailsModel);
        this.commonService.sendfetchRateStatus(CommonConstants.shipmentRate.hidden);
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.carrierId);
    }

    onRequestQuote(): void {
        this.isRequestQuoteLoading = true;
        this.isRequestQuote.emit(true);
    }

    checkRequestQuoteEnabled(): boolean {
        if ((this.isRequestQuoteEnabled || this.isRedLineWarning) && this.isRequestQuoteBtn && this.fetchRateBtn) {
            return true;
        }
        return false;
    }

    onPrintDocument(): void {
        this.isPrintDocument.emit(true);
    }

    onShowRedLinePopup(): void {
        this.informationDataModel = this.commonService.setInformationDialogStatus(
            CommonConstants.shipmentRateForm.redLineWarningError, 'warning_amber', '#ff4747', '45px', 'Ok', false);
        this.matDialog.open(InformationDialogComponent, {
            panelClass: 'upload-dialog-wrapper',
            hasBackdrop: false,
            data: this.informationDataModel
        });
    }

    resetRates(): void {
        this.clearSelection();
        this.gridData = { items: [], totalResults: 0 };
    }

    isRowEnable(dataItem: any) {
        return (this.isVendorModule() && this.allowLCPSelectionOnly && dataItem.totalCost !== this.lcpCost ||
            !dataItem.isSelectable)
    }

    checkModule() {
        if (this.moduleName == this.commonConstants.moduleName.scheduleShipment) {
            return true;
        } else {
            return false;
        }
    }

    resizeEnable(value: any) {
        if (value) {
            return 'rate-section';
        } else {
            return 'rate-section-empty'
        }
    }

    checkShippoAuthStatus() {
        return this.shipmentSettingsModel.ShippoBearerToken && this.shipmentSettingsModel.ShippoBearerToken != 'Authorize';
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.signalrService.onDisconnect();
    }

}
