import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonDataModel } from '../common-data-model';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
  selector: 'app-insurance-quote-dialog',
  templateUrl: './insurance-quote-dialog.component.html',
  styleUrls: ['./insurance-quote-dialog.component.scss']
})
export class InsuranceQuoteDialogComponent implements OnInit {
  customPatterns = new CustomPatterns();
  commonDataModel = new CommonDataModel();
  shipperIsChecked: boolean = false;
  consigneeIsChecked: boolean = false;
  otherIsChecked: boolean = false;
  isChecked: boolean = false;
  youEmailId: string = '';
  otherEmailID: string = '';
  otherEmails: any[] = [{ value: '' }];
  insuranceCSemail: any;
  insuranceEmailList = this.commonDataModel.emailListModel;
  insuranceEmail = Object.assign({}, this.commonDataModel.insuranceEmailModel);
  constructor(
    private commonService: CommonService,
    private dialogRef: MatDialogRef<InsuranceQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data?: any) {
    this.insuranceEmailList = data;
  }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close(this.insuranceEmail);
  }
  onClose() {
    this.dialogRef.close(null);
  }

  addEmail() {
    this.otherEmails.push({ value: '' });
  }

  removeEmail(index: number) {
    this.otherEmails.splice(index, 1);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.otherEmails.map((data: any) => {
        if (data.value) {
          this.insuranceEmail.otherEmailIds.push(data.value);
        }
      })
      this.dialogRef.close(this.insuranceEmail);
    } else {
    }
  }

}
