import { Injector } from '@angular/core';
import { KendoGridHelper } from 'src/app-core/helper/kendo-grid-helper';

export abstract class AppComponentBase {

    kendogridHelper: KendoGridHelper;

    constructor(injector: Injector) {
        this.kendogridHelper = new KendoGridHelper();
    }

}
