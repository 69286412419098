import { Component, Input, OnInit } from '@angular/core';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { LoaderService } from 'src/app-core/services/loader.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-print-carrier-rates',
    templateUrl: './print-carrier-rates.component.html',
    styleUrls: ['./print-carrier-rates.component.scss']
})

export class PrintCarrierRatesComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    fetchRateList = this.commonDataModel.fetchRateModel;
    todayDate = this.commonService.setTodayDate();
    carrierRateList: Array<object> = [];
    shipmentBookingList = Object.assign({}, this.commonDataModel.shipmentBookingListModel);
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(data: any) {
        this.fetchRateList = data;
    }
    @Input() set shipmentRateData(data: any) {
        this.shipmentBookingList.shipmentRate = data;
    }
    @Input() set fetchRateResponse(data: any) {
        this.carrierRateList = data;
    }

    constructor(
        private loaderService: LoaderService,
        private commonService: CommonService) {
    }

    ngOnInit() {
    }

    onPrintDocument() {
        this.loaderService.show();
        const doc = new jsPDF('p', 'mm', 'a4', true);
        doc.setFontSize(14);
        doc.text('Quote Summary', 110, 12, 'center');
        doc.autoTable({
            html: '#tablePickupDate',
            theme: 'plain',
            styles: {
                halign: 'right'
            },
            startY: 20,
            margin: { top: 5, left: 100, right: 3, bottom: 5 },
        });
        doc.autoTable({
            html: '#tableQuoteDate',
            theme: 'plain',
            startY: 20,
            margin: { top: 5, left: 3, right: 100, bottom: 20 },
        });
        doc.autoTable({
            html: '#tableDestination',
            theme: 'plain',
            styles: {
                halign: 'right'
            },
            startY: 30,
            margin: { top: 5, left: 100, right: 3, bottom: 5 },
        });
        doc.autoTable({
            html: '#tableShipping',
            theme: 'plain',
            startY: 30,
            margin: { top: 5, left: 3, right: 100, bottom: 20 },
        });
        doc.autoTable({
            html: '#tableEquipments',
            theme: 'plain',
            styles: {
                halign: 'right',
            },
            startY: 50,
            margin: { top: 5, left: 100, right: 3, bottom: 0 },
        });
        doc.autoTable({
            html: '#tableServices',
            theme: 'plain',
            startY: 50,
            margin: { top: 5, left: 3, right: 100, bottom: 2 },
        });
        doc.autoTable({
            head: [['Commodity']],
            theme: 'plain',
            margin: { left: 3 },
        });
        doc.autoTable({
            html: '#tableCommodity',
            theme: 'plain',
            margin: 5,
            useCss: true,
            rowPageBreak: 'avoid'
        });
        doc.autoTable({
            head: [['Rates']],
            theme: 'plain',
            margin: { left: 3 },
        });
        doc.autoTable({
            html: '#tableRates',
            theme: 'plain',
            margin: 5,
            useCss: true,
            rowPageBreak: 'avoid'
        });
        doc.autoTable({
            html: '#tableDisclaimer',
            theme: 'plain',
            margin: 5,
        });
        this.loaderService.hide();
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(doc.output('blob'), 'Quote-Summary.pdf');
        } else {
            window.open(doc.output('bloburl'));
        }
    }

}
