import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from 'src/app/shared/common-data-model';

@Component({
    selector: 'app-notes-tab',
    templateUrl: './notes-tab.component.html',
    styleUrls: ['./notes-tab.component.scss'],
    providers: [DatePipe]
})

export class NotesTabComponent implements OnInit, OnDestroy {
    commonDataModel = new CommonDataModel();
    loggedUserModel = this.commonDataModel.loggedUserModel;
    isLoading = false;
    enableScroll = false;
    shipmentId: string;
    notesList: Array<object> = [];
    componentSubscriptions: Subscription = new Subscription();

    @Input() set loadAlertService(loadServices: boolean) {
        if (loadServices) {
            this.loadItems();
        }
    }

    @Input() set showShipmentTrackingNotes(value: any) {
        this.enableScroll = value;
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private commonService: CommonService,
        private datePipe: DatePipe) {
            this.loggedUserModel = this.commonService.getLoggedUserData();
    }


    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            if (this.shipmentId)
                this.loadItems();
        }));
    }

    loadItems(): void {
        this.isLoading = true;
        this.commonService.getNotesInfo(this.shipmentId, this.loggedUserModel.timezoneDescription).subscribe(response => {
            this.notesList = response;
            this.isLoading = false;
        });
    }

    enableScrollCheck(length: any) {
        if (this.enableScroll && length) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
