import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
    selector: 'app-confirm-radio-dialog',
    templateUrl: './confirm-radio-dialog.component.html',
    styleUrls: ['./confirm-radio-dialog.component.scss']
})

export class ConfirmRadioDialogComponent implements OnInit {

    confirmButton = false;
    canNotify = false;
    showNotificationOption = false;
    modelTypeValue: any;
    commonConstants: typeof CommonConstants = CommonConstants;
    constructor(
        private commonService: CommonService,
        private dialogRef: MatDialogRef<ConfirmRadioDialogComponent>,
        @Inject(MAT_DIALOG_DATA) activeUsersPage: any) {
        if (activeUsersPage.hasOwnProperty('selectedValue')) {
            this.showNotificationOption = activeUsersPage.selectedValue;
            this.modelTypeValue = this.commonConstants.confirmationDialogBody.default;
        } else if (activeUsersPage.hasOwnProperty('modelValue')) {
            this.modelTypeValue = activeUsersPage.modelValue;
            if (this.modelTypeValue === this.commonConstants.confirmationDialogBody.requestQuote) {
                this.canNotify = this.commonService.getRequestQuoteType();
            }
        }
    }

    ngOnInit() {
    }

    onConfirm() {
        this.confirmButton = true;
        this.dialogRef.close({ confirmResult: this.confirmButton, radioButtonValue: this.canNotify });
    }

    onClose() {
        this.confirmButton = false;
        this.dialogRef.close({ confirmResult: this.confirmButton });
    }

    showDialogBoxBody(modelValue: any) {
        if (this.modelTypeValue === modelValue) {
            return true;
        }
        return false;
    }
}
