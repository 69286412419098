import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
    selector: 'app-date-range-filter',
    templateUrl: './date-range-filter.component.html',
    styleUrls: ['./date-range-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DateRangeFilterComponent implements OnInit {

    @ViewChild('popup', { static: true }) popup: any;
    @Output() dateFilterEvent = new EventEmitter<object>();

    range = {
        start: null,
        end: null
    };

    @Input() set startDate(data: any) {
        if (data) {
            this.range.start = new Date(data);
        }
    }

    @Input() set endDate(data: any) {
        if (data) {
            this.range.end = new Date(data);
        }
    }

    constructor(
        private commonService: CommonService) {
    }

    ngOnInit() {
    }

    onChangeDate(): void {
        if (this.range.start && this.range.end) {
            const data = {
                dateFrom: this.commonService.convertedDateTimeFormat(this.range.start),
                dateTo: this.commonService.convertedDateTimeFormat(this.range.end)
            };
            this.dateFilterEvent.emit(data);
            this.popup.toggle();
        }
    }

    onClearDates(): void {
        this.range.start = null;
        this.range.end = null;
        this.dateFilterEvent.emit(this.range);
    }

}
