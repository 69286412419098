import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { ShipmentConstants } from 'src/app-core/constants/shipment-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../common-data-model';

@Component({
  selector: 'app-shipment-references',
  templateUrl: './shipment-references.component.html',
  styleUrls: ['./shipment-references.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ShipmentReferencesComponent implements OnChanges, OnInit, OnDestroy {

  @ViewChild('inputReferenceForm', { static: true }) inputReferenceForm: any;
  componentSubscriptions: Subscription = new Subscription();
  commonDataModel = new CommonDataModel();
  referenceRulesList: any[] = this.commonDataModel.referenceSettingsRulesModel;
  additionalReferenceModel = this.commonDataModel.additionalReferenceModel;
  shipmentBookingList = Object.assign({}, this.commonDataModel.shipmentBookingListModel);
  paramId: string;
  isLoading = false;
  rateStatus: string;
  referenceData = false;
  referenceformStatus: any;
  referenceSettingsList = [];
  preparedReferenceList = [];
  additionalReferenceList = [];
  referenceSettingsDropDownList = [];
  numberToDisplay: number;
  savedReferenceSettingsList = [];
  searchBoxClass: string;
  shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
  moduleName: string;
  allowCheckReference = true;
  findDependents: any[] = [];
  isDependendListClearedOnce = false;
  referenceInputList: any;
  isSaveQuoteTriggered = false;
  cyclicDependencyMessage: string;
  allDependents: any[] = [];
  isNameValueMatched = false;
  fromSaveQuote = false;
  updatedDisplayOrderIndex = [];
  checkltlVendorCopyShipment = false;
  isDisableReferenceSection = false;
  selectedShipmentMode: any;
  selectedRateType: any;
  commonConstants: typeof CommonConstants = CommonConstants;
  shipmentConstants: typeof ShipmentConstants = ShipmentConstants;

  @Output() settingsChanged: EventEmitter<any> = new EventEmitter();

  @Input() set childData(shipmentBookingListData: any) {
    this.shipmentBookingList = shipmentBookingListData;
    if (this.shipmentBookingList.shipmentReferenceSettings.referenceSettings.length) {
      this.referenceSettingsList = [];
      this.savedReferenceSettingsList = JSON.parse(JSON.stringify(this.shipmentBookingList.shipmentReferenceSettings.referenceSettings));
      this.isSaveQuoteTriggered = true;
      this.recheckQuoteAndReferenceInputList();
    }
  }
  @Input() set formStatus(formList: object) {
    this.referenceformStatus = formList;
  }
  @Input() set componentNo(data: number) {
    this.numberToDisplay = data;
  }
  @Input() set settings(settingsList: any) {
    this.shipmentSettingsModel = settingsList;
  }
  @Input() set module(value: any) {
    this.moduleName = value;
  }
  @Input() set ltlVendorCopyShipment(value: any) {
    this.checkltlVendorCopyShipment = value;
  }
  @Input() set isDisabledSection(data: boolean) {
    this.isDisableReferenceSection = data;
  }

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private customToastrService: CustomToastrService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.componentSubscriptions.add(this.commonService.shipmentModeSelection.subscribe((value) => {
      this.selectedShipmentMode = value;
    }))
    this.componentSubscriptions.add(this.commonService.selectedRateValue.subscribe((value) => {
      this.selectedRateType = value;
    }))
    this.componentSubscriptions.add(this.activatedRoute.paramMap.subscribe(params => {
      this.paramId = params.get('id');
      if (!this.paramId) {
        this.componentSubscriptions.add(this.activatedRoute.firstChild.paramMap.subscribe(childParams => {
          this.paramId = childParams.get('id');
        }));
      }
      if (this.paramId === CommonConstants.paramName.new || this.paramId === CommonConstants.paramName.scheduleShipment || this.paramId === CommonConstants.paramName.scheduleTruckLoad) {
      } else {
        this.allowCheckReference = false;
      }
      this.loadItems();
    }));
    this.componentSubscriptions.add(this.commonService.responseList.subscribe(rateStatus => {
      this.rateStatus = rateStatus;
      this.lcpeReferenceSetting(rateStatus);
    }));
    this.componentSubscriptions.add(this.commonService.getReferenceSettingStatus.subscribe(status => {
      this.checkDifferentFactors(status);
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.commonService.checkForChanges(changes)) {
      this.getReferenceSettings();
      this.recheckQuoteAndReferenceInputList();
    }
  }

  getReferenceSettings() {
    if (this.shipmentSettingsModel.ReferenceTypesComboValues) {
      this.referenceSettingsDropDownList = this.shipmentSettingsModel.ReferenceTypesComboValues;
    }
  }

  loadItems(): void {
    this.isLoading = true;
    this.commonService.getInputReferenceList(this.moduleName)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(resp => {
        this.referenceInputList = JSON.parse(JSON.stringify(resp.lstReferences));
        this.cyclicDependencyMessage = resp.message;
        if (Array.isArray(this.referenceInputList) && this.referenceInputList.length) {
          this.referenceSettingsList = [];
          this.referenceInputList.map(parentList => {
            parentList.dependents.filter(list => {
              if (!parentList.rules) {
                const copyList = Object.assign({}, list);
                copyList.staticField = true;
                if (copyList.visible) {
                  this.setMaxOccurs(copyList, true);
                  if (copyList.name === CommonConstants.moveType && this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
                    copyList.visible = false;
                    copyList.showField = false;
                    list.defaultValue = ShipmentConstants.LTLVendorMoveType.inbound;
                    copyList.defaultValue = ShipmentConstants.LTLVendorMoveType.inbound;
                    this.settingsChanged.emit(list);
                  }

                  if (this.moduleName === CommonConstants.moduleName.bolOnly) {
                    copyList.highlighted = copyList.required ? copyList.required : copyList.highlighted;
                    copyList.required = false;
                  }

                  const isDataAlreadyPresent = this.referenceSettingsList.findIndex(x => x.name === copyList.name);

                  if (isDataAlreadyPresent === -1) {
                    this.referenceSettingsList.push(copyList);
                  }
                }
                this.referenceRulesList.push({ name: copyList.name, value: copyList.defaultValue, static: true });
                if (list.values.length > 0) {
                  list.values.unshift({ display: 'Select', value: '' });
                }
              } else {
                // list.staticField = false;
                if (list.values.length > 0) {
                  list.values.unshift({ display: 'Select', value: '' });
                }
              }
            });
          });
          this.referenceRulesList.push({ name: '!loc', value: '', static: true, type: 'shipperLocation' });
          this.referenceRulesList.push({ name: '!loc', value: '', static: true, type: 'consigneeLocation' });
          this.allowCheckReference = true;
          this.referenceData = true;
          this.referenceformStatus.referenceForm = false;
          this.checkReferenceSettings();
          if (this.paramId !== CommonConstants.paramName.new && this.paramId !== CommonConstants.paramName.scheduleShipment) {
            if (!this.checkltlVendorCopyShipment) {
              this.isSaveQuoteTriggered = true;
              this.recheckQuoteAndReferenceInputList();
            }
          }
        } else {
          this.referenceData = false;
          this.onPrepareData();
        }
      });
  }

  recheckQuoteAndReferenceInputList() {
    if (this.isSaveQuoteTriggered && this.referenceInputList && this.referenceInputList.length > 0) {
      this.saveQuoteReferenceMapping();
      setTimeout(() => {
        this.saveQuoteReferenceMapping();
      }, 1000);
    }
  }

  saveQuoteReferenceMapping(): void {
    this.fromSaveQuote = true;
    this.referenceSettingsList = [];
    this.additionalReferenceList = [];
    if (this.isSaveQuoteTriggered && this.savedReferenceSettingsList.length) {
      this.triggerDifferenceFactors();
      this.savedReferenceSettingsList = _.sortBy(
        _.sortBy(this.savedReferenceSettingsList, o => !isNaN(parseInt(o.name, 0))), a => Number(a.maxOccurSequence));
      this.savedReferenceSettingsList.filter(list => {
        this.referenceInputList.filter(dataList => {
          dataList.dependents.filter(dependList => {
            if (dependList.name === CommonConstants.moveType && this.moduleName === CommonConstants.moduleName.LTLVendorBooking) {
              dependList.visible = false;
            }
            if (!dataList.rules) {
              if (dependList.name === list.name && dependList.visible) {
                dependList.staticField = true;
                dependList.defaultValue = list.value;

                const copyDependentList = Object.assign({}, dependList);
                let name = list.name;
                if (list.maxOccurSequence > 0) {
                  name = `${list.name}_${list.maxOccurSequence}`;
                }
                const existingData = this.referenceSettingsList.find(x => x.name === name);
                if (existingData) {
                  const index = this.referenceSettingsList.findIndex(x => x.name === name);
                  if (index !== -1) {
                    existingData.defaultValue = list.value;
                    this.referenceSettingsList[index] = existingData;
                  }
                } else {
                  copyDependentList.name = name;
                  this.referenceSettingsList.push(copyDependentList);
                }
                this.updateRuleList(list.name, list.value, true);
              } else if (dependList.visible) {
                const index = this.referenceSettingsList.findIndex(x => x.name === dependList.name);
                if (index === -1) {
                  this.referenceSettingsList.push(dependList);
                } else {
                }
              }
            } else {
              if (dependList.name === list.name) {
                dependList.staticField = false;
                dependList.defaultValue = list.value;
                this.updateRuleList(list.name, list.value, false);
              }
            }
          });
        });
      });
      if (this.referenceSettingsDropDownList.length) {
        this.savedReferenceSettingsList.filter(list => {
          if (list.isAdditional) {
            const copyAdditionalRefModel = Object.assign({}, this.additionalReferenceModel);
            copyAdditionalRefModel.type = list.label;
            copyAdditionalRefModel.value = list.value;
            this.additionalReferenceList.push(copyAdditionalRefModel);
          }
        });
        this.additionalReferenceList.forEach((value, index) => {
          this.onChangeAdditionalReference(value.type, index, false);
        });
      }
      this.checkReferenceSettings();
      this.lcpeReferenceSetting(this.rateStatus);
      this.saveQuoteMaxOccurrenceMapping();
      this.onPrepareData();
    }
    this.isLoading = false;
  }

  saveQuoteMaxOccurrenceMapping(): void {
    this.referenceSettingsList.filter(dataList => {
      this.savedReferenceSettingsList.find(list => {
        let name = list.name;
        if (list.maxOccurSequence > 0) {
          name = `${name}_${list.maxOccurSequence}`;
        }
        if (dataList.name === name) {
          dataList.defaultValue = list.value;
          dataList.visible = true;
        }
      });
    });

    setTimeout(() => {
      this.allowCheckReference = true;
    }, 500);

  }

  onChangeSelect(item: any): void {
    this.updateRuleList(item.name, item.defaultValue, item.staticField);

    if (item.maxOccursField || item.parentMaxOccurs) {
      this.onChangeMaxOccurs(item);
    } else {
      this.checkReferenceSettings(item.staticField, item.name, item.defaultValue);
    }
  }

  checkReferenceSettings(staticValue?: boolean, itemName?: string, itemValue?: string): void {

    if (itemName) {
      this.findAllChildDependents(itemName, itemValue, true);
    }

    this.reloadInitialFields();

    if (this.allDependents && this.allDependents.length > 0) {
      this.findAllChildDependentsAndMakeEmpty(this.allDependents[0], itemName, itemValue);
      this.allDependents.forEach(dependent => {
        _.remove(this.referenceSettingsList, (list) => (!list.staticField && list.name === dependent));
      });
    }

    this.removeChildFieldsIfParentIsDeleted();
    this.isDependendListClearedOnce = false;
    this.isNameValueMatched = false;

    let dependentNameWithPriority = [];

    if (Array.isArray(this.referenceInputList) && this.referenceInputList.length) {

      const referenceInputList = JSON.parse(JSON.stringify(this.referenceInputList));

      referenceInputList.filter(list => {

        if (list.rules) {
          const ruleListCount = list.rules.length;
          const verifyCount = [];
          const ruleListNameValue = [];

          list.rules.filter((ruleList) =>
            this.referenceRulesList.find(({ name, value }) => {
              if (ruleList.name === name && ruleList.value === value) {
                ruleListNameValue.push({ name: ruleList.name, value: ruleList.value });
                return verifyCount.push(true);
              }
            }));

          if (ruleListCount === verifyCount.length) {

            this.updateRuleListWithDependents(ruleListNameValue, list.dependents, itemName);

            list.dependents.filter(elementList => {
              if (elementList.visible) {
                this.setMaxOccurs(elementList);
              }
            });

            this.referenceRulesList.filter(ruleList => {
              if (ruleListNameValue.map(a => a.name).indexOf(ruleList.name) !== -1 &&
                ruleList.dependents && ruleList.dependents.length > 0) {
                ruleList.dependents.filter(dependents => {
                  dependentNameWithPriority.push({ name: dependents.name, priority: dependents.priority });
                  const dataIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === dependents.name);
                  const allPreviousPriorities: any[] =
                    dependentNameWithPriority.reduce((a, o) => (o.name === dependents.name && a.push(o.priority), a), []);

                  if (allPreviousPriorities.every(el => el >= dependents.priority)) {
                    if (dependents.name === itemName && itemValue) {
                      dependents.defaultValue = itemValue;
                    }

                    const copyDependents = dependents;
                    if (this.moduleName === CommonConstants.moduleName.bolOnly) {
                      copyDependents.highlighted = dependents.required ? dependents.required : dependents.highlighted;
                      copyDependents.required = false;
                    }

                    const childRule = this.referenceRulesList.find(x => x.name === copyDependents.name);

                    if (dataIndex === -1) {
                      if (childRule) {
                        childRule.value = copyDependents.defaultValue;
                      }
                      return this.referenceSettingsList.push(copyDependents);
                    } else {
                      const currentData = this.referenceSettingsList[dataIndex];

                      if (currentData.defaultValue && (!this.isNameValueMatched)) {
                        if (!copyDependents.values || copyDependents.values.length === 0 ||
                          (copyDependents.values.length > 0 &&
                            copyDependents.values.map(a => a.value).indexOf(currentData.defaultValue) !== -1)) {
                          copyDependents.defaultValue = currentData.defaultValue;
                        }
                      }
                      if (childRule) {
                        childRule.value = copyDependents.defaultValue;
                      }

                      if (copyDependents.visible === false && Number(copyDependents.maxOccurs) > 1) {
                        this.hideChildMaxOccursIfParentIsHidden(copyDependents.name, copyDependents.visible);
                      }
                      return this.referenceSettingsList[dataIndex] = copyDependents;
                    }
                  }
                });
              }
            });
          }
        }
      });
    }

    this.findDependents.forEach(x => {
      _.remove(this.referenceRulesList, (list) => (list.name === x));
    });

    this.findDependents = [];
    dependentNameWithPriority = [];
    this.allDependents = [];

    this.getDefaultValuesFromSettingsList();
    this.onPrepareData();
    this.sortingReferenceSettings();
  }

  hideChildMaxOccursIfParentIsHidden(name: string, visible: boolean) {
    this.referenceSettingsList.filter(a => {
      const listNames = a.name.split('_')[0];
      if (listNames === name) {
        a.visible = visible;
      }
    });
  }

  removeChildFieldsIfParentIsDeleted() {
    for (let i = this.referenceSettingsList.length - 1; i >= 0; i--) {
      if (this.referenceSettingsList[i].maxOccursField
        && this.referenceSettingsList.findIndex(x => x.name === this.referenceSettingsList[i].parent) === -1
        && this.referenceSettingsList[i].name.split('_').length > 1) {
        this.referenceSettingsList.splice(i, 1);
      }
    }
  }

  reloadInitialFields() {
    if (Array.isArray(this.referenceInputList) && this.referenceInputList.length) {
      const referenceInputList = JSON.parse(JSON.stringify(this.referenceInputList));
      referenceInputList.map(parentList => {
        if (!parentList.rules) {
          parentList.dependents.filter(list => {
            const copyList = Object.assign({}, list);
            copyList.staticField = true;
            if (this.checkIfDependentField(copyList.name)) {
              this.setMaxOccurs(copyList, true);

              if (this.moduleName === CommonConstants.moduleName.bolOnly) {
                copyList.highlighted = copyList.required ? copyList.required : copyList.highlighted;
                copyList.required = false;
              }

              const data = this.referenceSettingsList.find(fieldList => fieldList.name === copyList.name);

              if (!data) {

                const ruleListValue = this.referenceRulesList.find(x => x.name === copyList.name);
                if (ruleListValue && ruleListValue.value) {
                  copyList.defaultValue = ruleListValue.value;
                }

                this.referenceSettingsList.push(copyList);

              } else if (!data.staticField) {
                const dataIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === list.name);

                if (dataIndex !== -1) {
                  if (this.referenceSettingsList[dataIndex].values.length > 0) {
                    copyList.defaultValue = list.defaultValue;
                  } else {
                    copyList.defaultValue = data.defaultValue;
                  }
                  this.referenceSettingsList[dataIndex] = copyList;
                }
              } else if (data) {
                const dataIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === list.name);
                data.displayOrder = copyList.displayOrder;
                this.referenceSettingsList[dataIndex] = data;
              }
            } else {
              const dataIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === list.name);
              if (dataIndex !== -1) {
                const currentReferenceSetting = this.referenceSettingsList.find(fieldList => fieldList.name === list.name);
                currentReferenceSetting.displayOrder = copyList.displayOrder;
                this.referenceSettingsList[dataIndex] = currentReferenceSetting;
              }
            }
          });
        }
      });
    }
  }

  checkIfDependentField(name: any): boolean {
    return this.allDependents.find(a => a === name) !== undefined;
  }

  getDefaultValuesFromSettingsList(): void {
    this.referenceSettingsList.filter(list => {
      if (list.parentMaxOccurs && Number(list.maxOccurs) > 1) {
        this.onChangeMaxOccurs(list, true);
        if (list.visible === false) {
          this.hideChildMaxOccursIfParentIsHidden(list.name, list.visible);
        }
      }
      const dataIndex = this.referenceRulesList.findIndex(fieldList => fieldList.name === list.name);
      if (dataIndex === -1) {
        return this.referenceRulesList.push({ name: list.name, value: list.defaultValue, static: false });
      } else {
        return this.referenceRulesList[dataIndex].value = list.defaultValue;
      }
    });
  }

  triggerDifferenceFactors(): void {
    this.checkDifferentFactors(CommonConstants.referenceSettingCode.carrierId);
    this.checkDifferentFactors(CommonConstants.referenceSettingCode.shipperLocationCode);
    this.checkDifferentFactors(CommonConstants.referenceSettingCode.consigneeLocationCode);
  }

  checkDifferentFactors(statusKey: string): void {
    _.remove(this.referenceRulesList, (list) => {
      return (list.name === CommonConstants.referenceSettingCode.serviceCode ||
        list.name === CommonConstants.referenceSettingCode.itemCode);
    });

    this.referenceRulesList.find((list) => {
      switch (list.name) {

        case CommonConstants.referenceSettingCode.shipperLocationCode:
          list.value = this.shipmentBookingList.originAddress.locationCode;
          list.static = true;
          break;

        case CommonConstants.referenceSettingCode.consigneeLocationCode:
          list.value = this.shipmentBookingList.destinationAddress.locationCode;
          list.static = true;
          break;

        case CommonConstants.referenceSettingCode.carrierId:
          if (this.moduleName !== CommonConstants.moduleName.bolOnly && this.moduleName !== CommonConstants.moduleName.scheduleTruckload) {
            list.value = this.shipmentBookingList.shipmentRate.scac;
            list.static = true;
            if (this.shipmentBookingList.isToReRate) {
              list.value = null;
            }
            list.dependents = [];
          }
          break;

        case CommonConstants.referenceSettingCode.locationCode:
          if (list.type && list.type === 'shipperLocation') {
            list.value = this.shipmentBookingList.originAddress.locationCode;
          }
          if (list.type && list.type === 'consigneeLocation') {
            list.value = this.shipmentBookingList.destinationAddress.locationCode;
          }
          list.static = true;
          break;
      }

      if (!list.value) {
        if (Array.isArray(list.dependents) && list.dependents.length) {
          this.initiateValuesAfterModifyingFactors(list.dependents);
        }
        list.dependents = [];
      }
    });

    if (this.moduleName === CommonConstants.moduleName.bolOnly) {
      this.shipmentBookingList.shipmentItems.filter(item => {
        if (item.itemId) {
          this.referenceRulesList.push({
            name: CommonConstants.referenceSettingCode.itemCode,
            value: item.itemId,
            static: true
          });
        }
      });
    } else {
      this.shipmentBookingList.shipmentHandlingUnits.filter(list => {
        list.items.filter(item => {
          if (item.itemId) {
            this.referenceRulesList.push({
              name: CommonConstants.referenceSettingCode.itemCode,
              value: item.itemId,
              static: true
            });
          }
        });
      });
    }

    this.shipmentBookingList.shipmentServices.services.find(item => {
      if (item.value) {
        this.referenceRulesList.push({
          name: CommonConstants.referenceSettingCode.serviceCode,
          value: item.value,
          static: true
        });
      }
    });

    if (this.moduleName === CommonConstants.moduleName.LTLVendorBooking &&
      Array.isArray(this.referenceInputList) && this.referenceInputList.length) {
      this.referenceInputList.filter(item => {
        item.dependents.find(list => {
          if (list.name === CommonConstants.moveType) {
            setTimeout(() => {
              list.defaultValue = this.shipmentBookingList.destinationAddress.moveType;
              list.staticField = true;
              const index = this.referenceSettingsList.findIndex(x => x.name === CommonConstants.moveType);
              if (index !== -1) {
                this.referenceSettingsList[index].defaultValue = this.shipmentBookingList.destinationAddress.moveType;
              }
            }, 500);
          }
        });
      });
    }

    if (this.allowCheckReference) {
      this.checkReferenceSettings(undefined, statusKey);
    }

  }

  initiateValuesAfterModifyingFactors(data: any): void {
    data.filter(list => {
      this.referenceInputList.filter(parent => {
        if (!parent.rules) {
          const filteredData = parent.dependents.find(dependent => dependent.name === list.name);
          if (filteredData) {
            const index = this.referenceSettingsList.findIndex(x => x.name === filteredData.name);
            if (index !== -1) {
              this.referenceSettingsList[index] = filteredData;
            }
          }
          return;
        }
      });
    });
  }

  lcpeReferenceSetting(status: string): void {
    if (status && Array.isArray(this.referenceInputList) && this.referenceInputList.length) {
      this.referenceInputList.filter(list => {
        if (list.rules) {
          list.rules.find(ruleList => {
            if (ruleList.name === CommonConstants.referenceSettingCode.lcpeCode) {
              list.dependents[0].staticField = true;
              if (ruleList.value === 'true' && status === CommonConstants.shipmentRate.lowRate) {
                this.mappingLcpeObject(list.dependents[0], true, true);
              } else if (ruleList.value === 'false' && status === CommonConstants.shipmentRate.highRate) {
                this.mappingLcpeObject(list.dependents[0], true, true);
              } else if (status === CommonConstants.shipmentRate.hidden) {
                this.mappingLcpeObject(list.dependents[0], false);
              } else {
                this.mappingLcpeObject(list.dependents[0], false);
              }
            }
          });
        }
      });
    }
  }

  mappingLcpeObject(list: any, status: boolean, checkVisibility?: boolean): void {
    const index = this.referenceSettingsList.findIndex(item => item.isLcpe);
    const copyList = Object.assign({}, list);
    copyList.isLcpe = true;
    if (!checkVisibility) {
      copyList.visible = status;
    }
    if (index === -1) {
      this.referenceSettingsList.push(copyList);
    } else {
      this.referenceSettingsList[index] = copyList;
      this.referenceSettingsList = this.referenceSettingsList.filter(item => item.name !== 'lcpeOther');
    }
  }

  updateRuleList(keyProperty: string, selectedValue: string, staticValue?: boolean): void {
    if (keyProperty) {
      const index = this.referenceRulesList.findIndex(item => item.name === keyProperty);
      if (index === -1) {
        this.referenceRulesList.push({ name: keyProperty, value: selectedValue, static: false });
      } else {
        this.referenceRulesList[index].value = selectedValue;
        this.referenceRulesList[index].static = staticValue;
      }
    }
  }

  updateRuleListWithDependents(ruleListNameValue: any[], dependents: any, itemName: string) {
    if (ruleListNameValue && ruleListNameValue.length > 0) {
      let actualIndex: number;

      this.referenceRulesList.find((x, index) => {
        if (x.name === itemName) {
          actualIndex = index;
          return x;
        }
      });

      const isRuleListSame = ruleListNameValue.map(x => x.name).indexOf(itemName);

      if (actualIndex !== undefined && isRuleListSame === -1 && !this.isDependendListClearedOnce) {
        const currentRuleListName = this.referenceRulesList[actualIndex].name;
        const currentRuleListDependents = Object.assign([], this.referenceRulesList[actualIndex].dependents);
        if (currentRuleListName) {
          this.referenceRulesList.filter(x => {
            if (x.name === currentRuleListName) {
              x.dependents = [];
              this.isDependendListClearedOnce = true;
            }
          });
        }

        if (currentRuleListDependents) {
          currentRuleListDependents.filter(x => {
            this.findDependents.push(x.name);
            this.findAllChildDependentsAndMakeEmpty(x.name);
          });
        }
      } else if (isRuleListSame >= 0) {
        const otherRules: any[] = [];
        this.referenceInputList.forEach(x => {
          if (x.rules && x.rules.length > 1 && x.rules.find(a => a.name === itemName)) {
            x.rules.forEach(r => {
              if (r.name !== itemName) {
                otherRules.push(r.name);
              }
            });
          }
        });

        if (otherRules && otherRules.length > 0) {
          this.referenceRulesList.filter(x => {
            otherRules.forEach(oRules => {
              if (x.name === oRules) {
                x.dependents = [];
              }
            });

          });
        }

        ruleListNameValue.filter(rules => {
          this.referenceRulesList.find((x, index) => {
            if (rules.name === x.name && rules.value === x.value) {
              x.dependents = dependents;
              return x;
            }
          });
        });
      }

      ruleListNameValue.filter(rules => {
        this.referenceRulesList.find((x, index) => {
          if (rules.name === x.name && rules.value === x.value) {
            const referenceSettingValues = this.referenceSettingsList.find(rs => rs.name === x.name);
            if (referenceSettingValues && referenceSettingValues.values && referenceSettingValues.values.length > 0
              && (referenceSettingValues.values.map(a => a.value).indexOf(referenceSettingValues.defaultValue) === -1 ||
                referenceSettingValues.defaultValue !== rules.value)) {
              x.dependents = [];
              return x;
            } else if ((referenceSettingValues && (!referenceSettingValues.values || referenceSettingValues.values.length === 0))) {
              return x;
            }
            x.dependents = dependents;
            return x;
          }
        });
      });
    }
  }

  findAllChildDependentsAndMakeEmpty(dependentName: string, parentItemName?: string, parentItemValue?: any) {
    let firstChildValue: string;
    this.referenceRulesList.find(d => {
      if (d.name === dependentName) {
        const updateRuleListParent = this.referenceRulesList.find(x => x.name === parentItemName);
        if (updateRuleListParent) {

          const indexParent = this.referenceRulesList.findIndex(x => x.name === parentItemName);
          const indexFirstChildIndex = this.referenceRulesList.findIndex(x => x.name === dependentName);
          const referenceInputList = JSON.parse(JSON.stringify(this.referenceInputList));

          referenceInputList.every(a => {
            if (a.rules && a.rules.length > 0) {
              return a.rules.filter(r => {
                if (r.name === parentItemName && r.value === parentItemValue) {
                  const referenceSettingsType = this.referenceSettingsList.find(rs => rs.name === parentItemName);
                  if (referenceSettingsType && referenceSettingsType.values && referenceSettingsType.values.length > 0) {
                    a.dependents.forEach(de => {
                      if (de.name === dependentName) {
                        if (!this.referenceRulesList[indexParent].dependents) {
                          this.referenceRulesList[indexParent].dependents = [];
                          if (indexFirstChildIndex !== -1) {
                            firstChildValue = this.referenceRulesList[indexFirstChildIndex].value;
                          }
                        }
                        let depParent = [];
                        depParent = a.dependents;
                        const dep = Object.assign([], depParent);
                        this.referenceRulesList[indexParent].dependents = dep;
                        return false;
                      }
                      return true;
                    });
                  }
                }
              });
            }
            return true;
          });
        }
        if (d.dependents && d.dependents.length > 0) {
          d.dependents.forEach(dc => {
            this.findDependents.push(dc.name);
            this.findAllChildDependentsAndMakeEmpty(dc.name, d.name, d.value);
          });
        }
        d.dependents = [];
      }
    });
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  setMaxOccurs(dependentObject: any, dontAddParentMaxOccurs?: boolean): void {
    if (Number(dependentObject.maxOccurs) > 1) {

      // check if parent max occurs exists in referenceSettingsList
      const rootParentName = dependentObject.name.split('_')[0];
      const parentMaxOccursIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === rootParentName);

      if (parentMaxOccursIndex === -1 && !dontAddParentMaxOccurs) {
        dependentObject.nonStaticMaxOccurs = true;
        dependentObject.maxOccursField = true;
        this.referenceSettingsList.push(dependentObject);
      }

      dependentObject.parentMaxOccurs = true;
      dependentObject.nextField = dependentObject.name + '_' + (1);
      for (let i = 1; i < Number(dependentObject.maxOccurs); i++) {
        const dataIndex = this.referenceSettingsList.findIndex(fieldList => fieldList.name === dependentObject.name + '_' + (i));
        if (dataIndex === -1) {
          const parentData = Object.assign({}, dependentObject);
          parentData.parent = dependentObject.name;
          parentData.name = dependentObject.name + '_' + (i);
          parentData.label = dependentObject.label + '_' + (i);
          parentData.maxOccursField = true;
          parentData.required = false;
          parentData.highlighted = false;
          parentData.maxOccurs = 1;
          parentData.visible = false;
          parentData.defaultValue = '';
          parentData.parentMaxOccurs = false;
          parentData.nextField = dependentObject.name + '_' + (i + 1);
          parentData.displayOrder = Number(Number(dependentObject.displayOrder) + i + 1);
          this.referenceSettingsList.push(parentData);
        } else {
          const previousValue = this.referenceSettingsList.find(fieldList => fieldList.name === dependentObject.name + '_' + (i - 1));
          if (previousValue && previousValue.defaultValue) {
            this.referenceSettingsList[dataIndex].visible = true;
          }
        }
      }
    }
  }

  onChangeMaxOccurs(item: any, dontSortReference?: boolean): void {
    if (item.parentMaxOccurs || item.maxOccursField) {
      if (item.defaultValue && item.nextField) {
        const dataIndex = this.referenceSettingsList.findIndex(list => list.name === item.nextField);
        if (dataIndex !== -1) {
          const referenceSettings = this.referenceSettingsList[dataIndex];
          if (referenceSettings && referenceSettings.parent) {
            const currentIteration = referenceSettings.name.split('_')[1];
            let immediateParentName: any;
            if (currentIteration && Number(currentIteration) > 1) {
              immediateParentName = `${referenceSettings.parent}_${Number(currentIteration - 1)}`;
            } else {
              immediateParentName = referenceSettings.parent;
            }
            const immediateParent = this.referenceSettingsList.find(list => list.name === immediateParentName);

            if (immediateParent) {
              if (immediateParent.visible) {
                referenceSettings.visible = true;
              }
            }
            this.referenceSettingsList[dataIndex] = referenceSettings;
          }
        }
      }
      // commented below code because max occurs field should not be removed on clearing field
      // else {
      //   if (item.parentMaxOccurs && !item.defaultValue) {
      //     this.referenceSettingsList.filter(list => {
      //       if (list.parent === item.name && list.maxOccursField) {
      //         list.visible = false;
      //         list.defaultValue = '';
      //       }
      //     });
      //   } else {
      //     item.visible = false;
      //   }
      // }
    }
    this.onPrepareData();

    if (!dontSortReference) {
      this.sortingReferenceSettings();
    }
  }

  sortingReferenceSettings(): void {
    let lastDisplayOrder = 0;
    this.referenceSettingsList = _.sortBy(this.referenceSettingsList, a => Number(a.displayOrder));
    for (let i = 0; i < this.referenceSettingsList.length; i++) {
      if (this.updatedDisplayOrderIndex.map(a => a).indexOf(i) === -1) {
        if (this.referenceSettingsList[i + 1]) {

          lastDisplayOrder = this.updateMaxOccursDisplayOrder(this.referenceSettingsList[i], lastDisplayOrder + 1);
          if (this.updatedDisplayOrderIndex.map(a => a).indexOf(i + 1) === -1
            && Number(this.referenceSettingsList[i + 1].displayOrder) <= lastDisplayOrder) {
            this.referenceSettingsList[i + 1].displayOrder = Number(lastDisplayOrder) + 1;
          }
        }
      }
    }
    this.referenceSettingsList = _.sortBy(this.referenceSettingsList, a => Number(a.displayOrder));
    this.updatedDisplayOrderIndex = [];
  }

  updateMaxOccursDisplayOrder(referenceSetting: any, displayOrder: number): number {
    const parentReferenceSetting = this.referenceSettingsList.find(x => x.name === referenceSetting.name.split('_')[0]);
    if (parentReferenceSetting) {
      parentReferenceSetting.displayOrder = displayOrder;
      if (Number(parentReferenceSetting.maxOccurs) > 1) {
        let checkMaxOccurs = 1;
        for (let j = 0; j < this.referenceSettingsList.length; j++) {
          if (checkMaxOccurs <= Number(parentReferenceSetting.maxOccurs)) {
            if (this.referenceSettingsList[j].name === `${parentReferenceSetting.name}_${checkMaxOccurs}`) {
              this.referenceSettingsList[j].displayOrder = ++displayOrder;
              checkMaxOccurs++;
              this.updatedDisplayOrderIndex.push(j);
            }
          }
        }
      }
    }
    return displayOrder;
  }

  onChangeAdditionalReference(selectedValue: string, index: number, propertyCheck: boolean) {
    if (Array.isArray(this.referenceSettingsDropDownList) && this.referenceSettingsDropDownList.length) {
      const fieldName = propertyCheck ? 'display' : 'value';
      const result = this.referenceSettingsDropDownList.find(list => list[fieldName] === selectedValue);
      if (result) {
        if (propertyCheck) {
          this.additionalReferenceList[index].type = result.value;
        } else {
          this.additionalReferenceList[index].label = result.display;
        }
        this.onPrepareData();
      }
    }
  }

  onPrepareData(): void {
    this.preparedReferenceList = [];
    if (Array.isArray(this.referenceSettingsList) && this.referenceSettingsList.length) {
      const occurrenceDict = [];
      this.referenceSettingsList.filter(list => {
        if (list.defaultValue) {
          list.defaultValue = list.defaultValue.trim();
          if (list.required && !list.defaultValue) {
            setTimeout(() => {
              list.defaultValue = null;
            }, 100);
          }
        }
        if (list.visible && list.defaultValue) {
          let maxOccurSequence: number = this.getMaxOccurSequence(list.name);
          const name = list.name.split('_')[0];
          if (occurrenceDict.findIndex(od => od.name === name) === -1) {
            occurrenceDict.push({ name, maxOccurSequence });
          }
          if (maxOccurSequence > 0) {
            occurrenceDict.filter(a => {
              if (a.name === name) {
                maxOccurSequence = a.maxOccurSequence + 1;
                a.maxOccurSequence = maxOccurSequence;
              }
            });
          }
          this.preparedReferenceList.push({
            id: list.id,
            type: list.type,
            label: list.label,
            name: list.name,
            value: list.defaultValue,
            isAdditional: false,
            maxOccurSequence
          });
        }
      });
    }
    if (Array.isArray(this.additionalReferenceList) && this.additionalReferenceList.length) {
      const additionalReferenceData = JSON.parse(JSON.stringify(this.additionalReferenceList));
      additionalReferenceData.filter(list => {
        if (list.label && list.value) {
          delete list.searchBox;
          this.preparedReferenceList.push(list);
        }
      });
    }
    this.shipmentBookingList.shipmentReferenceSettings.referenceSettings = this.preparedReferenceList;
    setTimeout(() => {
      this.referenceformStatus.referenceForm = this.inputReferenceForm.valid;
      if (!this.referenceData || this.isDisableReferenceSection) {
        this.referenceformStatus.referenceForm = true;
      }
    }, 200);
  }

  getMaxOccurSequence(name: any): number {
    const array = name.split('_');
    if (array.length === 1) {
      return 0;
    } else {
      return Number(array[1]);
    }
  }

  onAddReferences(): void {
    if (this.additionalReferenceList.length < 10) {
      this.additionalReferenceList.push(Object.assign({}, this.additionalReferenceModel));
      this.onPrepareData();
    } else {
      this.customToastrService.error('We can add maximum 10 items!');
    }
  }

  onRemoveReferences(i: number): void {
    this.additionalReferenceList.splice(i, 1);
    this.onPrepareData();
  }

  resetReferences(): void {
    this.shipmentBookingList.shipmentReferenceSettings.referenceSettings = [];
    this.inputReferenceForm.resetForm();
    this.loadItems();
  }

  getMaskTip(index: number) {
    const item = this.referenceSettingsList[index];
    if (item) {
      if (!item.maskTip || item.maskTip === '') {
        return `Please enter a valid entry: ${item.mask}`;
      }
      return item.maskTip;
    }
    return '';
  }

  findAllChildDependents(name: string, value: string, isFirstLoop?: boolean): void {
    if (Array.isArray(this.referenceInputList) && this.referenceInputList.length) {
      const referenceInputList = JSON.parse(JSON.stringify(this.referenceInputList));
      referenceInputList.forEach(r => {
        if (r.rules && r.rules.find(a => a.name === name)) {
          if (isFirstLoop && r.rules.find(a => a.name === name && a.value === value)) {
            this.isNameValueMatched = true;
          }
          r.dependents.forEach(d => {
            this.allDependents.push(d.name);
            this.findAllChildDependents(d.name, d.value);
          });
        }
      });
    }
    this.allDependents = this.allDependents.filter(this.onlyUnique);
  }

  getMaxOccursNumber(name: any): number {
    const parentName = this.referenceSettingsList.find(a => a.name === name.split('_')[0]);
    if (parentName) {
      return Number(parentName.maxOccurs);
    }
    return 1;
  }

  isRequired(): boolean {
    if (this.moduleName === CommonConstants.moduleName.bolOnly) {
      return false;
    }
    return true;
  }

  showBookingCount() {
    if (this.selectedShipmentMode == 2 || this.selectedRateType == 'Parcel') {
      return 5;
    } else {
      return 6;
    }
  }

  ngOnDestroy(): void {
    this.componentSubscriptions.unsubscribe();
  }

}
