import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-terminal-tab',
    templateUrl: './terminal-tab.component.html',
    styleUrls: ['./terminal-tab.component.scss']
})

export class TerminalTabComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    isLoading = false;
    shipmentId: string;
    showTerminal = false;
    commonDataModel = new CommonDataModel();
    terminalList = this.commonDataModel.terminalInfoModel;

    constructor(
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            this.loadItems();
        }));
    }

    loadItems(): void {
        this.isLoading = true;
        this.commonService.getTerminalInfo(this.shipmentId).subscribe(response => {
            if (response.origin && response.destination) {
                this.terminalList = response;
                this.showTerminal = true;
            }
            this.isLoading = false;
        });
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
