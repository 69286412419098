import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-carrier-info',
    templateUrl: './carrier-info.component.html'
})

export class CarrierInfoComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    carrierList = this.commonDataModel.carrierInfoModel;
    moduleName: string;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(carrier: any) {
        if (carrier) {
            this.carrierList = carrier;
        }
    }
    @Input() isPublicTracking?: boolean;

    constructor(
        private activeRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
    }

    isVendorModule() {
        return this.moduleName === CommonConstants.moduleName.LTLVendorBooking;
    }

    showFreightCost() {
        return !this.isVendorModule() || this.isVendorModule() && this.carrierList.vrShowCost;
    }

}
