import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from 'src/app/shared/common-data-model';

@Component({
    selector: 'app-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

    @ViewChild('feedbackForm', { static: true }) feedbackForm: NgForm;
    customPatterns = new CustomPatterns();
    commonDataModel = new CommonDataModel();
    userFeedback = CommonConstants.userFeedback;
    commonConstants: typeof CommonConstants = CommonConstants;
    feedbackformModel = Object.assign({}, this.commonDataModel.feedbackformModel);
    activeFilterBar: boolean;
    isShowActive = false;
    isShowInactive = false;
    showHideFeedback = true;
    feedbackInput = false;
    comentedDate = new Date();
    formNamePrefix = 'shipper';
    adjustFeedBackForm = false;
    loggedUserModel = this.commonDataModel.loggedUserModel;

    @Input() set childData(feedbackform: any) {
        this.feedbackInput = feedbackform;
    }

    @Input() set alignFeedBack(value: boolean) {
        this.adjustFeedBackForm = value;
    }

    constructor(
        private customToastrService: CustomToastrService,
        private router: Router,
        public commonService: CommonService,
        private activeRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.feedbackformModel.commentedDate = this.comentedDate.toISOString();
        this.feedbackformModel.shipmentId = this.activeRoute.snapshot.paramMap.get('id');
        if (this.commonService.getLoggedUserData()) {
            this.loggedUserModel = this.commonService.getLoggedUserData();
        }
    }

    onShowFilter() {
        this.activeFilterBar = !this.activeFilterBar;
    }

    onToggleDisplayDiv(feedBackType: any) {
        switch (feedBackType) {
            case this.commonConstants.userFeedback.goodFeedback:
                this.isShowInactive = false;
                this.isShowActive = !this.isShowActive;
                this.feedbackformModel.isThumbsUp = true;
                if (this.isShowActive) {
                    this.showHideFeedback = false;
                    this.feedbackForm.reset();
                } else {
                    this.showHideFeedback = true;
                }
                break;
            case this.commonConstants.userFeedback.badFeedback:
                this.isShowActive = false;
                this.isShowInactive = !this.isShowInactive;
                this.feedbackformModel.isThumbsUp = false;
                if (this.isShowInactive) {
                    this.showHideFeedback = false;
                    this.feedbackForm.reset();
                } else {
                    this.showHideFeedback = true;
                }
                break;
        }

    }

    onEmailValidation(): void {
        if (this.feedbackformModel.email) {
            this.feedbackformModel.email =
                this.commonService.multipleEmailValidation(
                    this.feedbackformModel.email, this.feedbackForm, this.formNamePrefix + CommonConstants.addressForm.email
                );
        }
    }

    onSubmit() {
        const payload = {
            ownerId: this.loggedUserModel.ownerId,
            isThumbsUp: this.feedbackformModel.isThumbsUp,
            commentedDate: this.feedbackformModel.commentedDate,
            shipmentId: this.feedbackformModel.shipmentId,
            comments: this.feedbackformModel.comments,
            userName: this.feedbackformModel.userName,
            email: this.feedbackInput ? this.feedbackformModel.email : this.loggedUserModel.email,
            ownerName: this.loggedUserModel.ownerName
        };
        if (this.feedbackInput) {
            delete payload.ownerName;
            delete payload.ownerId;
        } else {
            delete payload.userName;
        }

        this.commonService.sendFeedback(payload).subscribe(() => {
            this.feedbackForm.reset();
            this.isShowInactive = false;
            this.isShowActive = false;
            this.showHideFeedback = true;
            this.customToastrService.success(this.commonConstants.userFeedback.addFormSuccessMessage);
        });

    }

    getPositionOfFeedbackBtn() {
        return this.router.url.match('tracking/tracking-details')?true:false;
    }

}
