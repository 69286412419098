import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})

export class ConfirmDialogComponent implements OnInit {

    confirmButton = false;
    confirmText: string;
    showWarning = false;
    showDeafultBtns = true;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) confirmText?: any) {
        if (confirmText.hasOwnProperty('residential')) {
            this.showWarning = confirmText.residential;
            this.confirmText = confirmText.warningMessage;
        } else if (confirmText.hasOwnProperty('locationInstructins')) {
            this.showDeafultBtns = confirmText.locationInstructins;
            this.confirmText = confirmText.warningMessage;
        } else {
            this.confirmText = confirmText;
        }
    }

    ngOnInit() {
    }

    onConfirm() {
        this.confirmButton = true;
        this.dialogRef.close({ confirmResult: this.confirmButton });
    }

    onClose(closeString: any) {
        if (closeString === 'close') {
            this.confirmButton = null;
        } else {
            this.confirmButton = false;
        }
        this.dialogRef.close({ confirmResult: this.confirmButton });
    }

}
