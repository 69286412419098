import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class CustomToastrService {

    constructor(
        private toastr: ToastrService) {
    }

    toasterSettings(timeOutValue?: number) {
        const commonSettings = {
            timeOut: timeOutValue ? timeOutValue : 0,
            extendedTimeOut: 0,
            tapToDismiss: false,
            enableHtml: true
        };
        return commonSettings;
    }

    success(message: string) {
        this.toastr.success(message, 'Success');
    }

    successToastr(message: string) {
        this.toastr.success(message, 'Success', this.toasterSettings());
    }

    warning(message: string) {
        this.toastr.warning(message, 'Warning', this.toasterSettings());
    }

    error(message: string) {
        this.toastr.error(message, 'Alert');
    }

    errorToastr(message: string, timeOutValue?: number) {
        this.toastr.error(message, 'Alert', this.toasterSettings(timeOutValue));
    }

    clear() {
        this.toastr.clear();
    }

}
