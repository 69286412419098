import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-parcel-information-dialog',
  templateUrl: './parcel-information-dialog.component.html',
  styleUrls: ['./parcel-information-dialog.component.scss']
})
export class ParcelInformationDialogComponent implements OnInit {

  constructor(
      private dialogRef: MatDialogRef<ParcelInformationDialogComponent>) {
  }

  ngOnInit() {
  }

  onClose() {
      this.dialogRef.close();
  }


}
