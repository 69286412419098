import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonConstants } from '../constants/common-constants';
import { APITimeoutEnum } from '../enums/common-enum';
import { CustomToastrService } from './custom-toastr-service';

@Injectable({
    providedIn: 'root'
})

export class ApiCommonService {

    public baseUrl = environment.baseUrl;
    authUrl = environment.authority;
    APITimeoutEnum: typeof APITimeoutEnum = APITimeoutEnum;
    commonConstants: typeof CommonConstants = CommonConstants;
    constructor(
        private http: HttpClient,
        private customToastrService: CustomToastrService,
    ) { }

    // Get list
    getRecords(url: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.get(this.baseUrl + url).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Get List by Id
    getRecordId(url: string, id: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.get(this.baseUrl + url + id).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    getRecordIdByUrl(url: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.get(this.baseUrl + url).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Create new record
    postRecord(url: string, inputData: object, timeOutValue = APITimeoutEnum.defaultTime, showTimeOutError?: boolean): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError && !showTimeOutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Update record
    putRecord(url: string, inputData: object, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.put(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Delete records
    deleteRecord(url: string, deleteList: Array<number>, ownerId: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.post(this.baseUrl + url, { ids: deleteList, selectedOwnerId: ownerId, ownerId }).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Exports records
    exportRecords(url: string, inputData: object): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }));
    }

    exportIdentityRecord(url: string, inputData: object): Observable<any> {
        return this.http.post(this.authUrl + url, inputData, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }));
    }

    // Get permision List by userId
    getPermissionId(url: string, id: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.get(this.authUrl + url + id).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Create new record for Identity Server
    postIdentityRecord(url: string, inputData: object, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.post(this.authUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Delete Identity Server Records
    deleteIdentityRecords(url: string, deleteList: Array<number>, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.post(this.authUrl + url, { ids: deleteList }).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Get List form Identity Server
    getIdentityRecords(url: string, timeOutValue = APITimeoutEnum.defaultTime): Observable<any> {
        return this.http.get(this.authUrl + url).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    // Download Document
    downloadDocument(url: string, timeOutValue = APITimeoutEnum.defaultDownloadFileTime): Observable<any> {
        return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    downloadShipmentDocument(url: string, inputData: any, timeOutValue = APITimeoutEnum.defaultDownloadFileTime): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }), timeout(timeOutValue),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(this.customTimeoutError());
                }
                return throwError(error);
            })
        );
    }

    customTimeoutError() {
        this.customToastrService.errorToastr(CommonConstants.APITimeoutErrorMessage);
    }

}
