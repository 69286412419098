export class PermissionDataModel {

    permissionListModel = [
        {
            permissionId: 1,
            permissionName: 'RemoteInterfaceServiceAccess',
            displayName: 'Access to Remote Interface Service',
            isChecked: false
        },
        {
            permissionId: 2,
            permissionName: 'PrintCarrierInvoicesAccess',
            displayName: 'Access to see/print Carrier Invoices',
            isChecked: false
        },
        {
            permissionId: 3,
            permissionName: 'ModulesAccess',
            displayName: 'Access to Modules',
            isChecked: false
        },
        {
            permissionId: 4,
            permissionName: 'ShipmentTrackingModuleAccess',
            displayName: 'Shipment Tracking',
            isChecked: false
        },
        {
            permissionId: 5,
            permissionName: 'ScheduleShipmentModuleAccess',
            displayName: 'Schedule Shipment',
            isChecked: false
        },
        {
            permissionId: 6,
            permissionName: 'QuickRateModuleAccess',
            displayName: 'Quick Rate',
            isChecked: false
        },
        {
            permissionId: 7,
            permissionName: 'LessThanTruckLoadAccess',
            displayName: 'Schedule Shipment',
            isChecked: false
        },
        {
            permissionId: 8,
            permissionName: 'TruckloadModuleAccess',
            displayName: 'Truckload Shipment',
            isChecked: false
        },
        {
            permissionId: 9,
            permissionName: 'ScheduleShipmentRelatedAccess',
            displayName: 'Access relating to Schedule Shipment',
            isChecked: false
        },
        {
            permissionId: 10,
            permissionName: 'ShowLinearFeetFieldOnShipment',
            displayName: 'Show Linear feet field',
            isChecked: false
        },
        {
            permissionId: 11,
            permissionName: 'ShowTotalPalletFieldOnShipment',
            displayName: 'Show Total Handling Units field',
            isChecked: false
        },
        {
            permissionId: 12,
            permissionName: 'ShowCarrierPriceOnRatesOnShipment',
            displayName: 'Show carrier price on rates',
            isChecked: false
        },
        {
            permissionId: 13,
            permissionName: 'QuickRateRelatedAccess',
            displayName: 'Access relating to Quick Rate',
            isChecked: false
        },
        {
            permissionId: 14,
            permissionName: 'ShowLinearFeetFieldOnQuickRate',
            displayName: 'Show Linear feet field',
            isChecked: false
        },
        {
            permissionId: 15,
            permissionName: 'ShowCarrierPriceOnRatesOnQuickRate',
            displayName: 'Show carrier price on rates',
            isChecked: false
        },
        {
            permissionId: 16,
            permissionName: 'MaintenanceRelatedAccess',
            displayName: 'Access relating to Maintenance',
            isChecked: false
        },
        {
            permissionId: 17,
            permissionName: 'MaintenanceOwner',
            displayName: 'Owner',
            isChecked: false
        },
        {
            permissionId: 18,
            permissionName: 'MaintenanceOwnerImport',
            displayName: 'Import',
            isChecked: false
        },
        {
            permissionId: 19,
            permissionName: 'MaintenanceOwnerDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 20,
            permissionName: 'MaintenanceLocation',
            displayName: 'Location',
            isChecked: false
        },
        {
            permissionId: 21,
            permissionName: 'MaintenanceLocationImport',
            displayName: 'Import',
            isChecked: false
        },
        {
            permissionId: 22,
            permissionName: 'MaintenanceLocationDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 23,
            permissionName: 'MaintenanceItem',
            displayName: 'Item',
            isChecked: false
        },
        {
            permissionId: 24,
            permissionName: 'MaintenanceItemImport',
            displayName: 'Import',
            isChecked: false
        },
        {
            permissionId: 25,
            permissionName: 'MaintenanceItemUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 26,
            permissionName: 'MaintenanceItemDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 27,
            permissionName: 'MaintenanceUser',
            displayName: 'User',
            isChecked: false
        },
        {
            permissionId: 28,
            permissionName: 'MaintenanceUserUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 29,
            permissionName: 'MaintenanceSettings',
            displayName: 'Settings',
            isChecked: false
        },
        {
            permissionId: 30,
            permissionName: 'MaintenanceSettingsCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 31,
            permissionName: 'MaintenanceSettingsUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 32,
            permissionName: 'MaintenanceRoles',
            displayName: 'Roles',
            isChecked: false
        },
        {
            permissionId: 33,
            permissionName: 'MaintenanceRolesCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 34,
            permissionName: 'MaintenanceRolesUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 35,
            permissionName: 'MaintenanceRolesDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 36,
            permissionName: 'MaintenanceLoginLogs',
            displayName: 'Login Logs',
            isChecked: false
        },
        {
            permissionId: 37,
            permissionName: 'MaintenanceReferenceType',
            displayName: 'Reference Type',
            isChecked: false
        },
        {
            permissionId: 38,
            permissionName: 'MaintenanceReferenceTypeCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 39,
            permissionName: 'MaintenanceReferenceTypeUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 40,
            permissionName: 'MaintenanceReferenceSettings',
            displayName: 'Reference Settings',
            isChecked: false
        },
        {
            permissionId: 41,
            permissionName: 'MaintenanceReferenceSettingsCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 42,
            permissionName: 'MaintenanceReferenceSettingsUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 43,
            permissionName: 'MaintenanceReferenceSettingsDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 44,
            permissionName: 'MaintenanceCallLogs',
            displayName: 'Call Logs',
            isChecked: false
        },
        {
            permissionId: 45,
            permissionName: 'MaintenanceAlertConfiguration',
            displayName: 'Alert Configuration',
            isChecked: false
        },
        {
            permissionId: 46,
            permissionName: 'MaintenanceAlertConfigurationCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 47,
            permissionName: 'MaintenanceAlertConfigurationUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 48,
            permissionName: 'MaintenanceAlertConfigurationDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 49,
            permissionName: 'MaintenanceSLSSLogs',
            displayName: 'SLSS Logs',
            isChecked: false
        },
        {
            permissionId: 50,
            permissionName: 'MaintenanceUserImport',
            displayName: 'Import',
            isChecked: false
        },
        {
            permissionId: 51,
            permissionName: 'TrackingRelatedAccess',
            displayName: 'Access Relating to Tracking',
            isChecked: false
        },
        {
            permissionId: 52,
            permissionName: 'ShipmentDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 53,
            permissionName: 'MaintenanceSettingsDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 54,
            permissionName: 'MaintenanceLocationUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 55,
            permissionName: 'MaintenanceLocationCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 57,
            permissionName: 'MaintenanceReferenceTypeDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 59,
            permissionName: 'UpdateShipmentStatus',
            displayName: 'Update Shipment Status',
            isChecked: false
        },
        {
            permissionId: 60,
            permissionName: 'ModifyDefaultSettingAcces',
            displayName: 'Modify Default Setting',
            isChecked: false
        },
        {
            permissionId: 61,
            permissionName: 'MaintenanceCarrier',
            displayName: 'Carrier',
            isChecked: false
        },
        {
            permissionId: 62,
            permissionName: 'MaintenanceCarrierAdd',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 63,
            permissionName: 'MaintenanceCarrierEdit',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 64,
            permissionName: 'MaintenanceCarrierDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 65,
            permissionName: 'MaintenanceCarrierImport',
            displayName: 'Import',
            isChecked: false
        },
        {
            permissionId: 66,
            permissionName: 'MaintenanceItemAdd',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 67,
            permissionName: 'UpdatePassword',
            displayName: 'Update Password',
            isChecked: false
        },
        {
            permissionId: 100,
            permissionName: 'InvoiceModuleAccess',
            displayName: 'Invoice',
            isChecked: false
        },
        {
            permissionId: 101,
            permissionName: 'MasterInvoiceModuleAccess',
            displayName: 'View Master Invoice',
            isChecked: false
        },
        {
            permissionId: 201,
            permissionName: 'ReportsModuleAccess',
            displayName: 'Reports',
            isChecked: false
        },
        {
            permissionId: 206,
            permissionName: 'MaintenanceEproSettings',
            displayName: 'epro Settings',
            isChecked: false
        },
        {
            permissionId: 207,
            permissionName: 'MaintenanceEproSettingsCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 208,
            permissionName: 'MaintenanceEproSettingsUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 209,
            permissionName: 'MaintenanceEproSettingsDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 210,
            permissionName: 'BOLOnlyModuleAccess',
            displayName: 'BOL Only',
            isChecked: false
        },
        {
            permissionId: 211,
            permissionName: 'ReportsRun',
            displayName: 'Run',
            isChecked: false
        },
        {
            permissionId: 212,
            permissionName: 'ReportsSchedule',
            displayName: 'Schedule',
            isChecked: false
        },
        {
            permissionId: 213,
            permissionName: 'BISettingsPermissions',
            displayName: 'BI Settings Permission',
            isChecked: false
        },
        {
            permissionId: 214,
            permissionName: 'BISettingsCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 215,
            permissionName: 'BISettingsUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 216,
            permissionName: 'BISettingsDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 217,
            permissionName: 'Dashboards',
            displayName: 'Dashboard',
            isChecked: false
        },
        {
            permissionId: 218,
            permissionName: 'WhiteLabelSettings',
            displayName: 'White label settings',
            isChecked: false
        },
        {
            permissionId: 219,
            permissionName: 'WhiteLabelCreate',
            displayName: 'Add',
            isChecked: false
        },
        {
            permissionId: 220,
            permissionName: 'WhiteLabelUpdate',
            displayName: 'Edit',
            isChecked: false
        },
        {
            permissionId: 221,
            permissionName: 'WhiteLabelDelete',
            displayName: 'Delete',
            isChecked: false
        },
        {
            permissionId: 222,
            permissionName: 'EditServices',
            displayName: 'Can Edit Required Services',
            isChecked: false
        },
        {
            permissionId: 223,
            permissionName: 'VR_ItemEdit',
            displayName: 'Allow Item Editing',
            isChecked: false
        },
        {
            permissionId: 224,
            permissionName: 'RequestQuote',
            displayName: 'Request Quote',
            isChecked: false
        },
        {
            permissionId: 301,
            permissionName: 'VendorBooking_LTL',
            displayName: 'LTL Vendor Booking',
            isChecked: false
        },
        {
            permissionId: 302,
            permissionName: 'IsResidentialCheckboxAccess',
            displayName: 'Is Residential',
            isChecked: false
        },
        {
            permissionId: 303,
            permissionName: 'VendorBooking',
            displayName: 'Vendor Booking',
            isChecked: false
        },
        {
            permissionId: 304,
            permissionName: 'VendorBooking_TL',
            displayName: 'TL',
            isChecked: false
        },
        {
            permissionId: 225,
            permissionName: 'SS_AllowClassModification',
            displayName: 'Allow Class Modification When Processing Class Breaks',
            isChecked: false
        },
        {
            permissionId: 226,
            permissionName: 'VR_AllowClassModification',
            displayName: 'Allow Class Modification When Processing Class Breaks',
            isChecked: false
        },
        {
            permissionId: 68,
            permissionName: 'PROSearchIPDetails',
            displayName: 'BOL/PRO IP Details',
            isChecked: false
        }
    ];
}
