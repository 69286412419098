import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { detailedDiff, updatedDiff } from 'deep-object-diff';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonDataModel } from 'src/app/shared/common-data-model';
import { environment } from 'src/environments/environment';
import { PermissionDataModel } from '../auth/permission-data-model';
import { CommonConstants } from '../constants/common-constants';
import { APITimeoutEnum, EmailCountLimitEnum, PermissionModuleEnum } from '../enums/common-enum';
import { ValidateHelper } from '../helper/vailidate-helper';
import { ApiCommonService } from './api-common.service';
import { CustomToastrService } from './custom-toastr-service';

declare var google: any;

@Injectable({
    providedIn: 'root'
})

export class CommonService {

    static isUserPermissionMapped = false;
    commonDataModel = new CommonDataModel();
    getActiveVendorModule = new BehaviorSubject<any>(null);
    getShipmentStatus = new BehaviorSubject<boolean>(false);
    getShipmentStatusValue = this.getShipmentStatus.asObservable();
    getActiveVendorModuleValue = this.getActiveVendorModule.asObservable();
    setBookingError = new BehaviorSubject<boolean>(null);
    getBookingError = this.setBookingError.asObservable();
    setValueToShowFilter = new BehaviorSubject<boolean>(false);
    getValueToShowFilter = this.setValueToShowFilter.asObservable();
    globalSearchSubject = new BehaviorSubject(null);
    globalSearchText = this.globalSearchSubject.asObservable();
    interceptorSubject = new BehaviorSubject(null);
    skipInterceptor = this.interceptorSubject.asObservable();
    autoCompleteSubject = new BehaviorSubject(null);
    autocomplete = this.autoCompleteSubject.asObservable();
    totalItemValueSubject = new BehaviorSubject(null);
    totalItemValueCalculated = this.totalItemValueSubject.asObservable();
    shipmentItemSubject = new BehaviorSubject(this.commonDataModel.shipmentMultiClassItems);
    shipmentItemSubjectValue = this.shipmentItemSubject.asObservable();
    permissionDataModel = new PermissionDataModel();
    loggedUserModel = this.commonDataModel.loggedUserModel;
    reportPermissions = this.commonDataModel.reportListPermissionModel;
    permissionDataModelList = this.permissionDataModel.permissionListModel;
    USTerritoriesListModel = this.commonDataModel.USTerritoriesListModel;
    breadcrumbsModel = Object.assign({}, this.commonDataModel.breadcrumbsModel);
    gridCopyColumnModel = Object.assign({}, this.commonDataModel.gridCopyColumnModel);
    gridSelectionColumnModel = Object.assign({}, this.commonDataModel.gridSelectionColumnModel);
    informationDataModel = Object.assign({}, this.commonDataModel.informationDataModel);
    moduleAccessModel = this.commonDataModel.moduleAccessModel;
    ipAddress = environment.ipAddress;
    geocoder: any;
    addressAutoComplete: any;
    userTimeZone = this.commonDataModel.userTimeZone;
    public baseUrl = environment.baseUrl;
    public appUrl = environment.appUrl;
    validatePostalCodeLocalities = true;
    googleAddressListModel = this.commonDataModel.googleAddressModel;
    checkTenderTimeSubject = new BehaviorSubject(false);
    checkTenderTimeValue = this.checkTenderTimeSubject.asObservable();
    APITimeoutEnum: typeof APITimeoutEnum = APITimeoutEnum;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private router: Router,
        private http: HttpClient,
        private apiService: ApiCommonService,
        private customToastrService: CustomToastrService) {
        this.moduleAccessModel = this.permissionChecker(this.moduleAccessModel);
    }

    // LTL and Parcel Rate
    private shipmentMode = new BehaviorSubject<any>(0);
    shipmentModeSelection = this.shipmentMode.asObservable();

    private containsAlcohol = new BehaviorSubject<any>(0);
    containsAlcoholValue = this.containsAlcohol.asObservable();

    // Quick Rate Parcel Message
    private showQRParcelMessage = new BehaviorSubject<any>(0);
    showQRParcelText = this.showQRParcelMessage.asObservable();

    // Parcel Rate Validation
    private parcelRateValidtiy = new BehaviorSubject<boolean>(false);
    checkParcelRateValidation = this.parcelRateValidtiy.asObservable();

    //Map Parcel and LTL Rates
    private enableRatesMapping = new BehaviorSubject<any>(false);
    mapRateResponse = this.enableRatesMapping.asObservable();

    private enableMgRatesMapping = new BehaviorSubject<any>(false);
    mapRateMgResponse = this.enableMgRatesMapping.asObservable();

    // LTL and Parcel Rate
    private ltlParcelRateSelection = new BehaviorSubject<string>(null);
    getSelectedRate = this.ltlParcelRateSelection.asObservable();

    // Truckload BOL Number
    private truckloadBOLnumber = new BehaviorSubject<string>(null);
    getBOLnumber = this.truckloadBOLnumber.asObservable();

    // Side Navigation Toggle
    private setSideNavToggleValue = new BehaviorSubject<boolean>(false);
    getSideNavToggleValue = this.setSideNavToggleValue.asObservable();

    // Refresh Fetch Data
    private refreshFetchRate = new BehaviorSubject<boolean>(false);
    getRefreshFetchRate = this.refreshFetchRate.asObservable();

    // Set User Display Name
    private setUserDisplayName = new BehaviorSubject<boolean>(false);
    getUserDisplayName = this.setUserDisplayName.asObservable();

    // Set Enterprise Name
    private setEnterpriseDisplayName = new BehaviorSubject<string>('');
    getEnterpriseDisplayName = this.setEnterpriseDisplayName.asObservable();

    // Set Tracking Display Mode
    private setTrackingDisplayMode = new BehaviorSubject<string>('');
    getTrackingDisplayMode = this.setTrackingDisplayMode.asObservable();

    // Set Tab Index
    private tabIndexValue = new BehaviorSubject<number>(0);
    getTabIndexValue = this.tabIndexValue.asObservable();

    private fetchRateStatus = new BehaviorSubject<string>('hidden');
    responseList = this.fetchRateStatus.asObservable();

    private referenceSettingStatus = new BehaviorSubject<string>('');
    getReferenceSettingStatus = this.referenceSettingStatus.asObservable();

    private updateWhiteLabelingSettings = new BehaviorSubject<boolean>(false);
    getWhiteLabelingUpdateCommand = this.updateWhiteLabelingSettings.asObservable();

    private setCopyShipmentSubject = new BehaviorSubject(null);
    getCopyShipment = this.setCopyShipmentSubject.asObservable();

    private locationInstructionSubject = new BehaviorSubject(false);
    locationInstructions = this.locationInstructionSubject.asObservable();

    private importResponseList = new BehaviorSubject([]);
    getImportResponseList = this.importResponseList.asObservable();

    private validateZipCode = new BehaviorSubject(null);
    validateZipCodeControl = this.validateZipCode.asObservable();

    private selectedRate = new BehaviorSubject(null);
    selectedRateValue = this.selectedRate.asObservable();

    //get selected parcelRates
    private selectedParcelRates = new BehaviorSubject<any>([]);
    selectedParcelRateComponent = this.selectedParcelRates.asObservable();

    //get puchaseLables
    private puchaseLabels = new BehaviorSubject(null);
    purchaseLabels = this.puchaseLabels.asObservable();

    //restrict scheduling
    private enterpriseRestrictScheduling = new BehaviorSubject<any>(true);
    enterpriseRestrictSchedulingValue = this.enterpriseRestrictScheduling.asObservable();

    getRestrictSchedulingValue(data: any) {
        this.enterpriseRestrictScheduling.next(data);
    }

    checkParcelRateValidity(data: any) {
        this.parcelRateValidtiy.next(data);
    }

    selectedRateMethod(data: any) {
        this.selectedRate.next(data);
    }

    saveshowQRParcelText(data: any) {
        this.showQRParcelMessage.next(data);
    }

    triggerRatesMapping(data: any) {
        this.enableRatesMapping.next(data);
    }

    triggerMgRatesMapping(data: any) {
        this.enableMgRatesMapping.next(data);
    }

    sendShipmentModeSelection(data: any) {
        this.shipmentMode.next(data);
    }

    sendContainsAlcoholValue(data: any) {
        this.containsAlcohol.next(data);
    }

    sendSelectedRate(data: any) {
        this.ltlParcelRateSelection.next(data);
    }

    checkAddressZipCode(data: any) {
        this.validateZipCode.next(data);
    }

    sendImportResponseList(responseData: any, itemsModule?: boolean) {
        const data = {
            itemsModule: itemsModule,
            ...responseData
        }
        this.importResponseList.next(data);
    }

    sendBOLnumber(data: any) {
        this.truckloadBOLnumber.next(data);
    }

    sendCopyShipment(data: any) {
        this.setCopyShipmentSubject.next(data);
    }

    sendSideNavBarToggle(data: boolean) {
        this.setSideNavToggleValue.next(data);
    }

    refreshingRateGrid(data: boolean) {
        this.refreshFetchRate.next(data);
    }

    sendEnterpriseName(data: any) {
        this.setEnterpriseDisplayName.next(data);
    }

    sendUserName(data: any) {
        this.setUserDisplayName.next(data);
    }

    sendTrackingDisplayMode(data: string) {
        this.setTrackingDisplayMode.next(data);
    }

    sendTabIndexValue(data: any) {
        this.tabIndexValue.next(data);
    }

    sendfetchRateStatus(data: string) {
        this.fetchRateStatus.next(data);
    }

    sendReferenceSettingStatus(data: string) {
        this.referenceSettingStatus.next(data);
    }

    sendUpdateWhiteLabelingSettings() {
        this.updateWhiteLabelingSettings.next(true);
    }

    sendBOLInstruction(data: any) {
        this.locationInstructionSubject.next(data);
    }

    selectedParcelRatesMapping(data: any) {
        this.selectedParcelRates.next(data);
    }

    getPurchaseLabels(data: any) {
        this.puchaseLabels.next(data);
    }

    // Get Notifications
    getNotificationList(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/list', data);
    }

    getBadgeCount(id: string, currentConnectionId: any): Observable<any> {
        return this.apiService.getRecords(`Notification/badgeCount?UserId=${id}&NotificationSignalRId=${currentConnectionId}`);
    }

    deleteNotification(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/delete', data);
    }

    deleteAllNotification(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/delete/all', data);
    }

    readNotification(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/read', data);
    }

    openChannelForNotification(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/notificationChannel/open', data);
    }

    openChannelForLogInNotification(data: object): Observable<any> {
        return this.apiService.postIdentityRecord('/Account/isActiveSession', data);
    }

    closeChannelForNotification(data: object): Observable<any> {
        return this.apiService.postRecord('Notification/notificationChannel/close', data);
    }
    // Get Global Search
    getGlobalSearch(inputData: object): Observable<any> {
        return this.apiService.postRecord('ElasticSearch/Search', inputData);
    }

    // Get Public Search
    getGlobalPublicSearch(inputData: object): Observable<any> {
        return this.apiService.postRecord('ElasticSearch/ProSearch', inputData);
    }

    // Get Public Search
    blockIPs(inputData: object): Observable<any> {
        return this.apiService.postRecord('ProSearch/BlockOrUnblockIps', inputData);
    }

    // Check blocked IPs
    checkBlockIp(inputData: any): Observable<any> {
        return this.apiService.getRecords(`ProSearch/GetIpDetails?IPAddress=${inputData}`);
    }


    getCopiedShipment(inputData): Observable<any> {
        return this.apiService.postRecord('Shipments/copyShipment', inputData);
    }

    getShipmentQuote(shipmentId: string): Observable<any> {
        return this.apiService.getRecordId('Shipments/ShipmentQuote?ShipmentId=', shipmentId);
    }

    getCopySettings(ownerId: string): Observable<any> {
        return this.apiService.getRecords(`settings/copyShipmentSettings?OwnerId=${ownerId}`);
    }

    getClientId() {
        return this.apiService.getRecords(`ShippoOAuth/clientId`);
    }

    initialiseShippoGetAuthToken(connectionId) {
        return this.apiService.getRecords(`ShippoOAuth/oauthRedirect?ownerId=${this.getOwnerId()}&userId=${this.getUserId()}&singnalRConnectionId=${connectionId}`);
    }

    saveShippoAuthToken(data: any) {
        return this.apiService.postRecord(`ShippoOAuth/saveShippoToken`, data);
    }

    // Shipment

    // Fetch Rate
    getInsuranceQuote(inputData: object): Observable<any> {
        return this.apiService.postRecord('Insurance/quotes/quote', inputData, this.getMGResponseTimeout());
    }

    getFetchRate(inputData: object): Observable<any> {
        return this.apiService.postRecord('Rate', inputData, this.getMGResponseTimeout());
    }

    getFetchParcelRate(inputData: object): Observable<any> {
        return this.apiService.postRecord('ParcelShipment/parcelRates', inputData, this.getMGResponseTimeout());
    }

    // Get quick rate
    getQuickRate(): Observable<any> {
        this.loggedUserModel = this.getLoggedUserData();
        return this.apiService.getRecordId('QuickRates/', this.loggedUserModel.ownerId);
    }

    // Save quick rate
    postQuickRate(inputData: object): Observable<any> {
        return this.apiService.postRecord('QuickRates', inputData);
    }

    // Item search
    getSearchItem(inputData: object): Observable<any> {
        return this.apiService.postRecord('Items/SearchItems', inputData);
    }

    // Hnadling Unit Drop Down
    getHandlingUnitList(): Observable<any> {
        return this.apiService.getRecordId('settings/getHandlingUnitList?OwnerId=', this.loggedUserModel.ownerId);
    }

    //ItemsCategoryList 
    getItemsCategoryList(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    // Item Class Drop Down
    getItemClassList(): Observable<any> {
        return this.apiService.getRecords('Shipments/getItemClassList');
    }

    // Item Class Drop Down
    getVendorItemClassList(): Observable<any> {
        return this.apiService.getRecords('VendorShipments/getItemClassList');
    }

    // Package Group Drop Down
    getPackageGroupList(): Observable<any> {
        return this.apiService.getRecordId('settings/getHazmetPackageGroupList?OwnerId=', this.loggedUserModel.ownerId);
    }

    // Delete Tracking
    deleteTracking(inputData: any, ownerId: string): Observable<any> {
        return this.apiService.deleteRecord('shipments/delete', inputData, ownerId);
    }

    // Tracking details
    getTrackingDetails(inputData: object): Observable<any> {
        return this.apiService.postRecord('Shipments/details', inputData);
    }

    // Parcel Tracking details
    getParcelTrackingDetails(inputData: object): Observable<any> {
        return this.apiService.postRecord('ParcelShipment/details', inputData);
    }

    // Notes Info
    getNotesInfo(trackingId: string, timeZoneDescription: string): Observable<any> {
        return this.apiService.getRecords('TrackShipments/ShipmentNotes?ShipmentId=' + trackingId + '&TimeZoneDescription=' + timeZoneDescription);
    }

    // History Info
    getHistoryInfo(trackingId: string): Observable<any> {
        return this.apiService.getRecordId('TrackShipments/StatusHistory?ShipmentId=', trackingId);
    }

    //getParcelStatusHistory
    getParcelStatusHistory(trackingId: string): Observable<any> {
        return this.apiService.getRecords('ParcelShipment/StatusHistory?ShipmentId=' + trackingId);
    }

    //getTrackShipment
    getTrackShipment(trackingId: string): Observable<any> {
        return this.apiService.getRecords('ParcelShipment/Track?ShipmentId=' + trackingId);
    }

    // Terminal Info
    getTerminalInfo(trackingId: string): Observable<any> {
        return this.apiService.getRecordId('TrackShipments/TerminalInfo?ShipmentId=', trackingId);
    }

    // Tracking Ride Side Details
    getTrackingAdditionalInfo(trackingId: string): Observable<any> {
        return this.apiService.getRecordId('TrackShipments/Details?ShipmentId=', trackingId);
    }

    //get shipment counts 
    getShipmentCounts(): Observable<any> {
        return this.apiService.getRecords(`TrackShipments/Tiles?OwnerId=${this.getOwnerId()}`);
    }

    // Export Tracking Grid
    exportTrackingGrid(inputData: object): Observable<any> {
        return this.apiService.exportRecords('TrackShipments/export', inputData);
    }

    // Country List
    getCountryList(moduleName?: string): Observable<any> {
        let url = 'Locations/countries';
        if (moduleName) {
            url += `?Module=${moduleName}&OwnerId=${this.getOwnerId()}`;
        }
        return this.apiService.getRecords(url);
    }

    // Services
    getServiceList(): Observable<any> {
        return this.apiService.getRecordId('settings/getServiceList?OwnerId=', this.loggedUserModel.ownerId);
    }

    // Equipment
    getEquipmentList(): Observable<any> {
        return this.apiService.getRecordId('settings/getEquipmentList?OwnerId=', this.loggedUserModel.ownerId);
    }

    // Get Owner Hierarchy list based on OwnerID
    getOwnerHierarchy(isRoot: boolean): Observable<any> {
        return this.apiService.getRecords('Owners/getOwnerHierarchyList?OwnerId=' +
            this.loggedUserModel.parentOwnerId + '&IsRoot=' + isRoot);
    }

    // Save Default Owner
    postDefaultOwner(inputData: object): Observable<any> {
        return this.apiService.postIdentityRecord('/api/AccountSetup/PostDefaultOwner', inputData);
    }

    // Get Default OwnerId
    getDefaultOwner(): Observable<any> {
        return this.apiService.getIdentityRecords('/api/AccountSetup/GetDefaultOwner?userid=' + this.loggedUserModel.userId);
    }

    // Login Logs
    sendLoggedInfo(inputData: object): Observable<any> {
        return this.apiService.postRecord('LoginLog', inputData);
    }

    // Get Default Definition
    getDefaultDefinition(userId: string, gridName: string): Observable<any> {
        return this.apiService.getRecords('GridDefinition/getDefaultColumnSetting?UserId=' + userId +
            '&GridName=' + gridName);
    }

    // Save Default Definition
    updateDefaultGridSettings(inputData: object): Observable<any> {
        return this.apiService.postRecord('GridDefinition/defaultColumnSetting', inputData);
    }

    // Get definition by id
    getSelectedDefinition(inputData: any): Observable<any> {
        return this.apiService.getRecords('GridDefinition/GetById?UserId=' + inputData.userId +
            '&GridName=' + inputData.gridName + '&Id=' + inputData.id);
    }

    // Get definition list
    getDefinitionList(gridName: string): Observable<any> {
        return this.apiService.getIdentityRecords('/api/UserGridDefinition/GetGridDefinitions?UserId=' +
            this.loggedUserModel.userId + '&GridName=' + gridName);
    }

    // Add/Update definition
    saveDefinition(inputData: object): Observable<any> {
        return this.apiService.postRecord('GridDefinition', inputData);
    }

    // Defualt definition
    defaultDefinition(inputData: object): Observable<any> {
        return this.apiService.postRecord('GridDefinition/defaultDefinition', inputData);
    }

    // Delete definition
    deleteDefinitionList(inputData: object) {
        return this.apiService.postRecord('GridDefinition/delete', inputData);
    }
    // End

    // Print Document

    // Save message
    createMessageForTl(inputData: object): Observable<any> {
        return this.apiService.postRecord('TruckLoadShipments/SendAlert', inputData);
    }

    createMessage(inputData: object): Observable<any> {
        return this.apiService.postRecord('Shipments/AlertService', inputData);
    }

    createVendorMessage(inputData: object): Observable<any> {
        return this.apiService.postRecord('VendorShipments/AlertService', inputData);
    }

    // Shipment Tags
    getShipmentTags(shipmentId: string): Observable<any> {
        return this.apiService.getRecordId('Shipments/ShipmentTags?ShipmentId=', shipmentId);
    }

    getVendorShipmentTags(shipmentId: string): Observable<any> {
        return this.apiService.getRecordId('VendorShipments/ShipmentTags?ShipmentId=', shipmentId);
    }

    postShipmentTags(inputData: object): Observable<any> {
        return this.apiService.postRecord('Shipments/ShipmentTag', inputData);
    }

    postVendorShipmentTags(inputData: object): Observable<any> {
        return this.apiService.postRecord('VendorShipments/ShipmentTag', inputData);
    }

    postPublicUrlShipmentTag(inputData: object): Observable<any> {
        return this.apiService.postRecord('publicurlshipmenttag', inputData);
    }

    getDocumentOwnerSettings(moduleName: string): Observable<any> {
        return this.apiService.getRecordIdByUrl(`settings/documentsOwnerSettings?OwnerId=${this.loggedUserModel.ownerId}&ModuleName=${moduleName}`);
    }

    getVRDocumentOwnerSettings(): Observable<any> {
        return this.apiService.getRecordId('settings/documentsOwnerSettings?OwnerId=', this.loggedUserModel.ownerId);
    }

    getAllDocuments(inputData: any): Observable<any> {
        return this.apiService.getRecords('Document?ShipmentId=' + inputData.shipmentId +
            '&OwnerId=' + inputData.ownerId, this.getMGResponseTimeout());
    }

    getFetchDocuments(inputData: object): Observable<any> {
        return this.apiService.postRecord('Document/FetchDocuments', inputData, this.getMGResponseTimeout());
    }

    // Get Settings
    getAccountSettings(inputData: object): Observable<any> {
        return this.apiService.postRecord('AccountSetting', inputData);
    }

    // Get Settings
    getProfileInformation(): Observable<any> {
        return this.apiService.getPermissionId('/api/AccountSetup?userid=', this.loggedUserModel.userId);
    }

    // POST Settings
    postProfileInformation(inputData: object): Observable<any> {
        return this.apiService.postIdentityRecord('/api/AccountSetup/UpdateProfile', inputData);
    }

    // Change Password
    postChangePassword(inputData: object): Observable<any> {
        return this.apiService.postIdentityRecord('/api/AccountSetup/ChangePassword', inputData);
    }

    // Update Password
    postUpdatePassword(inputData: object): Observable<any> {
        return this.apiService.postIdentityRecord('/api/AccountSetup/updatePassword', inputData);
    }

    // Save Location
    checkLocation(inputData: object): Observable<any> {
        return this.apiService.postRecord('Locations/validateisresidential', inputData);
    }

    // Save Location
    saveLocation(gridName: string, inputData: object): Observable<any> {
        return this.apiService.postRecord(gridName, inputData);
    }

    // Save Location
    updateLocationInstruction(inputData: object): Observable<any> {
        return this.apiService.postRecord('Locations/update/LocationInstructions', inputData);
    }

    //send claims request
    sendClaimsRequest(inputData: any): Observable<any> {
        return this.apiService.postRecord('shipmentClaims/sendQuery', inputData);
    }

    submitClaimsFormRequest(inputData: any): Observable<any> {
        return this.apiService.postRecord('shipmentclaims/processClaim', inputData, APITimeoutEnum.defaultClaimRequestTime, true);
    }

    submitInsuranceClaimsFormRequest(inputData: any): Observable<any> {
        return this.apiService.postRecord('Insurance/claim', inputData);
    }

    getClaimsRequest(inputData: any): Observable<any> {
        return this.apiService.getRecords(`shipmentClaims/shipmentDetails?Id=${inputData.Id}&OwnerId=${inputData.ownerId}`, this.getMGResponseTimeout());
    }

    uploadClaimsDocument(inputData: object): Observable<any> {
        return this.apiService.postRecord('ShipmentClaims/uploadDocument', inputData);
    }

    deleteClaimsDocument(inputData: object): Observable<any> {
        return this.apiService.postRecord('ShipmentClaims/deleteDocument', inputData);
    }

    // Download Single Document
    downloadShipmentDocument(inputData: any): Observable<any> {
        return this.apiService.downloadShipmentDocument('Shipments/downloadDocument', inputData);
    }

    // Download Single Document
    downloadPurchaseLabel(inputData: any): Observable<any> {
        return this.apiService.downloadShipmentDocument('ParcelShipment/downloadDocument', inputData);
    }

    // Download All Document
    downloadAllShipmentDocument(inputData: any): Observable<any> {
        return this.apiService.downloadShipmentDocument('Shipments/downloadAllDocuments', inputData);
    }

    // Download All Document
    downloadAllPurchaseLabels(inputData: any): Observable<any> {
        return this.apiService.downloadShipmentDocument('ParcelShipment/downloadAllDocuments', inputData);
    }

    // Search Address
    getSearchAddress(inputData: object) {
        return this.apiService.postRecord('Address/search', inputData);
    }

    // Search Address By Location Code
    getSearchAddressByLocationCode(inputData: object) {
        return this.apiService.postRecord('Address/searchbycode', inputData);
    }

    // Check Duplicate Location
    checkDuplicateLocation(inputData: object): Observable<any> {
        return this.apiService.postRecord('Address/checkduplicate', inputData);
    }

    getVendorTrackingMapView(inputData: object): Observable<any> {
        return this.apiService.postRecord('VendorMapView/GetMapView', inputData);
    }

    getVendorTrackingGridRecords(inputData: object): Observable<any> {
        return this.apiService.postRecord('VendorShipments/Get', inputData);
    }

    // References
    getInputReferenceList(moduleNameValue: string): Observable<any> {
        let moduleName = CommonConstants.referenceSettingsModuleName.scheduleShipment;
        if (moduleNameValue === CommonConstants.moduleName.LTLVendorBooking || moduleNameValue === CommonConstants.moduleName.TLVendorBooking) {
            moduleName = CommonConstants.referenceSettingsModuleName.vendorBooking;
        }
        return this.apiService.getRecordIdByUrl(`ReferenceSettings/OwnerReferenceSettings?OwnerId=${this.loggedUserModel.ownerId}&ModuleName=${moduleName}`);
    }

    // References Settings drop down
    getReferenceSettingsList(): Observable<any> {
        return this.apiService.getRecordId('settings/referenceTypeList?OwnerId=', this.loggedUserModel.ownerId);
    }

    generateBOLDocuments(inputData: object): Observable<any> {
        return this.apiService.postRecord('Shipments/GenerateBOLDocuments', inputData);
    }

    // Get Schedule Shipment Settings
    // getScheduleShipmentSettings(): Observable<any> {
    //     return this.apiService.getRecordId('settings/ownerSettingsByModule?OwnerId=', this.loggedUserModel.ownerId);
    // }

    // getBOLSettings(): Observable<any> {
    //     return this.apiService.getRecordId('settings/ownerSettingsByModule?OwnerId=', this.loggedUserModel.ownerId);
    // }

    getTimeZones(): Observable<any> {
        return this.apiService.getIdentityRecords('/api/Collection/GetTimeZones');
    }

    getPublicTrackingUrl(shipmentId: string) {
        return `${this.appUrl}tracking/shipment-tracking/${shipmentId}`;
    }

    getWhiteLabelingSettings() {
        return this.apiService.getRecordIdByUrl(`WhiteLabeling/OwnerWhiteLabelDetails?ownerId=${this.getOwnerId()}`);
    }

    getRootWhiteLabelingSettings(id: any) {
        return this.apiService.getRecordIdByUrl(`WhiteLabeling/OwnerWhiteLabelDetails?ownerId=${id}`);
    }

    // Power BI
    getBIDashboard() {
        return this.apiService.getRecords(`PowerBISettings/powerBIService?OwnerId=${this.loggedUserModel.ownerId}`);
    }

    getDefaultBILink(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    getSettingByName(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    getRedkikRedirectUrl(setting: any) {
        return this.apiService.getRecords(`insurance/provider?isTest=${setting}`);
    }

    getDefaultGNLink(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    getSSshippoAccountId(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    //
    ssoDashboardPost(): Observable<any> {
        return this.apiService.getRecordIdByUrl('SsoConfiguration/token');
    }

    getDefaultDashboardId(ownerId: string) {
        return this.apiService.getRecordId('SsoConfiguration/defaultDashboard/', ownerId);
    }

    verifyCaptcha(token: string) {
        return this.apiService.getRecordIdByUrl(`Shipments/verifytoken?token=${token}`);
    }

    validateLocationCode(inputData: any) {
        return this.apiService.postRecord('Locations/validateLocation', inputData);
    }

    // GetOwnerSettings
    getOwnerSettings(moduleName: string): Observable<any> {
        return this.apiService.getRecordIdByUrl(`settings/ownerSettingsByModule?OwnerId=${this.loggedUserModel.ownerId}&ModuleName=${moduleName}`);
    }

    // GetOwnerDetails
    getOwnerDetails(): Observable<any> {
        return this.apiService.getRecordIdByUrl(`/Owners/${this.loggedUserModel.ownerId}`);
    }

    // Add/Update Account Settings
    postAccountSettings(inputData: any) {
        return this.apiService.postRecord('AccountSetting/Create/AccountSetting', inputData);
    }

    // Tracking Page
    getInfoWindowRecords(inputData: object, moduleName: string): Observable<any> {
        const APIEndPoint = (moduleName === CommonConstants.moduleName.shipmentTracking ? 'Mapview' : 'VendorMapview');
        return this.apiService.postRecord(APIEndPoint + '/latlongdetails', inputData);
    }

    // Download Document
    downloadDocument(url: string, appendBaseUrl = false): Observable<any> {
        const APIUrl = appendBaseUrl ? this.baseUrl + url : url;
        return this.apiService.downloadDocument(APIUrl);
    }

    // Get MG Response Timeout
    getMGResponseTimeoutCall() {
        return this.apiService.getRecords('ApplicationSettings/MGResponseTimeOut');
    }

    // Get Invoices for shipments
    getInvoices(shipmentId: string, invoiceId: string): Observable<any> {
        const shipmentData = shipmentId ? '?ShipmentId=' + shipmentId : '?';
        const invoiceData = invoiceId ? '&InvoiceId=' + invoiceId : '';
        return this.apiService.getRecords('Invoice' + shipmentData + invoiceData);
    }

    // Get Invoice Summary
    getInvoiceSummary(inputData: any): Observable<any> {
        return this.apiService.postRecord('Invoice/details', inputData);
    }

    // Get Master Invoice Summary
    getMasterInvoiceSummary(masterInvoiceId: string): Observable<any> {
        return this.apiService.getRecords('masterinvoice/details?OwnerId=' +
            this.loggedUserModel.ownerId + '&MasterInvoiceId=' + masterInvoiceId);
    }

    // Get Comments
    getComments(invoiceId: string): Observable<any> {
        return this.apiService.getRecords('Invoice/' + invoiceId + '/comment');
    }

    getServiceListForLocations() {
        return this.apiService.getRecords(`settings/ownerServiceSettings?OwnerId=${this.loggedUserModel.ownerId}`);
    }

    // Add/Update Comments
    postComments(inputData: any): Observable<any> {
        return this.apiService.postRecord('Invoice/comment', inputData);
    }

    // Download Master Invoice Summary
    postMasterInvoiceSummary(inputData: any) {
        return this.apiService.exportRecords('MasterInvoice/download', inputData);
    }

    // Download Invoice Summary
    postInvoiceSummary(inputData: any) {
        return this.apiService.exportRecords('Invoice/download', inputData);
    }

    // Request Quote for shipment Rates
    postRequestQuote(inputData: any) {
        return this.apiService.exportRecords('Shipments/EmailRedLineWarning', inputData);
    }

    //
    getPackageTypeComboValues(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    // Status Drop Down
    getMaxShipmentStatusUpdate(settingName: string) {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=' + settingName);
    }

    // Status Drop Down
    getStatusDropDownList() {
        return this.apiService.getRecords('Shipments/deliveredStatusList');
    }

    // Post Status
    postShipmentStatus(inputData: any): Observable<any> {
        return this.apiService.postRecord('Shipments/update/status', inputData);
    }

    // validate postalcode localities
    getValidatePostalCodeLocalities() {
        return this.apiService.getRecords('settings/settingsbyname?OwnerId=' +
            this.loggedUserModel.ownerId + '&SettingName=SS_ValidatePostalCodeLocalities');
    }

    // Import File
    importFile(inputData: object, listName: string): Observable<any> {
        if (listName === 'Enterprise') {
            listName = 'Owners';
        }
        return this.apiService.postRecord(listName + '/import', inputData, APITimeoutEnum.defaultDownloadFileTime);
    }

    // Import File
    importUserFile(inputData: object): Observable<any> {
        return this.apiService.postIdentityRecord('/api/AccountSetup/import', inputData);
    }

    importShipmentItem(inputData: object): Observable<any> {
        return this.apiService.postRecord('Shipments/ImportItems', inputData, APITimeoutEnum.defaultDownloadFileTime);
    }

    // Get IP Address
    getIpAddress(): Observable<any> {
        return this.http.get(this.ipAddress);
    }

    setMappedPermissions() {
        if (!CommonService.isUserPermissionMapped) {
            this.mappingPermission();
            CommonService.isUserPermissionMapped = true;
        }
    }

    // Mapping selected permission
    mappingPermission() {
        this.loggedUserModel = this.getLoggedUserData();
        const userPermission = this.getUserPermission();
        if (Array.isArray(userPermission) && userPermission.length) {
            this.permissionDataModelList.filter(list => {
                userPermission.filter(mapObject => {
                    if (list.permissionId === mapObject) {
                        return list.isChecked = true;
                    }
                });
            });
        }
    }

    // Menu item by permission
    getMenuByPermission(menuObject: any) {
        this.permissionDataModelList.filter(list => {
            menuObject.filter(menuList => {
                if (list.permissionName === menuList.permission) {
                    return menuList.isActive = list.isChecked;
                }
                if (menuList.items.length !== 0) {
                    menuList.items.filter(subMenuList => {
                        if (list.permissionName === subMenuList.permission) {
                            return subMenuList.isActive = list.isChecked;
                        }
                    });
                }
            });
        });
        return menuObject;
    }

    // Permission Check
    permissionChecker(permissionList: any) {
        this.permissionDataModelList.filter(list => {
            Object.keys(permissionList).map(key => {
                if (list.permissionName === key) {
                    permissionList[key] = list.isChecked;
                }
            });
        });
        return permissionList;
    }

    permissionCheckByName(permissionName: string) {
        const permission = this.permissionDataModelList.find(x => x.permissionName === permissionName);
        if (permission && permission.isChecked) {
            return true;
        }
        return false;
    }

    getPermissionObjectByName(permissionName: string) {
        return _.find(this.permissionDataModelList, ['permissionName', permissionName]);
    }

    checkLoginRedirection(startUpPage: number, displayMode: string) {
        let navigationUrl: string;
        switch (startUpPage) {
            case PermissionModuleEnum.trackingModule:
                switch (displayMode) {
                    case '1':
                        navigationUrl = 'app/tracking/tracking-map/0';
                        break;
                    case '2':
                        navigationUrl = 'app/tracking/tracking-list';
                        break;
                }
                break;
            case PermissionModuleEnum.shipmentBookingModule:
                navigationUrl = 'app/shipment/shipment-booking/schedule-shipment/new';
                break;
            case PermissionModuleEnum.quickRateModule:
                navigationUrl = 'app/quick-rate';
                break;
            case PermissionModuleEnum.notificationPage:
                navigationUrl = 'app/notification';
                break;
            case PermissionModuleEnum.reportModule:
                this.reportPermissions = this.permissionChecker(this.reportPermissions);
                if (this.reportPermissions.ReportsSchedule) {
                    navigationUrl = 'app/reports/reports-list';
                } else if (this.reportPermissions.ReportsRun) {
                    navigationUrl = 'app/reports/report-details/new';
                } else {
                    navigationUrl = 'app/reports';
                }
                break;
            case PermissionModuleEnum.bolModule:
                navigationUrl = 'app/shipment/bol/schedule-bol/new';
                break;
            case PermissionModuleEnum.dashboardModule:
                navigationUrl = 'app/dashboard';
                break;
            case PermissionModuleEnum.ltlVendorModule:
                navigationUrl = `app/${this.vendorStartUpModuleNavigation()}`;
                break;
            case PermissionModuleEnum.TLVendorModule:
                navigationUrl = `app/${this.vendorStartUpModuleNavigation()}`;
                break;
            case PermissionModuleEnum.noStartup:
                navigationUrl = 'app/page-not-found';
                break;
            case PermissionModuleEnum.truckLoadAccess:
                navigationUrl = 'app/shipment/truckload-booking/schedule-truckload/new';
                break;
        }
        this.router.navigateByUrl(navigationUrl);
        return navigationUrl;
    }

    checkStartUpModule(permissionList: any) {
        return permissionList.find(list =>
            list === PermissionModuleEnum.trackingModule || list === PermissionModuleEnum.shipmentBookingModule ||
            list === PermissionModuleEnum.quickRateModule || list === PermissionModuleEnum.reportModule ||
            list === PermissionModuleEnum.bolModule || list === PermissionModuleEnum.dashboardModule ||
            list === PermissionModuleEnum.ltlVendorModule || list === PermissionModuleEnum.TLVendorModule);
    }

    vendorStartUpModuleNavigation() {
        let vendorModulePath = 'ltl-vendor';
        const vendorStartUpPage = this.getVendorBookingStartUpPage();
        this.moduleAccessModel = this.permissionChecker(this.moduleAccessModel);
        if (this.moduleAccessModel.VendorBooking_LTL && this.moduleAccessModel.VendorBooking_TL) {
            vendorModulePath = (vendorStartUpPage === CommonConstants.vendorBookingModuleConstants.LTL ? 'ltl-vendor' : 'tl-vendor');
        } else if (this.moduleAccessModel.VendorBooking_TL) {
            vendorModulePath = 'tl-vendor';
        } else {
            vendorModulePath = 'ltl-vendor';
        }
        return vendorModulePath;
    }

    // Zip Code List
    getGeoCode(postalCodeSearch: string, countrySearch: string) {
        this.geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({
                componentRestrictions: {
                    postalCode: postalCodeSearch, country: countrySearch
                }
            }, (results: any, status: any) => {
                resolve(results);
            });
        });
    }

    // Common methods

    setLoggedUserData(loggedUserModel: any) {
        this.loggedUserModel = loggedUserModel;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setShippoAccessTokenData(shippoAuthData: any) {
        sessionStorage.setItem('shippoAuthDetails', btoa(JSON.stringify(shippoAuthData)));
    }

    setEnterpriseData(data: any) {
        sessionStorage.setItem('restrictScheduling', btoa(JSON.stringify(data)));
    }

    setOwnerId(owner: any) {
        this.loggedUserModel.ownerId = owner.ownerId;
        this.loggedUserModel.ownerName = owner.ownerName;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setParentOwnerId(parentOwnerId: string) {
        this.loggedUserModel.parentOwnerId = parentOwnerId;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setDefaultOwnerId(defaultOwnerId: any) {
        this.loggedUserModel.defaultOwnerId = defaultOwnerId;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setAccountNumber(accountNumber: string) {
        this.loggedUserModel.accountNumber = accountNumber;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setSelectedParentOwnerId(selectedParentOwnerId: string) {
        this.loggedUserModel.selectedParentOwnerId = selectedParentOwnerId;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setNotificationId(ID: any) {
        sessionStorage.setItem('connectionIdList', btoa(JSON.stringify(ID)));
    }

    setVisitedTab(tabName: any) {
        sessionStorage.setItem('visitedTab', btoa(JSON.stringify(tabName)));
    }

    setRequestQuoteType(value: boolean) {
        this.loggedUserModel.isRequestQuoteSelected = value;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setIsAddressCollapse(value: boolean) {
        this.loggedUserModel.isAddressCollapse = value;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setIsEnableAddressLookUp(value: boolean) {
        this.loggedUserModel.EnableAddressLookup = value;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setCookieConfirmationDialog(value: boolean) {
        const cookieConfirmationValue = value;
        sessionStorage.setItem('cookieConfirmation', btoa(JSON.stringify(cookieConfirmationValue)));
    }

    setCopyShipmentNavigation(value: boolean) {
        this.loggedUserModel.copyShipmentNavigation = value;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setVendorBookingStartUpPage(value: string) {
        this.loggedUserModel.vendorBookingStartUpPage = value;
        sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setVendorShipperLocationCode(value: string) {
        this.loggedUserModel.vrShipLocationCode = value;
        localStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
    }

    setTrackingGridBreadcrumbs(value: any) {
        this.breadcrumbsModel.trackingGrid = value;
        sessionStorage.setItem('oidcBreadcrumbs', btoa(JSON.stringify(this.breadcrumbsModel)));
    }

    setLTLVendorGridBreadcrumbs(value: any) {
        this.breadcrumbsModel.ltlVendorGrid = value;
        sessionStorage.setItem('oidcBreadcrumbs', btoa(JSON.stringify(this.breadcrumbsModel)));
    }

    setInvoiceGridBreadcrumbs(value: any) {
        this.breadcrumbsModel.invoiceGrid = value;
        sessionStorage.setItem('oidcBreadcrumbs', btoa(JSON.stringify(this.breadcrumbsModel)));
    }

    setQrData(data: any) {
        sessionStorage.setItem('quickRateData', btoa(JSON.stringify(data)));
    }

    getLoggedUserData() {
        if (sessionStorage.getItem('oidcTranslogistics')) {
            return JSON.parse(atob(sessionStorage.getItem('oidcTranslogistics')));
        }
    }

    getShippoAuthDetails() {
        if (sessionStorage.getItem('shippoAuthDetails')) {
            return JSON.parse(atob(sessionStorage.getItem('shippoAuthDetails')));
        }
    }

    getEnterpriseDetails() {
        if (sessionStorage.getItem('restrictScheduling')) {
            return JSON.parse(atob(sessionStorage.getItem('restrictScheduling')));
        }
    }

    getOwnerId() {
        const userData = this.getLoggedUserData();
        return userData.ownerId;
    }

    getDefaultOwnerId() {
        const userData = this.getLoggedUserData();
        return userData.defaultOwnerId;
    }

    getLoggedInOwnerId() {
        const userData = this.getLoggedUserData();
        return userData.loggedInOwnerId;
    }

    getUserId() {
        const userData = this.getLoggedUserData();
        return userData.userId;
    }

    getOwnerName() {
        const userData = this.getLoggedUserData();
        return userData.ownerName;
    }

    getUserName() {
        const userData = this.getLoggedUserData();
        return userData.displayName;
    }

    getParentOwnerId() {
        const userData = this.getLoggedUserData();
        return userData.parentOwnerId;
    }

    getUserPermission() {
        const userData = this.getLoggedUserData();
        return userData.userPermission;
    }

    getAccountNumber() {
        const userData = this.getLoggedUserData();
        return userData.accountNumber;
    }

    getNotificationId() {
        if (sessionStorage.getItem('connectionIdList')) {
            return JSON.parse(atob(sessionStorage.getItem('connectionIdList')));
        }
    }

    getVisitedTab() {
        if (sessionStorage.getItem('visitedTab')) {
            return JSON.parse(atob(sessionStorage.getItem('visitedTab')));
        }
    }

    getQrData() {
        if (sessionStorage.getItem('quickRateData')) {
            return JSON.parse(atob(sessionStorage.getItem('quickRateData')));
        }
    }

    getMGResponseTimeout() {
        const userData = this.getLoggedUserData();
        return userData.MGResponseTimeout;
    }

    getRequestQuoteType() {
        const userData = this.getLoggedUserData();
        return userData.isRequestQuoteSelected;
    }

    getIsAddressCollapse() {
        const userData = this.getLoggedUserData();
        return userData.isAddressCollapse;
    }

    getIsEnableAddressLookUp() {
        const userData = this.getLoggedUserData();
        return userData.EnableAddressLookup;
    }

    getCopyShipmentNavigation() {
        const userData = this.getLoggedUserData();
        return userData.copyShipmentNavigation;
    }

    getBreadcrumbsData() {
        if (sessionStorage.getItem('oidcBreadcrumbs')) {
            return JSON.parse(atob(sessionStorage.getItem('oidcBreadcrumbs')));
        } else {
            sessionStorage.setItem('oidcBreadcrumbs', btoa(JSON.stringify(Object.assign({}, this.commonDataModel.breadcrumbsModel))));
            return Object.assign({}, this.commonDataModel.breadcrumbsModel);
        }
    }

    getTrackingGridBreadcrumbs() {
        const breadcrumbsData = this.getBreadcrumbsData();
        return breadcrumbsData.trackingGrid;
    }

    getLTLVendorGridBreadcrumbs() {
        const breadcrumbsData = this.getBreadcrumbsData();
        return breadcrumbsData.ltlVendorGrid;
    }

    getCookieConfirmationDialog() {
        if (sessionStorage.getItem('cookieConfirmation')) {
            return JSON.parse(atob(sessionStorage.getItem('cookieConfirmation')));
        }
    }

    getInvoiceGridBreadcrumbs() {
        const breadcrumbsData = this.getBreadcrumbsData();
        return breadcrumbsData.invoiceGrid;
    }

    getVendorBookingStartUpPage() {
        const userData = this.getLoggedUserData();
        return userData.vendorBookingStartUpPage;
    }

    getVendorShipperLocationCode() {
        const userData = this.getLoggedUserData();
        return userData.vrShipLocationCode;
    }

    getTomorrowDate(date: Date) {
        return new Date(date.getTime() + (24 * 60 * 60 * 1000));
    }

    setTodayDate() {
        return new Date(new Date().setHours(0, 0, 0, 0));
    }

    setTomorrowDate() {
        return new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
    }

    setDeafultFromTime() {
        return new Date(new Date().setHours(8, 0, 0, 0));
    }

    setDeafultTenderTime() {
        return new Date(new Date().setHours(14, 0, 0, 0));
    }

    setDeafultToTime() {
        return new Date(new Date().setHours(16, 0, 0, 0));
    }

    convertDate(date: Date) {
        return moment(date).format('YYYY-MM-DD');
    }

    convertTime(date: Date) {
        return moment(date).format('HH:mm');
    }

    convertStartDate(date: Date) {
        return moment(date).format('YYYY-MM-DD') + 'T08:00:00';
    }

    convertEndDate(date: Date) {
        return moment(date).format('YYYY-MM-DD') + 'T16:00:00';
    }

    convertPasswordDate(date: Date) {
        return moment(date).format('YYYY-MM-DD') + 'T23:59:00';
    }

    convertScheduleDates(date: Date, time: Date) {
        return moment(date).format('YYYY-MM-DD') + 'T' + moment(time).format('HH:mm:ss');
    }

    getConvertedDateFormat(date: Date, time: Date) {
        return (moment(date).format('MM/DD/YYYY') + ' ' + moment(time).format('HH:mm'));
    }

    convertedDateTimeFormat(date: Date) {
        return (moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss'));
    }

    convertedDateTimeMinFormat(date: Date) {
        return (moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm'));
    }

    convertedDateTimeMinSecondFormat(date: Date) {
        return (moment(date).format('YYYY-MM-DD') + 'T' + moment(date).format('HH:mm:ss'));
    }

    getConvertedTLDateFormat(date: Date) {
        return (moment(date).format('MM/DD/YYYY') + ' ' + moment(date).format('HH:mm'));
    }

    convertLocalDateToUtcDate(date: Date) {
        return moment.utc(moment(date)).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
    }

    convertUtcDateToLocalDate(date: Date) {
        return moment.utc(date).local().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
    }
    convertedDateTimeTruckloadFormat(date: Date) {
        return (moment(date).format('MM/DD/YYYY') + moment(date).format(' hh:mm a'));
    }

    covertTodayDateWithPlusMint(mint: number) {
        const newDate = new Date();
        newDate.setMinutes(newDate.getMinutes() + mint);
        return newDate;
    }

    convertDateToCurrentYear(sourceDate, targetDate) {
        const hours = sourceDate.getHours();
        const minutes = sourceDate.getMinutes();
        const seconds = sourceDate.getSeconds();
        const milliseconds = sourceDate.getMilliseconds();

        targetDate.setHours(hours);
        targetDate.setMinutes(minutes);
        targetDate.setSeconds(seconds);
        targetDate.setMilliseconds(milliseconds);
        return targetDate;
    }

    returnNewDateString(getNewDate: any) {
        const year = getNewDate.getFullYear();
        const month = String(getNewDate.getMonth() + 1).padStart(2, '0');
        const day = String(getNewDate.getDate()).padStart(2, '0');
        const hours = String(getNewDate.getHours()).padStart(2, '0');
        const minutes = String(getNewDate.getMinutes()).padStart(2, '0');
        const seconds = String(getNewDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(getNewDate.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
    }

    isEmptyObject(obj: object) {
        return (obj && (Object.keys(obj).length === 0));
    }

    // Object Mapping
    objectMapping(mappingObject: object, sourceObject: object) {
        Object.keys(sourceObject).map((key, index) => {
            if (mappingObject.hasOwnProperty(key)) {
                mappingObject[key] = _.cloneDeep(sourceObject[key]);
            }
        });
    }

    // Object Mapping with null check
    objectMappingNullCheck(mappingObject: object, sourceObject: object) {
        Object.keys(sourceObject).map((key, index) => {
            if (mappingObject.hasOwnProperty(key) && sourceObject[key]) {
                mappingObject[key] = sourceObject[key];
            }
        });
    }

    // Download Files
    downloadBolbFile(body: any, options: any, filename: any) {
        const blob = new Blob([body], options);
        if ((window.navigator as any).msSaveBlob) {
            (window.navigator as any).msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    getFileNameFromResponseContentDisposition(res: any) {
        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').replace(/['"]+/g, '').trim();
        return fileName;
    }

    // Grid Object Mapping
    gridObjectMapping(mappingObject: any, event: DataStateChangeEvent) {
        mappingObject.definition.groups = [];
        mappingObject.definition.filters = [];
        mappingObject.definition.sortByColumn = null;
        mappingObject.definition.sortByAscending = false;
        if (Array.isArray(event.group) && event.group.length) {
            mappingObject.definition.groups = event.group;
        }
        if (event.sort && event.sort.length) {
            if (event.sort[0].dir !== undefined) {
                mappingObject.definition.sortByColumn = event.sort[0].field;
                mappingObject.definition.sortByAscending = (event.sort[0].dir === 'asc' ? true : false);
            }
        }
        if (event.filter !== undefined) {
            mappingObject.definition.filters = event.filter.filters;
        }
        mappingObject.results = event.take;
        mappingObject.skipCount = event.skip;
    }

    // Zip Data Response Change
    zipDataResponseChange(response: any) {
        response[0].address_components.filter(item => {
            item.types.find(list => {
                switch (list) {
                    case 'country':
                        const data = this.USTerritoriesListModel.find(x =>
                            x.stateCode.toLowerCase() === item.short_name.toLowerCase() ||
                            x.stateName.toLowerCase() === item.long_name.toLowerCase()
                        );
                        if (data && data.stateCode !== CommonConstants.defaultCountryCode) {
                            item.short_name = this.USTerritoriesListModel[0].stateCode;
                            item.long_name = this.USTerritoriesListModel[0].stateName;
                            response[0].address_components.push({
                                long_name: data.stateName,
                                short_name: data.stateCode,
                                types: ['administrative_area_level_1', 'political']
                            });
                        }
                        break;
                }
            });
        });
    }

    // Prepare Zip Data
    prepareZipData(response: any) {
        let data = [];
        this.googleAddressListModel.isCityPresent = false;
        const zipCodeList = Object.assign({}, response[0]);
        const prepareList = Object.assign({}, this.commonDataModel.zipListModel);
        prepareList.latitude = zipCodeList.geometry.location.lat();
        prepareList.longitude = zipCodeList.geometry.location.lng();
        zipCodeList.address_components.forEach(item => {
            item.types.forEach(list => {
                switch (list) {
                    case 'postal_code':
                        prepareList.zipCode = item.short_name;
                        break;
                    case 'administrative_area_level_1':
                        prepareList.stateCode = item.short_name;
                        prepareList.stateName = item.long_name;
                        break;
                    case 'country':
                        prepareList.countryCode = item.short_name;
                        prepareList.countryName = item.long_name;
                        break;
                    case 'street_number':
                        prepareList.address1 = item.long_name;
                        break;
                    case 'route':
                        prepareList.address1 = (prepareList.address1 ? prepareList.address1 + ' ' : '') + item.long_name;
                        break;
                }
            });
        });
        const responseData = _.cloneDeep(response)
        const addressListData = _.cloneDeep(this.googleAddressListModel)
        responseData[0].address_components.map((data, index) => {
            data.types.map((type) => {
                const pos = addressListData.addressTypes.findIndex((x) => x.type === type)
                if (pos != -1) {
                    addressListData.addressTypes[pos].long_name = data.long_name;
                    addressListData.addressTypes[pos].short_name = data.short_name;
                }
            })
        })
        if (addressListData.addressTypes.length) {
            addressListData.addressTypes.map((list) => {
                if (list.short_name && !addressListData.isCityPresent) {
                    prepareList.city = list.short_name;
                    addressListData.isCityPresent = true;
                }
            })
        }
        data.push(prepareList);

        if (this.validatePostalCodeLocalities) {
            Object.keys(zipCodeList).forEach((key, index) => {
                if (key === 'postcode_localities') {
                    data = [];
                    zipCodeList.postcode_localities.forEach(item => {
                        data.push({
                            city: item,
                            zip: prepareList.zipCode,
                            zipCode: prepareList.zipCode,
                            stateCode: prepareList.stateCode,
                            stateName: prepareList.stateName,
                            countryCode: prepareList.countryCode,
                            countryName: prepareList.countryName,
                            latitude: zipCodeList.geometry.location.lat(),
                            longitude: zipCodeList.geometry.location.lng(),
                            address1: prepareList.address1,
                        });
                    });
                }
            });
        }
        return data;
    }

    // Calculate Multiple Class Items Totals for TruckLoad

    calculateTLMultiClassItemTotals(list: any, linearFeetField?: boolean, totalPalletsField?: boolean) {
        const itemTotalList = _.cloneDeep(this.commonDataModel.itemTotalModelTL);
        const valueLimit = 48;
        list.map((elements: any) => {
            itemTotalList.totalWeight += parseFloat(elements.weight ? elements.weight : 0);
            this.totalItemValueSubject.next(itemTotalList);
            itemTotalList.totalValue += parseFloat(elements.value ? elements.value : 0);
            this.totalItemValueSubject.next(itemTotalList);
            if (!elements.isNestedItems) {
                if (elements.quantity && !totalPalletsField) {
                    itemTotalList.totalPallets += parseFloat(elements.quantity);
                    this.totalItemValueSubject.next(itemTotalList);
                }
                if (elements.length && elements.width && elements.quantity && !linearFeetField) {
                    if (+elements.length <= valueLimit || +elements.width <= valueLimit) {
                        const max = Math.max(elements.length, elements.width);
                        const inches = (max / 2) * elements.quantity;
                        itemTotalList.linearFeet += inches / 12;
                        this.totalItemValueSubject.next(itemTotalList);
                    } else {
                        const max = Math.max(elements.length, elements.width);
                        const inches = max * elements.quantity;
                        itemTotalList.linearFeet += inches / 12;
                        this.totalItemValueSubject.next(itemTotalList);
                    }
                }
                if (!elements.quantity && !totalPalletsField && !linearFeetField) {
                    itemTotalList.linearFeet = 0;
                    itemTotalList.totalPallets = 0;
                    this.totalItemValueSubject.next(itemTotalList);
                }
            }
        });

        if (!linearFeetField) {
            itemTotalList.linearFeet = Math.ceil(itemTotalList.linearFeet);
        }
        return itemTotalList;
    }

    // Calculate Multiple Class Items Totals
    calculateMultiClassItemTotals(arrayList: any, linearFeetField?: boolean, totalPalletsField?: boolean) {
        const itemTotalList = Object.assign({}, this.commonDataModel.itemTotalModel);
        const valueLimit = 48;
        arrayList.forEach(list => {
            list.totalWeight = 0;
            list.totalVolume = 0;
            list.totalDensity = 0;
            list.totalValue = 0;
            list.totalItems = 0;
            if (list.isNestedItems) {
                if (list.quantity && !totalPalletsField) {
                    itemTotalList.totalPallets += parseFloat(list.quantity);
                }
                if (list.length && list.width && list.quantity && !linearFeetField) {
                    if (+list.length <= valueLimit || +list.width <= valueLimit) {
                        const max = Math.max(list.length, list.width);
                        const inches = (max / 2) * list.quantity;
                        itemTotalList.linearFeet += inches / 12;
                    } else {
                        const max = Math.max(list.length, list.width);
                        const inches = max * list.quantity;
                        itemTotalList.linearFeet += inches / 12;
                    }
                }
                if (list.quantity && list.width && list.height && list.length) {
                    list.totalVolume = parseFloat((list.width * list.height * list.length * 1 / 1728).toFixed(2));
                } else {
                    list.totalVolume = 0;
                    list.totalDensity = 0;
                }
            }
            list.items.forEach(item => {
                if (item.weight) {
                    if (!list.isNestedItems) {
                        itemTotalList.totalWeight += parseFloat(item.weight);
                    }
                    list.totalWeight += parseFloat(item.weight);
                    if (list.totalWeight && list.totalVolume) {
                        list.totalDensity = parseFloat((list.totalWeight / list.totalVolume).toFixed(2));
                    } else {
                        list.totalDensity = 0;
                    }
                }
                if (item.value) {
                    if (!list.isNestedItems) {
                        itemTotalList.totalValue += parseFloat(item.value);
                    }
                    list.totalValue += parseFloat(item.value);
                }
                if (!list.isNestedItems) {
                    if (item.quantity && !totalPalletsField) {
                        itemTotalList.totalPallets += parseFloat(item.quantity);
                    }
                    if (item.length && item.width && item.quantity && !linearFeetField) {
                        if (+item.length <= valueLimit || +item.width <= valueLimit) {
                            const max = Math.max(item.length, item.width);
                            const inches = (max / 2) * item.quantity;
                            itemTotalList.linearFeet += inches / 12;
                        } else {
                            const max = Math.max(item.length, item.width);
                            const inches = max * item.quantity;
                            itemTotalList.linearFeet += inches / 12;
                        }
                    }
                    if (item.volume) {
                        itemTotalList.totalVolume += item.volume;
                    }
                }
            });
            if (list.isNestedItems) {
                itemTotalList.totalWeight += list.totalWeight * list.quantity;
                itemTotalList.totalValue += list.totalValue * list.quantity;
                itemTotalList.totalVolume += list.totalVolume * list.quantity;
            }
            list.totalItems += list.items.length;
            if (itemTotalList.totalWeight > 0 && itemTotalList.totalVolume > 0) {
                itemTotalList.totalDensity = (itemTotalList.totalWeight / itemTotalList.totalVolume);
            }
        });
        if (!linearFeetField) {
            itemTotalList.linearFeet = Math.ceil(itemTotalList.linearFeet);
        }
        return itemTotalList;
    }

    // Calculate totals
    calculateItemTotals(arrayList: any, linearFeetField?: boolean, totalPalletsField?: boolean, palletItem = false) {
        const itemTotalList = Object.assign({}, this.commonDataModel.itemTotalModel);
        const valueLimit = 48;
        arrayList.forEach(list => {
            if (list.weight) {
                itemTotalList.totalWeight += parseFloat(list.weight);
            }
            if (list.quantity && !totalPalletsField) {
                if (list.handlingType && palletItem) {
                    if (this.checkTLVendorBookingItemPallets(list.handlingType)) {
                        itemTotalList.totalPallets += parseFloat(list.quantity);
                    }
                }
                if (!palletItem) {
                    itemTotalList.totalPallets += parseFloat(list.quantity);
                }
            }
            if (list.value) {
                itemTotalList.totalValue += parseFloat(list.value);
            }
            if (list.length && list.width && list.quantity && !linearFeetField) {
                if (+list.length <= valueLimit || +list.width <= valueLimit) {
                    const max = Math.max(list.length, list.width);
                    const inches = (max / 2) * list.quantity;
                    itemTotalList.linearFeet += inches / 12;
                } else {
                    const max = Math.max(list.length, list.width);
                    const inches = max * list.quantity;
                    itemTotalList.linearFeet += inches / 12;
                }
            }
            itemTotalList.totalVolume += list.volume;
        });
        if (itemTotalList.totalWeight > 0 && itemTotalList.totalVolume > 0) {
            itemTotalList.totalDensity = (itemTotalList.totalWeight / itemTotalList.totalVolume);
        }
        if (!linearFeetField) {
            itemTotalList.linearFeet = Math.ceil(itemTotalList.linearFeet);
        }
        return itemTotalList;
    }

    // Item Mapping
    shipmentItemMapping(sourceData: any, result: any) {
        const data = Object.assign({}, result);
        data.weight = (result.weight === 0 ? null : result.weight);
        data.quantity = (result.quantity === 0 ? null : result.quantity);
        data.length = (result.length === 0 ? null : result.length);
        data.width = (result.width === 0 ? null : result.width);
        data.height = (result.height === 0 ? null : result.height);
        data.value = (result.value === 0 ? null : result.value);
        data.pieces = (result.pieces === 0 ? null : result.pieces);
        if (data.freightClass) {
            data.freightClass = data.freightClass.toString();
        }
        if (sourceData.weight != null && sourceData.weight > 0) {
            data.weight = sourceData.weight;
        }
        if (sourceData.quantity != null && sourceData.quantity > 0) {
            data.quantity = sourceData.quantity;
        }
        if (sourceData.length != null && sourceData.length > 0) {
            data.length = sourceData.length;
        }
        if (sourceData.width != null && sourceData.width > 0) {
            data.width = sourceData.width;
        }
        if (sourceData.height != null && sourceData.height > 0) {
            data.height = sourceData.height;
        }
        if (sourceData.value != null && sourceData.value > 0) {
            data.value = sourceData.value;
        }
        if (sourceData.pieces != null && sourceData.pieces > 0) {
            data.pieces = sourceData.pieces;
        }
        if (sourceData.packageType != null) {
            data.handlingType = sourceData.packageType;
        }
        this.objectMapping(sourceData, data);
    }

    // Common Search for TreeView
    ownerSearch(children: any[], term: string): any[] {
        if (term.trim() === '') { return children; }
        return children.reduce((acc, item) => {
            if (this.checkSearchedText(item.ownerName, term)) {
                acc.push({ ownerId: item.ownerId, ownerName: item.ownerName, accountNumber: item.accountNumber, restrictScheduling: item.restrictScheduling });
            }
            if (item.children && item.children.length) {
                const newItems = this.ownerSearch(item.children, term);
                if (newItems.length) {
                    for (const childItem of newItems) {
                        acc.push({ ownerId: childItem.ownerId, ownerName: childItem.ownerName, accountNumber: childItem.accountNumber, restrictScheduling: childItem.restrictScheduling });
                    }
                }
            }
            return acc;
        }, []);
    }

    // Expand Selected OwnerId
    expandSelected(items: any[], term: string): any {
        return items.reduce((store, item) => {
            if (this.checkSearchedText(item.ownerId, term)) {
                store.acc.push(item);
                if (item.ownerId === term) {
                    store.selectedOwnerName = item.ownerName;
                    store.accountNumber = item.accountNumber;
                    store.selectedEnterpriseData = item;
                }
            } else if (item.children && item.children.length) {
                const { acc, expandedKeysList, selectedOwnerName, accountNumber, selectedEnterpriseData } = this.expandSelected(item.children, term);
                store.expandedKeysList = store.expandedKeysList.concat(expandedKeysList);
                store.selectedOwnerName = store.selectedOwnerName.concat(selectedOwnerName);
                store.accountNumber = store.accountNumber.concat(accountNumber);
                if (selectedEnterpriseData) {
                    store.selectedEnterpriseData = selectedEnterpriseData;
                }
                if (acc.length) {
                    store.expandedKeysList.push(item.ownerId);
                    store.acc.push({ ownerId: item.ownerId, items: acc });
                }
            }
            return store;
        }, { acc: [], expandedKeysList: [], selectedOwnerName: '', accountNumber: '' });
    }

    checkSearchedText(text: string, term: string): boolean {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    }

    // Validate Phone Number
    validatePhoneNumber(formValue: any) {
        let count = 0;
        let status: boolean;
        if (formValue) {
            const originNumber = formValue.match(/()\d+/g);
            if (originNumber) {
                originNumber.filter(list => {
                    count += list.length;
                });
                status = count < 10 ? false : true;
                return status;
            }
        }
    }

    getBrowserDetails() {
        const appVersion = navigator.userAgent.split(' ');
        const userAgent = navigator.userAgent;
        let terms: any;
        let browserPlatForm: any;
        let browserObject = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\.?\d+(\.\d+)*)/i) || [];
        const hasstr = appVersion[4].includes(';');
        if (hasstr) {
            const str = appVersion[4];
            browserPlatForm = str.substring(0, str.length - 1);
            browserObject[4] = browserPlatForm;
        } else {
            browserObject[4] = appVersion[4];
        }
        if (/trident/i.test(browserObject[1])) {
            terms = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            browserObject[0] = 'Internet Explorer/' + (terms[1]);
            browserObject[1] = 'Internet Explorer';
            browserObject[2] = terms[1];
        }
        if (browserObject[1] === 'Chrome') {
            terms = userAgent.match(/\b(OPR|Edge|Edg)\/?\s*(\.?\d+(\.?\d+)*)/i);
            if (terms) {
                if (terms[1].toLowerCase() === 'edg') {
                    terms[1] = CommonConstants.browserName.edge;
                }
                browserObject = [];
                browserObject = terms;
                browserObject[4] = browserPlatForm;
            }
        }
        return browserObject;
    }

    getOSName() {
        let OSName = 'Unknown OS';
        if (navigator.userAgent.indexOf('Win') !== -1) { OSName = 'Windows'; }
        if (navigator.userAgent.indexOf('Mac') !== -1) { OSName = 'MacOS'; }
        if (navigator.userAgent.indexOf('X11') !== -1) { OSName = 'UNIX'; }
        if (navigator.userAgent.indexOf('Linux') !== -1) { OSName = 'Linux'; }
        return OSName;
    }

    downloadDocumentWithData(url: string, inputData: object): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    downloadDocumentData(url: string, inputData: object): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Get Default Location
    getDefaultLocation(inputData: any): Observable<any> {
        return this.apiService.postRecord('Address/defaultlocations', inputData);
    }

    sendFeedback(inputData: any): Observable<any> {
        return this.apiService.postRecord('Feedback', inputData);
    }

    resendReport(inputData: any): Observable<any> {
        return this.apiService.postRecord('ActivityReport/resendReport', inputData);
    }

    getUserInfo(): Observable<any> {
        return this.apiService.getIdentityRecords('/api/user/profile');
    }

    getUserTimeZoneDetails() {
        const userData = this.getLoggedUserData();
        this.userTimeZone = {
            timeZoneId: userData.timezoneId,
            description: userData.timezoneDescription
        };

        return this.userTimeZone;
    }

    checkForChanges(changes: SimpleChanges): boolean {
        // tslint:disable-next-line:forin
        for (const propName in changes) {
            const change = changes[propName];
            // if (change.previousValue) {
            const to = JSON.stringify(change.currentValue);
            const from = JSON.stringify(change.previousValue);
            if (to !== from) {
                return true;
            }
            // }
        }
        return false;
    }

    mappingGridSettings(response: any, kendoHelperObject: any, gridSettings: any, gridColumnModel: any): void {
        const responseData = Object.assign({}, response);
        this.checkDefinitionValues(responseData);
        this.mappingKendoGridHelper(kendoHelperObject, true, responseData, gridColumnModel);
        this.objectMapping(gridSettings.definition, responseData);
        if (responseData.columns.length > gridColumnModel.length || !responseData.columns.length) {
            gridSettings.definition.columns = gridColumnModel;
        }
        this.setGridDefinitionColumns(gridSettings, gridColumnModel);
        this.sortingData(gridColumnModel, CommonConstants.appConstant.reorder);
    }

    mappingKendoGridHelper(sourceObject: any, value: boolean, responseData?: any, gridColumnModel?: any): void {
        if (value) {
            let numericFilterColumns = [];

            if (gridColumnModel) {
                numericFilterColumns = gridColumnModel.filter(list => list.filter === 'numeric');
            }
            sourceObject.sort = [{
                dir: responseData.sortByAscending ? 'asc' : 'desc',
                field: responseData.sortByColumn ? responseData.sortByColumn : null
            }];
            sourceObject.groups = responseData.groups;

            if (responseData.filters.length && numericFilterColumns.length) {
                responseData.filters.filter(list => {
                    list.filters.filter(item => numericFilterColumns.some(x => {
                        if (item.field === x.field) {
                            item.value = Number(item.value);
                        }
                    }));
                });
            }
            sourceObject.filter = { filters: responseData.filters, logic: 'and' };
        } else {
            sourceObject.groups = [];
            sourceObject.filter = undefined;
            sourceObject.sort = [{ dir: 'asc', field: null }];
        }
        return sourceObject;
    }

    setGridDefinitionColumns(sourceObject: any, mappingObject: any) {
        sourceObject.definition.columns.filter(sourceList => {
            mappingObject.find(list => {
                if (sourceList.field === list.field) {
                    list.hidden = sourceList.hidden;
                    list.reorder = sourceList.reorder;
                    list.width = sourceList.width;
                }
            });
        });
        return mappingObject;
    }

    setGridColumnVisibility($event: any, mappingObject: any) {
        $event.columns.filter(parentList => {
            mappingObject.map(list => {
                if (parentList.field === list.field) {
                    list.hidden = parentList.hidden;
                }
            });
        });
        return mappingObject;
    }

    setGridColumnReorder(event: any, gridColumnModel: any, deletePermission: boolean, gridCopyColumnModel = false) {
        const data = gridColumnModel.filter(list => list.hidden === false);
        if (deletePermission) {
            data.unshift(this.gridSelectionColumnModel);
        }
        if (gridCopyColumnModel) {
            data.unshift(this.gridCopyColumnModel);
        }
        const reorderedColumn = data.splice(event.oldIndex, 1);
        data.splice(event.newIndex, 0, reorderedColumn[0]);
        data.join();

        data.forEach((value, index) => {
            gridColumnModel.filter(parentList => {
                if (!parentList.hidden) {
                    if (value.field === parentList.field) {
                        parentList.reorder = index;
                    }
                }
            });
        });

        this.sortingData(gridColumnModel, CommonConstants.appConstant.reorder);

        gridColumnModel.forEach((value, index) => {
            value.reorder = index + 1;
        });
        return gridColumnModel;
    }

    setGridColumnResize($event: any, mappingObject: any) {
        mappingObject.find(list => {
            if (list.field === $event[0].column.field) {
                list.width = $event[0].newWidth;
            }
        });
        return mappingObject;
    }

    updateGridSettings(defaultGridSetting: boolean, gridSettings: any, gridColumnsModel: any) {
        this.sortingData(gridColumnsModel, CommonConstants.appConstant.reorder);
        gridColumnsModel.forEach((value: any, index: number) => {
            value.reorder = index + 1;
        });
        gridSettings.definition.columns = gridColumnsModel;
        if (defaultGridSetting) {
            this.updateDefaultGridSettings(gridSettings.definition).subscribe(response => {
            });
        } else {
            if (gridSettings.definition.allowToEdit) {
                this.saveDefinition(gridSettings.definition).subscribe(response => {
                    this.customToastrService.success(CommonConstants.appConstant.savedDefinition);
                });
            }
        }
    }

    checkGridSettingsDiff(gridSettings: any, duplicateGridSettings: any, defaultGridSetting: boolean, gridColumnsModel: any): void {
        const diff: any = detailedDiff(duplicateGridSettings.definition, gridSettings.definition);
        if (Object.keys(diff.added).length !== 0 || Object.keys(diff.updated).length !== 0 || Object.keys(diff.deleted).length !== 0) {
            this.updateGridSettings(defaultGridSetting, gridSettings, gridColumnsModel);
        } else {
            this.sortingData(gridColumnsModel, CommonConstants.appConstant.reorder);
            this.sortingData(duplicateGridSettings.definition.columns, CommonConstants.appConstant.reorder);
            const columnDiff: any = updatedDiff(duplicateGridSettings.definition.columns, gridColumnsModel);
            if (Object.keys(columnDiff).length !== 0 && columnDiff.constructor === Object) {
                this.updateGridSettings(defaultGridSetting, gridSettings, gridColumnsModel);
            }
        }
    }

    setDefinitionQuery(definitionId: string, definition: any) {
        const resultQuery = {
            id: definitionId,
            userId: definition.userId,
            gridName: definition.gridName
        };
        return resultQuery;
    }

    checkDefinitionValues(responseData: any): any {
        if (responseData.columns === null) {
            responseData.columns = [];
        }
        if (responseData.filters === null) {
            responseData.filters = [];
        }
        if (responseData.groups === null) {
            responseData.groups = [];
        }
        return responseData;
    }

    sortingData(mappingArray: any, field: string): void {
        mappingArray.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return mappingArray;
    }

    callSisense(url: string) {
        const options: any = {
            headers: new HttpHeaders({
                'No-Auth': 'True',
                Accept: 'text/html,application/xhtml+xml,application/xml, application/json, */*',
            }),
            withCredentials: true,
            responseType: 'text'
        };

        return this.http.post(url, null, options).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    logoutFromSisense(url: string) {
        const options: any = {
            headers: new HttpHeaders({
                'No-Auth': 'True',
            }),
            withCredentials: true,
        };

        return this.http.get(url, options).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    clearHazMatFields(sourceObject: any) {
        sourceObject.id = null;
        sourceObject.isHazmat = false;
        sourceObject.uN_NA = null;
        sourceObject.hazMatClass = null;
        sourceObject.hazMatGroup = null;
        sourceObject.hazMatShipping = null;
        sourceObject.hazMatContactName = null;
        sourceObject.hazMatContactPhone = null;
        return sourceObject;
    }

    shipmentItemValueMapping(sourceData: any, result: any) {
        const data = Object.assign({}, result);
        data.weight = (result.weight === 0 ? null : result.weight);
        data.quantity = (result.quantity === 0 ? null : result.quantity);
        data.length = (result.length === 0 ? null : result.length);
        data.width = (result.width === 0 ? null : result.width);
        data.height = (result.height === 0 ? null : result.height);
        data.value = (result.value === 0 ? null : result.value);
        data.pieces = (result.pieces === 0 ? null : result.pieces);
        if (data.freightClass) {
            data.freightClass = data.freightClass.toString();
        }
        this.objectMapping(sourceData, data);
    }

    checkPreserveSearchData(sourceObject: any, mappingObject: any) {
        mappingObject.density = sourceObject.density === 0 ? null : sourceObject.density;
        if (sourceObject.length || sourceObject.width || sourceObject.height ||
            sourceObject.handlingType || sourceObject.weight || sourceObject.quantity ||
            sourceObject.pieces || sourceObject.value) {
            mappingObject.preserveCheckBox = true;
        } else {
            mappingObject.preserveCheckBox = false;
        }
        return mappingObject;
    }

    multipleEmailValidation(fieldValue: string, form: any, fieldName: string) {
        let emailValidation = false;
        if (fieldValue) {
            let tempEmailList = [];
            const emailValues = fieldValue.split(/;|,/);
            tempEmailList = _.uniqWith(emailValues, (a, b) => a.toLowerCase() === b.toLowerCase());
            tempEmailList.filter((list, index) => {
                if (ValidateHelper.validateEmail(list.trim())) {
                    if (tempEmailList && tempEmailList.length < EmailCountLimitEnum.value) {
                        emailValidation = true;
                    } else {
                        this.customToastrService.error(CommonConstants.appConstant.emailCountErrorMessage + EmailCountLimitEnum.value);
                        emailValidation = false;
                    }
                } else {
                    this.customToastrService.error(CommonConstants.appConstant.invalidEmailErrorMessage);
                    emailValidation = false;
                }
            });
            if (emailValidation) {
                form.form.controls[fieldName].setErrors(null);
                fieldValue = null;
                this.customToastrService.clear();
                return fieldValue = tempEmailList.join(';');
            } else {
                form.form.controls[fieldName].setErrors({ incorrect: true });
            }
            return fieldValue = tempEmailList.join(';');
        }
    }

    base64toBlob(base64Data: any, contentType: any) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    // Sorting Array By Field
    sortingByField(sourceArray: any, Field: string) {
        if (Array.isArray(sourceArray) && sourceArray.length) {
            return _.sortBy(sourceArray, sortList => sortList[Field]);
        }
    }

    setInformationDialogStatus(message: string, iconName: string, iconColor: string, iconSize: string, buttonName: string, TLoptions: boolean) {
        this.informationDataModel.message = message;
        this.informationDataModel.iconName = iconName;
        this.informationDataModel.iconColor = iconColor;
        this.informationDataModel.iconSize = iconSize;
        this.informationDataModel.buttonName = buttonName;
        this.informationDataModel.showTLElements = TLoptions;
        return this.informationDataModel;
    }

    checkRedLineWarningFromData(gridData: any) {
        if (Array.isArray(gridData) && gridData.length) {
            const data = _.find(gridData, (list) => {
                return list.isRedLineWarning;
            });
            if (!data) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    requestQuoteObjectMapping(mappingList: any, sourceList: any, totalList: any, isQuoteEmailSelected?: boolean, shipmentId?: string) {
        const sourceData = _.cloneDeep(sourceList);
        mappingList.ownerId = this.getOwnerId();
        mappingList.ownerName = this.getOwnerName();
        this.objectMapping(mappingList, totalList);
        this.objectMapping(mappingList.originAddress, sourceData.originAddress);
        this.objectMapping(mappingList.destinationAddress, sourceData.destinationAddress);
        mappingList.shipmentHandlingUnits = sourceData.shipmentHandlingUnits;
        mappingList.shipmentServices.services = sourceData.shipmentServices.services;
        mappingList.shipmentEquipments.equipments = sourceData.shipmentEquipments.equipments;
        mappingList.referenceSettings = sourceData.shipmentReferenceSettings.referenceSettings;
        mappingList.isQuoteEmailSelected = isQuoteEmailSelected;
        mappingList.isQuoteRequest = true;
        mappingList.shipmentId = shipmentId;
        mappingList.bolInstructions = sourceData.bolInstructions;
        mappingList.missedRequiredReferences = sourceData.missedRequiredReferences;
        return mappingList;
    }

    copyShipmentObjectMapping(mappingObject: object, sourceObject: object) {
        Object.keys(sourceObject).map((key, index) => {
            if (key !== 'pickupDate' && key !== 'copyRates') {
                mappingObject[key] = sourceObject[key];
            }
        });
    }

    copyShipmentObjectNullCheck(sourceObject: any) {
        const responseObject = Object.assign({}, sourceObject);
        if (!responseObject.shipmentRate) {
            delete responseObject.shipmentRate;
        }
        if (!responseObject.shipmentEquipments) {
            delete responseObject.shipmentEquipments;
        }
        if (!responseObject.shipmentServices) {
            delete responseObject.shipmentServices;
        }
        if (!responseObject.shipmentReferenceSettings) {
            delete responseObject.shipmentReferenceSettings;
        }
        if (!responseObject.shipmentHandlingUnits) {
            delete responseObject.shipmentHandlingUnits;
        }
        delete responseObject.id;
        return responseObject;
    }

    copiedFieldResponse(shipmentId: any, ownerId: any, sourceObject: any) {
        const targetObject = {
            shipmentId,
            ownerId,
            copyItems: sourceObject.SN_CopyItemsByDefault,
            copyReferences: sourceObject.SN_CopyReferencesByDefault,
            copyServices: sourceObject.SN_CopyServicesByDefault,
            copyEquipments: sourceObject.SN_CopyEquipmentByDefault,
            copyRates: sourceObject.SN_CopyAutoRate,
            pickupDate: sourceObject.pickupDate
        };
        return targetObject;
    }

    checkModuleNameValue(moduleName: any) {
        if (moduleName === CommonConstants.moduleName.scheduleTruckload ||
            moduleName === CommonConstants.moduleName.TLVendorBooking || moduleName === CommonConstants.moduleName.parcel) {
            return false;
        }
        return true;
    }

    // TruckloadItem Object Mapping
    truckLoadItemsMapping(targetObject: any, sourceObject: any) {
        sourceObject.map((item, index) => {
            targetObject[index] = item;
        });
    }

    checkLengthRange(item: any): boolean {
        if (item.minLength > 0 && item.maxLength > 0 && (item.length < item.minLength || item.length > item.maxLength)) {
            return true;
        }
        return false;
    }

    checkDensityRange(item: any): boolean {
        if (item.minPCF > 0 && item.maxPCF > 0 && (item.density < item.minPCF || item.density > item.maxPCF)) {
            return true;
        }
        return false;
    }

    setDefaultHandlingUnitFlag(sourceObject: any) {
        sourceObject.map((item, index) => {
            item.isHandlingUnitSelected = false;
        });
    }

    getSelectedParentOwnerId() {
        const userData = this.getLoggedUserData();
        return userData.selectedParentOwnerId;
    }

    getBIVisitedDashboards() {
        if (sessionStorage.getItem('oidcBIVisitedDashboards')) {
            return JSON.parse(atob(sessionStorage.getItem('oidcBIVisitedDashboards')));
        }
        return [];
    }

    setBIVisitedDashboards(data: any) {
        if (!data) { data = []; }
        sessionStorage.setItem('oidcBIVisitedDashboards', btoa(JSON.stringify(data)));
    }

    // Get Selected Enterprise
    getSelectedEnterprise(enterpriseId: string, parentEnterpriseId: string): Observable<any> {
        return this.apiService.getRecords(
            'SsoConfiguration/BIEnterpriseFilters?ownerId=' + enterpriseId + '&RootOwnerId=' + parentEnterpriseId);
    }

    checkResidentialPickupService(serviceList: any, isResidentialFlag: boolean, residentialPickupForm: any) {
        const isResidentialPickup = _.some(serviceList, ['value', 'REP']);
        if (isResidentialPickup) {
            const residentialPickupValue = residentialPickupForm.controls.REP.value;
            if (isResidentialFlag && !residentialPickupValue) {
                return false;
            }
            return true;
        }
        return true;
    }

    checkResidentialDeliveryService(serviceList: any, isResidentialFlag: boolean, residentialDelieveryForm: any) {
        const isResidentialPickup = _.some(serviceList, ['value', 'RES']);
        if (isResidentialPickup) {
            const residentialDelieveryValue = residentialDelieveryForm.controls.RES.value;
            if (isResidentialFlag && !residentialDelieveryValue) {
                return false;
            }
            return true;
        }
        return true;
    }

    // Array Mapping
    arrayMapping(mappingObject: any, sourceObject: any) {
        sourceObject.map((item, index) => {
            mappingObject[index] = item;
        });
    }

    checkTLVendorBookingItemPallets(handlingType: string) {
        if (handlingType) {
            const imageFileExtensionList = ['plt', 'pallets', 'pallet'];
            return imageFileExtensionList.includes(handlingType.toLowerCase());
        }
    }

}
