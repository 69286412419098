import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../common-data-model';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { UploadDocumentFileComponent } from '../upload-document-file/upload-document-file.component';
import { APITimeoutEnum } from 'src/app-core/enums/common-enum';

@Component({
    selector: 'app-confirm-claims-dialog',
    templateUrl: './confirm-claims-dialog.component.html',
    styleUrls: ['./confirm-claims-dialog.component.scss']
})
export class ConfirmClaimsDialogComponent implements OnInit {
    @ViewChild('claimsForm', { static: false }) claimsForm: any;
    contact = null;
    selectedData: any;
    isFormLoading = false;
    commonDataModel = new CommonDataModel();
    commonConstant: typeof CommonConstants = CommonConstants;
    claimsFormPayload = Object.assign({}, this.commonDataModel.claimsFormPayload);
    ownerId: any;
    documentFile: any;
    regexIntPattern = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    informationDataModel: any;
    timeOutArray = [];
    doNotShowPopUp = true;
    constructor(public dialogRef: MatDialogRef<ConfirmClaimsDialogComponent>,
        private customToastrService: CustomToastrService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService) {
        this.selectedData = data
        this.ownerId = this.commonService.getOwnerId();
    }

    ngOnInit() {
        this.getClaimsRequestDetails();
    }

    getClaimsRequestDetails() {
        const data = {
            Id: this.selectedData.shipmentId,
            ownerId: this.ownerId
        }
        this.commonService.getClaimsRequest(data).subscribe((response: any) => {
            if (response.bol) {
                this.claimsFormPayload.bol = response.bol
            } else {
                this.claimsFormPayload.bol = response.pro
            }
            this.claimsFormPayload.claimInformationLink = response.claimInformationLink
        })
    }

    onSubmit() {
        this.isFormLoading = true;
        const payload = {
            userQuery: this.contact,
            shipmentId: this.selectedData.shipmentId,
            ownerId: this.selectedData.ownerId
        }

        this.commonService.sendClaimsRequest(payload).subscribe((response: any) => {
            this.contact = null;
            this.isFormLoading = false;
            this.dialogRef.close({ confirmResult: true });
            this.customToastrService.success(this.commonConstant.trackingDetailConstant.claimStatusFormMessage);
        })
    }

    onSubmitClaimsForm() {
        if (this.claimsForm.valid) {
            this.isFormLoading = true;
            this.claimsFormPayload.shipmentId = this.selectedData.shipmentId;
            this.claimsFormPayload.ownerId = this.commonService.getOwnerId();
            this.claimsFormPayload.pieces = this.claimsFormPayload.pieces ? this.claimsFormPayload.pieces : 0;
            this.claimsFormPayload.totalWeightOfDamagedOrLostItem = this.claimsFormPayload.totalWeightOfDamagedOrLostItem ? this.claimsFormPayload.totalWeightOfDamagedOrLostItem : 0;
            this.claimsFormPayload.totalAmountToFile = this.claimsFormPayload.totalAmountToFile ? this.claimsFormPayload.totalAmountToFile : 0;
            this.commonService.submitClaimsFormRequest(this.claimsFormPayload).subscribe((response) => {
                this.isFormLoading = false;
                this.timeOutArray.map((time) => {
                    clearTimeout(time);
                })
                if (this.doNotShowPopUp) {
                    this.informationDataModel = this.commonService.setInformationDialogStatus(
                        CommonConstants.trackingDetailConstant.claimsFormSuccessMessage, 'task_alt', '#4DB74A', '45px', 'OK', false);
                    const dialogRef = this.dialog.open(InformationDialogComponent, {
                        panelClass: 'upload-dialog-wrapper',
                        hasBackdrop: false,
                        data: this.informationDataModel
                    });
                    dialogRef.afterClosed().subscribe(() => {
                        this.onClose();
                    });
                }
            }, error => {
                this.timeOutArray.map((time) => {
                    clearTimeout(time);
                })
                this.isFormLoading = false;
            })
            this.timeOutArray.push(setTimeout(() => {
                this.doNotShowPopUp = false;
                this.customToastrService.errorToastr(CommonConstants.ClaimsRequestTimeOutError);
                this.onClose();
            }, APITimeoutEnum.defaultClaimRequestTime));
        } else {
            this.isFormLoading = false;
            this.customToastrService.error(CommonConstants.appConstant.formMandatoryErrorMessage);
        }
    }

    isClaimTypeRequired(isClaimTypeDamage: boolean, isClaimTypeLoss: boolean, isDamageType_Shortage?: boolean, isDamageType_VisibleDamage?: boolean, isDamageType_ConcealedDamage?: boolean, isDamageType_Others?: boolean, isDamageType_Loss?: boolean) {
        return isClaimTypeDamage || isClaimTypeLoss || isDamageType_Shortage || isDamageType_VisibleDamage || isDamageType_ConcealedDamage || isDamageType_Others || isDamageType_Loss ? false : true;
    }

    isDamageRepairable(damagedFreightBeRepairedYes: boolean, damagedFreightBeRepairedNo: boolean) {
        return damagedFreightBeRepairedYes || damagedFreightBeRepairedNo ? false : true;
    }

    isShipmentLocated(isReturnedToShipper: boolean, isDeliveredToCustomer: boolean) {
        return isReturnedToShipper || isDeliveredToCustomer ? false : true;
    }

    damagedFreightBeRepairedYes() {
        if (this.claimsForm.controls.damagedFreightBeRepairedYes.value) {
            this.claimsFormPayload.damagedFreightBeRepairedNo = false;
            this.claimsFormPayload.explanationTotalLossIfNotRepairable = null;
        }
    }

    damagedFreightBeRepairedNo() {
        if (this.claimsForm.controls.damagedFreightBeRepairedNo.value) {
            this.claimsFormPayload.damagedFreightBeRepairedYes = false;
            this.claimsFormPayload.quoteOrInvoiceForRepairCosts = null
        }
    }

    claimTypeYesSelected() {
        if (this.claimsForm.controls.isClaimTypeDamage.value) {
            this.claimsFormPayload.damagedFreightBeRepairedNo = false;
            this.claimsFormPayload.explanationTotalLossIfNotRepairable = null;
        }
    }

    claimTypeNoSelected() {
        if (this.claimsForm.controls.isClaimTypeDamage.value) {
            this.claimsFormPayload.damagedFreightBeRepairedYes = false;
            this.claimsFormPayload.quoteOrInvoiceForRepairCosts = null
        }
    }

    onReturnToShipper() {
        if (this.claimsForm.controls.isReturnedToShipper.value) {
            this.claimsFormPayload.isDeliveredToCustomer = false;
        }
    }

    onReturnToCustomer() {
        if (this.claimsForm.controls.isDeliveredToCustomer.value) {
            this.claimsFormPayload.isReturnedToShipper = false;
        }
    }

    isOtherDamageTypeRequired() {
        if (this.claimsFormPayload.isDamageType_Others) {
            return true;
        } else {
            return false;
        }
    }

    onClickUpload(index: any) {
        const dialogRef = this.dialog.open(UploadDocumentFileComponent,
            {
                width: '40%',
                hasBackdrop: false
            }
        );

        dialogRef.afterClosed().subscribe((response) => {
            const fileData = {
                file: null,
                fileName: null,
                supportedDocumentType: null,
                shipmentId: this.selectedData.shipmentId
            }
            if (response) {
                fileData.fileName = response.fileName;
                fileData.file = response.file;
                switch (index) {
                    case 0:
                        fileData.supportedDocumentType = this.commonConstant.supportedDocumentTyes.damagedProductType;
                        break;
                    case 1:
                        fileData.supportedDocumentType = this.commonConstant.supportedDocumentTyes.invoiceType;
                        break;
                    case 2:
                        fileData.supportedDocumentType = this.commonConstant.supportedDocumentTyes.bolType;
                        break;
                    case 3:
                        fileData.supportedDocumentType = this.commonConstant.supportedDocumentTyes.repairedInvoiceType;
                        break;
                }
                const formData = new FormData();
                formData.append('file', fileData.file);
                formData.append('fileName', fileData.fileName);
                formData.append('shipmentId', fileData.shipmentId);
                formData.append('supportedDocumentType', fileData.supportedDocumentType);
                this.isFormLoading = true;
                this.commonService.uploadClaimsDocument(formData).subscribe((res: any) => {
                    this.isFormLoading = false;
                    const documentData = {
                        fileName: response.fileName,
                        supportedDocumentType: fileData.supportedDocumentType
                    }
                    this.claimsFormPayload.supportedDocuments.push(documentData);
                }, error => {
                    this.isFormLoading = false;
                })
            }
        })
    }

    onDeleteDocument(index: number) {
        this.isFormLoading = true;
        this.deleteDocument(index, true);
    }

    isAcknowledgeCheckboxValid() {
        if (this.claimsFormPayload.isApprovedTLIClaimFee) {
            return false;
        } else {
            return true;
        }
    }

    onClose() {
        this.deleteDocument();
        this.timeOutArray.map((time) => {
            clearTimeout(time);
        })
        if (this.claimsForm) {
            this.claimsForm.reset();
        }
        this.dialogRef.close({ confirmResult: true });
    }

    deleteDocument(index?: number, singleDelete?: boolean) {
        if (this.claimsFormPayload.supportedDocuments.length) {
            const payload = {
                documents: singleDelete ? [this.claimsFormPayload.supportedDocuments[index]] : this.claimsFormPayload.supportedDocuments,
                shipmentId: this.selectedData.shipmentId
            }
            this.commonService.deleteClaimsDocument(payload).subscribe((response: any) => {
                this.isFormLoading = false;
                this.claimsFormPayload.supportedDocuments.splice(index, 1);
            }, error => {
                this.isFormLoading = false;
            })
        }
    }

    onClaims() {
        if (this.selectedData && this.selectedData.redKikUrl) {
            this.isFormLoading = true;
            const payload = {
                userQuery: this.contact,
                shipmentId: this.selectedData.shipmentId,
                ownerId: this.selectedData.ownerId
            }
            window.open(this.selectedData.redKikUrl, '_blank');
            this.commonService.submitInsuranceClaimsFormRequest(payload).subscribe((response: any) => {
                this.contact = null;
                this.isFormLoading = false;
                this.dialogRef.close({ confirmResult: true });
                this.customToastrService.success(this.commonConstant.trackingDetailConstant.claimStatusFormMessage);
            })
        }
    }

}
