import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-shipment-address-save',
    templateUrl: './shipment-address-save.component.html',
    styleUrls: ['./shipment-address-save.component.scss']
})

export class ShipmentAddressSaveComponent implements OnInit {

    addressList: any;
    duplicateAddressList: any;
    locationCodeExists = false;

    constructor(
        private dialogRef: MatDialogRef<ShipmentAddressSaveComponent>,
        @Inject(MAT_DIALOG_DATA) receiveAddressData?: any) {
        this.addressList = receiveAddressData.addressList;
        this.duplicateAddressList = receiveAddressData.locationDtos;
        this.locationCodeExists = receiveAddressData.locationCodeExists;
    }

    ngOnInit() {
    }

    onConfirm() {
        this.dialogRef.close({ confirmResult: true });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}
