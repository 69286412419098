import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-tracking-warning-dialog',
    templateUrl: './tracking-warning-dialog.component.html',
    styleUrls: ['./tracking-warning-dialog.component.scss']
})

export class TrackingWarningDialogComponent implements OnInit {

    confirmButton = false;
    trackingSettings: any;

    constructor(
        private dialogRef: MatDialogRef<TrackingWarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) settings?: object) {
        this.trackingSettings = settings;
    }

    ngOnInit() {
    }

    onConfirm() {
        this.confirmButton = true;
        this.dialogRef.close({ confirmResult: this.confirmButton, settings: this.trackingSettings });
    }

    onClose() {
        this.confirmButton = false;
        this.dialogRef.close({ confirmResult: this.confirmButton, settings: this.trackingSettings });
    }

}
