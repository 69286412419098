import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { EnterpriseSearchLengthEnum, PermissionModuleEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../common-data-model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-tree-popup',
    templateUrl: './tree-popup.component.html',
    styleUrls: ['./tree-popup.component.scss']
})

export class TreePopupComponent implements OnInit {

    @ViewChild('searchBox', { static: true }) private searchBox: ElementRef;
    commonDataModel = new CommonDataModel();
    seletedEnterprise = this.commonDataModel.selectedEnterpriseModel;
    treeData: any[];
    keys: string[] = [];
    selectedKeys: any[] = [];
    data: any[];
    isLoading = false;
    expandedKeys: any[] = [];
    expandedList: any;
    isRoot = false;
    searchBoxModel: string;
    selectedParentOwnerId: string;
    selectedEnterpriseData: any;
    isVendorItemEditMode = false;
    isVendorItemFormDirty = false;
    loggedUserModel = this.commonDataModel.loggedUserModel;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<TreePopupComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        this.isVendorItemEditMode = data.editMode;
        this.isVendorItemFormDirty = data.formDirty;
    }

    ngOnInit() {
        this.loadItems();
    }

    loadItems(): void {
        const selectedOwner = this.commonService.getOwnerId();
        this.selectedKeys = [];
        this.selectedKeys.push(selectedOwner);
        this.isLoading = true;
        this.commonService.getOwnerHierarchy(this.isRoot).subscribe(response => {
            this.isLoading = false;
            this.data = new Array(response);
            this.treeData = this.data;
            this.expandedList = this.commonService.expandSelected(this.treeData, selectedOwner);
            this.expandedKeys = this.expandedList.expandedKeysList;
            setTimeout(() => document.querySelector('.kendo-tree-popup .k-state-selected').scrollIntoView(), 500);
            this.searchBox.nativeElement.focus();
        });
    }

    onHandleExpand(node: any) {
        this.keys = this.keys.concat(node.index);
    }

    onSearch(value: string): void {
        if (value && value.length >= EnterpriseSearchLengthEnum.value) {
            this.treeData = this.commonService.ownerSearch(this.data, value.trim());
        } else {
            this.treeData = this.data;
        }
        this.cdRef.detectChanges();
        this.searchBox.nativeElement.focus();
    }

    // A function that determines whether a given node has children
    hasChildren = (item: any) => item.children && item.children.length > 0;

    // A function that returns an observable instance which contains the child nodes for a given parent node
    fetchChildren = (item: any) => of(item.children);

    onHandleSelection({ dataItem }: any): void {
        this.seletedEnterprise.ownerId = dataItem.ownerId;
        this.seletedEnterprise.ownerName = dataItem.ownerName;
        this.selectedEnterpriseData = dataItem;
        this.commonService.setEnterpriseData(dataItem.restrictScheduling);
        const quickRateData = this.commonService.getQrData()
        if (quickRateData && quickRateData.rateDetails) {
            quickRateData.rateDetails.fetchRateData = [];
            this.commonService.postQuickRate(quickRateData).subscribe(response => { });
        }

        if (dataItem.parentId) {
            this.onFindParentFromTreeData(this.treeData, dataItem.parentId);
        }

        if (this.isVendorItemEditMode && this.isVendorItemFormDirty) {
            this.onShowConformationDialog();
        } else {
            this.updateEnterprise();
        }
    }

    onFindParentFromTreeData(items: any, matchingId: number) {
        items.find(list => {
            if (list.enterpriseId === matchingId) {
                this.selectedParentOwnerId = list.ownerId;
            } else if (Array.isArray(list.children) && list.children.length) {
                this.onFindParentFromTreeData(list.children, matchingId);
            }
        });
    }

    updateEnterprise() {
        this.commonMethod.sendVendorItemExit(true);
        this.commonService.setEnterpriseData(this.selectedEnterpriseData.restrictScheduling);
        this.commonService.sendEnterpriseName(this.seletedEnterprise.ownerName);
        this.commonService.setOwnerId(this.seletedEnterprise);
        this.commonService.setAccountNumber(this.selectedEnterpriseData.accountNumber);

        if (this.selectedParentOwnerId) {
            this.commonService.setSelectedParentOwnerId(this.selectedParentOwnerId);
        } else {
            this.commonService.setSelectedParentOwnerId(this.selectedEnterpriseData.ownerId);
        }

        if (this.seletedEnterprise) {
            this.customToastrService.success(CommonConstants.treePopupForm.updateFormSuccessMessage);
            setTimeout(() => {
                this.reloadPage();
            }, 100);
        } else {
            this.customToastrService.error(CommonConstants.treePopupForm.updateFormErrorMessage);
        }
    }

    reloadPage() {
        this.loggedUserModel = this.commonService.getLoggedUserData();
        if (this.loggedUserModel.userPermission) {
            const selectedPermission = this.loggedUserModel.userPermission;
            if (this.loggedUserModel.startUpPage === '0') {
                this.checkStartUpModule(selectedPermission);
            } else {
                const startUpPage = selectedPermission.find(list => list === Number(this.loggedUserModel.startUpPage));
                if (startUpPage) {
                    this.loginRedirection(Number(startUpPage));
                } else {
                    this.checkStartUpModule(selectedPermission);
                }
            }
        } else {
            this.loginRedirection(0);
            this.loggedUserModel.userPermission = [];
        }
    }

    checkStartUpModule(permissionList: any) {
        const redirectionPage = this.commonService.checkStartUpModule(permissionList);
        if (redirectionPage) {
            this.loginRedirection(redirectionPage);
        } else {
            const maintenancePage = permissionList.find(list => list === PermissionModuleEnum.notificationPage);
            if (maintenancePage) {
                this.loginRedirection(maintenancePage);
            } else {
                this.loginRedirection(0);
            }
        }
    }

    loginRedirection(value: number) {
        const settingsGetModel: any = {};
        settingsGetModel.userId = this.loggedUserModel.userId;
        settingsGetModel.ownerId = this.loggedUserModel.ownerId;
        settingsGetModel.accountSettingType = 'ShipmentTracking';
        this.commonService.getAccountSettings(settingsGetModel).subscribe(response => {
            const responseData = Object.assign({}, response);
            const trackingDisplayMode = responseData.DisplayMode ? responseData.DisplayMode : '1';
            this.loggedUserModel.displayMode = trackingDisplayMode;
            sessionStorage.setItem('oidcTranslogistics', btoa(JSON.stringify(this.loggedUserModel)));
            const returnURL = this.commonService.checkLoginRedirection(value, trackingDisplayMode);
            this.commonService.setBIVisitedDashboards([]);

            if (this.router.url.substring(1) === returnURL ||
                (this.router.url.includes('ltl-vendor') && returnURL.includes('ltl-vendor')) ||
                (this.router.url.includes('tl-vendor') && returnURL.includes('tl-vendor')) ||
                (this.router.url.includes('tracking-map') && returnURL.includes('tracking-map'))) {
                location.reload();
            } else if ((this.router.url.includes('schedule-shipment') && returnURL.includes('new'))) {
                this.router.navigateByUrl(returnURL);
                setTimeout(() => {
                    location.reload();
                }, 1000);
            } else {
                this.commonService.globalSearchSubject.next(true);
                this.commonService.sendUpdateWhiteLabelingSettings();
                this.dialogRef.close();
            }
            this.commonMethod.sendVendorBookingStartupPage('');
        });
    }

    onShowConformationDialog() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: CommonConstants.TLVendorModuleConstants.exitConformation
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result.confirmResult) {
                this.updateEnterprise();
            } else {
                const selectedOwner = this.commonService.getOwnerId();
                this.selectedKeys = [];
                this.selectedKeys.push(selectedOwner);
            }
        });
    }

    onClose() {
        this.dialogRef.close();
    }

}
