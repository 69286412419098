export class TrackingDataModel {

    public settingsGetModel = {
        userId: null,
        ownerId: null,
        accountSettingType: 'Map'
    };

    public mapFilterModel = [
        {
            title: 'Modes',
            items: [
                { value: 'LTL', text: 'LTL', isSelected: false },
                { value: 'Truckload', text: 'Truckload', isSelected: false },
                { value: 'Intermodal', text: 'Intermodal', isSelected: false },
                { value: 'Partial TL', text: 'Partial TL', isSelected: false },
                { value: 'Power Only', text: 'Power Only', isSelected: false },
                { value: 'Volume', text: 'Volume', isSelected: false },
                { value: 'Expedite', text: 'Expedite', isSelected: false },
                { value: 'Mode Missing', text: 'Mode Missing', isSelected: false },
                { value: 'Contracted Intermodal', text: 'Contracted Intermodal', isSelected: false },
                { value: 'Contracted Truckload', text: 'Contracted Truckload', isSelected: false },
                { value: 'Spot Truck Load', text: 'Spot Truck Load', isSelected: false },
                { value: 'Parcel', text: 'Parcel', isSelected: false },
                { value: 'Courier', text: 'Courier', isSelected: false },
                { value: 'Customs Clearance', text: 'Customs Clearance', isSelected: false },
                { value: 'Drayage', text: 'Drayage', isSelected: false },
                { value: 'International - Air', text: 'International - Air', isSelected: false },
                { value: 'International - Breakbulk', text: 'International - Breakbulk', isSelected: false },
                { value: 'International - FCL', text: 'International - FCL', isSelected: false },
                { value: 'International - LCL', text: 'International - LCL', isSelected: false },
                { value: 'Warehousing', text: 'Warehousing', isSelected: false }
            ]
        },
        {
            title: 'Directions',
            items: [
                { value: 'Inbound', text: 'Inbound', isSelected: false },
                { value: 'Outbound', text: 'Outbound', isSelected: false },
                { value: 'Drop', text: 'Drop', isSelected: false },
                { value: 'Shuttle', text: 'Shuttle', isSelected: false }
            ]
        }
    ];

}
