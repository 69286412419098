import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-equipment',
    templateUrl: './shipment-equipment.component.html'
})

export class ShipmentEquipmentComponent implements OnInit, OnChanges {

    @ViewChild('shipmentEquipmentsForm', { static: true }) shipmentEquipmentsForm: NgForm;
    isLoading = false;
    equipmentForm: any;
    equipmentList: Array<any> = [];
    commonDataModel = new CommonDataModel();
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    savedDataList = Object.assign({}, this.commonDataModel.savedDataModel);
    shipmentBookingList = Object.assign({}, this.commonDataModel.shipmentBookingListModel);
    numberToDisplay: any;
    @Input() set formStatus(formList: object) {
        this.equipmentForm = formList;
    }
    @Input() set savedDataStatus(savedlist: any) {
        this.savedDataList = savedlist;
    }
    @Input() set childData(shipmentEquipmentsList: any) {
        this.shipmentBookingList.shipmentEquipments = shipmentEquipmentsList;
    }
    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
    }
    @Input() set componentNo(data: number) {
        this.numberToDisplay = data;
    }

    constructor(
        private commonService: CommonService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            this.loadItems();
        }
    }

    loadItems(): void {
        this.isLoading = true;
        if (this.shipmentSettingsModel.Equipment) {
            this.equipmentList = JSON.parse(JSON.stringify(this.shipmentSettingsModel.Equipment));
            this.isLoading = false;
            this.preSelectedList();
        }
    }

    preSelectedList(): void {
        if (Array.isArray(this.shipmentBookingList.shipmentEquipments.equipments) &&
            this.shipmentBookingList.shipmentEquipments.equipments.length) {
            this.equipmentList.map((list) => {
                list.isSelected = false;
                this.shipmentBookingList.shipmentEquipments.equipments.find(item => {
                    if (list.value === item.value) {
                        list.isSelected = item.isSelected;
                    }
                });
            });
            this.equipmentForm.equipmentForm = true;
        } else {
            if (!this.savedDataList.equipments) {
                this.onChangeEquipment();
            } else {
                this.equipmentList.filter(item => { item.isSelected = false; });
            }
        }
    }

    onChangeEquipment(): void {
        const itemList = this.equipmentList.filter((list) => {
            return list.isSelected;
        });
        this.shipmentBookingList.shipmentEquipments.equipments = itemList;
        this.savedDataList.equipments = true;
        this.equipmentForm.equipmentForm = itemList.length > 0 ? true : false;
    }

    resetEquipments(): void {
        this.shipmentEquipmentsForm.resetForm();
        this.equipmentList = [];
        this.savedDataList.equipments = false;
        this.shipmentBookingList.shipmentEquipments.equipments = [];
        this.loadItems();
    }

}
