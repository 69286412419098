import { Component, Input, OnInit } from '@angular/core';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-address-info',
    templateUrl: './address-info.component.html'
})

export class AddressInfoComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    addressList = this.commonDataModel.addressInfoModel;

    @Input() set childData(detailsList: any) {
        if (detailsList.shipperInfo !== null) {
            this.addressList.shipperInfo = detailsList.shipperInfo;
        }
        if (detailsList.consigneeInfo !== null) {
            this.addressList.consigneeInfo = detailsList.consigneeInfo;
        }
    }

    constructor() {
    }

    ngOnInit() {
    }

}
