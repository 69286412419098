import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { MaintenanceDataModel } from 'src/app/maintenance/maintenance-data-model';

@Component({
    selector: 'app-filter-selection-form',
    templateUrl: './filter-selection-form.component.html',
    styleUrls: ['../filter-selection.component.scss']
})

export class FilterSelectionFormComponent implements OnInit {

    customPatterns = new CustomPatterns();
    maintenanceDataModel = new MaintenanceDataModel();
    queryList = this.maintenanceDataModel.gridSettingsModel.definition;
    isLoading = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<FilterSelectionFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        this.queryList = data;
    }

    ngOnInit() {
    }

    onSubmit(form: boolean): void {
        if (form) {
            this.isLoading = true;
            this.queryList.isActive = true;
            this.commonService.saveDefinition(this.queryList)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    if (this.queryList.definitionId) {
                        this.customToastrService.success(CommonConstants.definitionForm.updateFormSuccessMessage);
                    } else {
                        this.customToastrService.success(CommonConstants.definitionForm.addFormSuccessMessage);
                    }
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}
