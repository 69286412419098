import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { CommonDataModel } from '../common-data-model';
import { ShipmentAddressComponent } from '../shipment-address/shipment-address.component';

@Component({
    selector: 'app-shipment-bol-instructions',
    templateUrl: './shipment-bol-instructions.component.html',
    styleUrls: ['./shipment-bol-instructions.component.scss']
})

export class ShipmentBolInstructionsComponent implements OnInit {

    @ViewChild('shipmentAddress', { static: false }) addressComponent: ShipmentAddressComponent;
    @Output() updateInstructions: EventEmitter<any> = new EventEmitter<any>();
    commonDataModel = new CommonDataModel();
    shipmentBookingList = Object.assign({}, this.commonDataModel.shipmentBookingListModel);
    bolInstruction: string;
    numberToDisplay: number;
    shipmentInfo: any;
    shipperText: any;
    conigneeText: any;
    removeShipperInstruction = false;
    removeConsigneeInstruction = false;

    @Input() set childData(data: any) {
        this.shipmentInfo = data;
    }

    @Input() set componentNo(data: number) {
        this.numberToDisplay = data;
    }

    constructor() {
    }

    ngOnInit() {
    }

    onInstructionChanges(event: any) {
        const consigneeLen = 'Consignee:';
        const shipperLen = 'Shipper:';
        const text = event;
        const index1 = text.indexOf(shipperLen);
        const index2 = text.indexOf(consigneeLen);
        this.removeConsigneeInstruction = false;
        this.removeShipperInstruction = false;
        if (index1 !== -1 && index2 !== -1 && index1 > index2) {
            const txt = text.slice(index2 + consigneeLen.length, index1);
            this.conigneeText = txt.trimEnd();
            this.updateLocationInstructions();
        } else if (index2 !== -1) {
            const txt = text.slice(index2 + consigneeLen.length);
            this.conigneeText = txt;
        } else {
            this.removeConsigneeInstruction = true;
            this.updateLocationInstructions();
        }
        if (index1 !== -1 && index2 !== -1 && index2 > index1) {
            const txt = text.slice(index1 + shipperLen.length, index2);
            this.shipperText = txt.trimEnd();
            this.updateLocationInstructions();
        } else if (index1 !== -1) {
            const txt = text.slice(index1 + shipperLen.length);
            this.shipperText = txt;
            this.updateLocationInstructions();
        } else {
            this.removeShipperInstruction = true;
            this.updateLocationInstructions();
        }
    }

    updateLocationInstructions() {
        if (this.removeConsigneeInstruction && this.removeShipperInstruction) {
            this.updateInstructions.emit(null);
        } else {
            const data = {
                shipper: this.shipperText ? this.shipperText : null,
                consignee: this.conigneeText ? this.conigneeText : null,
                instructionUpdated: true
            };
            this.updateInstructions.emit(data);
        }
    }

}
