import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-selection-dialog',
    templateUrl: './shipment-selection-dialog.component.html',
    styleUrls: ['./shipment-selection-dialog.component.scss']
})
export class ShipmentSelectionDialogComponent implements OnInit {

    startDatefrom = new Date();
    selectedDate = new Date();
    commonDataModel = new CommonDataModel();
    moduleName: string;
    pageName: string;
    isFormLoading = false;
    selectionList = this.commonDataModel.copySettingsModel;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonService: CommonService,
        private router: Router,
        public dialogRef: MatDialogRef<ShipmentSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.moduleName = data.module;
        this.pageName = data.page;
        this.isFormLoading = true;
    }

    ngOnInit() {
        this.getDefaultCheckboxSettings();
    }

    getDefaultCheckboxSettings() {
        this.commonService.getCopySettings(this.commonService.getOwnerId()).subscribe((response) => {
            response.map((list: any) => {
                this.selectionList[list.settingName] = list.value;
            });
            this.isFormLoading = false;
        });
    }

    onSelectionChange(event: any, objectName: any) {
        if (objectName !== 'pickup date') {
            this.selectionList[objectName] = event.checked;
        }
    }

    onCopyShipment() {
        this.commonService.setVisitedTab(null);
        this.commonService.setCopyShipmentNavigation(true);
        if (this.moduleName === CommonConstants.moduleName.LTLVendorBooking || this.moduleName === CommonConstants.moduleName.TLVendorBooking) {
            this.selectionList.SN_CopyEquipmentByDefault = false;
        }
        this.selectionList.pickupDate = this.selectedDate;
        this.commonService.sendCopyShipment(this.selectionList);
        switch (this.moduleName) {
            case CommonConstants.moduleName.scheduleLTL:
                this.router.navigate(['app/shipment/shipment-booking/schedule-shipment/' + this.data.id]);
                this.onClose();
                break;
            case CommonConstants.moduleName.scheduleTruckload:
                this.router.navigate(['app/shipment/truckload-booking/schedule-truckload/' + this.data.id]);
                this.onClose();
                break;
            case CommonConstants.moduleName.LTLVendorBooking:
                if (this.pageName === 'Tracking') {
                    this.router.navigate(['app/shipment/shipment-booking/schedule-shipment/' + this.data.id]);
                } else {
                    this.router.navigate(['app/ltl-vendor/booking/' + this.data.id]);
                }
                this.onClose();
                break;
            case CommonConstants.moduleName.TLVendorBooking:
                if (this.pageName === 'Tracking') {
                    this.router.navigate(['app/shipment/truckload-booking/schedule-truckload/' + this.data.id]);
                } else {
                    this.router.navigate(['app/tl-vendor/booking/' + this.data.id]);
                }
                this.onClose();
                break;
            default:
                break;
        }
    }

    onClose() {
        this.dialogRef.close();
    }

}
