// export const environment = {
//     production: false,
//     baseUrl: 'http://172.30.238.30:5000/api/',
//     // appUrl: 'http://localhost:4400/',
//     appUrl: 'http://172.30.238.30:4200/',
//     authority: 'http://172.30.238.30:5001',
//     instanceName: 'Development',
//     ipAddress: 'http://api.ipify.org/?format=json',
//     recaptchaSiteKey: '6LeQePwUAAAAAHRmHK7rsIrXwaAptx4HZQ80mXIA',
//     recaptchaSiteKeyV2: '6LdMnB0iAAAAAO44NzbwH5ZBLrkwsj6ZN-SQzo-v',
//     googleAPIKey: "AIzaSyA3tv7L5Dgna58SAsuXYjMIKmEVxiMVwi8"
// };

export const environment = {
    production: true,
    baseUrl: 'https://vp-gateway-service-prod.azurewebsites.net/api/',
    appUrl: 'https://vp.translogisticsinc.com/',
    authority: 'https://vp-identity.translogisticsinc.com',
    instanceName: 'Production',
    ipAddress: 'https://api.ipify.org/?format=json',
    recaptchaSiteKey: '6Le9pPoUAAAAAFnOjDzCITl-9Zz_UQKjm8bNq7j1',
    recaptchaSiteKeyV2: '6LdMnB0iAAAAAO44NzbwH5ZBLrkwsj6ZN-SQzo-v',
    googleAPIKey: "AIzaSyA4avBbCeDLtBC15kIoTfkCiZqiONUJWCU"
};
