import { Component, Inject, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { AppComponentBase } from '../app-component-base';
import { CommonDataModel } from '../common-data-model';
import { LTLVendorService } from 'src/app/ltl-vendor/ltl-vendor.service';

@Component({
    selector: 'app-shipment-item-search',
    templateUrl: './shipment-item-search.component.html',
    styleUrls: ['./shipment-item-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentItemSearchComponent extends AppComponentBase implements OnInit {

    @ViewChild('kg', { static: true }) private grid: GridComponent;
    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    searchItemModel = Object.assign({}, this.commonDataModel.searchItemsModel);
    retainSearchItemModel = Object.assign({}, this.commonDataModel.searchItemsModel);
    selectedList: any;
    shipmentItemData: any;
    densityCheckBox = false;
    rowSelectionList: number[] = [];
    hideDensityFilter: any;
    moduleName: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        injector: Injector,
        private commonService: CommonService,
        private LTLVendorBookingService: LTLVendorService,
        private dialogRef: MatDialogRef<ShipmentItemSearchComponent>,
        @Inject(MAT_DIALOG_DATA) searchQuery?: any) {
        super(injector);
        this.moduleName = searchQuery.moduleName;
        delete searchQuery.moduleName;
        this.commonService.objectMapping(this.searchItemModel, searchQuery);
        this.commonService.objectMapping(this.retainSearchItemModel, searchQuery);
        this.densityCheckBox = this.retainSearchItemModel.density ? true : false;
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.commonService.getValueToShowFilter.subscribe((response: any) => {
            this.hideDensityFilter = response;
        }));
        this.onRefreshGrid();
    }

    onRefreshGrid() {
        this.kendogridHelper.showLoadingIndicator();
        this.grid.dataStateChange.emit({
            skip: 0,
            take: this.kendogridHelper.defaultRecordsCountPerPage,
            sort: this.kendogridHelper.sort,
            filter: undefined,
            group: undefined
        });
    }

    getGridRecords(event: DataStateChangeEvent) {
        if (event) {
            this.kendogridHelper.dataStateChangeEvent(event);
        }
        this.kendogridHelper.showLoadingIndicator();
        if (event.sort && event.sort.length) {
            this.searchItemModel.orderBy = event.sort[0].field;
            this.searchItemModel.sortByAscending = (event.sort[0].dir === 'asc' ? true : false);
        }
        this.searchItemModel.results = event.take;
        this.searchItemModel.skipCount = event.skip;
        switch (this.moduleName) {
            case CommonConstants.moduleName.LTLVendorBooking:
                this.LTLVendorBookingService.getSearchLTLVendorItems(this.searchItemModel)
                    .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
                    .subscribe(response => {
                        this.shipmentItemData = response.items;
                        this.kendogridHelper.setGridData(response);
                    });
                break;

            default:
                this.commonService.getSearchItem(this.searchItemModel)
                    .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
                    .subscribe(response => {
                        this.shipmentItemData = response.items;
                        this.kendogridHelper.setGridData(response);
                    });
                break;
        }
    }

    onSearchFilter(searchInput: string): void {
        if (searchInput && searchInput.length >= 3) {
            this.onRefreshGrid();
        } else {
            if (!searchInput) {
                this.onRefreshGrid();
            }
        }
    }

    onClearSearch(): void {
        this.searchItemModel.searchFor = null;
        this.onRefreshGrid();
    }

    onCellClickHandler(list: any): void {
        this.selectedList = list.dataItem;
        this.onItemSelection();
    }

    onChangeDensity(value: boolean): void {
        if (value) {
            this.searchItemModel.density = this.retainSearchItemModel.density;
        } else {
            this.searchItemModel.density = null;
        }
        this.onRefreshGrid();
    }

    onSubmit(): void {
        if (Array.isArray(this.rowSelectionList) && this.rowSelectionList.length) {
            this.selectedList = this.shipmentItemData.find(list => list.id === this.rowSelectionList[0]);
            this.onItemSelection();
        }
    }

    onItemSelection(): void {
        this.selectedList.preserveCheckBox = this.retainSearchItemModel.preserveCheckBox;
        this.dialogRef.close({ itemResult: this.selectedList });
    }

    onClose(): void {
        this.dialogRef.close({ itemResult: {} });
    }

}
