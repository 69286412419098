import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss']
})

export class InformationDialogComponent implements OnInit {

    inputData: any;
    cancelButon: any;

    constructor(
        private dialogRef: MatDialogRef<InformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: string) {
        this.inputData = data;
    }

    ngOnInit() {
    }

    onClose(selection: any) {
        this.dialogRef.close({ confirmResult: selection });
    }

}
