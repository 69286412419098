import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { GridDefinitionEnum } from 'src/app-core/enums/common-enum';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { MaintenanceDataModel } from 'src/app/maintenance/maintenance-data-model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FilterSelectionFormComponent } from './filter-selection-form/filter-selection-form.component';

@Component({
    selector: 'app-filter-selection',
    templateUrl: './filter-selection.component.html',
    styleUrls: ['./filter-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FilterSelectionComponent implements OnInit {

    @ViewChild('dropdownlist', { static: true }) dropdownlist: any;
    @Output() filterEvent = new EventEmitter<any>();
    maintenanceDataModel = new MaintenanceDataModel();
    queryList = this.maintenanceDataModel.gridSettingsModel;
    selectedValue: any;
    allowToEdit = false;
    definitionList: Array<any> = [];
    gridDefinitionEnum: typeof GridDefinitionEnum = GridDefinitionEnum;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set childData(gridSettings: any) {
        this.queryList = gridSettings;
        this.allowToEdit = this.queryList.definition.allowToEdit;
    }

    constructor(
        private matDialog: MatDialog,
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
    }

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };

    ngOnInit() {
        this.loadItems();
    }

    loadItems(): void {
        this.commonService.getDefinitionList(this.queryList.definition.gridName).subscribe(response => {
            this.definitionList = response;
            if (this.definitionList && this.definitionList.length) {
                const data = this.definitionList.find(list => list.isDefault);
                if (data !== undefined) {
                    this.selectedValue = data.id;
                    this.filterEvent.emit(data.id);
                } else {
                    this.selectedValue = '';
                    this.filterEvent.emit(GridDefinitionEnum.clearDefinition);
                }
            } else {
                this.filterEvent.emit(GridDefinitionEnum.clearDefinition);
            }
        });
    }

    onValueChange(value: any): void {
        if (value === undefined) {
            this.allowToEdit = false;
            this.filterEvent.emit(GridDefinitionEnum.clearDefinition);
        }
    }

    onSelectDefinition(dataItem: any): void {
        this.allowToEdit = dataItem.allowToEdit;
        this.filterEvent.emit(dataItem.id);
    }

    onShowModal(value: string): void {
        let data: any;
        const tempData = Object.assign({}, this.queryList.definition);
        if (value === CommonConstants.appConstant.addForm) {
            tempData.id = null;
            tempData.definitionId = null;
            tempData.definitionName = null;
            tempData.definitionShortName = null;
            tempData.isDefault = false;
            tempData.isPublic = false;
            data = tempData;
        } else {
            data = Object.assign({}, this.queryList.definition);
        }
        const dialogRef = this.matDialog.open(FilterSelectionFormComponent, {
            position: {
                right: '0',
            },
            panelClass: 'sm-dialog-wrapper',
            hasBackdrop: false,
            data: data || undefined
        });
        dialogRef
            .afterClosed()
            .subscribe((result: { confirmResult: boolean }) => {
                if (result.confirmResult) {
                    this.loadItems();
                }
            });
    }

    onRemoveFilter(): void {
        this.selectedValue = '';
        this.filterEvent.emit(GridDefinitionEnum.removeOnlyFilter);
    }

    onDefaultDefinition(definition: any): void {
        const active = definition.isDefault ? false : true;
        const data = {
            definitionId: definition.id,
            userId: definition.userId,
            gridName: definition.gridName,
            isActive: active
        };
        this.commonService.defaultDefinition(data).subscribe(response => {
            this.loadItems();
            this.customToastrService.success(CommonConstants.definitionForm.defaultDefinitionSuccessMessage);
        });
    }

    onDeleteDefinition(definitionId: string): void {
        const confirmText = CommonConstants.definitionForm.deletePopupTitle;
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: confirmText || undefined,
        });
        dialogRef
            .afterClosed()
            .subscribe((result: { confirmResult: boolean }) => {
                if (result.confirmResult) {
                    this.commonService.deleteDefinitionList({ id: definitionId }).subscribe(response => {
                        this.customToastrService.success(CommonConstants.definitionForm.deleteSuccessMessage);
                        this.loadItems();
                        this.selectedValue = '';
                    });
                }
            });
    }

}
