import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { ZipCodeRegex } from 'src/app-core/pattern/zip-code-regex';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from 'src/app/shared/common-data-model';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ShipmentAddressBookComponent } from '../shipment-address-book/shipment-address-book.component';
import { ShipmentAddressFormComponent } from '../shipment-address-form/shipment-address-form.component';
import { ShipmentAddressSaveComponent } from '../shipment-address-save/shipment-address-save.component';
import { ShipmentRateComponent } from '../shipment-rate/shipment-rate.component';

@Component({
    selector: 'app-shipment-address',
    templateUrl: './shipment-address.component.html',
    styleUrls: ['./shipment-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentAddressComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('shipper', { static: true }) shipperScrollContainer: MatAutocomplete;
    @ViewChild('consignee', { static: true }) consigneeScrollContainer: MatAutocomplete;
    @ViewChild('shipperForm', { static: true }) shipperForm: any;
    @ViewChild('consigneeForm', { static: true }) consigneeForm: any;
    @ViewChild('shipmentRates', { static: true }) ratesComponent: ShipmentRateComponent;
    @ViewChild('shipmentOriginFormComponent', { static: true }) shipmentOriginFormComponent: ShipmentAddressFormComponent;
    @ViewChild('shipmentDestinationFormComponent', { static: true }) shipmentDestinationFormComponent: ShipmentAddressFormComponent;
    @ViewChild('shipperCode', { read: MatAutocompleteTrigger, static: false }) shipperCode: MatAutocompleteTrigger;
    @ViewChild('consigneeCode', { read: MatAutocompleteTrigger, static: false }) consigneeCode: MatAutocompleteTrigger;
    componentSubscriptions: Subscription = new Subscription();
    customPatterns = new CustomPatterns();
    commonDataModel = new CommonDataModel();
    zipListModel = this.commonDataModel.zipListModel;
    searchLocationList = this.commonDataModel.searchLocationModel;
    defaultLocationList = this.commonDataModel.defaultLocationList;
    shipmentSettingsModel = this.commonDataModel.shipmentSettingsModel;
    duplicateLocationModel = this.commonDataModel.duplicateLocationModel;
    originAddressList = Object.assign({}, this.commonDataModel.shipmentBookingListModel.originAddress);
    destinationAddressList = Object.assign({}, this.commonDataModel.shipmentBookingListModel.destinationAddress);
    addressForm: any;
    expandForm = true;
    isLoading = false;
    paramId: string;
    addressSwapped = false;
    onClickShipper = false;
    onClickConsignee = false;
    todayDate = this.commonService.setTodayDate();
    pickupDate = new Date();
    pickupFromTime = new Date();
    pickupToTime = new Date();
    deliveryDate = new Date();
    deliveryFromTime = new Date();
    deliveryToTime = new Date();
    checkOrginTime = false;
    checkDestinationTime = false;
    moduleName: string;
    countryList: Array<any> = [];
    defaultLocations: Array<any> = [];
    originLocationCode: string;
    destinationLocationCode: string;
    tempLocationId: string;
    addressAutoCollapse: boolean;
    zipCodeRegex: ZipCodeRegex = new ZipCodeRegex();
    truckloadModuleName = CommonConstants.moduleName.scheduleTruckload;
    latestPickupDate: any;
    earliestPickupDate: any;
    latestDelieveryDate: any;
    earliestDelieveryDate: any;
    minScheduledDate: any;
    rangeValidation: any;
    checkResidentialFlag = true;
    residentialAccess = false;
    updateShipInstruction = false;
    updateRecieveInstruction = false;
    changeInstructions = true;
    shipperCheckboxState = 'Unknown';
    consigneeCheckboxState = 'Unknown';
    residentialChecboxUpdated = false;
    timeOutArray = [];
    locationList = [];
    skipCount = 0;
    resultsPerPage = 20;
    locationCode: any;
    locationType: any;
    shipperLocationCode: any;
    consigneeLocationCode: any;
    selectedLocation = this.commonDataModel.addressBookModel;
    allLocationList: any;
    currentDate = new Date();
    tenderTimeExceeded = false;
    disabledMinTenderCheck = false;
    jumpToNewDate: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set originAddressData(data: any) {
        this.originAddressList = data;
        if (this.originAddressList.shippingStartTime) {
            this.pickupDate = new Date(this.originAddressList.shippingStartTime);
            this.pickupFromTime = new Date(this.originAddressList.shippingStartTime);
            this.pickupToTime = new Date(this.originAddressList.shippingEndTime);
            if (this.paramId === CommonConstants.paramName.scheduleShipment) {
                this.onChecktTenderTime();
            }
        } else {
            this.prepareDateFormats();
        }
    }
    @Input() set destinationAddressData(data: any) {
        this.destinationAddressList = data;
        if (this.destinationAddressList.receivingStartTime) {
            this.deliveryDate = this.destinationAddressList.receivingStartTime;
            this.deliveryFromTime = new Date(this.destinationAddressList.receivingStartTime);
            this.deliveryToTime = new Date(this.destinationAddressList.receivingEndTime);
        } else {
            this.prepareDateFormats();
        }
    }
    @Input() set formStatus(formList: object) {
        this.addressForm = formList;
    }
    @Input() set module(value: any) {
        this.moduleName = value;
    }
    @Input() set defaultLocation(value: Array<any>) {
        this.defaultLocations = value;
    }
    @Input() set settings(settingsList: any) {
        this.shipmentSettingsModel = settingsList;
    }
    @Input() set searchedAddressInstruction(value: any) {
        this.changeInstructions = value;
    }
    @Output() checkResidential: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() resetRequiredServices: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private customToastrService: CustomToastrService) {
        this.searchLocationList.ownerId = this.commonService.getOwnerId();
        this.addressAutoCollapse = this.commonService.getIsAddressCollapse();
        this.componentSubscriptions.add(this.commonService.checkTenderTimeValue.subscribe((value) => {
            if (value) {
                const currentDateTime = moment();
                const getCurrentTime = currentDateTime.format("HH:mm");
                if (this.shipmentSettingsModel.LatestTenderTime && !this.tenderTimeExceeded && getCurrentTime > this.shipmentSettingsModel.LatestTenderTime) {
                    this.jumpToNextDay();
                } else {
                    this.isLoading = false;
                }
            }
        }))
    }

    ngOnInit() {
        this.isLoading = false;
        if (this.commonService.permissionCheckByName(CommonConstants.residentialLocationAccess.residentialAddressAccess)) {
            this.residentialAccess = true;
        }
        this.componentSubscriptions.add(this.activatedRoute.firstChild.paramMap.subscribe(params => {
            this.paramId = params.get('id');
            if (this.paramId === CommonConstants.paramName.new) {
                this.loadItems();
            }
        }));
        this.componentSubscriptions.add(this.shipperForm.statusChanges.subscribe(result => {
            this.sendingAddressList(this.shipperForm.valid, this.consigneeForm.valid);
        }));
        this.componentSubscriptions.add(this.consigneeForm.statusChanges.subscribe(result => {
            this.sendingAddressList(this.shipperForm.valid, this.consigneeForm.valid);
        }));
        this.commonService.getCountryList(this.moduleName).subscribe(response => {
            this.countryList = response;
            this.originAddressList.loggedInOwnerId = this.commonService.getLoggedInOwnerId();
            this.destinationAddressList.loggedInOwnerId = this.commonService.getLoggedInOwnerId();
            this.isLoading = false;
        });
        this.componentSubscriptions.add(this.commonService.locationInstructions.subscribe((response: any) => {
            if (response) {
                this.updateShipInstruction = response ? !response.shipper : false;
                this.updateRecieveInstruction = response ? !response.consignee : false;
            } else {
                this.updateShipInstruction = false;
                this.updateRecieveInstruction = false;
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.commonService.checkForChanges(changes)) {
            if (changes.settings) {
                this.initiateDatesForDefaultLocation();
            } else {
                this.loadItems();
            }
        }
    }

    ngAfterViewInit(): void {
        this.commonService.autocomplete.subscribe((response) => {
            if (this.shipperCode.panelOpen) {
                this.shipperCode.closePanel();
            }
            if (this.consigneeCode.panelOpen) {
                this.consigneeCode.closePanel();
            }
        });
    }

    loadItems(): void {
        if (this.paramId && this.paramId === CommonConstants.paramName.new) {
            if (this.defaultLocations && this.defaultLocations.length > 0) {
                const defaultShipLocation = this.defaultLocations.find(x => x.locationType ===
                    CommonConstants.locationSettingsName.defaultShipLocation);
                if (defaultShipLocation) {
                    const orginLocationId = defaultShipLocation.id;
                    this.originLocationCode = defaultShipLocation.locationCode;
                    this.commonService.objectMapping(this.originAddressList, defaultShipLocation);
                    if (Array.isArray(defaultShipLocation.contacts) && defaultShipLocation.contacts.length) {
                        const contacts = defaultShipLocation.contacts.find(list => list.isDefault);
                        if (contacts) {
                            this.commonService.objectMapping(this.originAddressList, contacts);
                            this.originAddressList.contactId = contacts.id;
                            this.originAddressList.id = orginLocationId;
                        }
                        this.checkOrginTime = true;
                        this.defaultLocationList.originAddress.shippingStartTime = defaultShipLocation.shippingStartTime;
                        this.defaultLocationList.originAddress.shippingEndTime = defaultShipLocation.shippingEndTime;
                    }
                    if (this.originAddressList.latitude === 0 && this.originAddressList.latitude === 0) {
                        this.getGeoLocations(
                            CommonConstants.addressType.originAddressList,
                            this.originAddressList.zipCode, this.originAddressList.countryCode
                        );
                    }
                    this.originAddressList.updatedShipInstruction = defaultShipLocation.shipInstructions;
                    this.shipmentOriginFormComponent.zipCodePattern = this.zipCodeRegex[this.originAddressList.countryCode];
                    this.triggerReferenceFactors(CommonConstants.addressType.originAddressList);
                } else {
                    this.originAddressList.ownerId = this.commonService.getOwnerId();
                    this.originAddressList.ownerName = this.commonService.getOwnerName();
                }

                this.timeOutArray.push(setTimeout(() => {
                    if (!this.residentialAccess) {
                        this.commonService.interceptorSubject.next(true);
                    }

                    switch (this.moduleName) {
                        case CommonConstants.moduleName.bolOnly:
                            this.saveResidentialAddress(this.checkBOLonlyShipperForm(), 'shipperlocation', this.originAddressList, 'originLocationCode');
                            break;
                        default:
                            this.saveResidentialAddress(this.shipperForm.valid, 'shipperlocation', this.originAddressList, 'originLocationCode');
                            break;
                    }
                }, 2000));

                const defaultConsigneeLocation = this.defaultLocations.find(x => x.locationType ===
                    CommonConstants.locationSettingsName.defaultConsLocation);

                if (defaultConsigneeLocation) {
                    const destinationLocationId = defaultConsigneeLocation.id;
                    this.destinationLocationCode = defaultConsigneeLocation.locationCode;
                    this.commonService.objectMapping(this.destinationAddressList, defaultConsigneeLocation);
                    if (Array.isArray(defaultConsigneeLocation.contacts) && defaultConsigneeLocation.contacts.length) {
                        const contacts = defaultConsigneeLocation.contacts.find(list => list.isDefault);
                        if (contacts) {
                            this.commonService.objectMapping(this.destinationAddressList, contacts);
                            this.destinationAddressList.contactId = contacts.id;
                            this.destinationAddressList.id = destinationLocationId;
                        }
                        this.checkDestinationTime = true;
                        this.defaultLocationList.destinationAddress.receivingStartTime = defaultConsigneeLocation.receivingStartTime;
                        this.defaultLocationList.destinationAddress.receivingEndTime = defaultConsigneeLocation.receivingEndTime;
                    }
                    if (this.destinationAddressList.latitude === 0 && this.destinationAddressList.latitude === 0) {
                        this.getGeoLocations(CommonConstants.addressType.destinationAddressList, this.destinationAddressList.zipCode,
                            this.destinationAddressList.countryCode);
                    }
                    this.destinationAddressList.updatedConsigneeInstruction = defaultConsigneeLocation.receiveInstructions;
                    this.shipmentDestinationFormComponent.zipCodePattern = this.zipCodeRegex[this.destinationAddressList.countryCode];
                    this.triggerReferenceFactors(CommonConstants.addressType.destinationAddressList);
                } else {
                    this.destinationAddressList.ownerId = this.commonService.getOwnerId();
                    this.destinationAddressList.ownerName = this.commonService.getOwnerName();
                }
                this.timeOutArray.push(setTimeout(() => {
                    if (!this.residentialAccess) {
                        this.commonService.interceptorSubject.next(true);
                    }
                    switch (this.moduleName) {
                        case CommonConstants.moduleName.bolOnly:
                            this.saveResidentialAddress(this.checkBOLonlyConsigneeForm(), 'consigneelocation', this.destinationAddressList, 'destinationLocationCode');
                            break;
                        default:
                            this.saveResidentialAddress(this.consigneeForm.valid, 'consigneelocation', this.destinationAddressList, 'destinationLocationCode');
                            break;
                    }
                }, 2000));
                this.initiateDates(true);
            } else {
                this.timeOutArray.push(setTimeout(()=>{
                    this.mappingOwnerId(true);
                },1000));
            }
        } else if (this.paramId === CommonConstants.paramName.scheduleShipment || this.paramId === CommonConstants.paramName.scheduleTruckLoad) {
            this.mappingOwnerId(true);
        } else {
            // this.initiateDates();
        }
    }

    initiateDatesForDefaultLocation() {
        if (this.paramId && this.paramId === CommonConstants.paramName.new) {
            if (this.defaultLocations && this.defaultLocations.length > 0) {
                this.checkOrginTime = true;
                this.checkDestinationTime = true;
                this.initiateDates(true);
            } else {
                this.checkOrginTime = false;
                this.checkDestinationTime = false;
                this.mappingOwnerId();
            }
        } else if (this.paramId === CommonConstants.paramName.scheduleShipment || this.paramId === CommonConstants.paramName.scheduleTruckLoad) {
            this.mappingOwnerId(true);
        } else {
            // this.initiateDates();
        }
    }

    mappingOwnerId(checkTenderTimeLimit?: boolean): void {
        this.originAddressList.ownerId = this.commonService.getOwnerId();
        this.originAddressList.ownerName = this.commonService.getOwnerName();
        this.destinationAddressList.ownerId = this.commonService.getOwnerId();
        this.destinationAddressList.ownerName = this.commonService.getOwnerName();
        this.originAddressList.loggedInOwnerId = this.commonService.getLoggedInOwnerId();
        this.destinationAddressList.loggedInOwnerId = this.commonService.getLoggedInOwnerId();
        this.initiateDates(checkTenderTimeLimit);
    }

    initiateDates(checkTenderTime?: boolean): void {
        this.deliveryDate = this.commonService.setTomorrowDate();
        this.deliveryFromTime = this.commonService.setDeafultFromTime();
        this.deliveryToTime = this.commonService.setDeafultToTime();
        if (this.paramId && this.paramId === CommonConstants.paramName.new) {
            this.pickupDate = this.commonService.setTodayDate();
            this.pickupFromTime = this.commonService.setDeafultFromTime();
            this.pickupToTime = this.commonService.setDeafultToTime();
            if (this.checkOrginTime && this.defaultLocationList.originAddress.shippingStartTime) {
                this.pickupFromTime = new Date(this.defaultLocationList.originAddress.shippingStartTime);
                this.pickupToTime = new Date(this.defaultLocationList.originAddress.shippingEndTime);
            } else {
                if (this.shipmentSettingsModel.DefaultShipStartTime) {
                    this.pickupFromTime = new Date(this.shipmentSettingsModel.DefaultShipStartTime);
                }
                if (this.shipmentSettingsModel.DefaultShipEndTime) {
                    this.pickupToTime = new Date(this.shipmentSettingsModel.DefaultShipEndTime);
                }
            }
            if (this.checkDestinationTime && this.defaultLocationList.destinationAddress.receivingStartTime) {
                this.deliveryFromTime = new Date(this.defaultLocationList.destinationAddress.receivingStartTime);
                this.deliveryToTime = new Date(this.defaultLocationList.destinationAddress.receivingEndTime);
            } else {
                if (this.shipmentSettingsModel.DefaultDeliverStartTime) {
                    this.deliveryFromTime = new Date(this.shipmentSettingsModel.DefaultDeliverStartTime);
                }
                if (this.shipmentSettingsModel.DefaultDeliverEndTime) {
                    this.deliveryToTime = new Date(this.shipmentSettingsModel.DefaultDeliverEndTime);
                }
            }
        }
        this.prepareDateFormats(checkTenderTime);
    }

    sendingAddressList(shipperForm: boolean, consigneeForm: boolean): void {
        if (!this.isRequired() ||
            (shipperForm && consigneeForm && this.originAddressList.stateCode && this.destinationAddressList.stateCode)) {
            this.addressForm.addressForm = true;
        } else {
            this.addressForm.addressForm = false;
        }
    }

    triggerReferenceFactors(listName: string): void {
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.locationCode);
        if (listName === CommonConstants.addressType.originAddressList) {
            this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.shipperLocationCode);
        } else {
            this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.consigneeLocationCode);
        }
    }

    onChangeLocationCode(locationCode: string, listName: string): void {
        this.locationType = listName;
        this.locationCode = locationCode;
        this.skipCount = 0;
        if (!locationCode) {
            this.onClearLocationCode(listName);
        }
        if (locationCode && locationCode.length >= 3) {
            this.getLocationList();
        }
    }

    onClearLocationCode(listName: string): void {
        this[listName].id = null;
        this[listName].locationCode = null;
        this.locationCode = null;
        this.skipCount = 0;
        this.locationList = [];
        this.onTriggerAllReferenceSettings();
    }

    onSaveLocation(form: boolean, gateWay: string, listName: string, locationCode: string) {
        if (form) {
            this.tempLocationId = this[listName].id;
            if (this[listName].stateCode) {
                if (this[locationCode] !== this[listName].locationCode) {
                    this[listName].id = null;
                    this[listName].ownerId = this.commonService.getOwnerId();
                    this[listName].ownerName = this.commonService.getOwnerName();
                }
                if (this[listName].id) {
                    this.showLocationConformation(gateWay, listName, locationCode);
                } else {
                    let locationData: any;
                    if (gateWay === 'shipperlocation') {
                        locationData = Object.assign({}, this.originAddressList);
                    } else {
                        locationData = Object.assign({}, this.destinationAddressList);
                    }
                    this.commonService.objectMapping(this.duplicateLocationModel, locationData);
                    this.commonService.checkDuplicateLocation(this.duplicateLocationModel).subscribe(response => {
                        this.isLoading = false;
                        const responseData = Object.assign({}, response);
                        if (responseData.isDuplicate) {
                            this.showDuplicateLocationPopup(gateWay, listName, responseData, locationCode);
                        } else {
                            this.showLocationConformation(gateWay, listName, locationCode);
                        }
                    });
                }
            } else {
                this.customToastrService.error(CommonConstants.appConstant.zipCodeErrorMessage);
            }
        } else {
            if (gateWay === 'shipperlocation') {
                if (!this.shipperForm.controls.earliestPickupDate.value) {
                    this.shipperForm.controls.earliestPickupDate.setErrors({ incorrect: true });
                    this.shipperForm.controls.earliestPickupDate.markAsDirty();
                }
                if (!this.shipperForm.controls.latestPickupDate.value) {
                    this.shipperForm.controls.latestPickupDate.setErrors({ incorrect: true });
                    this.shipperForm.controls.latestPickupDate.markAsDirty();
                }
            } else if (gateWay === 'consigneelocation') {
                if (!this.consigneeForm.controls.earliestDeliveryDate.value) {
                    this.consigneeForm.controls.earliestDeliveryDate.setErrors({ incorrect: true });
                    this.consigneeForm.controls.earliestDeliveryDate.markAsDirty();
                }
                if (!this.consigneeForm.controls.latestDeliveryDate.value) {
                    this.consigneeForm.controls.latestDeliveryDate.setErrors({ incorrect: true });
                    this.consigneeForm.controls.latestDeliveryDate.markAsDirty();
                }
            }
        }
    }

    showDuplicateLocationPopup(gateWay: string, listName: string, responseData: any, locationCode: string): void {
        const sendingList = responseData;
        sendingList.addressList = this[listName];
        const dialogRef = this.matDialog.open(ShipmentAddressSaveComponent, {
            panelClass: 'upload-dialog-wrapper',
            hasBackdrop: false,
            data: sendingList
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result.confirmResult) {
                this.saveUpdatedLocationInstructions(gateWay, listName, locationCode);
            }
        });
    }

    checkResidentialAddress(gateWay: string, listName: string, locationCode: string) {
        const dialogObject = {
            residential: true,
            warningMessage: CommonConstants.shipmentBooking.residentailErrorMessage
        };
        this.commonService.interceptorSubject.next(true);
        this.commonService.checkLocation(this[listName]).subscribe((res) => {
            this.isLoading = false;
            if (this[listName].isResidential !== res.results && this[listName].isResidential !== null) {
                const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                    panelClass: 'confirm-dialog-wrapper',
                    hasBackdrop: false,
                    data: dialogObject
                });
                dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
                    if (result.confirmResult) {
                        if (gateWay === 'shipperlocation') {
                            this.originAddressList.isResidential = res.results;
                            this[listName].isResidential = res.results;
                        } else if (gateWay === 'consigneelocation') {
                            this.destinationAddressList.isResidential = res.results;
                            this[listName].isResidential = res.results;
                        }
                        this.saveLocation(gateWay, listName, locationCode);
                    } else {
                        if (result.confirmResult !== null) {
                            this.saveLocation(gateWay, listName, locationCode);
                        }
                    }
                });
            } else {
                if (gateWay === 'shipperlocation' && this.originAddressList.isResidential === null) {
                    this.originAddressList.isResidential = res.results;
                    this[listName].isResidential = res.results;
                } else if (gateWay === 'consigneelocation' && this.destinationAddressList.isResidential === null) {
                    this.destinationAddressList.isResidential = res.results;
                    this[listName].isResidential = res.results;
                }
                if (res.results) {
                    this.customToastrService.success(CommonConstants.shipmentBooking.residentialSuccessMessage);
                } else {
                    if (res.message && !res.result) {
                        this.customToastrService.success(res.message);
                    }
                }
                this.saveLocation(gateWay, listName, locationCode);
            }
        },
            error => {
                this.isLoading = false;
                if (this[listName].isResidential !== error.error.isSuccess && this[listName].isResidential !== null) {
                    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                        panelClass: 'confirm-dialog-wrapper',
                        hasBackdrop: false,
                        data: dialogObject
                    });
                    dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
                        if (result.confirmResult) {
                            if (gateWay === 'shipperlocation') {
                                this.originAddressList.isResidential = error.error.results;
                                this[listName].isResidential = error.error.results;
                            } else if (gateWay === 'consigneelocation') {
                                this.destinationAddressList.isResidential = error.error.results;
                                this[listName].isResidential = error.error.results;
                            }
                            this.saveLocation(gateWay, listName, locationCode);
                        } else {
                            if (result.confirmResult !== null) {
                                this.saveLocation(gateWay, listName, locationCode);
                            }
                        }
                    });
                } else {
                    if (gateWay === 'shipperlocation' && this.originAddressList.isResidential === null) {
                        this.originAddressList.isResidential = error.error.results;
                        this[listName].isResidential = error.error.results;
                    } else if (gateWay === 'consigneelocation' && this.destinationAddressList.isResidential === null) {
                        this.destinationAddressList.isResidential = error.error.results;
                        this[listName].isResidential = error.error.results;
                    }
                    this.saveLocation(gateWay, listName, locationCode);
                }
            });
    }

    showLocationConformation(gateWay: string, listName: string, locationCode: string): void {
        const confirmText = this[listName].id ?
            CommonConstants.addressForm.popupUpdateFormTitle + '<b>' + this[listName].ownerName + '</b>' +
            CommonConstants.addressForm.level : CommonConstants.addressForm.popupAddFormTitle + '<b>' +
            this[listName].ownerName + '</b> ' + CommonConstants.addressForm.level;
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: confirmText || undefined
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result.confirmResult) {
                this.saveUpdatedLocationInstructions(gateWay, listName, locationCode);
            } else {
                if (!this[listName].id) {
                    this[listName].id = this.tempLocationId;
                }
            }
        });
    }

    saveUpdatedLocationInstructions(gateWay: string, listName: string, locationCode: string) {
        const shipInstructionsUpdated = _.isEqual(this.originAddressList.shipInstructions, this.originAddressList.updatedShipInstruction);
        const receiveInstructionsUpdated = _.isEqual(this.destinationAddressList.receiveInstructions, this.destinationAddressList.updatedConsigneeInstruction);
        if ((gateWay === 'shipperlocation' && !shipInstructionsUpdated) || (gateWay === 'consigneelocation' && !receiveInstructionsUpdated)) {
            const locationInstructionData = {
                warningMessage: CommonConstants.shipmentBooking.locationInstructionsMessage,
                locationInstructins: true
            };
            const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog-wrapper',
                hasBackdrop: false,
                data: locationInstructionData || undefined
            });
            dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
                if (result.confirmResult) {
                    if (gateWay === 'shipperlocation') {
                        const data = {
                            shipperLocationCode: this.originAddressList.locationCode,
                            shipInstructions: this.originAddressList.updatedShipInstruction
                        };
                        this.originAddressList.shipInstructions = this.originAddressList.updatedShipInstruction;
                        this.commonService.updateLocationInstruction(data).subscribe((response) => { });
                        this.updateShipInstruction = false;
                    } else if (gateWay === 'consigneelocation') {
                        const data = {
                            consigneeLocationCode: this.destinationAddressList.locationCode,
                            receiveInstructions: this.destinationAddressList.updatedConsigneeInstruction
                        };
                        this.destinationAddressList.receiveInstructions = this.destinationAddressList.updatedConsigneeInstruction;
                        this.commonService.updateLocationInstruction(data).subscribe((response) => { });
                        this.updateRecieveInstruction = false;
                    }
                    this.checkResidentialAccess(gateWay, listName, locationCode);
                } else {
                    if (result.confirmResult !== null) {
                        if (gateWay === 'shipperlocation') {
                            this.updateShipInstruction = false;
                            this.originAddressList.updatedShipInstruction = this.originAddressList.shipInstructions;
                        } else if (gateWay === 'consigneelocation') {
                            this.updateRecieveInstruction = false;
                            this.destinationAddressList.updatedConsigneeInstruction = this.destinationAddressList.receiveInstructions;
                        }
                        this.commonMethod.sendBolInformationData(true);
                        this.checkResidentialAccess(gateWay, listName, locationCode);
                    }
                }
            });
        } else {
            this.checkResidentialAccess(gateWay, listName, locationCode);
        }
    }

    checkResidentialAccess(gateWay: any, listName: any, locationCode: any) {
        if (this.residentialAccess && !this.shipmentSettingsModel.OnCreditHold) {
            this.checkResidentialAddress(gateWay, listName, locationCode);
        } else {
            this.saveLocation(gateWay, listName, locationCode);
        }
    }

    saveLocation(gateWay: string, listName: string, locationCode: string): void {
        this.isLoading = true;
        this.commonService.interceptorSubject.next(false);
        this.commonService.saveLocation('Address/' + gateWay, this[listName])
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                if (response.isSuccess) {
                    this.tempLocationId = null;
                    this[listName].locationCode = this[listName].locationCode ? this[listName].locationCode : response.results.locationCode;
                    this[locationCode] = this[listName].locationCode;
                    this.customToastrService.success(this[listName].id ?
                        CommonConstants.addressForm.updateFormSuccessMessage : CommonConstants.addressForm.addFormSuccessMessage);
                    if (this.originAddressList.locationCode && this.destinationAddressList.locationCode
                        && this.originAddressList.locationCode === this.destinationAddressList.locationCode) {
                        this.originAddressList.id = response.results.locationId;
                        this.destinationAddressList.id = response.results.locationId;
                        this.originAddressList.isShipmentLocation = false;
                        this.destinationAddressList.isShipmentLocation = false;

                        if (this.originAddressList.contactId && this.destinationAddressList.contactId
                            && this.originAddressList.contactId === this.destinationAddressList.contactId) {
                            this.originAddressList.contactId = response.results.contactId;
                            this.destinationAddressList.contactId = response.results.contactId;
                        }

                    } else {
                        this[listName].id = response.results.locationId;
                        this[listName].isShipmentLocation = false;
                        this[listName].contactId = response.results.contactId;
                    }
                    if (!this.residentialAccess) {
                        if (gateWay === 'shipperlocation') {
                            this.originAddressList.isResidential = response.results.isResidential;
                        } else if (gateWay === 'consigneelocation') {
                            this.destinationAddressList.isResidential = response.results.isResidential;
                        }
                    }
                } else {
                    this.customToastrService.error(this[listName].id ?
                        CommonConstants.addressForm.updateFormErrorMessage : CommonConstants.addressForm.addFormErrorMessage);
                }
            });
    }

    onSearchAddressBook(list: any, form: string): void {
        this.commonService.objectMapping(this.searchLocationList, list);
        this.onClickShipper = (form === '1' ? true : false);
        this.onClickConsignee = (form === '2' ? true : false);
        this.searchLocationList.showShipperServices = this.onClickShipper;
        this.searchLocationList.showConsigneeServices = this.onClickConsignee;
        this.searchLocationList.moduleName = this.moduleName;
        const dialogRef = this.matDialog.open(ShipmentAddressBookComponent, {
            panelClass: 'center-dialog-wrapper',
            hasBackdrop: false,
            data: this.searchLocationList || null
        });
        dialogRef.afterClosed().subscribe((result: { addressResult: any }) => {
            if (!this.commonService.isEmptyObject(result.addressResult)) {
                if (this.onClickShipper) {
                    this.mapShipperLocation(result);
                }
                if (this.onClickConsignee) {
                    this.mapConsigneeLocation(result);
                }
                this.commonMethod.sendBolInformationData(true);
                this.commonMethod.sendLocationRequiredServices(true);
                this.prepareDateFormats(true);
            }
        });
    }

    getGeoLocations(listName: string, zipCodeSearch: string, countryCodeSearch: string): void {
        this.commonService.getGeoCode(zipCodeSearch, countryCodeSearch).then(response => {
            if (Array.isArray(response) && response.length) {
                this[listName].latitude = response[0].geometry.location.lat();
                this[listName].longitude = response[0].geometry.location.lng();
            }
        });
    }

    onValidateDates(isPickUpTimeUpdated?: boolean): void {
        if (this.deliveryDate && this.pickupDate) {
            if (this.commonService.convertDate(this.deliveryDate) < this.commonService.convertDate(this.pickupDate)) {
                this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.setErrors({ incorrect: true });
            } else {
                this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.setErrors(null);
            }
            const shipPickFromDate = moment(this.pickupFromTime);
            const shipPickToDate = moment(this.pickupToTime);
            const shippingstartTime = shipPickFromDate.format('HH:mm');
            const shippingendTime = shipPickToDate.format('HH:mm');
            if (shippingendTime < shippingstartTime) {
                this.shipperForm.form.controls.shipperEndTime.setErrors({ incorrect: true });
            } else {
                this.shipperForm.form.controls.shipperEndTime.setErrors(null);
            }

            const consigneePickFromDate = moment(this.deliveryFromTime);
            const consigneePickToDate = moment(this.deliveryToTime);
            const consigneeStartTime = consigneePickFromDate.format('HH:mm');
            const consigneeEndTime = consigneePickToDate.format('HH:mm');
            if (consigneeEndTime < consigneeStartTime) {
                this.consigneeForm.form.controls.consigneeTimeTo.setErrors({ incorrect: true });
            } else {
                this.consigneeForm.form.controls.consigneeTimeTo.setErrors(null);
            }

            if (isPickUpTimeUpdated && shippingendTime >= shippingstartTime) {
                this.onCheckTenderMinTime();
            }
            this.prepareDateFormats();
        }
    }

    onSwapAddress(): void {
        const shipInstruction = this.originAddressList.shipInstructions;
        const consigneeInstruction = this.destinationAddressList.receiveInstructions;
        this.originAddressList.clearedServices = this.originAddressList.receiveServices;
        this.destinationAddressList.clearedServices = this.destinationAddressList.senderServices;
        const originAddress = Object.assign({}, this.originAddressList);
        const destinationAddress = Object.assign({}, this.destinationAddressList);
        this.originAddressList.pickupNotes = destinationAddress.deliveryNotes;
        this.destinationAddressList.deliveryNotes = originAddress.pickupNotes;
        Object.keys(destinationAddress).forEach((key, index) => {
            if (originAddress.hasOwnProperty(key)) {
                this.originAddressList[key] = destinationAddress[key];
                this.destinationAddressList[key] = originAddress[key];
            }
        });
        const originZipList = JSON.parse(JSON.stringify(this.shipmentOriginFormComponent.zipCodeDropDownList));
        const destZipList = JSON.parse(JSON.stringify(this.shipmentDestinationFormComponent.zipCodeDropDownList));
        this.shipmentOriginFormComponent.zipCodeDropDownList = destZipList;
        this.shipmentDestinationFormComponent.zipCodeDropDownList = originZipList;
        this.addressSwapped = true;
        this.shipmentOriginFormComponent.zipCodePattern = this.zipCodeRegex[this.originAddressList.countryCode];
        this.shipmentDestinationFormComponent.zipCodePattern = this.zipCodeRegex[this.destinationAddressList.countryCode];
        this.originAddressList.updatedShipInstruction = consigneeInstruction;
        this.originAddressList.shipInstructions = consigneeInstruction;
        this.destinationAddressList.updatedConsigneeInstruction = shipInstruction;
        this.destinationAddressList.receiveInstructions = shipInstruction;
        this.onTriggerAllReferenceSettings();
        if (!this.updateShipInstruction && !this.updateRecieveInstruction) {
            this.commonMethod.sendBolInformationData(true);
        }
        const shipperServiceList = _.concat(this.originAddressList.receiveServices, this.originAddressList.defaultReceiveServices);
        const consigneeServiceList = _.concat(this.destinationAddressList.receiveServices, this.destinationAddressList.defaultReceiveServices);
        const recieverServiceList = _.compact(shipperServiceList);
        const senderServiceList = _.compact(consigneeServiceList);
        if (this.moduleName !== CommonConstants.moduleName.bolOnly && (recieverServiceList.length || senderServiceList.length)) {
            this.customToastrService.success(CommonConstants.shipmentBooking.resetServiceMessage);
        }

        if (this.originAddressList.shippingStartTime && this.originAddressList.shippingEndTime) {
            this.pickupFromTime = new Date(this.originAddressList.shippingStartTime);
            this.pickupToTime = new Date(this.originAddressList.shippingEndTime);
        } else {
            this.pickupFromTime = this.commonService.setDeafultFromTime();
            this.pickupToTime = this.commonService.setDeafultToTime();
        }

        if (this.destinationAddressList.receivingStartTime && this.destinationAddressList.receivingEndTime) {
            this.deliveryFromTime = new Date(this.destinationAddressList.receivingStartTime);
            this.deliveryToTime = new Date(this.destinationAddressList.receivingEndTime);
        } else {
            this.deliveryFromTime = this.commonService.setDeafultFromTime();
            this.deliveryToTime = this.commonService.setDeafultToTime();
        }
        this.commonMethod.sendLocationRequiredServices(true);
        this.prepareDateFormats(true);
    }

    onClearLocation(listName: string): void {
        this.earliestPickupDate = this.originAddressList.earliestPickupDateTime;
        this.latestPickupDate = this.originAddressList.latestPickupDateTime;
        this.earliestDelieveryDate = this.destinationAddressList.earliestDelieveryDateTime;
        this.latestDelieveryDate = this.destinationAddressList.latestDelieveryDateTime;

        if(this.consigneeForm.form.controls.destinationAddressGroup){
            this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.setErrors(null);
        }

        switch (listName) {
            case CommonConstants.addressType.originAddressList:
                this.tenderTimeExceeded = false;
                this.disabledMinTenderCheck = false;
                this.shipperForm.resetForm({ earliestPickupDate: this.earliestPickupDate, latestPickupDate: this.latestPickupDate });
                this.shipmentOriginFormComponent.zipCodeDropDownList = [];
                this.pickupDate = this.commonService.setTodayDate();
                this.pickupToTime = this.commonService.setDeafultToTime();
                this.pickupFromTime = this.commonService.setDeafultFromTime();
                this.originAddressList.pickupNotes = null;
                this.originAddressList.ownerId = this.commonService.getOwnerId();
                this.originAddressList.ownerName = this.commonService.getOwnerName();
                this.shipmentOriginFormComponent.onCountryChange(CommonConstants.defaultCountryCode);
                this.shipperForm.form.controls.addressGroup.controls.shipperCountryCode.setValue(CommonConstants.defaultCountryCode);
                this.originAddressList.isResidential = null;
                this.originAddressList.clearedServices = [];
                if (!this.updateShipInstruction) {
                    this.originAddressList.shipInstructions = null;
                    this.originAddressList.receiveInstructions = null;
                    this.originAddressList.updatedShipInstruction = null;
                }
                if (this.moduleName !== CommonConstants.moduleName.bolOnly && (!_.isEmpty(this.originAddressList.receiveServices) || !_.isEmpty(this.originAddressList.defaultReceiveServices))) {
                    this.originAddressList.clearedServices = _.concat(this.originAddressList.receiveServices, this.originAddressList.defaultReceiveServices);
                    this.originAddressList.receiveServices = [];
                    this.originAddressList.senderServices = [];
                    this.originAddressList.defaultReceiveServices = [];
                    this.originAddressList.defaultSenderServices = [];
                    this.resetRequiredServices.emit(true);
                    this.customToastrService.successToastr(CommonConstants.shipmentBooking.resetServiceMessage);
                }
                break;
            case CommonConstants.addressType.destinationAddressList:
                this.consigneeForm.resetForm({ earliestDeliveryDate: this.earliestDelieveryDate, latestDeliveryDate: this.latestDelieveryDate });
                this.shipmentDestinationFormComponent.zipCodeDropDownList = [];
                this.deliveryDate = this.commonService.setTomorrowDate();
                this.deliveryFromTime = this.commonService.setDeafultFromTime();
                this.deliveryToTime = this.commonService.setDeafultToTime();
                this.destinationAddressList.pickupNotes = null;
                this.destinationAddressList.ownerId = this.commonService.getOwnerId();
                this.destinationAddressList.ownerName = this.commonService.getOwnerName();
                this.shipmentDestinationFormComponent.onCountryChange(CommonConstants.defaultCountryCode);
                this.consigneeForm.form.controls.addressGroup.controls.consigneeCountryCode.setValue(CommonConstants.defaultCountryCode);
                this.destinationAddressList.isResidential = null;
                this.destinationAddressList.clearedServices = [];
                if (!this.updateRecieveInstruction) {
                    this.destinationAddressList.shipInstructions = null;
                    this.destinationAddressList.receiveInstructions = null;
                    this.destinationAddressList.updatedConsigneeInstruction = null;
                }
                if (this.moduleName !== CommonConstants.moduleName.bolOnly && (!_.isEmpty(this.destinationAddressList.senderServices) || !_.isEmpty(this.destinationAddressList.defaultSenderServices))) {
                    this.destinationAddressList.clearedServices = _.concat(this.destinationAddressList.senderServices, this.destinationAddressList.defaultSenderServices);
                    this.destinationAddressList.senderServices = [];
                    this.destinationAddressList.receiveServices = [];
                    this.destinationAddressList.defaultSenderServices = [];
                    this.destinationAddressList.defaultReceiveServices = [];
                    this.resetRequiredServices.emit(true);
                    this.customToastrService.successToastr(CommonConstants.shipmentBooking.resetServiceMessage);
                }
                break;
        }
        this.commonMethod.sendBolInformationData(true);
        this.prepareDateFormats();
        this.onTriggerAllReferenceSettings();
        this.checkResidentialFlag = true;
    }

    prepareDateFormats(checkTenderTimeLimit?: boolean): void {
        this.checkOrginTime = false;
        this.checkDestinationTime = false;
        this.originAddressList.shippingStartTime =
            this.commonService.convertScheduleDates(this.pickupDate, this.pickupFromTime);
        this.originAddressList.shippingEndTime =
            this.commonService.convertScheduleDates(this.pickupDate, this.pickupToTime);
        this.destinationAddressList.receivingStartTime =
            this.commonService.convertScheduleDates(this.deliveryDate, this.deliveryFromTime);
        this.destinationAddressList.receivingEndTime =
            this.commonService.convertScheduleDates(this.deliveryDate, this.deliveryToTime);
        this.originAddressList.eventStartDateTime =
            this.commonService.getConvertedDateFormat(this.pickupDate, this.pickupFromTime);
        this.destinationAddressList.eventStartDateTime =
            this.commonService.getConvertedDateFormat(this.deliveryDate, this.deliveryToTime);
        if (checkTenderTimeLimit) {
            this.onChecktTenderTime();
        }
    }

    onExpandSection(): void {
        if (this.shipperForm.valid && this.consigneeForm.valid) {
            this.expandForm = !this.expandForm;
            this.addressAutoCollapse = false;
        }
    }

    onTriggerAllReferenceSettings(): void {
        this.commonService.sendReferenceSettingStatus(CommonConstants.referenceSettingCode.allCode);
    }

    resetAddressForms(): void {
        this.tenderTimeExceeded = false;
        this.disabledMinTenderCheck = false;
        this.shipperForm.resetForm();
        this.consigneeForm.resetForm();
        this.shipmentOriginFormComponent.zipCodeDropDownList = [];
        this.shipmentDestinationFormComponent.zipCodeDropDownList = [];
        this.expandForm = true;
        this.mappingOwnerId();
        this.shipmentOriginFormComponent.onCountryChange(CommonConstants.defaultCountryCode);
        this.shipmentDestinationFormComponent.onCountryChange(CommonConstants.defaultCountryCode);
        this.consigneeForm.form.controls.addressGroup.controls.consigneeCountryCode.setValue(CommonConstants.defaultCountryCode);
        this.shipperForm.form.controls.addressGroup.controls.shipperCountryCode.setValue(CommonConstants.defaultCountryCode);
        this.originAddressList.shipInstructions = null;
        this.originAddressList.updatedShipInstruction = null;
        this.destinationAddressList.receiveInstructions = null;
        this.destinationAddressList.updatedConsigneeInstruction = null;
        this.originAddressList.receiveInstructions = null;
        this.destinationAddressList.shipInstructions = null;
    }

    isRequired(): boolean {
        if (this.moduleName === CommonConstants.moduleName.bolOnly) {
            return false;
        }
        return true;
    }

    getShipperFormZipCleared() {
        return this.shipmentOriginFormComponent.zipCleared;
    }

    getConsigneeFormZipCleared() {
        return this.shipmentDestinationFormComponent.zipCleared;
    }

    onReceiveExpandClicked(data: boolean) {
        this.expandForm = data;
        this.addressAutoCollapse = false;
    }

    saveResidentialAddress(form: boolean, gateWay: string, listName: any, locationCode: string) {
        if (form && !this.shipmentSettingsModel.OnCreditHold) {
            switch (gateWay) {
                case 'shipperlocation':
                    if (this.originAddressList.isResidential === null || this.originAddressList.isResidential === undefined) {
                        this.commonService.checkLocation(listName).subscribe((res) => {
                            this.originAddressList.isResidential = res.results;
                            if (res.results) {
                                this.customToastrService.success(CommonConstants.shipmentBooking.residentialSuccessMessage);
                            } else {
                                if (res.message && !res.result) {
                                    this.customToastrService.success(res.message);
                                }
                            }
                        }, error => {
                            this.originAddressList.isResidential = error.error.results;
                        });
                    }
                    break;
                case 'consigneelocation':
                    if (this.destinationAddressList.isResidential === null || this.destinationAddressList.isResidential === undefined) {
                        this.commonService.checkLocation(listName).subscribe((res) => {
                            this.destinationAddressList.isResidential = res.results;
                            if (res.results) {
                                this.customToastrService.success(CommonConstants.shipmentBooking.residentialSuccessMessage);
                            } else {
                                if (res.message && !res.result) {
                                    this.customToastrService.success(res.message);
                                }
                            }
                            this.isLoading = false;
                        }, error => {
                            this.destinationAddressList.isResidential = error.error.results;
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        setTimeout(() => {
            this.commonService.interceptorSubject.next(false);
        }, 2000);
    }

    onAddressUpdate(listName: string) {
        switch (listName) {
            case 'shipperlocation':
                this.originAddressList.isResidential = null;
                this.checkResidentialFlag = true;
                break;
            case 'consigneelocation':
                this.destinationAddressList.isResidential = null;
                this.checkResidentialFlag = true;
                break;
        }
    }

    onCheckboxChange(event: boolean, name: string, formName: any) {
        if (formName === 'originAddressList') {
            switch (name) {
                case CommonConstants.residentialCheckboxStatus.residential:
                    this.originAddressList.isResidential = true;
                    this.residentialChecboxUpdated = true;
                    if (!event) {
                        this.originAddressList.isResidential = true;
                        this.checkResidentialFlag = true;
                    }
                    break;
                case CommonConstants.residentialCheckboxStatus.nonResidential:
                    this.originAddressList.isResidential = false;
                    this.residentialChecboxUpdated = true;
                    this.checkResidentialFlag = true;
                    break;
                default:
                    this.originAddressList.isResidential = null;
                    this.residentialChecboxUpdated = true;
                    this.checkResidentialFlag = true;
                    break;
            }
        } else {
            switch (name) {
                case CommonConstants.residentialCheckboxStatus.residential:
                    this.destinationAddressList.isResidential = true;
                    this.residentialChecboxUpdated = true;
                    break;
                case CommonConstants.residentialCheckboxStatus.nonResidential:
                    this.destinationAddressList.isResidential = false;
                    this.residentialChecboxUpdated = true;
                    this.checkResidentialFlag = true;
                    break;
                default:
                    this.destinationAddressList.isResidential = null;
                    this.residentialChecboxUpdated = true;
                    this.checkResidentialFlag = true;
                    break;
            }
        }
    }

    getConsigneeResidentialValue(checkBoxName: any) {
        return this.commonMethod.checkResidentialCheckbox(checkBoxName, this.destinationAddressList.isResidential);
    }

    getShipperResidentialValue(checkBoxName: any) {
        return this.commonMethod.checkResidentialCheckbox(checkBoxName, this.originAddressList.isResidential);
    }

    checkBOLonlyShipperForm() {
        if (this.originAddressList.address1 && this.originAddressList.locationName && this.originAddressList.city && this.originAddressList.stateName && this.originAddressList.countryCode && this.originAddressList.zipCode) {
            return true;
        }
        return false;
    }

    checkBOLonlyConsigneeForm() {
        if (this.destinationAddressList.address1 && this.destinationAddressList.locationName && this.destinationAddressList.city && this.destinationAddressList.stateName && this.destinationAddressList.countryCode && this.destinationAddressList.zipCode) {
            return true;
        }
        return false;
    }

    onSelectLocationCode(list: any, type: any) {
        const responseData = Object.assign({}, list);
        this.commonService.objectMapping(this.selectedLocation, responseData);
        delete this.selectedLocation.contacts;
        if (Array.isArray(responseData.contacts) && responseData.contacts.length) {
            const contacts = responseData.contacts.find(obj => obj.isDefault);
            if (contacts) {
                const locationId = this.selectedLocation.id;
                this.commonService.objectMapping(this.selectedLocation, contacts);
                this.selectedLocation.id = locationId;
                this.selectedLocation.contactId = contacts.id;
            }
        }
        const result = { addressResult: this.selectedLocation }
        switch (type) {
            case this.commonConstants.locationTypes.shipperLocation:
                this.shipperLocationCode = list;
                this.mapShipperLocation(result);
                break;
            case this.commonConstants.locationTypes.consigneelocation:
                this.consigneeLocationCode = list;
                this.mapConsigneeLocation(result);
                break;
            default:
                break;
        }
        this.commonMethod.sendBolInformationData(true);
        this.commonMethod.sendLocationRequiredServices(true);
        this.prepareDateFormats();
    }

    mapShipperLocation(result: any) {
        if (result.addressResult.latitude === 0 && result.addressResult.latitude === 0) {
            this.getGeoLocations(
                CommonConstants.addressType.originAddressList,
                result.addressResult.zipCode, result.addressResult.countryCode
            );
        }
        if (result.addressResult.shippingStartTime && result.addressResult.shippingEndTime) {
            this.pickupFromTime = new Date(result.addressResult.shippingStartTime);
            this.pickupToTime = new Date(result.addressResult.shippingEndTime);
        } else {
            this.pickupFromTime = this.commonService.setDeafultFromTime();
            this.pickupToTime = this.commonService.setDeafultToTime();
        }
        this.originLocationCode = result.addressResult.locationCode;
        this.commonService.objectMapping(this.originAddressList, result.addressResult);
        this.triggerReferenceFactors(CommonConstants.addressType.originAddressList);
        if (!this.residentialAccess) {
            this.commonService.interceptorSubject.next(true);
        }
        if (!this.updateShipInstruction) {
            this.originAddressList.updatedShipInstruction = result.addressResult.shipInstructions;
        }
        this.saveResidentialAddress(true, this.commonConstants.locationTypes.shipperLocation, this.originAddressList, 'originLocationCode');
    }

    mapConsigneeLocation(result: any) {
        if (result.addressResult.latitude === 0 && result.addressResult.latitude === 0) {
            this.getGeoLocations(
                CommonConstants.addressType.destinationAddressList,
                result.addressResult.zipCode, result.addressResult.countryCode
            );
        }
        if (result.addressResult.receivingStartTime && result.addressResult.receivingEndTime) {
            this.deliveryFromTime = new Date(result.addressResult.receivingStartTime);
            this.deliveryToTime = new Date(result.addressResult.receivingEndTime);
        } else {
            this.deliveryFromTime = this.commonService.setDeafultFromTime();
            this.deliveryToTime = this.commonService.setDeafultToTime();
        }
        this.destinationLocationCode = result.addressResult.locationCode;
        this.commonService.objectMapping(this.destinationAddressList, result.addressResult);
        this.triggerReferenceFactors(CommonConstants.addressType.destinationAddressList);
        if (!this.residentialAccess) {
            this.commonService.interceptorSubject.next(true);
        }

        if (!this.updateRecieveInstruction) {
            this.destinationAddressList.updatedConsigneeInstruction = result.addressResult.receiveInstructions;
        }
        this.saveResidentialAddress(true, this.commonConstants.locationTypes.consigneelocation, this.destinationAddressList, 'destinationLocationCode');
    }

    onScrollHandler(addressType: any) {
        this.locationType = addressType;
        this.skipCount = 0;
        setTimeout(() => {
            if (this.locationType === this.commonConstants.addressType.originAddressList) {
                this.getShipperLocationByCode();
            }
            if (this.locationType === this.commonConstants.addressType.destinationAddressList) {
                this.getConsigneeLocationByCode();
            }
        });
    }

    getShipperLocationByCode() {
        if (this.shipperLocationCode) {
            this.locationList = [this.shipperLocationCode];
        } else {
            this.locationList = []
        }
        if (
            this.shipperScrollContainer &&
            this.shipperScrollContainer.panel &&
            this.shipperScrollContainer.panel.nativeElement
        ) {
            const element = this.shipperScrollContainer.panel.nativeElement;
            this.shipperScrollContainer.panel.nativeElement.addEventListener("scroll", (() => {
                const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
                if (atBottom && this.allLocationList) {
                    this.skipCount = this.skipCount + this.resultsPerPage;
                    this.getLocationList();
                }
            })
            );
        }
    }

    getConsigneeLocationByCode() {
        if (this.consigneeLocationCode) {
            this.locationList = [this.consigneeLocationCode];
        } else {
            this.locationList = []
        }
        if (
            this.consigneeScrollContainer &&
            this.consigneeScrollContainer.panel &&
            this.consigneeScrollContainer.panel.nativeElement
        ) {
            const element = this.consigneeScrollContainer.panel.nativeElement;
            this.consigneeScrollContainer.panel.nativeElement.addEventListener("scroll", (() => {
                const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
                if (atBottom && this.allLocationList) {
                    this.skipCount = this.skipCount + this.resultsPerPage;
                    this.getLocationList();
                }
            })
            );
        }
    }

    getLocationList() {
        const payload = {
            skipCount: this.skipCount,
            results: this.resultsPerPage,
            orderBy: null,
            sortByAscending: true,
            ownerId: this.commonService.getOwnerId(),
            locationCode: this.locationCode
        }
        this.commonService.getSearchAddressByLocationCode(payload).subscribe((response: any) => {
            if (response.items && response.items.length) {
                response.items.map((location) => {
                    if (this.skipCount == 0) {
                        this.locationList = response.items;
                    }
                    else {
                        this.locationList.push(location);
                    }
                })
            }
            else if (this.skipCount == 0) {
                this.locationList = response.items;
            }
            this.allLocationList = response.items;
        });
    }

    onChecktTenderTime() {
        if (this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            this.isLoading = true;
            const currentDateTime = moment();
            const getCurrentTime = currentDateTime.format("HH:mm");
            this.timeOutArray.push(setTimeout(() => {
                if (this.shipmentSettingsModel.LatestTenderTime && !this.tenderTimeExceeded && getCurrentTime > this.shipmentSettingsModel.LatestTenderTime) {
                    this.jumpToNextDay();
                } else {
                    this.onCheckTenderMinTime();
                }
            }, 3000))
        }
    }

    onCheckTenderMinTime() {
        if (this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            this.isLoading = true;
            const today = new Date()
            const pickFromDate = moment(today);
            const pickToDate = moment(this.pickupToTime);
            const endDate = pickToDate.format("HH:mm");
            const startDate = pickFromDate.format("HH:mm")
            const startTime = moment(startDate, 'HH:mm');
            const endTime = moment(endDate, 'HH:mm');
            const difference = endTime.diff(startTime);
            const duration = moment.duration(difference);
            const currentDateTime = moment();
            const getCurrentTime = currentDateTime.format("HH:mm");

            if (this.shipmentSettingsModel.LatestTenderTime && !this.tenderTimeExceeded && getCurrentTime > this.shipmentSettingsModel.LatestTenderTime) {
                this.jumpToNextDay();
            } else {
                if (!this.disabledMinTenderCheck && Number(this.shipmentSettingsModel.TenderMinimumTime) && duration.asHours() < Number(this.shipmentSettingsModel.TenderMinimumTime)) {
                    this.jumpToNextDay();
                } else {
                    this.prepareDateFormats();
                    this.isLoading = false;
                }
            }
        }
    }


    jumpToNextDay() {
        const todaysDate = new Date();
        const formattedDateObject = moment(todaysDate).format('YYYY-MM-DD');
        const pickupDate = this.commonService.convertDate(this.pickupDate);
        const deliveryDate = this.commonService.convertDate(this.deliveryDate);
        if (formattedDateObject == pickupDate && this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            const getPickUpdate = new Date(this.pickupDate);
            const getDeliveryDate = new Date(this.deliveryDate);
            let jumpDeliveryDate;

            switch (getPickUpdate.getDay()) {
                case 0:
                    this.jumpToNewDate = moment(getPickUpdate).add(1, 'days').toString();
                    this.pickupDate = new Date(this.jumpToNewDate);
                    jumpDeliveryDate = moment(getDeliveryDate).add(1, 'days').toString();
                    this.deliveryDate = new Date(jumpDeliveryDate);
                    break;
                case 5:
                    this.jumpToNewDate = moment(getPickUpdate).add(3, 'days').toString();
                    this.pickupDate = new Date(this.jumpToNewDate);
                    jumpDeliveryDate = moment(getDeliveryDate).add(3, 'days').toString();
                    this.deliveryDate = new Date(jumpDeliveryDate);
                    break;
                case 6:
                    this.jumpToNewDate = moment(getPickUpdate).add(2, 'days').toString();
                    this.pickupDate = new Date(this.jumpToNewDate);
                    jumpDeliveryDate = moment(getDeliveryDate).add(2, 'days').toString();
                    this.deliveryDate = new Date(jumpDeliveryDate);
                    break;
                default:
                    this.jumpToNewDate = moment(getPickUpdate).add(1, 'days').toString();
                    this.pickupDate = new Date(this.jumpToNewDate);
                    jumpDeliveryDate = moment(getDeliveryDate).add(1, 'days').toString();
                    this.deliveryDate = new Date(jumpDeliveryDate);
                    break;
            }
            this.prepareDateFormats();
            if (this.commonService.convertDate(this.deliveryDate) < this.commonService.convertDate(this.pickupDate)) {
                this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.setErrors({ incorrect: true });
            } else {
                this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.setErrors(null);
            }
            this.consigneeForm.form.controls.destinationAddressGroup.controls.consigneedate.markAsTouched();
            this.isLoading = false;
            this.tenderTimeExceeded = true;
            this.disabledMinTenderCheck = true;
        } else {
            this.isLoading = false;
        }
    }

    checkModuleName() {
        if (this.moduleName === this.commonConstants.moduleName.scheduleShipment) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.timeOutArray.map((timeOutId) => {
            clearTimeout(timeOutId);
        });
    }

}
