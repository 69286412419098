import { Directive, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[buttonBusy]'
})

export class ButtonBusyDirective implements OnInit, AfterViewInit {

    @Input() set buttonBusy(isBusy: boolean) {
        this.refreshState(isBusy);
    }

    @Input() busyText: string;

    private button: any;
    private originalButtonInnerHtml: any;

    constructor(
        private element: ElementRef) {
    }

    ngOnInit(): void {
        this.button = this.element.nativeElement;
    }

    ngAfterViewInit(): void {
        this.originalButtonInnerHtml = this.button.innerHTML;
    }

    refreshState(isBusy: boolean): void {
        if (!this.button) {
            return;
        }
        if (isBusy) {
            // disable button
            this.button.setAttribute('disabled', 'disabled');
            this.button.innerHTML = this.busyText ? this.busyText : 'Processing...';
            this.button.setAttribute('_disabledBefore', true);
        } else {
            if (!this.button.getAttribute('_disabledBefore')) {
                return;
            }
            // enable button
            this.button.removeAttribute('disabled');
            this.button.innerHTML = this.originalButtonInnerHtml;
        }
    }

}
