export class CustomPatterns {
    numeric = '^[0-9]';
    onlyAlpha = '^[a-zA-Z]*';
    name = '^[a-zA-Z0-9]{1}?[a-zA-Z0-9-_ ]*';
    twoDecimalPoints = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
    totalValueDecimalPoints = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    address = '^[a-zA-Z0-9]{1}?[a-zA-Z0-9-_., ]*';
    phoneNumber = '^(?!-)(?!.*--)(?=.*[0-9]{1,10})[- +()0-9 .]+$';
    phoneExtension = '^(?!-)(?!.*--)[A-Za-z0-9{1,5} .-]+$';
    email = "^[a-zA-Z0-9._%!#$&*'?`/=^{|}~+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$";
    specialCharName = '^[a-zA-Z0-9]{1}?[a-zA-Z0-9-_"/ ]*';
    specialChars = '^[^-]{1}?[^]*$';
    fiveDecimalPoints = '\\-?\\d*\\.?\\d{1,5}';
    threeDecimalPoints = '\\-?\\d*\\.?\\d{1,3}';
    alphaNumericPassword = '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$';
    sixDecimalPoints = '\\-?\\d*\\.?\\d{0,6}';
    password = /^\s*(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#&$!^*%\-\_])[\w@#&$!^*%-_]{6,50}\s*$/;
    allSpecialChars = '^[a-zA-Z0-9]{1}?[a-zA-Z0-9\x22\x27-.,:;_+=<>/}{)(@#&$!^*%~`\\?\\[|/\\]\\\\ ]*';
    zipCode = /^(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/;
    numberBracesAmp = '^[0-9,()&]*$';
    website = '^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$';
    alphaNumericSingleAsterisk = /^(?!(?:.*\*){2})[A-Za-z\d*]+$/;
    alphaNumerichypen = /^[\A-Za-z\d-]+$/;
    classDensityBreakPattern = /^\(\d+(?:\.\d+)?,\d+(?:\.\d+)?\)(?:,\(\d+(?:\.\d+)?,\d+(?:\.\d+)?\))*$/;
}
