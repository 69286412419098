import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-shipment-status-form',
    templateUrl: './shipment-status-form.component.html',
    styleUrls: ['./shipment-status-form.component.scss']
})

export class ShipmentStatusFormComponent implements OnInit {

    @ViewChild('statusForm', { static: true }) statusForm: any;
    commonDataModel = new CommonDataModel();
    shipmentStatusFormModel = this.commonDataModel.shipmentStatusFormModel;
    statusDropDownList: Array<object> = [];
    searchBoxStatus: string;
    isLoading = false;
    statusDate = null;
    statusTime = null;
    formSubmit = false;
    statusDropDownValue = null;
    todayDate = this.commonService.setTodayDate();
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ShipmentStatusFormComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.shipmentStatusFormModel.shipmentDetails = data;
    }

    ngOnInit() {
        this.shipmentStatusFormModel.ownerId = this.commonService.getOwnerId();
        this.shipmentStatusFormModel.ownerName = this.commonService.getOwnerName();
        this.loadItems();
    }

    loadItems(): void {
        this.isLoading = true;
        this.commonService.getStatusDropDownList()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.statusDropDownList = response;
            });
    }

    onStatusChange(): void {
        this.shipmentStatusFormModel.status = this.statusDropDownValue.value;
        this.shipmentStatusFormModel.statusCode = this.statusDropDownValue.code;
    }

    onStatusTimeChange(): void {
        this.formSubmit = this.statusTime ? false : true;
    }

    onSubmit(form: boolean): void {
        this.onStatusTimeChange();
        if (form) {
            this.isLoading = true;
            this.formSubmit = false;
            const statusDate = this.commonService.convertScheduleDates(this.statusDate, this.statusTime);
            this.shipmentStatusFormModel.statusDateTime = statusDate;
            this.commonService.postShipmentStatus(this.shipmentStatusFormModel)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    if (response.isSuccess && !response.results) {
                        this.customToastrService.success(this.shipmentStatusFormModel.shipmentDetails.length +
                            CommonConstants.trackingGrid.statusSuccessMessage);
                    } else if (response.message) {
                        this.customToastrService.error(response.message);
                    }
                    this.dialogRef.close({ data: response.results, confirmResult: true });
                }, error => {
                    this.onClose();
                });
        }
    }

    onClose() {
        this.dialogRef.close({ data: null, confirmResult: false });
    }

}
