import { ScheduleType, ReportFormat, MoveType, ReportDownloadTypeSetting, ShipmentDateType } from './report-enum';
import { CommonDataModel } from '../shared/common-data-model';
import { ReportConstants } from 'src/app-core/constants/report-constants';

export class ReportsDataModel {

    commonDataModel = new CommonDataModel();

    public gridEvents = {
        skipCount: null,
        results: null,
        orderBy: null,
        sortOrder: null,
        filterFields: null
    };

    public gridSettingsModel = {
        definition: {
            id: null,
            definitionId: null,
            userId: null,
            definitionName: null,
            definitionShortName: null,
            gridName: null,
            sortByColumn: null,
            sortByAscending: true,
            allowToDelete: false,
            allowToEdit: false,
            groups: [],
            columns: [],
            filters: [],
            isDefault: false,
            isPublic: false,
            isActive: true,
            tenantId: null
        },
        ownerId: null,
        associatedOwnerId: null,
        isActive: true,
        userId: null,
        skipCount: null,
        results: null,
        userTimeZone: null
    };

    public itemList = {
        reportJobId: null,
        jobTitle: null,
        frequency: null,
        nextSchedule: null,
        createdBy: null,
        createdOn: null,
        format: null,
        emailList: null,
        actions: null
    };

    public moveType = [
        { name: 'inbound', isSelected: false, text: 'Inbound', value: MoveType.inbound },
        { name: 'outbound', isSelected: false, text: 'Outbound', value: MoveType.outbound },
        { name: 'drop', isSelected: false, text: 'Drop', value: MoveType.drop },
        { name: 'shuttle', isSelected: false, text: 'Shuttle', value: MoveType.shuttle },
    ];

    public ShipmentDateTypeModel = [
        { id: ShipmentDateType.booked, value: ReportConstants.shipmentReportDateType.booked },
        { id: ShipmentDateType.schedulePickup, value: ReportConstants.shipmentReportDateType.schedulePickup },
        { id: ShipmentDateType.pickup, value: ReportConstants.shipmentReportDateType.pickup }
    ];

    public configureReportFormModel = {
        ownerId: null,
        ownerName: null,
        reportType: null,
        reportTypeId: null,
        moveType: [],
        referenceTypes: [],
        expectedDeliveryFromDate: null,
        expectedDeliveryToDate: null,
        showTimeLine: false,
        includeCharge: false,
        includeItemDetails: false,
        cascadeDown: false,
        showOnlyLate: false,
        lateDays: 0,
        daysAfterDelivery: null,
        reportFormat: null,
        reportFormatId: null,
        reportSourceId: null,
        expectedDeliveryBefore: null,
        expectedDeliveryAfter: null,
        expectedDeliveryTypeId: null,
        shipmentDateType: ShipmentDateType.booked,
        dateBefore: null,
        dateAfter: null,
        dateFrom: null,
        dateTo: null,
        reportFileName: null
    };

    public reportDetails = {
        reportScheduleType: [
            { name: 'downloadNow', value: ScheduleType.downloadNow, text: 'Download Now', disabled: false },
            { name: 'scheduleReport', value: ScheduleType.schedule, text: 'Schedule Report', disabled: false }
        ],
        format: [
            { name: 'pdf', value: ReportFormat.pdf, text: 'PDF', type: ReportDownloadTypeSetting.type_Pdf },
            { name: 'xls', value: ReportFormat.excel_xls, text: '.xls', type: ReportDownloadTypeSetting.type_Xls_Xlsx },
            { name: 'xlsx', value: ReportFormat.xlsx, text: '.xlsx', type: ReportDownloadTypeSetting.type_Xls_Xlsx }
        ],
    };

    public shipmentReportModel = {
        bookedDateBefore: null,
        bookedDateAfter: null,
        schedulePickupDateBefore: null,
        schedulePickupDateAfter: null,
        pickupDateBefore: null,
        pickupDateAfter: null,
        bookedDaysBefore: null,
        bookedDaysAfter: null,
        schedulePickupDaysBefore: null,
        schedulePickupDaysAfter: null,
        pickupDaysBefore: null,
        pickupDaysAfter: null,
        relativeDaysType: 1
    };

    public monthlyDropdownList = [
        { value: 'first', viewValue: 'First' },
        { value: 'second', viewValue: 'Second' },
        { value: 'third', viewValue: 'Third' },
        { value: 'fourth', viewValue: 'Fourth' },
        { value: 'last', viewValue: 'Last' }
    ];

    public monthlyDropdownWeekList = [
        { value: 'monday', viewValue: 'Monday' },
        { value: 'tuesday', viewValue: 'Tuesday' },
        { value: 'wednesday', viewValue: 'Wednesday' },
        { value: 'thursday', viewValue: 'Thursday' },
        { value: 'friday', viewValue: 'Friday' },
        { value: 'saturday', viewValue: 'Saturday' },
        { value: 'sunday', viewValue: 'Sunday' },
        { value: 'day', viewValue: 'Day' },
        { value: 'weekday', viewValue: 'Weekday' },
        { value: 'weekend', viewValue: 'Weekend-Day' },
    ];

    public weeklylist = [
        { value: 'saturday', viewValue: 'S' },
        { value: 'sunday', viewValue: 'S' },
        { value: 'monday', viewValue: 'M' },
        { value: 'tuesday', viewValue: 'T' },
        { value: 'wednesday', viewValue: 'W' },
        { value: 'thursday', viewValue: 'T' },
        { value: 'friday', viewValue: 'F' }
    ];

    public occuranceDayOptions = [
        { displayText: '2', value: 2 },
        { displayText: '3', value: 3 },
        { displayText: '4', value: 4 }
    ];

    public reportDownloadFormModel = {
        downloadType: null,
    };

    public scheduleReportFormModel = {
        reportId: null,
        reportJobId: null,
        name: null,
        description: null,
        nextExecutionTime: null,
        lastExecutionTime: null,
        isSendIfEmpty: false,
        frequencyTypeId: null,
        occurence: null,
        scheduledDateTime: null,
        periodicityOccuranceId: null,
        reccursOnDay: null,
        ownerId: null,
        periodicity: [],
        isMonthlyOnDay: null,
        ReportFileName: null
    };

    public communicationDetails = {
        emailId: null,
        useFtp: false,
        ftpFilename: null,
        ftpUrl: null,
        ftpUsername: null,
        ftpPassword: null,
        ftpHost: null,
        ftpPort: null,
    };

    public reportFormModel = {
        userTimeZone: this.commonDataModel.userTimeZone,
        configureReport: this.configureReportFormModel,
        communicationDetails: this.communicationDetails,
        scheduleReport: this.scheduleReportFormModel,
        isFrequencyChanged: false
    };

    public formStatusModel = {
        configureReportForm: false,
        reportDetailsForm: false
    };

    public reportTimings = [{
        occuranceTimes: null,
        selectedTime: null
    }];

    public frequencyListModel = [
        { label: 'Once', value: 'once', isChecked: false },
        { label: 'Daily', value: 'daily', isChecked: false },
        { label: 'Weekly', value: 'weekly', isChecked: false },
        { label: 'Monthly', value: 'monthly', isChecked: false }
    ];

}
