import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-info',
    templateUrl: './services-info.component.html'
})

export class ServicesInfoComponent implements OnInit {

    serviceList: Array<object> = [];

    @Input() set childData(services: any) {
        this.serviceList = services;
    }

    constructor() {
    }

    ngOnInit() {
    }

}
