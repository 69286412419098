import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonDataModel } from '../common-data-model';

@Component({
    selector: 'app-print-document',
    templateUrl: './print-document.component.html',
    styleUrls: ['./print-document.component.scss']
})

export class PrintDocumentComponent implements OnInit, OnDestroy {
    @ViewChild('messageForm', { static: false }) messageForm: any;
    @Output() sendDocResponse = new EventEmitter<any>();
    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    shipmentTagModel = this.commonDataModel.shipmentTagModel;
    alertServiceModel = this.commonDataModel.alertServiceModel;
    fetchDocumentModel = this.commonDataModel.fetchDocumentModel;
    trackingDetailsGetModel = this.commonDataModel.trackingDetailsGetModel;
    documentOwnerSettingsModel = this.commonDataModel.documentOwnerSettingsModel;
    trackingDetailsModel = Object.assign({}, this.commonDataModel.trackingDetailsModel);
    paramId: string;
    isLoading = false;
    isDownloadButtonBusy = false;
    showEmailErrorMessage = false;
    publicTrackingUrl: string;
    moduleName: string;
    allDocumentList: Array<any> = [];
    pageName: string;
    browserDetails: any;
    showCost = false;
    isBolDocument = false;
    showFetchDocumentText = true;
    documentSettingName: any;
    bookingTypeName: any;
    documentsUrl: Array<any> = [];
    isOnHoldStatus = true;
    truckloadSummary = false;
    shipmentBOL: any;
    loadAlertServices = false;
    showAlertService = false;
    showAlertServiceForm = true;
    showShipmentTrackingNotesValue = true;
    purchaseLabels = [];
    shipmentMode: string;
    hideFetchDocument = false;
    shippingModeName: any;
    labelUrl: any;
    labelCreatedDate: any;
    isParcelShipment = false;
    truckloadSummaryValue = false;
    showParcelDocuments = false;
    loadLTLDocuments = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Output() getOSDStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() set pageReference(pageName: string) {
        this.pageName = pageName;
    }

    @Input() set moduleTypeValue(name: any) {
        if (name) {
            this.moduleName = name;
        }
    }

    @Input() set summary(name: any) {
        this.truckloadSummaryValue = name;
    }

    @Input() set showDocuments(value: any) {
        if (value == 'Communications') {
            this.loadLTLDocuments = false;
        } else {
            this.loadLTLDocuments = true;
        }
    }

    @Input() set checkSummaryPageType(summaryPage: any) {
        if (summaryPage) {
            this.isOnHoldStatus = !summaryPage;
            this.truckloadSummary = summaryPage;
        }
    }

    @Input() set bookingType(name: any) {
        if (name) {
            this.bookingTypeName = name;
        }
    }

    @Input() set shipmentStatus(status: any) {
        if (status === CommonConstants.trackingStatus.OnHold) {
            this.isOnHoldStatus = false;
        } else {
            this.isOnHoldStatus = true;
        }
    }

    @Input() set shippingMode(name: any) {
        if (name) {
            this.shippingModeName = name;
            this.isParcelShipment = this.shippingModeName === CommonConstants.moduleName.parcel ? true : false;
        }
    }

    @Input() set showAlertServices(value: any) {
        this.showAlertService = value;
    }

    @Input() set showAlertServicesFormInputs(value: any) {
        this.showAlertServiceForm = value;
    }

    @Input() set hideFetchDocumentBtn(value: boolean) {
        this.hideFetchDocument = value;
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
        this.fetchDocumentModel.ownerId = this.commonService.getOwnerId();
        this.trackingDetailsGetModel.ownerId = this.commonService.getOwnerId();
    }

    ngOnInit() {
        const moduleName = this.activeRoute.snapshot.queryParamMap.get('moduleName');
        const bookingType = this.activeRoute.snapshot.queryParamMap.get('bookingType');
        const shipmentMode = this.activeRoute.snapshot.queryParamMap.get('mode');
        const loadDocuments = this.activeRoute.snapshot.queryParamMap.get('loadDocuments');

        this.moduleName = moduleName ? moduleName : this.moduleName;
        this.bookingTypeName = bookingType ? bookingType : this.bookingTypeName;
        this.shipmentMode = shipmentMode === null ? this.shippingModeName : shipmentMode;
        this.showParcelDocuments = JSON.parse(loadDocuments);
        const purchaseLabelStr = this.activeRoute.snapshot.queryParamMap.get('parchaseLabels');
        this.purchaseLabels = JSON.parse(purchaseLabelStr);
        this.commonService.getPurchaseLabels(this.purchaseLabels);

        switch (this.moduleName) {
            case CommonConstants.moduleName.scheduleTruckload:
                this.documentSettingName = CommonConstants.documentSetting.truckLoadSetting;
                break;
            case CommonConstants.moduleName.scheduleShipment:
            case CommonConstants.moduleName.scheduleLTL:
                this.documentSettingName = CommonConstants.documentSetting.scheduleShipmentSetting;
                break;
            case CommonConstants.moduleName.LTLVendorBooking:
                this.documentSettingName = CommonConstants.documentSetting.ltlVendorSetting;
                break;
            case CommonConstants.moduleName.TLVendorBooking:
                this.documentSettingName = CommonConstants.documentSetting.tlVendorSetting;
                break;
            case CommonConstants.moduleName.shipmentTracking:
            case CommonConstants.moduleName.invoice:
                switch (this.bookingTypeName) {
                    case CommonConstants.moduleName.scheduleShipment:
                    case CommonConstants.moduleName.scheduleLTL:
                        this.documentSettingName = CommonConstants.documentSetting.scheduleShipmentSetting;
                        break;
                    case CommonConstants.moduleName.scheduleTruckload:
                        this.documentSettingName = CommonConstants.documentSetting.truckLoadSetting;
                        break;
                    case CommonConstants.moduleName.LTLVendorBooking:
                        this.moduleName = CommonConstants.moduleName.LTLVendorBooking;
                        this.documentSettingName = CommonConstants.documentSetting.ltlVendorSetting;
                        break;
                    case CommonConstants.moduleName.TLVendorBooking:
                        this.moduleName = CommonConstants.moduleName.TLVendorBooking;
                        this.documentSettingName = CommonConstants.documentSetting.tlVendorSetting;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        this.browserDetails = this.commonService.getBrowserDetails();
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            if (this.shipmentMode == 'Parcel' && this.purchaseLabels && this.purchaseLabels.length > 0) {
                this.paramId = '0';
                this.alertServiceModel.shipmentId = this.paramId;
                this.shipmentTagModel.shipmentId = this.paramId;
                this.fetchDocumentModel.shipmentId = this.paramId;
            } else {
                this.purchaseLabels = [];
                this.paramId = params.get('id');
                this.alertServiceModel.shipmentId = params.get('id');
                this.shipmentTagModel.shipmentId = params.get('id');
                this.fetchDocumentModel.shipmentId = params.get('id');
                this.publicTrackingUrl = this.commonService.getPublicTrackingUrl(params.get('id'));
            }
            this.loadItems();
        }));

        this.componentSubscriptions.add(this.commonService.getBOLnumber.subscribe((response: any) => {
            this.shipmentBOL = response;
        }));
    }

    loadItems(): void {
        this.isLoading = true;
        this.getShipmentTags();
        this.getVRShowCost();
        if (this.moduleName) {
            switch (this.moduleName) {
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.documentOwnerSettingsModel.AutoFetchDocuments = true;
                    break;
                case CommonConstants.moduleName.TLVendorBooking:
                    this.documentOwnerSettingsModel.AutoFetchDocuments = true;
                    break;
                default:
                    break;
            }
        }

        if (this.loadLTLDocuments) {
            this.commonService.getDocumentOwnerSettings(this.documentSettingName).subscribe(response => {
                this.loadDocumentSettings(response);
            });
        }
    }

    loadDocumentSettings(response: any): void {
        const settingsModel = this.commonDataModel.documentOwnerSettingsModel;
        let modulePrefix: string;
        switch (this.moduleName) {
            case CommonConstants.moduleName.LTLVendorBooking:
                modulePrefix = CommonConstants.modulePrefix.ltlVendor;
                break;
            case CommonConstants.moduleName.TLVendorBooking:
                modulePrefix = CommonConstants.modulePrefix.tlVendor;
                break;
            default:
                modulePrefix = CommonConstants.modulePrefix.scheduleShipment;
                break;
        }
        response.filter(list => {
            Object.keys(settingsModel).map((key) => {
                if (list.setting === `${modulePrefix}${key}`) {
                    settingsModel[key] = list.value;
                }
            });
        });
        if (settingsModel.DocumentsToAutoDownload) {
            const document = settingsModel.DocumentsToAutoDownload;
            const documentList = document.split(',');
            settingsModel.DocumentsToAutoDownload = [];
            documentList.forEach(element => {
                settingsModel.DocumentsToAutoDownload.push(element);
            });
            const dataIndex = settingsModel.DocumentsToAutoDownload.findIndex(
                list => list === CommonConstants.shipmentDocumentType.bol
            );
            if (dataIndex === -1) {
                settingsModel.DocumentsToAutoDownload.push(CommonConstants.shipmentDocumentType.bol);
                this.isBolDocument = true;
            }
            settingsModel.DocumentsToAutoDownload = _.sortBy(settingsModel.DocumentsToAutoDownload);
        } else {
            settingsModel.DocumentsToAutoDownload = [];
            settingsModel.DocumentsToAutoDownload.push(CommonConstants.shipmentDocumentType.bol);
            this.isBolDocument = true;
        }
        this.documentOwnerSettingsModel = settingsModel;
        if (this.documentOwnerSettingsModel.AutoFetchDocuments && this.shipmentMode !== 'Parcel') {
            this.onFetchDocuments();
        } else {
            this.isLoading = false;
            if (this.showParcelDocuments) {
                this.getAllDocuments();
            }
        }
    }

    getShipmentTags() {
        this.shipmentTagModel.tags = [];
        if (this.paramId && this.paramId !== '0') {
            switch (this.moduleName) {
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.commonService.getVendorShipmentTags(this.paramId).subscribe(response => {
                        if (response) {
                            response.map(item => {
                                this.shipmentTagModel.tags.push(item.tag);
                            });
                        }

                        if (!this.loadLTLDocuments) {
                            this.isLoading = false;
                        }
                    });
                    break;
                default:
                    this.commonService.getShipmentTags(this.paramId).subscribe(response => {
                        if (response) {
                            response.map(item => {
                                this.shipmentTagModel.tags.push(item.tag);
                            });
                        }

                        if (!this.loadLTLDocuments) {
                            this.isLoading = false;
                        }
                    });
                    break;
            }
        }
    }

    onSaveMessage(form: boolean): void {
        this.loadAlertServices = false;
        if (form && this.alertServiceModel.comment) {
            this.alertServiceModel.comment = this.alertServiceModel.comment.trim();
            this.isLoading = true;
            switch (this.moduleName) {
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.commonService.createVendorMessage(this.alertServiceModel)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.loadAlertServices = true;
                            this.customToastrService.success(CommonConstants.successMessageSent);
                        });
                    break;
                default:
                    this.commonService.createMessage(this.alertServiceModel)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.loadAlertServices = true;
                            this.customToastrService.success(CommonConstants.successMessageSent);
                            this.messageForm.resetForm();
                        });
                    break;
            }
        }
    }

    receiveEmailList(event: any) {
        this.shipmentTagModel.tags = event;
            this.showEmailErrorMessage = false;
    }

    onSaveEmailTags(): void {
            this.isLoading = true;
            this.showEmailErrorMessage = false;
            switch (this.moduleName) {
                case CommonConstants.moduleName.publicShipmentTracking:
                    this.commonService.postPublicUrlShipmentTag(this.shipmentTagModel).pipe(finalize(() => this.isLoading = false))
                        .subscribe(() => {
                            this.customToastrService.success(CommonConstants.printDocumentForm.emailSuccessMessage);
                            this.getShipmentTags();
                        });
                    break;
                case CommonConstants.moduleName.LTLVendorBooking:
                    this.commonService.postVendorShipmentTags(this.shipmentTagModel).pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.customToastrService.success(CommonConstants.printDocumentForm.emailSuccessMessage);
                            this.getShipmentTags();
                        });
                    break;
                default:
                    this.commonService.postShipmentTags(this.shipmentTagModel).pipe(finalize(() => this.isLoading = false))
                        .subscribe(response => {
                            this.customToastrService.success(CommonConstants.printDocumentForm.emailSuccessMessage);
                            this.getShipmentTags();
                        });
                    break;
            }
    }

    getDocumentUrls(documentList: any) {
        const Urls = [];
        documentList.map((document: any) => {
            if (document.isGenerated) {
                if (document.link)
                    Urls.push(document.link);
                else {
                    Urls.push(document.labelUrl);
                }
            }
        });
        return Urls;
    }

    onFetchDocuments(): void {
        this.documentsUrl = [];
        this.isLoading = true;
        if (this.paramId !== '0') {
            this.showFetchDocumentText = true;
            this.isLoading = true;
            this.commonService.getFetchDocuments(this.fetchDocumentModel)
                .pipe(finalize(() => {
                    this.isLoading = false;
                    this.showFetchDocumentText = false;
                }))
                .subscribe(response => {
                    if (Array.isArray(response) && response.length) {
                        this.allDocumentList = [];
                        this.allDocumentList = response;
                        if (Array.isArray(this.allDocumentList) && this.allDocumentList.length) {
                            this.documentsUrl = this.getDocumentUrls(this.allDocumentList);
                        }
                        this.checkAutoDownloadDocuments();
                    }
                });
        } else {
            this.isLoading = false;
            this.showFetchDocumentText = false;
            this.showFetchDocumentText = false;
            this.isLoading = false;
            this.allDocumentList = this.purchaseLabels;
            this.allDocumentList.map((document: any) => {
                document['isGenerated'] = true;
                this.documentsUrl = this.getDocumentUrls(this.allDocumentList);
            });
        }
    }

    getAllDocuments(): void {
        this.documentsUrl = [];
        if (this.purchaseLabels.length <= 0) {
            this.showFetchDocumentText = true;
            this.isLoading = true;
            this.commonService.getAllDocuments(this.fetchDocumentModel)
                .pipe(finalize(() => {
                    this.isLoading = false;
                    this.showFetchDocumentText = false;
                }))
                .subscribe(response => {
                    this.allDocumentList = [];
                    this.allDocumentList = response;
                    if (Array.isArray(response) && response.length) {
                        this.allDocumentList.map((document: any) => {
                            if (document.isGenerated) {
                                this.documentsUrl = this.getDocumentUrls(this.allDocumentList);
                            }
                        });
                        this.checkAutoDownloadDocuments();
                    }
                });

        } else if (this.purchaseLabels.length > 0) {
            this.isLoading = false;
            this.showFetchDocumentText = false;
            this.allDocumentList = this.purchaseLabels;
            this.allDocumentList.map((document: any) => {
                document['showDocument'] = true;
                this.documentsUrl = this.getDocumentUrls(this.allDocumentList);
            });
            this.checkAutoDownloadDocuments();
        }
    }

    getVRShowCost() {
        if (this.paramId && this.paramId !== '0') {
            this.allDocumentList = [];
            this.trackingDetailsGetModel.shipmentId = this.paramId;
            if (this.shipmentMode === this.commonConstants.moduleName.parcel || this.shippingModeName === this.commonConstants.moduleName.parcel) {
                this.commonService.getParcelTrackingDetails(this.trackingDetailsGetModel).subscribe(response => {
                    this.getOSDStatus.emit(response.osdStatus);
                    this.commonService.objectMappingNullCheck(this.trackingDetailsModel, response);
                    this.showCost = this.trackingDetailsModel.carrierInfo ? this.trackingDetailsModel.carrierInfo.vrShowCost : false;
                    if (response.shipmentMode === CommonConstants.moduleName.parcel) {
                        this.shippingModeName = response.shipmentMode;
                        this.labelUrl = response.purchaseLabels[0];
                        const obj = {
                            displayType: 'Label',
                            createdOn: this.labelUrl.labelCreatedDate,
                            isGenerated: true,
                            labelUrl: this.labelUrl.labelUrl,
                            showDocument: true,
                            packageName: this.labelUrl.packageName
                        }
                        const documentList = [];
                        documentList.push(obj);
                        this.allDocumentList = documentList;
                        this.labelCreatedDate = this.labelUrl.labelCreatedDate;
                        this.isParcelShipment = true;
                    }
                });
            } else {
                this.commonService.getTrackingDetails(this.trackingDetailsGetModel).subscribe(response => {
                    this.getOSDStatus.emit(response.osdStatus);
                    this.commonService.objectMappingNullCheck(this.trackingDetailsModel, response);
                    this.showCost = this.trackingDetailsModel.carrierInfo ? this.trackingDetailsModel.carrierInfo.vrShowCost : false;
                    if (response.shipmentMode === CommonConstants.moduleName.parcel) {
                        this.shippingModeName = response.shipmentMode;
                        this.labelUrl = response.labelUrl;
                        const obj = {
                            displayType: 'Label',
                            createdOn: this.labelUrl.labelCreatedDate,
                            isGenerated: true,
                            labelUrl: this.labelUrl,
                            showDocument: true
                        }
                        const documentList = [];
                        documentList.push(obj);
                        this.allDocumentList = documentList;
                        this.labelCreatedDate = this.labelUrl.labelCreatedDate;
                        this.isParcelShipment = true;
                    }
                });
            }
        }
    }

    checkAutoDownloadDocuments(): void {
        if (this.pageName === CommonConstants.pageName.shipmentDocument) {

            if (this.purchaseLabels.length > 0) {
                this.downloadPurchaselabels();
            }
            else {
                if (Array.isArray(this.documentOwnerSettingsModel.DocumentsToAutoDownload) &&
                    this.documentOwnerSettingsModel.DocumentsToAutoDownload.length) {
                    this.documentOwnerSettingsModel.DocumentsToAutoDownload.filter(parentList => {
                        this.allDocumentList.filter(list => {
                            list.showDocument = true;
                            if (list.displayType === parentList) {
                                if (this.showQuoteAndSummary(list.displayType)) {
                                    if (list.isGenerated) {
                                        if (list.displayType !== CommonConstants.shipmentDocumentType.bol) {
                                            this.downloadDocument(list.link);
                                        } else if (list.displayType === CommonConstants.shipmentDocumentType.bol && !this.isBolDocument) {
                                            this.downloadDocument(list.link);
                                        } else {
                                        }
                                    }
                                } else {
                                    list.showDocument = false;
                                }
                            } else if (!this.showQuoteAndSummary(list.displayType)) {
                                list.showDocument = false;
                            }
                        });
                    });
                    const data = this.allDocumentList.filter(list => list.showDocument);
                    this.sendDocResponse.emit(data);
                }
            }
        } else {
            this.allDocumentList.filter(list => {
                if (this.showQuoteAndSummary(list.displayType)) {
                    list.showDocument = true;
                } else {
                    list.showDocument = false;
                }
            });
        }
    }
    downloadPurchaselabels() {
        this.purchaseLabels.filter(list => {
            this.downloadDocument(list.labelUrl, list.packageName);
        });
        this.sendDocResponse.emit(this.allDocumentList);
    }

    downloadDocument(fileLink: string, item?: any): void {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            this.browserPopupCheck(fileLink);
        } else {
            switch (this.browserDetails[1]) {
                case CommonConstants.browserName.chrome:
                case CommonConstants.browserName.edge:
                case CommonConstants.browserName.firefox:
                    if (this.shipmentMode === 'Parcel') {
                        const documentUrl = { labelUrl: item.labelUrl, packageName: item.packageName };
                        if (item.labelUrl) {
                            this.commonService.downloadPurchaseLabel(documentUrl)
                                .pipe(finalize(() => this.isLoading = false))
                                .subscribe(response => {
                                    if (this.checkFileType(item.labelUrl)) {
                                        const blob = new Blob([response.body as any], { type: 'application/pdf' });
                                        const fileURL = URL.createObjectURL(blob);
                                        this.browserPopupCheck(fileURL);
                                    } else {
                                        this.browserPopupCheck(item.labelUrl);
                                    }
                                }, error => {
                                    this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                                    this.browserPopupCheck(item.labelUrl);
                                });
                        }
                    } else {
                        const documentUrl = { url: fileLink };
                        this.commonService.downloadShipmentDocument(documentUrl)
                            .pipe(finalize(() => this.isLoading = false))
                            .subscribe(response => {
                                if (this.checkFileType(fileLink)) {
                                    const blob = new Blob([response.body as any], { type: 'application/pdf' });
                                    const fileURL = URL.createObjectURL(blob);
                                    this.browserPopupCheck(fileURL);
                                } else {
                                    this.browserPopupCheck(fileLink);
                                }
                            }, error => {
                                this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                                this.browserPopupCheck(fileLink);
                            });
                    }

                    break;
                default:
                    this.browserPopupCheck(fileLink);
                    break;
            }
        }
    }

    async onDownloadAll() {
        this.isDownloadButtonBusy = true;
        const name = 'Documents.zip';
        if (this.shippingModeName === 'Parcel' || this.shipmentMode === 'Parcel') {
            const documentsToDownload = [];
            if (this.allDocumentList.length) {
                this.allDocumentList.map((document: any) => {
                    documentsToDownload.push({ labelUrl: document.labelUrl, packageName: document.packageName ? document.packageName : 'Item' })

                });
            } else {
                this.purchaseLabels.map((document: any) => {
                    documentsToDownload.push({ labelUrl: document.labelUrl, packageName: document.packageName ? document.packageName : 'Item' })

                });
            }

            this.commonService.downloadAllPurchaseLabels(documentsToDownload)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(response => {
                    try {
                        FileSaver.saveAs(response.body, name);
                        this.isDownloadButtonBusy = false;
                    } catch (err) {
                        this.isDownloadButtonBusy = false;
                        this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                    }
                }, error => {
                    this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                });
        }
        else {
            if (this.documentsUrl.length) {
                this.commonService.downloadAllShipmentDocument(this.documentsUrl)
                    .pipe(finalize(() => this.isLoading = false))
                    .subscribe(response => {
                        try {
                            FileSaver.saveAs(response.body, name);
                            this.isDownloadButtonBusy = false;
                        } catch (err) {
                            this.isDownloadButtonBusy = false;
                            this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                        }
                    }, error => {
                        this.customToastrService.error(CommonConstants.printDocumentForm.fileDownloadErrorMessage);
                    });
            } else {
                this.isDownloadButtonBusy = false;
            }
        }
    }

    browserPopupCheck(url: string) {
        const browserWindow = window.open(url, '_blank');
        if (browserWindow === null) {
            this.customToastrService.error(CommonConstants.printDocumentForm.browserPopupErrorMessage);
        }
    }

    showQuoteAndSummary(displayType: string) {
        if (displayType.toLowerCase() !== CommonConstants.shipmentDocumentType.quote.toLowerCase() &&
            displayType.toLowerCase() !== CommonConstants.shipmentDocumentType.summary.toLowerCase()) {
            return true;
        }
        return !this.isVendorModule() || this.isVendorModule() && this.showCost;
    }

    isVendorModule() {
        return this.moduleName === CommonConstants.moduleName.LTLVendorBooking;
    }

    checkFileType(url: any) {
        if (this.purchaseLabels.length <= 0)
            return (url.match(/\.(pdf)$/) != null);
        else {
            return true;
        }
    }

    showDownloadButton(data: any) {
        if (data && data.length) {
            const lableGenerated = data.every((x) => x.labelUrl === null);
            return lableGenerated;
        } else {
            return true;
        }
    }

    showDownload(list: any) {
        if (this.shipmentMode === 'Parcel') {
            if (list.hasOwnProperty('labelUrl') && list.labelUrl) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
