export class ReportConstants {

    static readonly frequencyTypes = {
        once: 'Once',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: ' Monthly'
    };

    static readonly reportSource = {
        customerPortal: 'CustomerPortal'
    };

    static readonly expectedDeliveryType = {
        relativeDays: 'Relative Days',
        lastMonth: 'Last Month',
        lastYear: 'Last Year'
    };

    static readonly reportTypes = {
        tracking: 'Tracking',
        shipment: 'Shipment'
    };

    static readonly shipmentReportDateType = {
        booked: 'Booked',
        schedulePickup: 'SchedulePickup',
        pickup: 'Pickup'
    };

    static readonly reportIcons = {
        trackingActive: 'assets/images/tracking-outline-icon.svg',
        trackingInActive: 'assets/images/tracking-outline-icon.svg',
        shipmentActive: 'assets/images/shipment-report-icon.svg',
        shipmentInActive: 'assets/images/shipment-report-icon.svg'
    };

    static readonly reportForm = {
        enable: 'Enable',
        disable: 'Disable',
        enabled: 'enabled',
        enabling: 'enabling',
        disabling: 'disabling',
        newReport: 'New Report',
        editReport: 'Edit Report',
        ofReports: ' of report(s)?',
        scheduleReport: 'Schedule Report',
        activityReport: 'ActivityReport',
        generateAndDownload: 'Generate & Download',
        reportScheduledSuccessMessage: 'Report has been successfully scheduled.',
        reportEditedSuccessMessage: 'Report has been successfully edited.',
        deleteFormSuccessMessage: 'Report deleted successfully.',
        updateFormSuccessMessage: 'Report updated successfully.',
        enableReportPopupTitle: 'Do you want to proceed with the ',
        deleteReportPopupTitle: 'Do you want to delete this report?',
        deleteRecordsPopupTitle: 'Do you want to proceed with the deletion of selected report(s)?',
        timeAdjustWarningMessage: 'The time has been adjusted. Please review before proceeding.',
        timeAdjustErrorMessage: 'The time selected should be a time at least 5 min ahead of the current time.'
    };

}
