import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app-core/services/common.service';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from '../../common-data-model';

@Component({
    selector: 'app-history-tab',
    templateUrl: './history-tab.component.html',
    styleUrls: ['./history-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HistoryTabComponent implements OnInit, OnDestroy {
    commonDataModel = new CommonDataModel();
    componentSubscriptions: Subscription = new Subscription();
    isLoading = false;
    shipmentId: string;
    historyList: Array<object> = [];
    shippingModeName: any;
    shipmentSettingsModel = Object.assign({}, this.commonDataModel.shipmentSettingsModel);
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set shippingMode(name: any) {
        if (name) {
            this.shippingModeName = name;
        }
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.shipmentId = params.get('id');
            this.loadItems();
        }));
    }

    loadItems(): void {
        this.isLoading = true;

        switch (this.shippingModeName) {
            case CommonConstants.moduleName.parcel:
                this.getShippoSettings();
                setTimeout(() => {
                    this.commonService.getParcelStatusHistory(this.shipmentId).subscribe(response => {
                        this.historyList = response.results;
                        this.isLoading = false;
                    });
                }, 2000)
                break;
            default:
                this.commonService.getHistoryInfo(this.shipmentId).subscribe(response => {
                    this.historyList = response;
                    this.isLoading = false;
                });
                break;
        }
    }

    onTrackInfo() {
        this.isLoading = true;
        this.commonService.getShipmentStatus.next(true);
        this.commonService.getTrackShipment(this.shipmentId).subscribe(response => {
            this.historyList = response.results ? response.results : [];
            this.isLoading = false;
        });
    }

    displayTrackButton() {
        return this.shippingModeName === CommonConstants.moduleName.parcel;
    }


    getShippoSettings() {
        this.commonService.getOwnerSettings(CommonConstants.moduleName.scheduleShipment).subscribe((response: any) => {
            const settingsModel = this.commonDataModel.shipmentSettingsModel;
            response.filter(list => {
                Object.keys(settingsModel).map((key) => {
                    if (list.setting === `${CommonConstants.modulePrefix.scheduleShipment}${key}`) {
                        settingsModel[key] = list.value;
                    }
                });
            });

            this.shipmentSettingsModel = settingsModel;
            if (this.shipmentSettingsModel.ShippoTestToken) {
                this.shipmentSettingsModel.ShippoBearerToken = this.shipmentSettingsModel.ShippoTestToken;
            }

            const shippoTokenDetails = { 'access_token': this.shipmentSettingsModel.ShippoBearerToken };
            this.commonService.setShippoAccessTokenData(shippoTokenDetails);
        })
    }

    showTimeZone(value: any) {
        if (value == 'UTC') {
            return value;
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

